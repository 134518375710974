import { IFrameProps } from 'shared/types';

const FacebookIFrame = (props: IFrameProps): JSX.Element => {
  const { src, ...iframeProps } = props;

  // Facebook will resize this automatically for any width that's under this count, so we will just set it to an extremely larger 16/9 ratio
  const pxWidth = 1920000;
  const pxHeight = 1080000;
  const urlEncodedSrc = encodeURI(src);
  const fullSrc = `https://www.facebook.com/plugins/video.php?href=${urlEncodedSrc}&width=${pxWidth}&height=${pxHeight}&show_text=false&t=0`;

  return (
    <iframe
      src={fullSrc}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...iframeProps}
      allowFullScreen={false} // this doesn't work on iOS, but it's necessary for Android as the back button from fullscreen goes back in the app as well
      allow="clipboard-write; encrypted-media; picture-in-picture; web-share"
      title="Facebook Video"
      scrolling="no"
    />
  );
};

export default FacebookIFrame;
