import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { localStorageEffect } from './localStorage';

type SearchDrawerState = {
  open: boolean;
};

type UseSearchDrawerState = {
  open: boolean;
  toggleOpen: () => void;
};

const DEFAULT_SEARCH_DRAWER_STATE: SearchDrawerState = {
  open: true
};

const searchDrawerStateAtom = atom({
  key: 'SEARCH_DRAWER_STATE',
  default: DEFAULT_SEARCH_DRAWER_STATE,
  effects: [localStorageEffect('SEARCH_DRAWER_STATE')]
});

const useSearchDrawerState = (): UseSearchDrawerState => {
  const [searchDrawerState, setSearchDrawerState] = useRecoilState(
    searchDrawerStateAtom
  );

  const toggleOpen = useCallback(() => {
    setSearchDrawerState((prevState) => ({ open: !prevState.open }));
  }, [setSearchDrawerState]);

  return {
    open: searchDrawerState.open,
    toggleOpen
  };
};

export default useSearchDrawerState;
