import { useCallback } from 'react';
import { Resetter, atom, useRecoilState, useResetRecoilState } from 'recoil';
import { LOCAL_STORAGE_KEY } from './localStorage';

type MessageType = 'default' | 'success' | 'error';

type SnackbarState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: any;
  type: MessageType;
  showMessageMs?: number | null;
};

type UseSnackbarState = {
  setSnackbar: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    message: any,
    type?: MessageType,
    showMessageMs?: number | null
  ) => void;
  snackbar: SnackbarState;
  reset: Resetter;
};

export const DEFAULT_MSG = 'default';
export const SUCCESS_MSG = 'success';
export const ERROR_MSG = 'error';

const SNACKBAR_SHOW_MS = 6000;

const DEFAULT_SNACKBAR_STATE: SnackbarState = {
  message: '',
  type: ERROR_MSG,
  showMessageMs: SNACKBAR_SHOW_MS // set to null to keep open indefinitely
};

export const snackbarStateAtom = atom({
  key: LOCAL_STORAGE_KEY.SNACKBAR,
  default: DEFAULT_SNACKBAR_STATE
});

export const useSnackbarState = (): UseSnackbarState => {
  const resetSnackbarState = useResetRecoilState(snackbarStateAtom);
  const [snackbar, setSnackbar] = useRecoilState(snackbarStateAtom);

  const handleSnackbarSet = useCallback(
    (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      message: any,
      type: MessageType = DEFAULT_MSG,
      showMessageMs: number | null = SNACKBAR_SHOW_MS
    ) => {
      setSnackbar({
        message,
        type,
        showMessageMs
      });
    },
    [setSnackbar]
  );

  return {
    setSnackbar: handleSnackbarSet,
    snackbar,
    reset: resetSnackbarState
  };
};
