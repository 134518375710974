import { useCallback } from 'react';
import { Capacitor } from '@capacitor/core';
import differenceInDays from 'date-fns/differenceInDays';
import { GeoEvent } from 'shared/types';
import { useAuthState, useSupportUsBannerAdState } from 'state';
import { getLastPushNotificationTimestamp } from 'state/localStorage';
import { setNaiveISODateStringToUTC } from 'shared/utils';

type UseGeoEventBannerProps = {
  geoEvent?: GeoEvent;
};

type UseGeoEventBanner = {
  showBanner: boolean;
  handleClose: () => void;
};

const TWO_DAY_MS = 1000 * 60 * 60 * 24 * 2; // 2 days
const FIVE_MINUTES_MS = 1000 * 60 * 5; // 5 minutes

export const useGeoEventBanner = (
  props: UseGeoEventBannerProps
): UseGeoEventBanner => {
  const { geoEvent } = props;
  const {
    isSupporter,
    permissions: { isInternalUser }
  } = useAuthState();
  const {
    getGeoEventBannerLastShownTimestamp,
    updateGeoEventBannerLastShownTimestamp
  } = useSupportUsBannerAdState();

  const lastShownTimestamp = geoEvent
    ? getGeoEventBannerLastShownTimestamp(geoEvent.id)
    : null;
  const lastPushNotificationTimestamp = getLastPushNotificationTimestamp();

  const getShowBanner = (): boolean => {
    if (!geoEvent) {
      return false;
    }

    if (isSupporter || isInternalUser) {
      return false;
    }

    if (lastShownTimestamp) {
      const daysDifference = Math.abs(
        differenceInDays(new Date(), new Date(lastShownTimestamp))
      );

      if (daysDifference < 7) {
        return false;
      }
    }

    if (!geoEvent.data.isPrescribed) {
      const createdAtMs = new Date(
        setNaiveISODateStringToUTC(geoEvent.dateCreated)
      ).getTime();

      const createdLessThan2DaysAgo = Date.now() - createdAtMs < TWO_DAY_MS;

      if (
        createdLessThan2DaysAgo &&
        (geoEvent.data.containment ?? 0) <= 40 &&
        !geoEvent.data.isFps
      ) {
        return false;
      }

      if (Capacitor.isNativePlatform()) {
        const pushNotifInLast5Mins =
          Date.now() - (lastPushNotificationTimestamp || 0) <= FIVE_MINUTES_MS;

        if (pushNotifInLast5Mins) {
          return false;
        }
      }
    }

    return true;
  };

  const handleClose = useCallback((): void => {
    if (!geoEvent) {
      return;
    }

    updateGeoEventBannerLastShownTimestamp(geoEvent.id);
  }, [geoEvent, updateGeoEventBannerLastShownTimestamp]);

  return { showBanner: getShowBanner(), handleClose };
};
