import { memo } from 'react';
import { Card, CardMedia, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { makeStyles } from 'tss-react/mui';

type ImagePreviewProps = {
  url: string;
  onDelete: () => void;
};

const useStyles = makeStyles()((theme) => ({
  imagePreview: {
    minHeight: 400,
    width: '100%',
    borderRadius: theme.shape.borderRadius * 2
  },
  deleteButton: {
    backgroundColor: theme.palette.common.black,
    borderRadius: theme.shape.borderRadius * 2,
    height: 42,
    width: 42,
    '&:hover': {
      backgroundColor: theme.palette.common.black
    },
    position: 'absolute',
    top: 16,
    right: 16
  }
}));

const ImagePreview = (props: ImagePreviewProps): JSX.Element => {
  const { url, onDelete } = props;
  const { classes } = useStyles();

  return (
    <Card sx={{ position: 'relative' }}>
      <CardMedia className={classes.imagePreview} image={url} />
      <IconButton
        color="primary"
        size="medium"
        aria-label="delete image"
        className={classes.deleteButton}
        onClick={onDelete}
      >
        <CloseIcon />
      </IconButton>
    </Card>
  );
};

export default memo(ImagePreview);
