import {
  Resetter,
  atomFamily,
  useRecoilState,
  useResetRecoilState
} from 'recoil';
import { localStorageEffect } from './localStorage';

type DrawerSnapPointState = {
  drawerHeight: number;
};

type UseDrawerSnapPointState = {
  drawerHeight: number;
  updateDrawerSnapPoint: (drawerHeight: number) => void;
  reset: Resetter;
};

const DEFAULT_DRAWER_SNAP_POINT_STATE: DrawerSnapPointState = {
  drawerHeight: 0
};

const drawerSnapPointStateAtom = atomFamily({
  key: 'DRAWER_SNAP_POINT_STATE',
  default: DEFAULT_DRAWER_SNAP_POINT_STATE,
  effects: [localStorageEffect('DRAWER_SNAP_POINT_STATE')]
});

const useDrawerSnapPointState = (drawerId: string): UseDrawerSnapPointState => {
  const resetDrawerSnapPointState = useResetRecoilState(
    drawerSnapPointStateAtom(drawerId)
  );
  const [drawerSnapPointState, setDrawerSnapPointState] = useRecoilState(
    drawerSnapPointStateAtom(drawerId)
  );

  const updateDrawerSnapPointState = (obj: DrawerSnapPointState): void =>
    setDrawerSnapPointState((prev) => ({ ...prev, ...obj }));

  const updateDrawerSnapPoint = (drawerHeight: number): void => {
    updateDrawerSnapPointState({ drawerHeight });
  };

  return {
    drawerHeight: drawerSnapPointState.drawerHeight,
    updateDrawerSnapPoint,
    reset: resetDrawerSnapPointState
  };
};

export default useDrawerSnapPointState;
