import { makeStyles } from 'tss-react/mui';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { green, blue, amber } from '@mui/material/colors';
import SnackbarContent from '@mui/material/SnackbarContent';
import { useSnackbarState } from 'state';

const useStyles = makeStyles()((theme) => ({
  content: {
    flexDirection: 'column',
    alignItems: 'start'
  },
  close: {
    padding: theme.spacing(0.5)
  },
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.main
  },
  info: {
    backgroundColor: blue[600]
  },
  warning: {
    backgroundColor: amber[700]
  }
}));

export default function SimpleSnackbar(): JSX.Element {
  const { classes, cx } = useStyles();

  const { snackbar, setSnackbar } = useSnackbarState();
  const { message, type, showMessageMs } = snackbar;

  const handleClose = (reason?: string): void => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar('');
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={Boolean(message)}
      autoHideDuration={showMessageMs}
      onClose={(_, reason) => handleClose(reason)}
    >
      <SnackbarContent
        className={cx(classes.content, classes[type])}
        message={message}
        action={[
          <IconButton
            key="close"
            color="inherit"
            onClick={() => handleClose()}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
}
