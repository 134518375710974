import { CircularProgress, Box, BoxProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import DownloadAppBanner from 'components/DownloadAppBanner';

type PageLayoutProps = Omit<BoxProps, 'children'> & {
  isLoading?: boolean;
  children: {
    content?: JSX.Element;
    headerBar?: JSX.Element;
  };
  showBanner?: boolean;
};

const useStyles = makeStyles()((theme) => {
  const { palette } = theme;

  return {
    appWrapper: {
      backgroundColor: palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0
    },
    containerWrapper: {
      display: 'flex',
      flex: 1,
      margin: 0,
      overflow: 'hidden',
      padding: 0
    }
  };
});

const PageLayout = (props: PageLayoutProps): JSX.Element => {
  const { children, isLoading, showBanner = true, ...otherProps } = props;
  const { content, headerBar } = children;
  const { classes } = useStyles();

  return (
    <Box className={classes.appWrapper} data-cname="PageLayout" {...otherProps}>
      {showBanner && <DownloadAppBanner />}

      {headerBar && <>{headerBar}</>}

      <Box className={classes.containerWrapper}>
        {isLoading ? <CircularProgress /> : content && content}
      </Box>
    </Box>
  );
};

export default PageLayout;
