import { Slider as MuiSlider } from '@mui/material';
import { grey } from '@mui/material/colors';
import { withStyles } from 'tss-react/mui';

const Slider = withStyles(MuiSlider, {
  root: {
    height: 8,
    padding: '14px 0px 8px'
  },
  thumb: {
    width: 16,
    height: 16,
    marginLeft: -1
  },
  track: {
    height: 6,
    borderRadius: 4
  },
  rail: {
    height: 6,
    borderRadius: 4,
    color: grey[800]
  }
});

export default Slider;
