import { Box, Theme, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from 'components/Tooltip';

const useStyles = makeStyles()((theme: Theme) => ({
  infobox: {
    backgroundColor: '#FFF4E5',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    padding: '8px 16px'
  },
  infoIcon: {
    width: 16,
    height: 16,
    marginLeft: theme.spacing(1)
  }
}));

const GeoEventStopped = (): JSX.Element => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <Box className={classes.infobox}>
      <Typography align="center" fontWeight="500" variant="body2">
        {t('geoEvent.stoppedTooltip.title')}
      </Typography>
      <Tooltip
        arrow
        open={open}
        title={
          <>
            <Typography>{t('geoEvent.stoppedTooltip.title')}</Typography>
            <Typography>{t('geoEvent.stoppedTooltip.description')}</Typography>
          </>
        }
        placement="top"
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <InfoOutlinedIcon
          onClick={() => setOpen(true)}
          fontSize="small"
          className={classes.infoIcon}
        />
      </Tooltip>
    </Box>
  );
};

export default GeoEventStopped;
