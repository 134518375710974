type LegendIconProps = {
  src: string;
  alt: string;
  size?: number;
};

const LegendIcon = (props: LegendIconProps): JSX.Element => {
  const { src, alt, size = 20 } = props;

  return <img src={src} alt={alt} width={size} height="auto" />;
};

export default LegendIcon;
