import { ReactNode, useEffect, useMemo } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Alert,
  AlertTitle
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { differenceBy } from 'lodash-es';
import {
  EvacZone,
  EvacZoneStatus,
  GeoEvent,
  NotificationType,
  FormEvacZoneStatus,
  RegionEvacZoneStyle
} from 'shared/types';
import { getValidRegExp } from 'shared/utils';
import { hasEvacInfoChanged, statusToTransKey } from './utils';
import { EVAC_ZONE_LABEL_TRANS_KEYS } from '../../constants';

type GeoEventChangelogProps = {
  geoEvent: GeoEvent;
  evacZones: EvacZone[];
  zoneStyle: RegionEvacZoneStyle;
  children?: ReactNode | null;
};

const statusIndex: Record<EvacZoneStatus, number> = {
  orders: 0,
  warnings: 1,
  advisories: 2
};

const findZoneNamesInMessage = (
  evacZones: EvacZone[],
  message: string
): string[] => {
  const found: string[] = [];
  evacZones.forEach((zone) => {
    if (getValidRegExp(`\\b(${zone.displayName})\\b`)?.test(message)) {
      found.push(zone.displayName);
    }
  });
  return found;
};

const useStyles = makeStyles()((theme) => ({
  checkboxError: {
    color: theme.palette.error.main
  }
}));

const ConfirmSelectedZones = (): JSX.Element => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { control, formState, getValues, setValue } = useFormContext();

  useEffect(() => {
    /**
     * This component is rendered dynamically whenever there are selected zones that have been not
     * referenced in the update/report message field. In order to make this field required when it is
     * rendered, we update the 'confirmSelectedZones' form value to be 'false' (initial value is
     * 'undefined'; if value exists it is required to be 'true'). Once this component is unmounted we
     * make it optional again by setting the value to be 'undefined' (see form 'validationSchema' at
     * the 'ReportForm' parent component).
     */
    const fieldValue = getValues('confirmSelectedZones');
    if (fieldValue === undefined) {
      setValue('confirmSelectedZones', false);
    }

    return () => {
      setValue('confirmSelectedZones', undefined);
    };
  }, [getValues, setValue]);

  return (
    <Controller
      name="confirmSelectedZones"
      control={control}
      render={({ field }): JSX.Element => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                name="confirmZones"
                checked={!!field.value}
                onChange={(event) => field.onChange(event.target.checked)}
                classes={{
                  root: formState.errors.confirmSelectedZones
                    ? classes.checkboxError
                    : undefined
                }}
              />
            }
            label={t('geoEventChangelog.confirmZones')}
            classes={{
              root: formState.errors.confirmSelectedZones
                ? classes.checkboxError
                : undefined
            }}
          />
        );
      }}
    />
  );
};

const GeoEventChangelog = (
  props: GeoEventChangelogProps
): JSX.Element | null => {
  const { geoEvent, evacZones, zoneStyle, children } = props;
  const { t } = useTranslation();

  const {
    status,
    acreage,
    containment,
    isFps,
    evacuationOrders,
    evacuationWarnings,
    evacuationAdvisories,
    evacuationNotes,
    evacZoneStatuses,
    notificationType,
    customOrders,
    customWarnings,
    customAdvisories
  } = useWatch();

  const acreageChanged = geoEvent.data.acreage !== acreage;
  const containmentChanged = geoEvent.data.containment !== containment;
  const evacuationNotesChanged = hasEvacInfoChanged(
    geoEvent.data.evacuationNotes,
    evacuationNotes
  );
  const evacuationWarningsChanged = hasEvacInfoChanged(
    geoEvent.data.evacuationWarnings,
    evacuationWarnings
  );
  const evacuationAdvisoriesChanged = hasEvacInfoChanged(
    geoEvent.data.evacuationAdvisories,
    evacuationAdvisories
  );
  const evacuationOrdersChanged = hasEvacInfoChanged(
    geoEvent.data.evacuationOrders,
    evacuationOrders
  );
  const fpsChanged = !!geoEvent.data.isFps !== isFps;
  const statusChanged = geoEvent.isActive !== (status === 'active');

  const evacZonesRemoved = differenceBy<FormEvacZoneStatus, FormEvacZoneStatus>(
    geoEvent.evacZoneStatuses,
    evacZoneStatuses,
    'evacZone.id'
  );

  const evacZonesAdded = differenceBy<FormEvacZoneStatus, FormEvacZoneStatus>(
    evacZoneStatuses,
    geoEvent.evacZoneStatuses,
    'evacZone.id'
  );

  const { downgradedEvacZones, upgradedEvacZones } = (
    evacZoneStatuses as FormEvacZoneStatus[]
  ).reduce(
    (zones, zone) => {
      if (zone.prevStatus && zone.prevStatus !== zone.status) {
        if (statusIndex[zone.prevStatus] > statusIndex[zone.status]) {
          zones.upgradedEvacZones.push(zone);
        } else {
          zones.downgradedEvacZones.push(zone);
        }
      }
      return zones;
    },
    {
      downgradedEvacZones: [] as FormEvacZoneStatus[],
      upgradedEvacZones: [] as FormEvacZoneStatus[]
    }
  );

  const evacZonesChanged =
    [
      ...evacZonesRemoved,
      ...evacZonesAdded,
      ...downgradedEvacZones,
      ...upgradedEvacZones
    ].length > 0;

  const evacMessage = useMemo(() => {
    let message = '';
    if (customOrders) {
      message += evacuationOrders;
    }
    if (customWarnings) {
      message += ` ${evacuationWarnings}`;
    }
    if (customAdvisories) {
      message += ` ${evacuationAdvisories}`;
    }
    return message;
  }, [
    evacuationOrders,
    evacuationWarnings,
    evacuationAdvisories,
    customOrders,
    customWarnings,
    customAdvisories
  ]);

  const selectedEvacZoneNames = useMemo(
    () =>
      (evacZoneStatuses as FormEvacZoneStatus[]).reduce((zoneNames, zone) => {
        if (customOrders && zone.status === 'orders') {
          zoneNames.push(zone.evacZone.displayName);
        }
        if (customWarnings && zone.status === 'warnings') {
          zoneNames.push(zone.evacZone.displayName);
        }
        if (customAdvisories && zone.status === 'advisories') {
          zoneNames.push(zone.evacZone.displayName);
        }
        return zoneNames;
      }, [] as string[]),
    [customAdvisories, customOrders, customWarnings, evacZoneStatuses]
  );

  const evacZonesInMessage = useMemo(
    () => findZoneNamesInMessage(evacZones, evacMessage),
    [evacZones, evacMessage]
  );

  const selectedNotReferencedZoneNames = selectedEvacZoneNames.filter(
    (zoneName) => !evacZonesInMessage.includes(zoneName)
  );

  const referencedNotSelectedZoneNames = evacZonesInMessage.filter(
    (zoneName) => !selectedEvacZoneNames.includes(zoneName)
  );

  const incidentInfoChanged =
    acreageChanged ||
    containmentChanged ||
    evacuationNotesChanged ||
    evacuationWarningsChanged ||
    evacuationAdvisoriesChanged ||
    evacuationOrdersChanged ||
    fpsChanged ||
    statusChanged ||
    evacZonesChanged ||
    selectedNotReferencedZoneNames.length > 0 ||
    referencedNotSelectedZoneNames.length > 0 ||
    !!children;

  if (!incidentInfoChanged) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Alert severity="warning" data-testid="changelog-alert">
            <AlertTitle>{t('geoEventChangelog.title')}</AlertTitle>
            <Grid item container spacing={2} direction="column">
              <Grid item>
                {children}

                {statusChanged && (
                  <Typography>
                    {t('geoEventChangelog.status', {
                      prevValue: t(
                        statusToTransKey[
                          geoEvent.isActive ? 'active' : 'inactive'
                        ]
                      ),
                      newValue: t(statusToTransKey[status])
                    })}
                  </Typography>
                )}

                {acreageChanged && (
                  <Typography>
                    {t('geoEventChangelog.acreage', {
                      prevValue: String(geoEvent.data.acreage),
                      newValue: String(acreage)
                    })}
                  </Typography>
                )}

                {containmentChanged && (
                  <Typography>
                    {t('geoEventChangelog.containment', {
                      prevValue: String(geoEvent.data.containment),
                      newValue: String(containment)
                    })}
                  </Typography>
                )}

                {evacuationOrdersChanged && (
                  <Typography>
                    {t(EVAC_ZONE_LABEL_TRANS_KEYS[zoneStyle].orders)}
                  </Typography>
                )}

                {evacuationWarningsChanged && (
                  <Typography>
                    {t(EVAC_ZONE_LABEL_TRANS_KEYS[zoneStyle].warnings)}
                  </Typography>
                )}

                {evacuationAdvisoriesChanged && (
                  <Typography>
                    {t(EVAC_ZONE_LABEL_TRANS_KEYS[zoneStyle].advisories)}
                  </Typography>
                )}

                {evacuationNotesChanged && (
                  <Typography>{t('evacZones.notes')}</Typography>
                )}

                {fpsChanged && (
                  <Typography>
                    {t('addIncidentReport.forwardProgressStopped')}
                  </Typography>
                )}
              </Grid>

              {evacZonesChanged && (
                <Grid item>
                  <Typography>
                    <span>{t('geoEventChangelog.evacuationInfo.line1')}</span>
                    {notificationType === NotificationType.Silent && (
                      <span>{t('geoEventChangelog.evacuationInfo.line2')}</span>
                    )}
                  </Typography>
                </Grid>
              )}

              {(selectedNotReferencedZoneNames.length > 0 ||
                referencedNotSelectedZoneNames.length > 0) && (
                <Grid item>
                  {selectedNotReferencedZoneNames.map((zoneName) => (
                    <Typography key={zoneName}>
                      <Trans
                        i18nKey="geoEventChangelog.selectedNotReferenced"
                        values={{ zoneName }}
                      >
                        Evacuation zone <b>NCO-E011</b> selected but not
                        referenced in text update.
                      </Trans>
                    </Typography>
                  ))}

                  {referencedNotSelectedZoneNames.map((zoneName) => (
                    <Typography key={zoneName}>
                      <Trans
                        i18nKey="geoEventChangelog.referencedNotSelected"
                        values={{ zoneName }}
                      >
                        Evacuation zone <b>NCO-E011</b> selected but not
                        referenced in text update.
                      </Trans>
                    </Typography>
                  ))}
                </Grid>
              )}

              {evacZonesAdded.length > 0 && (
                <Grid item>
                  <Typography>
                    {t('geoEventChangelog.zoneAdded.title')}
                  </Typography>

                  {evacZonesAdded.map((zone) => (
                    <Typography key={zone.evacZone.uid}>
                      <Trans
                        i18nKey="geoEventChangelog.zoneAdded.description"
                        values={{
                          zoneName: zone.evacZone.displayName,
                          zoneStatus: t(
                            EVAC_ZONE_LABEL_TRANS_KEYS[zoneStyle][zone.status]
                          )
                        }}
                      >
                        <b>NCO-E011</b> - <span>Order</span>
                      </Trans>
                    </Typography>
                  ))}
                </Grid>
              )}

              {upgradedEvacZones.length > 0 && (
                <Grid item>
                  <Typography>
                    {t('geoEventChangelog.zoneUpgraded.title')}
                  </Typography>

                  {upgradedEvacZones.map((zone) => (
                    <Typography key={zone.evacZone.uid}>
                      <Trans
                        i18nKey="geoEventChangelog.zoneUpgraded.description"
                        values={{
                          zoneName: zone.evacZone.displayName,
                          prevZoneStatus: t(
                            EVAC_ZONE_LABEL_TRANS_KEYS[zoneStyle][
                              zone.prevStatus!
                            ]
                          ),
                          zoneStatus: t(
                            EVAC_ZONE_LABEL_TRANS_KEYS[zoneStyle][zone.status]
                          )
                        }}
                      >
                        <b>NCO-E011</b> - <span>Warning &rarr; Order</span>
                      </Trans>
                    </Typography>
                  ))}
                </Grid>
              )}

              {downgradedEvacZones.length > 0 && (
                <Grid item>
                  <Typography>
                    {t('geoEventChangelog.zoneDowngraded.title')}
                  </Typography>

                  {downgradedEvacZones.map((zone) => (
                    <Typography key={zone.evacZone.uid}>
                      <Trans
                        i18nKey="geoEventChangelog.zoneDowngraded.description"
                        values={{
                          zoneName: zone.evacZone.displayName,
                          prevZoneStatus: t(
                            EVAC_ZONE_LABEL_TRANS_KEYS[zoneStyle][
                              zone.prevStatus!
                            ]
                          ),
                          zoneStatus: t(
                            EVAC_ZONE_LABEL_TRANS_KEYS[zoneStyle][zone.status]
                          )
                        }}
                      >
                        <b>NCO-E011</b> - <span>Warning &rarr; Order</span>
                      </Trans>
                    </Typography>
                  ))}
                </Grid>
              )}

              {evacZonesRemoved.length > 0 && (
                <Grid item>
                  <Typography>{t('geoEventChangelog.zoneRemoved')}</Typography>

                  {evacZonesRemoved.map((zone) => (
                    <Typography key={zone.evacZone.uid}>
                      <b>{zone.evacZone.displayName}</b>
                    </Typography>
                  ))}
                </Grid>
              )}
            </Grid>
          </Alert>
        </Grid>

        {selectedNotReferencedZoneNames.length > 0 && (
          <Grid item>
            <ConfirmSelectedZones />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default GeoEventChangelog;
