import { memo } from 'react';
import { Card, CardMedia } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

type ImagePreviewProps = {
  title: string;
  data: File | Blob;
};

const useStyles = makeStyles()((theme) => ({
  imagePreview: {
    minHeight: 400,
    width: '100%',
    borderRadius: theme.shape.borderRadius * 2
  }
}));

const ImagePreview = (props: ImagePreviewProps): JSX.Element => {
  const { title, data } = props;
  const { classes } = useStyles();

  return (
    <Card>
      <CardMedia
        className={classes.imagePreview}
        image={URL.createObjectURL(data)}
        title={title}
      />
    </Card>
  );
};

export default memo(ImagePreview);
