import {
  NotificationSetting,
  User,
  WDAppInfo,
  WDDeviceInfo
} from 'shared/types';
import i18n from 'i18n/config';
import { NotificationSettingTypes } from '../../../constants';
import {
  NotificationStatus,
  NotificationsState,
  State,
  TestAction
} from './types';

export const getInitialNotificationsState = (
  status: NotificationStatus
): NotificationsState => ({
  notificationSettings: status,
  deviceRegistration: status,
  countySubscriptions: status,
  networkConnection: status,
  sendNotification: status
});

export const reducer = (state: State, action: TestAction): State => {
  if ('type' in action) {
    if (action.type === 'init') {
      return {
        ...state,
        notificationsState: getInitialNotificationsState('pending')
      };
    }
    return { ...state, testCount: state.testCount + 1 };
  }
  if (action.status === 'success') {
    return {
      ...state,
      notificationsState: {
        ...state.notificationsState,
        [action.notificationKey]: action.status
      }
    };
  }
  switch (action.notificationKey) {
    case 'notificationSettings':
      return {
        ...state,
        notificationsState: {
          notificationSettings: 'error',
          deviceRegistration: 'idle',
          countySubscriptions: 'idle',
          networkConnection: 'idle',
          sendNotification: 'idle'
        }
      };
    case 'deviceRegistration':
      return {
        ...state,
        notificationsState: {
          ...state.notificationsState,
          deviceRegistration: 'error',
          countySubscriptions: 'idle',
          networkConnection: 'idle',
          sendNotification: 'idle'
        }
      };
    case 'countySubscriptions':
      return {
        ...state,
        notificationsState: {
          ...state.notificationsState,
          countySubscriptions: 'error',
          networkConnection: 'idle',
          sendNotification: 'idle'
        }
      };
    case 'networkConnection':
      return {
        ...state,
        notificationsState: {
          ...state.notificationsState,
          networkConnection: 'error',
          sendNotification: 'idle'
        }
      };
    case 'sendNotification':
      return {
        ...state,
        notificationsState: {
          ...state.notificationsState,
          sendNotification: 'error'
        }
      };
    default:
      return state;
  }
};

export const notificationSettingDescriptions = {
  [NotificationSettingTypes.all]: 'testNotifications.notifications.all',
  [NotificationSettingTypes.onlyNew]: 'testNotifications.notifications.onlyNew',
  [NotificationSettingTypes.allWithSilent]:
    'testNotifications.notifications.allWithSilent',
  [NotificationSettingTypes.onlyNewWithSilent]:
    'testNotifications.notifications.onlyNewWithSilent'
};

export const testStatusLabels = {
  idle: 'Idle',
  pending: 'Pending',
  success: 'Passed',
  error: 'Failed'
};

export const getEmailLink = (data: {
  notificationsState: NotificationsState;
  notificationSubscriptions: NotificationSetting[];
  user: User | null;
  deviceInfo: WDDeviceInfo | null;
  appInfo: WDAppInfo | null;
  pushToken: string | null;
}): string => {
  const {
    notificationsState,
    notificationSubscriptions,
    user,
    deviceInfo,
    appInfo,
    pushToken
  } = data;
  const {
    notificationSettings,
    deviceRegistration,
    countySubscriptions,
    networkConnection,
    sendNotification
  } = notificationsState;

  let emailLink = 'mailto:support@watchduty.org';

  emailLink += `?subject=${encodeURIComponent(
    'Troubleshooting Notifications'
  )}`;

  emailLink += `&body=${encodeURIComponent(
    `< Diagnostic Results >\nNotifications settings: [${testStatusLabels[notificationSettings]}]\nDevice registration: [${testStatusLabels[deviceRegistration]}]\nCounty subscriptions: [${testStatusLabels[countySubscriptions]}]\nConnection: [${testStatusLabels[networkConnection]}]\nTest notification: [${testStatusLabels[sendNotification]}]\n\n`
  )}`;

  if (user) {
    emailLink += encodeURIComponent(`Email: ${user.email}\n`);
  }

  if (deviceInfo) {
    emailLink += encodeURIComponent(
      `Device: ${deviceInfo.manufacturer}, ${deviceInfo.model}\nOS: ${deviceInfo?.osVersion}\n`
    );
  }

  if (pushToken) {
    emailLink += encodeURIComponent(`Push Token: ${pushToken.slice(-8)}\n`);
  }

  if (appInfo) {
    emailLink += encodeURIComponent(
      `App: ${appInfo.appVersion} (${appInfo.appBuild})\n\n`
    );
  }

  if (notificationSubscriptions.length > 0) {
    emailLink += encodeURIComponent(
      notificationSubscriptions
        .map(
          (countySetting) =>
            `${countySetting.region.displayName}, ${
              countySetting.region.state
            }: ${i18n.t(
              notificationSettingDescriptions[countySetting.setting]
            )}`
        )
        .join('\n')
    );
  }
  return emailLink;
};
