import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import HeaderBar from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import { API } from 'api';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { GeoEvent } from 'shared/types';
import { useAuthState } from 'state';
import Unauthorized from 'components/Unauthorized';
import ReportForm from './ReportForm';

type ContentProps = {
  geoEvent: GeoEvent;
};

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fetchGeoEvent = (geoEventId: string) =>
  API.get(`geo_events/${geoEventId}`);

const Content = (props: ContentProps): JSX.Element => {
  const { geoEvent } = props;
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('addIncidentReport.title')}</title>
      </Helmet>
      <ReportForm geoEvent={geoEvent} />
    </>
  );
};

const AddIncidentReport = (
  props: RouteComponentProps<{ geoEventId: string }>
): JSX.Element => {
  const {
    match: {
      params: { geoEventId }
    }
  } = props;
  const { t } = useTranslation();
  const {
    permissions: { canReport }
  } = useAuthState();

  const query = useQuery({
    queryKey: ['geoEvent', geoEventId],
    queryFn: () => fetchGeoEvent(geoEventId),
    enabled: !!geoEventId && canReport
  });

  const { data, isLoading, isError } = query;

  if (!canReport) {
    return (
      <PageLayout data-cname="AddIncidentReportPage">
        {{
          content: <Unauthorized />,
          headerBar: (
            <HeaderBar
              title={t('unauthorized.title')}
              action="back"
              hideAction
            />
          )
        }}
      </PageLayout>
    );
  }

  if (isLoading) {
    return (
      <PageLayout data-cname="AddIncidentReportPage">
        {{
          content: <LoadingAndErrors isLoading />,
          headerBar: <HeaderBar title="Loading" action="back" />
        }}
      </PageLayout>
    );
  }

  if (isError || !data) {
    return (
      <PageLayout data-cname="AddIncidentReportPage">
        {{
          content: <LoadingAndErrors />,
          headerBar: <HeaderBar title="Error..." action="back" />
        }}
      </PageLayout>
    );
  }

  return (
    <PageLayout data-cname="AddIncidentReportPage">
      {{
        content: <Content geoEvent={data.data} />,
        headerBar: (
          <HeaderBar
            action="back"
            title={t('addIncidentReport.title')}
            hideAction
          />
        )
      }}
    </PageLayout>
  );
};

export default AddIncidentReport;
