import { TFunction } from 'i18next';
import { CacheAPI } from 'api';
import { CameraTimeLapseFrameDetails } from 'state/useAlertCameraPlayerState';

export const parseReplayTime = (seconds: number, t: TFunction): string => {
  const hours = Math.floor(seconds / 60 / 60);
  if (hours) return t('common.hours', { count: hours });
  const minutes = Math.floor((seconds / 60) % 60);
  return t('common.minutes', { count: minutes });
};

export const preloadImages = (urls: string[]): void => {
  if (!urls.length) return;
  for (const url of urls) {
    const image = new Image();
    image.src = url;
  }
};

export const getAspectRatio = (image: HTMLImageElement): number => {
  const w = image.naturalWidth;
  const h = image.naturalHeight;
  if (w > h) {
    return w / h;
  }
  return h / w;
};

export const fetchCameraTimeLapse = async (
  cameraId: string,
  replayTime: string
): Promise<CameraTimeLapseFrameDetails[]> => {
  const response = await CacheAPI.get<CameraTimeLapseFrameDetails[]>(
    `/cameras/timelapse/${cameraId}`,
    { params: { duration: replayTime } }
  );
  return response.data;
};
