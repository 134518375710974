import { useCallback, useMemo, useState } from 'react';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { Report } from 'shared/types';
import { TARGET_ZOOM } from 'components/IncidentsMap/constants';
import { getTilePairsFromBounds } from 'components/IncidentsMap/tile_utils';
import Map from 'components/Map';
import { API } from 'api';
import GeoEventsLayer from 'components/Map/layers/GeoEventsLayer';
import { VisuallyBusyMapLayers } from 'components/Map/constants';
import { useMapState } from 'state';
import { IncidentMapStateUpdate } from 'state/useMapState';

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fetchGeoEvents = (x: number, y: number) =>
  API.get(`geo_events/?is_relevant=true&zyx=${TARGET_ZOOM},${y},${x}`);

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fetchReports = () =>
  API.get('reports/?is_moderated=false&is_active=true&has_lat_lng=true');

const ModerationMap = (): JSX.Element => {
  const { incidentMapState } = useMapState();
  const initTiles = incidentMapState.bounds
    ? getTilePairsFromBounds(incidentMapState.bounds)
    : [];
  const [tiles, setTiles] = useState(initTiles);
  const history = useHistory();

  const reportsQuery = useQuery({
    queryKey: ['reports'],
    queryFn: fetchReports
  });

  const queries = tiles.map((tile) => {
    return {
      queryKey: ['geoEvents', tile[0], tile[1]],
      queryFn: () => fetchGeoEvents(tile[0], tile[1])
    };
  });
  const results = useQueries({ queries });

  const geoEvents = useMemo(
    () =>
      results
        .filter((e) => e.isSuccess)
        .map((result) => result.data?.data)
        .flat(),
    [results]
  );
  const reports = reportsQuery.data?.data || [];

  const onViewportChange = useCallback((obj: IncidentMapStateUpdate): void => {
    const tilePairs = getTilePairsFromBounds(obj.bounds);
    setTiles(tilePairs);
  }, []);

  const handleClickReport = (reportId: number): void => {
    history.push(`/reports/${reportId}/moderate`);
  };

  return (
    <Map
      center={incidentMapState.center}
      zoom={incidentMapState.zoom}
      disableMapLayers={VisuallyBusyMapLayers}
    >
      {geoEvents?.length && <GeoEventsLayer geoEvents={geoEvents} isFadable />}

      {reports?.map((report: Report) => (
        <Map.ReportMarker
          key={report.id}
          media={report.media}
          onClick={() => handleClickReport(report.id)}
          isFadable={false}
          lat={report.lat}
          lng={report.lng}
        />
      ))}

      <Map.MapEvents onViewportChange={onViewportChange} />
    </Map>
  );
};

export default ModerationMap;
