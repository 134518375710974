import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'cordova-plugin-purchase';
import { Capacitor } from '@capacitor/core';
import GrayButton from 'components/GrayButton';
import { useMembershipManagement } from 'hooks/useMembershipManagement';
import { setNaiveISODateStringToUTC } from 'shared/utils';

const useStyles = makeStyles()((theme) => ({
  button: {
    borderRadius: theme.shape.borderRadius * 2,
    fontWeight: theme.typography.fontWeightMedium,
    minHeight: 48,
    fontSize: 16
  }
}));

export const ExpiryInfo = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { dateActiveUntil, isExpired } = useMembershipManagement();

  if (!dateActiveUntil) return null;

  return (
    <Typography fontSize={16} paragraph>
      {t(`supportUs.manageMembership.${isExpired ? 'expired' : 'expires'}`, {
        date: moment(setNaiveISODateStringToUTC(dateActiveUntil)).format(
          'MMM D, YYYY'
        )
      })}
    </Typography>
  );
};

export const ManageMembershipButton = (): JSX.Element | null => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { hostedLink, isIosSubscription, isLoading } =
    useMembershipManagement();

  if (hostedLink) {
    return (
      <Box pt={1}>
        <GrayButton
          fullWidth
          className={classes.button}
          component={Link}
          to={{ pathname: hostedLink }}
          target="_blank"
          disabled={isLoading || !hostedLink}
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            t('supportUs.manageMembership.button')
          )}
        </GrayButton>
      </Box>
    );
  }

  if (isIosSubscription && Capacitor.getPlatform() === 'ios') {
    return (
      <Box pt={1}>
        <GrayButton
          fullWidth
          className={classes.button}
          onClick={() =>
            CdvPurchase.store.manageSubscriptions(
              CdvPurchase.Platform.APPLE_APPSTORE
            )
          }
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            t('supportUs.manageMembership.button')
          )}
        </GrayButton>
      </Box>
    );
  }

  return null;
};
