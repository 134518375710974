import { Box, SxProps } from '@mui/material';

type VectorLogoProps = {
  size?: number;
  sx?: SxProps;
  className?: string;
};

const VectorLogo = (props: VectorLogoProps): JSX.Element => {
  const { size = 148, sx, className } = props;
  return (
    <Box sx={sx} className={className} data-testid="app-logo">
      <svg width={size} height={size} viewBox="0 0 148 148">
        <path
          d="M95 44.8218C95 44.8218 88.1352 14.3621 62.6974 0.856241C61.9657 0.467764 61.1101 1.11697 61.1915 1.94137C62.3978 14.1499 51.918 26.1338 43.6684 35.5673C40.9453 38.6812 38.4651 41.5173 36.7286 43.9918C28.813 52.8373 24 64.5175 24 77.3218C24 91.6763 30.0489 104.618 39.7368 113.737L68.343 142.343C71.4672 145.467 76.5325 145.467 79.6567 142.343L108.261 113.739C117.95 104.62 124 91.6773 124 77.3218C124 73.8971 123.656 70.5528 123 67.3216C123 67.3216 117.926 39.1703 102.399 21.8327C101.774 21.1356 100.644 21.6521 100.681 22.5871C101.209 35.6537 96.6935 42.6169 95.3624 44.3732L95 44.8218Z"
          fill="#E25345"
        />
        <path
          opacity="0.6"
          d="M97.9833 114.016C104.765 107.633 109 98.5737 109 88.5251C109 69.1952 93.33 53.5251 74 53.5251C54.67 53.5251 39 69.1952 39 88.5251C39 98.573 43.234 107.632 50.0152 114.015L68.3431 132.343C71.4673 135.467 76.5326 135.467 79.6568 132.343L97.9833 114.016Z"
          fill="#FBC02D"
        />
        <path
          opacity="0.6"
          d="M89.0753 112.733C93.3382 108.67 96 102.903 96 96.5068C96 84.2023 86.1503 74.2275 74 74.2275C61.8497 74.2275 52 84.2023 52 96.5068C52 102.903 54.6614 108.669 58.9239 112.732L68.3075 122.235C71.4397 125.407 76.5602 125.407 79.6924 122.235L89.0753 112.733Z"
          fill="#FBC02D"
        />
      </svg>
    </Box>
  );
};

export default VectorLogo;
