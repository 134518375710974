import { useTranslation } from 'react-i18next';
import PageLayout from 'components/PageLayout';
import HeaderBar from 'components/HeaderBar';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { CircularProgress } from '@mui/material';
import { useAuthState } from 'state';
import Unauthorized from 'components/Unauthorized';
import useLocationSearchParams from 'hooks/useLocationSearchParams';
import { parseLatitudeParam, parseLongitudeParam } from 'shared/utils';
import { Location } from 'shared/types';
import { API } from 'api';
import LocationForm from './LocationForm';

type ContentProps = {
  location?: Location;
  latitude?: number;
  longitude?: number;
  parentGeoEventId?: number;
};

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fetchGeoEvent = (geoEventId: string) =>
  API.get(`geo_events/${geoEventId}`);

const Content = (props: ContentProps): JSX.Element => {
  const { location, latitude, longitude, parentGeoEventId } = props;
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{location?.name || t(`createEditLocation.title.add`)}</title>
      </Helmet>
      <LocationForm
        location={location}
        initialLatitude={latitude}
        initialLongitude={longitude}
        parentGeoEventId={parentGeoEventId}
      />
    </>
  );
};

const CreateEditLocation = (
  props: RouteComponentProps<{ geoEventId?: string; locationId?: string }>
): JSX.Element => {
  const { t } = useTranslation();
  const {
    permissions: { canReport }
  } = useAuthState();
  const queryParams = useLocationSearchParams();
  const {
    match: {
      params: { locationId }
    }
  } = props;

  const query = useQuery({
    queryKey: ['geoEvent', locationId],
    queryFn: () => fetchGeoEvent(locationId!),
    enabled: !!locationId && canReport,
    refetchOnWindowFocus: false
  });

  const { data, isLoading, isError, error } = query;

  if (!canReport) {
    return (
      <PageLayout data-cname="CreateEditLocationPage">
        {{
          content: <Unauthorized />,
          headerBar: (
            <HeaderBar
              title={t('unauthorized.title')}
              action="back"
              hideAction
            />
          )
        }}
      </PageLayout>
    );
  }

  if (isLoading) {
    return (
      <PageLayout data-cname="CreateEditLocationPage">
        {{
          content: <CircularProgress />,
          headerBar: (
            <HeaderBar title={t('common.loading')} action="back" hideAction />
          )
        }}
      </PageLayout>
    );
  }

  if (isError) {
    return (
      <PageLayout data-cname="CreateEditLocationPage">
        {{
          content: <div> {JSON.stringify(error)} </div>,
          headerBar: (
            <HeaderBar
              title={t(
                `createEditLocation.title.${locationId ? 'edit' : 'add'}`
              )}
              action="back"
              hideAction
            />
          )
        }}
      </PageLayout>
    );
  }

  const location = data?.data;
  const latitude = parseLatitudeParam(queryParams.get('lat') ?? '');
  const longitude = parseLongitudeParam(queryParams.get('lng') ?? '');
  const parentGeoEventId = queryParams.get('parentGeoEventId');

  return (
    <PageLayout data-cname="CreateEditLocationPage">
      {{
        content: (
          <Content
            location={location}
            latitude={latitude}
            longitude={longitude}
            parentGeoEventId={
              parentGeoEventId ? parseInt(parentGeoEventId, 10) : undefined
            }
          />
        ),
        headerBar: (
          <HeaderBar
            title={t(`createEditLocation.title.${locationId ? 'edit' : 'add'}`)}
            action="back"
            hideAction
          />
        )
      }}
    </PageLayout>
  );
};

export default CreateEditLocation;
