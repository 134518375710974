import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useTranslation } from 'react-i18next';
import { Browser } from '@capacitor/browser';
import { Link, LinkType } from 'shared/types';
import { formatPhoneNumber, getAbsoluteUrl } from 'shared/utils';
import { LinkTypes } from '../../constants';

type LinkItemProps = {
  link: Link;
};

const mapLinkTypeToLabelTransKey: Record<LinkType, string> = {
  [LinkTypes.phone]: 'links.phone',
  [LinkTypes.website]: 'links.website',
  [LinkTypes.email]: 'links.email'
};

const mapLinkTypeToIcon: Record<LinkType, JSX.Element> = {
  [LinkTypes.phone]: <PhoneIcon />,
  [LinkTypes.website]: <OpenInNewIcon />,
  [LinkTypes.email]: <EmailIcon />
};

const LinkItem = (props: LinkItemProps): JSX.Element => {
  const { link } = props;
  const { t } = useTranslation();

  const handleLinkClick = async (): Promise<void> => {
    if (link.linkType === LinkTypes.phone) {
      window.open(`tel:${link.value}`);
      return;
    }

    if (link.linkType === LinkTypes.email) {
      window.open(`mailto:${link.value}`);
      return;
    }

    if (link.linkType === LinkTypes.website) {
      const url = getAbsoluteUrl(link.value);
      await Browser.open({ url, presentationStyle: 'popover' });
    }
  };

  return (
    <ListItemButton
      key={link.value + link.linkType}
      divider
      onClick={handleLinkClick}
    >
      <ListItemText
        primary={
          <Typography
            color="secondary"
            fontWeight="medium"
            sx={{ fontVariant: 'all-small-caps' }}
          >
            {t(mapLinkTypeToLabelTransKey[link.linkType as LinkType])}
          </Typography>
        }
        secondary={
          <>
            <Typography color="textPrimary" sx={{ fontWeight: 600 }}>
              {link.label}
            </Typography>
            {link.linkType !== LinkTypes.website && (
              <Typography color="textPrimary">
                {link.linkType === LinkTypes.phone
                  ? formatPhoneNumber(link.value)
                  : link.value}
              </Typography>
            )}
          </>
        }
      />
      <ListItemIcon
        sx={(theme) => ({
          minWidth: 'fit-content',
          color: theme.palette.text.primary
        })}
      >
        {mapLinkTypeToIcon[link.linkType as LinkType]}
      </ListItemIcon>
    </ListItemButton>
  );
};

export default LinkItem;
