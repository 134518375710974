import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * @deprecated use useQueryParams() instead
 */
const useLocationSearchParams = (): URLSearchParams => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export default useLocationSearchParams;
