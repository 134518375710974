import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
// Case conversation via https://github.com/mpyw/axios-case-converter#usage
import applyCaseMiddleware from 'axios-case-converter';
import i18next from 'i18next';
import { getAccessKey, clearDataForLogout } from './state/localStorage';
import { DEFAULT_LOCALE } from './i18n/utils';
import { getAppVersion } from './shared/utils';

// See https://github.com/axios/axios#request-config for options
const headers: AxiosRequestConfig['headers'] = {};

const baseApi = axios.create({
  baseURL: import.meta.env.VITE_BASE_API_URL,
  headers
});

const cacheApi = axios.create({
  baseURL: import.meta.env.VITE_BASE_API_URL_CACHE,
  headers
});

const applyInterceptors = (apiInstance: AxiosInstance): AxiosInstance => {
  apiInstance.interceptors.request.use(async (config) => {
    const appVersion = await getAppVersion();

    // add token to request headers
    const token = getAccessKey();
    const lang = i18next.resolvedLanguage || DEFAULT_LOCALE;
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: token ? `Token ${token}` : '',
        'Accept-Language': lang,
        'X-Git-Tag': import.meta.env.VITE_GIT_TAG || 'local',
        'X-App-Version': appVersion
      }
    };
  });

  apiInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      // If the user is no longer authorized or the token was deleted
      // on the server, we must log out the user. Skip when we're trying
      // to verify a membership client token.
      if (
        error?.response?.status === 401 &&
        error?.config.url !== 'recurly_integration/payment_verification/'
      ) {
        window.location.assign('/login');
        clearDataForLogout();
      }
      return Promise.reject(error);
    }
  );

  return apiInstance;
};

export const API = applyInterceptors(applyCaseMiddleware(baseApi));
export const CacheAPI = applyInterceptors(applyCaseMiddleware(cacheApi));

export const MapBoxAPI = applyCaseMiddleware(
  axios.create({
    baseURL: 'https://api.mapbox.com/geocoding/v5/mapbox.places/'
  })
);
