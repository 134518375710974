import { AircraftIconInfo } from './Icons';
import {
  DEFAULT_STROKE_COLOR,
  fillColorForVariant,
  STROKE_LINE_WIDTH
} from './layerStyles';
import { AircraftIconVariant } from './types';

type StaticIconProps = {
  icon: AircraftIconInfo;
  variant: AircraftIconVariant;
  scale: number;
};

export const AircraftIcon = (props: StaticIconProps): JSX.Element => {
  const { icon, variant, scale } = props;
  const { svg, urls } = icon;

  if (svg) {
    return (
      <svg
        width={icon.width * scale}
        height={icon.height * scale}
        style={{
          cursor: 'pointer',
          fill: fillColorForVariant(variant),
          strokeWidth: STROKE_LINE_WIDTH,
          stroke: DEFAULT_STROKE_COLOR
        }}
      >
        <g>{svg}</g>
      </svg>
    );
  }
  if (urls) {
    return (
      <img
        src={urls[variant]}
        alt=""
        style={{
          cursor: 'pointer',
          width: icon.width * scale,
          height: icon.height * scale
        }}
      />
    );
  }

  throw Error(`Missing icon for ${icon.name}-${variant}`);
};
