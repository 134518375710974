import { Theme, Button } from '@mui/material';

import { withStyles } from 'tss-react/mui';

const GrayButton = withStyles(Button, (theme: Theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius * 2,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[500]
    }
  }
})) as typeof Button;

export default GrayButton;
