import { useCallback } from 'react';
import { Layer, LayerProps } from 'react-map-gl/maplibre';
import { usePoisState } from 'state/usePoisState';
import { FirePerimeter } from 'shared/types';
import ArcGisLayer from './ArcGisLayer';
import getWatchdutyTileUrl from './getWatchdutyTileUrl';
import VisibilityToggledSource from './VisibilityToggledSource';
import addVisible from '../../../shared/addVisible';
import { FILL_PATTERN_LAYER_MIN_ZOOM } from '../constants';
import { MapLayerProps } from '../types';
import { useCacheState } from '../../../state';
import { ActiveFirePerimeterDialogContent } from '../ActiveFirePerimeterDialogContent';
import { getSelectedPatternStyle, getSelectedStrokeStyle } from '../utils';
import { MapboxFeature, useMapLayerEvents } from './useMapLayerEvents';
import { BEFORE_LAYER_ID } from '../styles/constants';

type ActiveFirePerimetersProperties = {
  OBJECTID: number;
  CreateDate?: number;
  date_added?: number;
};

const firisSrc = import.meta.env.VITE_FIRIS_PERIMETERS_URL as string;
const firisFields = ['OBJECTID', 'date_added'];

const FIRIS_DATA_STALE_TIME_MS = 60 * 1000;
const NIFC_PERIMETERS_FILL_LAYER_ID = 'perimeters-fill';
const FIRIS_PERIMETERS_FILL_LAYER_ID = 'firis-fill';

const ActiveFirePerimetersLayer = (props: MapLayerProps): JSX.Element => {
  const { cacheBusterTs } = useCacheState();
  const { visible } = props;
  const { selectedPoi, setSelectedPoi } = usePoisState();

  const handleClick = useCallback(
    (
      geoJsonFeatures: MapboxFeature<ActiveFirePerimetersProperties>[]
    ): void => {
      const { properties, sourceLayer = 'firis' } = geoJsonFeatures[0];

      const activeFirePerimeter: FirePerimeter = {
        id: properties.OBJECTID,
        type: sourceLayer as FirePerimeter['type'],
        dateAdded: (properties.CreateDate || properties.date_added)!
      };

      setSelectedPoi({
        type: 'firePerimeter',
        id: properties.OBJECTID,
        PoiDialogContent: () => (
          <ActiveFirePerimeterDialogContent
            activeFirePerimeter={activeFirePerimeter}
          />
        )
      });
    },
    [setSelectedPoi]
  );

  useMapLayerEvents<ActiveFirePerimetersProperties>({
    layerId: NIFC_PERIMETERS_FILL_LAYER_ID,
    onClick: handleClick
  });

  useMapLayerEvents<ActiveFirePerimetersProperties>({
    layerId: FIRIS_PERIMETERS_FILL_LAYER_ID,
    onClick: handleClick
  });

  const selectedId =
    (selectedPoi?.type === 'firePerimeter' && selectedPoi.id) || '';

  const selectedNifcFillStyle = getSelectedPatternStyle({
    idKey: 'OBJECTID',
    otherLayerProps: { 'source-layer': 'nifc' },
    selectedId
  });

  const selectedNicfcStrokeStyle = getSelectedStrokeStyle({
    idKey: 'OBJECTID',
    otherLayerProps: { 'source-layer': 'nifc' },
    selectedId
  });

  const selectedFirisFillStyle = getSelectedPatternStyle({
    idKey: 'OBJECTID',
    selectedId
  });

  const selectedFirisStrokeStyle = getSelectedStrokeStyle({
    idKey: 'OBJECTID',
    selectedId
  });

  return (
    <>
      <VisibilityToggledSource
        url={getWatchdutyTileUrl('active_perimeters')}
        volatile
        visible={visible}
      >
        <Layer
          id={NIFC_PERIMETERS_FILL_LAYER_ID}
          beforeId={BEFORE_LAYER_ID}
          {...activeFirePerimetersFillStyle}
        />
        <Layer
          id="perimeters-fill-pattern"
          beforeId={BEFORE_LAYER_ID}
          {...patternPerimetersFillStyleNifc}
        />
        <Layer
          id="perimeters-stroke"
          beforeId={BEFORE_LAYER_ID}
          {...activeFirePerimetersStrokeStyle}
        />
        <Layer beforeId={BEFORE_LAYER_ID} {...selectedNifcFillStyle} />
        <Layer beforeId={BEFORE_LAYER_ID} {...selectedNicfcStrokeStyle} />
      </VisibilityToggledSource>
      <ArcGisLayer
        visible={visible}
        src={firisSrc}
        staleTime={FIRIS_DATA_STALE_TIME_MS}
        cacheBusterTs={cacheBusterTs}
        fields={firisFields}
      >
        <Layer
          id={FIRIS_PERIMETERS_FILL_LAYER_ID}
          beforeId={BEFORE_LAYER_ID}
          {...addVisible(firisPerimetersFillStyle, visible)}
        />
        <Layer
          id="firis-fill-pattern"
          beforeId={BEFORE_LAYER_ID}
          {...addVisible(patternPerimetersFillStyle, visible)}
        />
        <Layer
          id="firis-stroke"
          beforeId={BEFORE_LAYER_ID}
          {...addVisible(firisPerimetersStrokeStyle, visible)}
        />
        <Layer
          id="firis-border"
          beforeId={BEFORE_LAYER_ID}
          {...addVisible(firisPerimetersBorderStyle, visible)}
        />
        <Layer beforeId={BEFORE_LAYER_ID} {...selectedFirisFillStyle} />
        <Layer beforeId={BEFORE_LAYER_ID} {...selectedFirisStrokeStyle} />
      </ArcGisLayer>
    </>
  );
};

const activeFirePerimetersFillStyle: LayerProps = {
  type: 'fill',
  'source-layer': 'nifc',
  paint: {
    'fill-color': '#FF0000',
    'fill-opacity': 0.25
  }
};

const activeFirePerimetersStrokeStyle: LayerProps = {
  type: 'line',
  'source-layer': 'nifc',
  paint: {
    'line-color': '#FF0000',
    'line-opacity': 0.75,
    'line-width': ['interpolate', ['linear'], ['zoom'], 8, 0.5, 18, 3.5]
  }
};

const patternPerimetersFillStyle: LayerProps = {
  type: 'fill',
  minzoom: FILL_PATTERN_LAYER_MIN_ZOOM,
  paint: {
    'fill-pattern': 'FirePerimeterPattern',
    'fill-opacity': 0.8
  }
};

const patternPerimetersFillStyleNifc: LayerProps = {
  ...patternPerimetersFillStyle,
  'source-layer': 'nifc'
};

const firisPerimetersFillStyle: LayerProps = {
  type: 'fill',
  paint: {
    'fill-color': '#FD2D2C',
    'fill-opacity': 0.5
  }
};

const firisPerimetersStrokeStyle: LayerProps = {
  type: 'line',
  paint: {
    'line-color': '#FD2D2C',
    'line-opacity': 1,
    'line-width': ['interpolate', ['linear'], ['zoom'], 8, 0.5, 18, 2.5],
    'line-dasharray': [2, 1]
  }
};

const firisOuterStrokeWidth = 8;

const firisPerimetersBorderStyle: LayerProps = {
  type: 'line',
  layout: {
    'line-cap': 'square'
  },
  paint: {
    'line-color': '#FD2D2C',
    'line-opacity': 0.32,
    'line-width': [
      'interpolate',
      ['linear'],
      ['zoom'],
      12,
      firisOuterStrokeWidth / 12,
      18,
      firisOuterStrokeWidth
    ],
    'line-offset': [
      'interpolate',
      ['linear'],
      ['zoom'],
      12,
      -firisOuterStrokeWidth / 24,
      18,
      -firisOuterStrokeWidth / 2
    ]
  }
};

export default ActiveFirePerimetersLayer;
