import { memo } from 'react';
import { Card, CardContent, Grid, Box, Link as MuiLink } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthState } from 'state';
import { Report, ReportMedia } from 'shared/types';
import RichTextContent from 'components/RichTextContent';
import ReportMetadata from './ReportMetadata';
import ResizedImage from '../ResizedImage';
import CardEmbed from './CardEmbed';

type ReportCardProps = {
  media: ReportMedia | null;
  report: Report;
  className?: string;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    borderRadius: 0
  },
  message: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    lineHeight: theme.typography.pxToRem(22)
  },
  image: {
    width: '100%',
    minHeight: 250,
    maxHeight: 485,
    objectFit: 'contain',
    backgroundColor: theme.palette.grey[300]
  },
  editButtonWrapper: {
    textAlign: 'right'
  },
  editButton: {
    color: theme.palette.grey[500]
  },
  notificationType: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  }
}));

const ReportCard = (props: ReportCardProps): JSX.Element => {
  const { report, media, className } = props;
  const { id, geoEventId, message } = report;
  const history = useHistory();
  const { classes, cx } = useStyles();
  const {
    permissions: { canReport }
  } = useAuthState();
  const { t } = useTranslation();

  return (
    <Card
      className={cx(classes.root, className)}
      data-testid="reportCard"
      elevation={0}
    >
      <CardContent sx={{ paddingBottom: '8px !important' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} container>
            <Grid item xs={canReport ? 11 : 12}>
              <ReportMetadata report={report} />
            </Grid>

            {canReport && (
              <Grid item xs={1}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <EditIcon
                    className={classes.editButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (canReport) {
                        history.push(
                          `/incident/${geoEventId}/reports/${id}/edit`
                        );
                      }
                    }}
                  />
                </Box>
              </Grid>
            )}
          </Grid>

          {media && (
            <Grid item xs={12}>
              <MuiLink
                key={id}
                to={`/i/${geoEventId}/reports/${id}`}
                underline="none"
                component={Link}
              >
                <ResizedImage
                  alt={t('geoEvent.reports.image')}
                  className={classes.image}
                  s3Path={media.url}
                  loading="lazy"
                />
              </MuiLink>
            </Grid>
          )}

          {report.embedUrl && (
            <Grid item xs={12}>
              <CardEmbed embedUrl={report.embedUrl} />
            </Grid>
          )}
        </Grid>

        <RichTextContent content={message} className={classes.message} />
      </CardContent>
    </Card>
  );
};

export default memo(ReportCard);
