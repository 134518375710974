import { MouseEvent } from 'react';
import { Box, Card, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo
} from '@watchduty/camera';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { getDeviceInfo } from 'state/localStorageTyped';
import { useAuthState } from 'state';
import { getResponsiveFontSize } from 'shared/utils';

type FilePickerProps = {
  message: string;
  onFilesPicked: (files: File | File[] | Photo) => void;
  onFilesInvalid: (error: string) => void;
  error?: string;
  disabled?: boolean;
  minHeight?: number;
};

type StyleProps = {
  hasError: boolean;
  disabled?: boolean;
  minHeight?: number;
};

const MAX_FILE_UPLOAD_BYTES = 5000000; // 5 MB
const ALLOWED_MIMETYPES = 'image/*';

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
// Arrow function has parameter type of Identifier instead of ObjectPattern (e.g. `(props) => ({...})` instead of `({color}) => ({...})`).
const useStyles = makeStyles<StyleProps>()(
  (theme, { hasError, disabled, minHeight }) => ({
    root: {
      alignItems: 'center',
      backgroundColor: theme.palette.grey[200],
      borderColor: hasError
        ? theme.palette.error.main
        : theme.palette.grey[500],
      cursor: disabled ? 'not-allowed' : 'pointer',
      display: 'flex',
      minHeight: minHeight ?? 115,
      justifyContent: 'center',
      padding: theme.spacing(3),
      position: 'relative',
      flexDirection: 'column',
      gap: 32,
      borderRadius: theme.shape.borderRadius * 1.34
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    description: {
      fontWeight: theme.typography.fontWeightMedium
    },
    icon: {
      fontSize: getResponsiveFontSize('4.5rem')
    }
  })
);

const takePicture = async (isInternalUser: boolean): Promise<Photo | null> => {
  const source = isInternalUser ? CameraSource.Prompt : CameraSource.Camera;
  try {
    return await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      saveToGallery: true,
      width: 1500,
      resultType: CameraResultType.Uri,
      source
    });
  } catch (e) {
    return null;
  }
};

const FilePicker = (props: FilePickerProps): JSX.Element => {
  const { message, onFilesPicked, onFilesInvalid, error, disabled, minHeight } =
    props;
  const { classes } = useStyles({
    hasError: Boolean(error),
    disabled,
    minHeight
  });
  const deviceInfo = getDeviceInfo();
  const {
    permissions: { isInternalUser }
  } = useAuthState();
  const { t } = useTranslation();

  const handleDropRejected = (dropErrors: FileRejection[]): void => {
    // convert the list of react-dropzone errors into a single human-readable error string
    const errorCodes = dropErrors
      .map((drop) => drop.errors.map((e) => e.code))
      .flat();

    const DROP_ERROR_CODE_TO_TEXT = {
      'file-too-large': t('filePicker.errors.tooLarge'),
      'file-invalid-type': t('filePicker.errors.invalidType'),
      default: t('filePicker.errors.default')
    };

    onFilesInvalid(DROP_ERROR_CODE_TO_TEXT[errorCodes[0]]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ALLOWED_MIMETYPES,
    onDropAccepted: (files: File[]) => onFilesPicked(files),
    onDropRejected: handleDropRejected,
    maxSize: MAX_FILE_UPLOAD_BYTES
  });

  const handleNativeClick = async (): Promise<void> => {
    const image = await takePicture(isInternalUser);
    if (!image) return;
    onFilesPicked(image);
  };

  const handleClick = async (event: MouseEvent<HTMLElement>): Promise<void> => {
    if (deviceInfo?.isWeb) {
      // defer to useDropzone()
      return;
    }

    event.stopPropagation();
    await handleNativeClick();
  };

  return (
    <Card
      variant="outlined"
      className={classes.root}
      {...getRootProps({
        onClick: handleClick,
        role: 'button',
        'aria-label': 'dropzone'
      })}
    >
      <input {...getInputProps()} disabled={disabled} />

      <Box className={classes.container}>
        <CameraAltOutlinedIcon className={classes.icon} />

        <Typography
          variant="body1"
          align="center"
          className={classes.description}
        >
          {message}
        </Typography>
      </Box>
    </Card>
  );
};

export default FilePicker;
