import { Capacitor } from '@capacitor/core';
import { SvgIcon, SvgIconProps } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import ShareIosIcon from 'assets/ios-share.svg?react';

type PlatformShareIconProps = SvgIconProps & {
  height?: number;
  width?: number;
};

const DEFAULT_IOS_SIZE = 24;

const PlatformShareIcon = (props: PlatformShareIconProps): JSX.Element => {
  if (Capacitor.getPlatform() !== 'ios') {
    return <ShareIcon {...props} />;
  }

  const {
    height = DEFAULT_IOS_SIZE,
    width = DEFAULT_IOS_SIZE,
    ...otherProps
  } = props;

  return (
    <SvgIcon {...otherProps}>
      <ShareIosIcon height={height} width={width} />
    </SvgIcon>
  );
};

export default PlatformShareIcon;
