import {
  Box,
  Divider,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Switch
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Membership } from 'shared/types';
import ThermalHotspotTimeGuide from './legends/ThermalHotspotTimeGuide';
import { MapLayers, MapLayer } from './constants';
import UserStatusChip from './UserStatusChip';

type LayerGroupControlItemProps = {
  imageSrc: string;
  titleKey: string;
  subtitleKey: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  mapLayer: MapLayer;
  disabled?: boolean;
  internalOnly?: boolean;
  minMembership?: Membership;
};

const useStyles = makeStyles()((theme) => ({
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2, 2, 0)
  },
  img: {
    width: '100%',
    height: 'auto'
  }
}));

const isSatelliteMapLayer = (layer: MapLayer): boolean => {
  return layer === MapLayers.VIIRS || layer === MapLayers.MODIS;
};

export const LayerGroupControlItem = (
  props: LayerGroupControlItemProps
): JSX.Element => {
  const {
    imageSrc,
    titleKey,
    subtitleKey,
    checked,
    onChange,
    mapLayer,
    disabled,
    internalOnly,
    minMembership = 'public'
  } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const handleChange = (): void => {
    if (disabled) return;
    onChange(!checked);
  };

  const isSatelliteLayer = isSatelliteMapLayer(mapLayer);

  return (
    <Stack direction="column">
      <Box className={classes.imgContainer}>
        <img src={imageSrc} alt={t(titleKey)} className={classes.img} />
      </Box>

      <ListItemButton
        divider={!isSatelliteLayer}
        disabled={disabled}
        onClick={handleChange}
        sx={{ paddingRight: 8 }}
      >
        <ListItemText
          primary={
            <>
              {t(titleKey)}
              {minMembership !== 'public' && (
                <UserStatusChip
                  type={minMembership === 'pro' ? 'pro' : 'members'}
                  sx={{ marginLeft: 1 }}
                />
              )}
              {internalOnly && (
                <UserStatusChip sx={{ marginLeft: 1 }} type="internal" />
              )}
            </>
          }
          primaryTypographyProps={{ variant: 'body1' }}
          secondary={disabled ? t('map.layers.disabled') : t(subtitleKey)}
          secondaryTypographyProps={{ variant: 'subtitle1' }}
        />
        <ListItemSecondaryAction>
          <Switch
            disabled={disabled}
            edge="end"
            checked={checked}
            inputProps={{
              'aria-labelledby': t('map.accessibility.switchLayer'),
              'aria-label': `Toggle ${mapLayer}`
            }}
          />
        </ListItemSecondaryAction>
      </ListItemButton>

      {isSatelliteLayer && (
        <>
          <Box sx={{ padding: 2, paddingTop: 0 }}>
            <ThermalHotspotTimeGuide size="medium" />
          </Box>

          <Divider />
        </>
      )}
    </Stack>
  );
};
