import { Helmet } from 'react-helmet';
import {
  Box,
  ThemeProvider,
  StyledEngineProvider,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import PageLayout from 'components/PageLayout';
import watchdutyTheme from 'theme';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundImage: `url("/images/app-background.png")`,
    backgroundSize: '100% 100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
}));

const Content = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={watchdutyTheme}>
        <Helmet>
          <title>{t('errorFallback.title')}</title>
        </Helmet>

        <Box className={classes.root}>
          <Typography variant="h2" align="center">
            {t('errorFallback.title')}
          </Typography>

          <Typography align="center">
            {t('errorFallback.description')}
          </Typography>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const ErrorFallback = (): JSX.Element => {
  return (
    <PageLayout data-cname="ErrorFallback" showBanner={false}>
      {{
        content: <Content />,
        headerBar: <></>
      }}
    </PageLayout>
  );
};

export default ErrorFallback;
