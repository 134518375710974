/* eslint-disable */
// taken from :https://bugs.webkit.org/show_bug.cgi?id=182424. This affects ios version < 15.
// backwards compatibility for this was dropped in maplibre-gl 3.x
if (!('createImageBitmap' in window)) {
  window.ImageBitmap = window.ImageBitmap || HTMLImageElement;
  window.createImageBitmap = async function (blob) {
    return new Promise((resolve, reject) => {
      const img = document.createElement('img');
      img.addEventListener('load', function () {
        resolve(this);
      });
      img.src = URL.createObjectURL(blob);
      img.deleteURL = function () {
        URL.revokeObjectURL(this.src);
      };
    });
  };
}
/* eslint-enable */
