import { useState, useCallback } from 'react';
import { useAuthState } from 'state';

export type ContextMenuCoordinatesType = {
  mouseX: number;
  mouseY: number;
  lat: number;
  lng: number;
};

type UseCtxMenuType = {
  contextMenuCoordinates: ContextMenuCoordinatesType | null;
  ctxMenuClose: () => void;
  openContextMenu: (event: maplibregl.MapLayerMouseEvent) => void;
};

const useContextMenu = (): UseCtxMenuType => {
  const {
    permissions: { isInternalUser }
  } = useAuthState();
  const [contextMenuCoordinates, setContextMenuCoordinates] =
    useState<ContextMenuCoordinatesType | null>(null);

  const ctxMenuClose = (): void => {
    setContextMenuCoordinates(null);
  };

  const openContextMenu = useCallback(
    (event: maplibregl.MapLayerMouseEvent): void => {
      event.preventDefault();
      if (isInternalUser) {
        setContextMenuCoordinates(
          contextMenuCoordinates === null
            ? {
                mouseX: event.originalEvent.clientX + 2,
                mouseY: event.originalEvent.clientY - 6,
                lat: event.lngLat.lat,
                lng: event.lngLat.lng
              }
            : null
        );
      }
    },
    [isInternalUser, contextMenuCoordinates, setContextMenuCoordinates]
  );

  return {
    ctxMenuClose,
    contextMenuCoordinates,
    openContextMenu
  };
};
export default useContextMenu;
