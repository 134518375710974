import { useCallback, useMemo } from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl/maplibre';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FeatureCollection, Feature, Geometry } from 'geojson';
import { WildfireListItem } from 'shared/types';
import { getAcreageStrParts } from 'shared/utils';
import useMapLayersState from 'state/useMapLayersState';
import { usePoisState } from 'state/usePoisState';
import {
  getGeoEventIcon,
  getGeoEventScale,
  getOpacityForGeoEventMarker
} from '../Icons';
import {
  FONT_NAMES,
  GEO_EVENT_ICONS_LAYER_ID,
  GEO_EVENT_SOURCE_ID
} from '../styles/constants';
import { DEFAULT_LOCALE } from '../../../i18n/utils';
import { MapboxFeature, useMapLayerEvents } from './useMapLayerEvents';

type GeoEventsLayerProps = {
  geoEvents: WildfireListItem[];
  isFadable?: boolean;
  selectedGeoEventId?: number;
  isEdit?: boolean;
  interactive?: boolean;
};

type GeoEventFeatureProperties = {
  id: number;
  name: string;
  icon: string;
  isActive: boolean;
  acreage: number | null;
  scale: number;
  offset: number[] | null;
  opacity: number;
  index: number;
  indexInv: number;
  acreageStr: string;
  geoEventId: number;
};

const LABELS_MIN_ZOOM = 7;
const LABELS_LAYER_ID = 'geo-event-labels';

const noop = (): void => {};

const getAcreageStr = (
  acreage: number | null,
  containment: number | null,
  locale: string
): string => {
  const parts = getAcreageStrParts(acreage, containment, locale);
  if (parts[0] == null) return '';
  return parts.join(' ');
};

const getIconStyle = (
  selectedGeoEventId: number,
  isEdit?: boolean
): LayerProps => {
  const isEditEvent = !!isEdit;

  return {
    type: 'symbol',
    layout: {
      'icon-image': ['get', 'icon'],
      'icon-allow-overlap': true,
      'icon-anchor': 'bottom',
      'icon-ignore-placement': false,
      'icon-size': ['get', 'scale'],
      'icon-offset': ['get', 'offset'],
      // For some reason, the icons need to be sorted by 'index',
      // but the labels by 'indexInv', in order to be sorted correctly
      // in both cases.
      'symbol-sort-key': ['get', 'index'],
      'symbol-z-order': 'source'
    },
    paint: {
      'icon-opacity': [
        'case',
        [
          'all',
          ['==', ['get', 'id'], selectedGeoEventId],
          ['==', ['get', 'opacity'], 0]
        ],
        1,
        [
          'case',
          [
            'all',
            ['==', isEditEvent, true],
            ['==', ['get', 'id'], selectedGeoEventId]
          ],
          0,
          ['get', 'opacity']
        ]
      ]
    }
  };
};

const labelStyleCommon: LayerProps = {
  type: 'symbol',
  layout: {
    'text-font': FONT_NAMES.regular,
    'text-field': [
      'format',
      ['get', 'name'],
      { 'text-font': ['literal', FONT_NAMES.bold] },
      '\n',
      {},
      ['get', 'acreageStr'],
      { 'font-scale': 0.7 }
    ],
    'text-anchor': 'top',
    'text-justify': 'auto',
    'symbol-sort-key': ['get', 'indexInv'],
    'symbol-z-order': 'source'
  },
  paint: {
    'text-halo-width': 1.8
  }
};

const labelStyleLight: LayerProps = {
  ...labelStyleCommon,
  paint: {
    ...labelStyleCommon.paint,
    'text-color': '#333333',
    'text-halo-color': 'rgba(255,255,255,0.8)'
  }
};

const labelStyleDark: LayerProps = {
  ...labelStyleCommon,
  paint: {
    ...labelStyleCommon.paint,
    'text-color': '#ffffff',
    'text-halo-color': 'rgba(0,0,0,0.8)'
  }
};

const getLabelStyle = (
  lightBaseLayer: boolean,
  selectedGeoEventId: number
): LayerProps => {
  const { layout, ...style } = lightBaseLayer
    ? labelStyleLight
    : labelStyleDark;
  return {
    ...style,
    layout: {
      ...layout,
      'symbol-sort-key': [
        // ensure it is always on top if it is selected
        'case',
        ['==', ['get', 'id'], selectedGeoEventId],
        -99999,
        ['get', 'indexInv']
      ]
    },
    paint: {
      ...style.paint,
      'text-opacity': [
        'step',
        ['zoom'],
        ['case', ['==', ['get', 'id'], selectedGeoEventId], 1, 0],
        LABELS_MIN_ZOOM,
        [
          'case',
          [
            'any',
            ['!=', ['get', 'opacity'], 0],
            ['==', ['get', 'id'], selectedGeoEventId]
          ],
          1,
          0
        ]
      ]
    }
  };
};

const toGeojsonFeature = (
  geoEvent: WildfireListItem,
  isFadable: boolean,
  locale: string
): Feature<Geometry, GeoEventFeatureProperties> => {
  const {
    isActive,
    id,
    name,
    data: { acreage, isPrescribed, containment }
  } = geoEvent;
  const icon = getGeoEventIcon(geoEvent);
  const geoEventScale = getGeoEventScale(geoEvent);
  const opacity = getOpacityForGeoEventMarker(geoEvent, isFadable);
  const acreageStr = getAcreageStr(acreage, containment, locale);

  // Sort indexes for labels and text. Inactive and Rx fires on the bottom,
  // then oldest to newest.
  const index =
    isActive && !isPrescribed ? Date.parse(geoEvent.dateCreated) : 0;
  const indexInv = 1 / (index + 1);

  // Calculate scale and associated offset.
  const iconScale = icon.scale || 1.0;
  const scale = geoEventScale * iconScale;
  const offset = icon.offset
    ? [icon.offset[0] * scale, icon.offset[1] * scale]
    : [0, 0];

  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [geoEvent.lng, geoEvent.lat]
    },
    properties: {
      id,
      name,
      icon: icon.name,
      isActive,
      acreage,
      scale,
      offset,
      opacity,
      index,
      indexInv,
      acreageStr,
      geoEventId: id
    }
  };
};

const GeoEventsLayer = (props: GeoEventsLayerProps): JSX.Element => {
  const {
    geoEvents,
    isFadable = false,
    selectedGeoEventId = 0,
    isEdit = false,
    interactive = true
  } = props;
  const history = useHistory();
  const { isLightBaseLayer } = useMapLayersState();
  const { clearSelectedPois } = usePoisState();

  const handleClick = useCallback(
    (geoJsonFeatures: MapboxFeature<GeoEventFeatureProperties>[]) => {
      // If there is ambiguity about which icon the user clicked on, select the
      // largest fire that has a higher index (based on activity/date created)
      const features = [...geoJsonFeatures];
      features.sort((a, b) => {
        if (
          a.properties.isActive === b.properties.isActive &&
          a.properties.acreage &&
          b.properties.acreage
        ) {
          return b.properties.acreage - a.properties.acreage;
        }
        return a.properties.isActive ? -1 : 1;
      });

      const feature = features[0];
      const { id, opacity } = feature.properties;
      if (opacity > 0) {
        // Close any selected POI dialog modal
        // Short setTimeout() is necessary to wait for POI array to fill (see usePoisState())
        setTimeout(() => clearSelectedPois(), 10);
        history.push(`/i/${id}`);
      }
    },
    [history, clearSelectedPois]
  );

  useMapLayerEvents<GeoEventFeatureProperties>({
    layerId: GEO_EVENT_ICONS_LAYER_ID,
    onClick: interactive ? handleClick : noop
  });

  const { i18n } = useTranslation();

  // do not modify the geojson based on selectedState or it will have to re-render.
  const geojson: FeatureCollection<Geometry, GeoEventFeatureProperties> =
    useMemo(
      () => ({
        type: 'FeatureCollection',
        features: geoEvents.map((geoEvent) => {
          return toGeojsonFeature(
            geoEvent,
            isFadable,
            i18n.resolvedLanguage || DEFAULT_LOCALE
          );
        })
      }),
      [geoEvents, isFadable, i18n.resolvedLanguage]
    );

  return (
    <Source id={GEO_EVENT_SOURCE_ID} type="geojson" data={geojson}>
      <Layer
        id={GEO_EVENT_ICONS_LAYER_ID}
        {...getIconStyle(selectedGeoEventId, isEdit)}
      />
      <Layer
        id={LABELS_LAYER_ID}
        {...getLabelStyle(isLightBaseLayer, selectedGeoEventId)}
      />
    </Source>
  );
};

export default GeoEventsLayer;
