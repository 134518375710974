import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import EN from './locales/en/common.json';
import ES from './locales/es/common.json';
import TL from './locales/tl/common.json';
import VI from './locales/vi/common.json';
import ZH from './locales/zh/common.json';
import { DEFAULT_LOCALE, Language, setLanguage } from './utils';

const options: InitOptions = {
  fallbackLng: DEFAULT_LOCALE,
  supportedLngs: ['en', 'es', 'tl', 'vi', 'zh'],
  // make sure we're getting `en`, not `en-us` from language detector
  load: 'languageOnly',
  debug:
    import.meta.env.VITE_ENV !== 'production' &&
    import.meta.env.VITE_ENV !== 'test',
  ns: ['common'],
  defaultNS: 'common',
  resources: {
    en: {
      common: EN
    },
    es: {
      common: ES
    },
    tl: {
      common: TL
    },
    vi: {
      common: VI
    },
    zh: {
      common: ZH
    }
  },
  // options for LanguageDetector
  detection: {
    order: ['localStorage', 'navigator']
  }
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(options);

// for initial load - if the user is auto-detected into a language
if (i18n.resolvedLanguage !== DEFAULT_LOCALE) {
  // ensure that momentjs uses the correct locale as well
  setLanguage(i18n.resolvedLanguage as Language);
}

export default i18n;
