import { useQuery } from '@tanstack/react-query';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { API } from 'api';
import HeaderBar from 'components/HeaderBar';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import PageLayout from 'components/PageLayout';

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fetchGeoEvent = (incidentId: string) => {
  return API.get(`geo_events/search/?incident_id=${incidentId}`);
};

// This page handle legacy /incident/:incidentId routes. New geo_events are no longer created with an incident_id
//   so this only works for geo_events up until Jan 2024
export const LegacyIncidentToGeoEventRedirector = (
  props: RouteComponentProps<{ incidentId: string }>
): JSX.Element => {
  const {
    match: {
      params: { incidentId }
    }
  } = props;
  const { t } = useTranslation();

  const query = useQuery({
    queryKey: ['search-geoevent', incidentId],
    queryFn: () => fetchGeoEvent(incidentId)
  });
  const { data, isLoading, isError } = query;

  if (isLoading) {
    return (
      <PageLayout data-cname="IncidentPage">
        {{
          content: <LoadingAndErrors isLoading />,
          headerBar: <HeaderBar title={t('common.loading')} action="/" />
        }}
      </PageLayout>
    );
  }

  const geoEventId = data?.data[0]?.id;

  if (isError || !geoEventId) {
    return (
      <PageLayout data-cname="IncidentPage">
        {{
          content: <LoadingAndErrors />,
          headerBar: <HeaderBar title={t('common.error')} action="/" />
        }}
      </PageLayout>
    );
  }

  return <Redirect to={`/i/${geoEventId}`} />;
};
