import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { Poi, PoiType } from 'shared/types';

type SelectedPoisState = {
  selectedPois: Poi[];
  activeIndex: number;
  lastSelectionTimestamp: number;
};

type UsePoisState = {
  selectedPois: Poi[];
  selectedPoi?: Poi;
  activeIndex: number;
  setSelectedPoi: (poi: Poi) => void;
  clearSelectedPois: () => void;
  selectPreviousPoi: () => void;
  selectNextPoi: () => void;
};

const CLICK_EVENT_GROUPING_MS_LIMIT = 500;

// Earlier in the array = first seen in the POI dialog
const POI_TYPE_ORDER: PoiType[] = [
  'externalGeoEvent',
  'evacZone',
  'firePerimeter',
  'place',
  'powerOutage',
  'electricalLine',
  'gasPipeline',
  'radioTower',
  'electricRetail',
  'rfw',
  'responsibilityArea'
];

const DEFAULT_SELECTED_POIS_STATE: SelectedPoisState = {
  selectedPois: [],
  activeIndex: 0,
  lastSelectionTimestamp: 0
};

const poiStateAtom = atom({
  key: 'POIS_STATE',
  default: DEFAULT_SELECTED_POIS_STATE
});

export const sortPoisByType = (pois: Poi[], order: PoiType[]): Poi[] =>
  pois.sort((a, b) => {
    const aIndex = order.indexOf(a.type);
    const bIndex = order.indexOf(b.type);
    return aIndex - bIndex;
  });

export const usePoisState = (): UsePoisState => {
  const [poiState, setPoiState] = useRecoilState(poiStateAtom);

  const setSelectedPoi = useCallback(
    (poi: Poi) => {
      setPoiState((prev) => {
        const selectionTimestamp = Date.now();

        if (
          poi.type === 'aircraft' ||
          prev.lastSelectionTimestamp + CLICK_EVENT_GROUPING_MS_LIMIT <
            selectionTimestamp ||
          prev.selectedPois?.slice(-1)[0].type === poi.type // Prevent double+ clicks from adding the same poi twice
        ) {
          // You can only select one aircraft at a time and prevents adding more pois
          // to the current selection by clicking on another map zone
          return {
            selectedPois: [poi],
            activeIndex: 0,
            lastSelectionTimestamp: selectionTimestamp
          };
        }

        if (poi.type === 'mapPin') {
          return {
            selectedPois: [poi],
            activeIndex: 0,
            lastSelectionTimestamp: selectionTimestamp
          };
        }

        // Consecutive selected pois are added to the current selection
        // This happens when a user clicks on a map zone with overlapping layers
        const { activeIndex } = prev;
        const selectedPois = [
          ...prev.selectedPois.filter((p) => p.type !== 'mapPin')
        ];

        return {
          selectedPois: sortPoisByType([...selectedPois, poi], POI_TYPE_ORDER),
          activeIndex,
          lastSelectionTimestamp: selectionTimestamp
        };
      });
    },
    [setPoiState]
  );

  const clearSelectedPois = useCallback(() => {
    setPoiState({
      selectedPois: [],
      activeIndex: 0,
      lastSelectionTimestamp: 0
    });
  }, [setPoiState]);

  const selectPreviousPoi = useCallback(() => {
    setPoiState((prev) => ({
      ...prev,
      activeIndex: Math.max(0, prev.activeIndex - 1)
    }));
  }, [setPoiState]);

  const selectNextPoi = useCallback(() => {
    setPoiState((prev) => ({
      ...prev,
      activeIndex: Math.min(prev.selectedPois.length - 1, prev.activeIndex + 1)
    }));
  }, [setPoiState]);

  const selectedPoi = poiState.selectedPois[poiState.activeIndex];

  return {
    selectedPois: poiState.selectedPois,
    selectedPoi,
    activeIndex: poiState.activeIndex,
    setSelectedPoi,
    clearSelectedPois,
    selectPreviousPoi,
    selectNextPoi
  };
};
