import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

type ApplePayButtonProps = {
  onClick: () => void;
  variant?: 'default' | 'donation';
  disabled?: boolean;
};

const useStyles = makeStyles()((theme) => ({
  applePayButton: {
    borderRadius: theme.shape.borderRadius * 2,
    '-apple-pay-button-type': 'pay',
    '-apple-pay-button-style': 'black',
    '-webkit-appearance': '-apple-pay-button',
    width: '100%',
    height: 56,
    '&:disabled': {
      opacity: 0.7
    }
  },
  applePayDonateButton: {
    '-apple-pay-button-type': 'donate'
  }
}));

const ApplePayButton = (props: ApplePayButtonProps): JSX.Element => {
  const { variant = 'default', onClick, disabled = false } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  return (
    <button
      aria-label={t('recurlyAccountInformation.applePay.button')}
      type="button"
      className={cx(
        classes.applePayButton,
        variant === 'donation' && classes.applePayDonateButton
      )}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default ApplePayButton;
