import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { API, CacheAPI } from '../../api';
import {
  AlertCamera,
  RealtimeAlertCamera,
  UseAlertCamera,
  UseAlertCameraProps,
  UseAlertCameras,
  UseAlertCamerasProps
} from './useAlertCameras.types';
import { groupCamerasByLocation } from './useAlertCameras.utils';

// don't even show cameras that haven't been online in 30 days
const STALE_THRESHOLD_SECONDS = 1000 * 60 * 60 * 24 * 30;

const LOCAL_CACHE_TIME_MS = 1000 * 60 * 60 * 60 * 24; // 24 hours

const REALTIME_POLL_FREQUENCY_MS = 1000 * 15; // 15 sec

type AlertCameraServer = AlertCamera & {
  imageTimestamp: string;
};

type AlertCamerasListResponse = {
  data: AlertCameraServer[];
};

type RealtimeAlertCamerasResponse = {
  data: RealtimeAlertCamera[];
};

type AlertCameraInstanceResponse = {
  data: AlertCameraServer;
};

// 100 = three decimal places
const CLUSTER_PRECISION_FACTOR = 1000;
const adaptAlertCameraData = (camera: AlertCameraServer): AlertCamera => ({
  ...camera,
  imageTimestamp: new Date(camera.imageTimestamp),
  // Only PTZ cameras should be clustered together.
  approximateLatlng: camera.hasPtz
    ? {
        lat:
          Math.round(camera.latlng.lat * CLUSTER_PRECISION_FACTOR) /
          CLUSTER_PRECISION_FACTOR,
        lng:
          Math.round(camera.latlng.lng * CLUSTER_PRECISION_FACTOR) /
          CLUSTER_PRECISION_FACTOR
      }
    : camera.latlng
});

const adaptAlertCameraListData = (
  cameras: AlertCameraServer[]
): AlertCamera[] => cameras.map((c) => adaptAlertCameraData(c));

export const fetchAlertCameras = async (): Promise<AlertCameraServer[]> => {
  const res = await CacheAPI.get<null, AlertCamerasListResponse>(
    `cameras/?updated_within_seconds=${STALE_THRESHOLD_SECONDS}`
  );
  return res.data;
};

const fetchRealtimeAlertCameras = async (): Promise<RealtimeAlertCamera[]> => {
  // Realtime API URL is configured to only cache for short amounts of time
  const res = await CacheAPI.get<null, RealtimeAlertCamerasResponse>(
    `cameras/realtime`
  );
  return res.data;
};

export const fetchAlertCameraDetails = async (
  id: string
): Promise<AlertCameraServer> => {
  const res = await API.get<null, AlertCameraInstanceResponse>(`cameras/${id}`);
  return res.data;
};

export const useAlertCameras = (
  props: UseAlertCamerasProps
): UseAlertCameras => {
  const { enabled, withRealtimeUpdates = false } = props;

  const {
    data: allCamerasData,
    isLoading,
    error
  } = useQuery({
    queryKey: ['alertCameras'],
    queryFn: fetchAlertCameras,
    select: adaptAlertCameraListData,
    staleTime: LOCAL_CACHE_TIME_MS,
    gcTime: LOCAL_CACHE_TIME_MS,
    enabled
  });

  const { data: realtimeAlertCameras, error: realtimeError } = useQuery({
    queryKey: ['realtimeAlertCameras'],
    queryFn: fetchRealtimeAlertCameras,
    refetchInterval: REALTIME_POLL_FREQUENCY_MS,
    enabled: enabled && withRealtimeUpdates
  });

  const allCamerasDataWithRealtime = useMemo(() => {
    const allCamerasDataSafe = allCamerasData || [];
    return allCamerasDataSafe.map((c) => {
      const realtimeAlertCamerasSafe =
        (withRealtimeUpdates && realtimeAlertCameras) || [];
      const realtimeCamera =
        realtimeAlertCamerasSafe.find((rc) => rc.id === c.id) || {};
      return {
        ...c,
        ...realtimeCamera
      };
    });
  }, [allCamerasData, withRealtimeUpdates, realtimeAlertCameras]);

  const cameraGroups = useMemo(() => {
    return groupCamerasByLocation(allCamerasDataWithRealtime);
  }, [allCamerasDataWithRealtime]);

  return {
    alertCameras: allCamerasDataWithRealtime,
    alertCameraGroups: cameraGroups,
    isLoading,
    error: error || realtimeError
  };
};

export const useAlertCamera = (props: UseAlertCameraProps): UseAlertCamera => {
  const { id, refetchInterval } = props;

  const {
    data: alertCamera,
    isLoading,
    error
  } = useQuery({
    queryKey: [`alertCameraInstance-${id}}`],
    queryFn: () => fetchAlertCameraDetails(id),
    enabled: true,
    refetchInterval
  });

  const adjustedCamera = alertCamera
    ? adaptAlertCameraData(alertCamera)
    : undefined;

  return { alertCamera: adjustedCamera, isLoading, error };
};
