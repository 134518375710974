import { useMemo } from 'react';
import { useQueries } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { API } from 'api';
import { TARGET_ZOOM } from 'components/IncidentsMap/constants';
import { ExternalGeoEvent } from 'shared/types';
import { useAuthState, useMapState } from 'state';
import { getTilePairsFromBounds } from 'components/IncidentsMap/tile_utils';

type UseExternalGeoEvents = {
  externalGeoEvents: ExternalGeoEvent[];
};

const EXTERNAL_GEO_EVENTS_URL = 'geo_events_external/';
const REFETCH_MS = 1000 * 20;

const fetchExternalGeoEvents = (
  x: number,
  y: number
): Promise<AxiosResponse<ExternalGeoEvent>> => {
  const params = new URLSearchParams({
    zyx: `${TARGET_ZOOM},${y},${x}`,
    lookback_minutes: '30'
  });
  return API.get<ExternalGeoEvent>(
    `${EXTERNAL_GEO_EVENTS_URL}?${params.toString()}`
  );
};

export const filterVisible = (entry?: ExternalGeoEvent): boolean => {
  // provide some client side filtering for what is shown to reporters. Pulsepoint records created
  // outside of our criteria are still written to the db but are not routed to the normal slack channels
  if (!entry) return false;
  if (entry.externalSource !== 'pulsepoint') return true;
  if (entry.channel === 'bots-extra-alerts') return false;
  return true;
};

export const useExternalGeoEvents = (): UseExternalGeoEvents => {
  const { activeMapBounds } = useMapState();
  const tiles = getTilePairsFromBounds(activeMapBounds);
  const {
    permissions: { isInternalUser }
  } = useAuthState();

  const queries = tiles.map((tile) => {
    return {
      queryKey: ['externalGeoEvents', tile[0], tile[1]],
      queryFn: () => fetchExternalGeoEvents(tile[0], tile[1]),
      refetchInterval: REFETCH_MS,
      enabled: isInternalUser
    };
  });

  const results = useQueries({ queries });

  // @ts-ignore
  const data: ExternalGeoEvent[] = useMemo(
    () =>
      results
        .filter((e) => e.isSuccess)
        .flatMap((result) => result.data?.data)
        .filter((result) => filterVisible(result)),
    [results]
  );

  return { externalGeoEvents: data };
};
