import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  formContainer: {
    overflowY: 'auto',
    height: '100%',
    width: '100%',
    maxWidth: 720
  },
  form: {
    paddingTop: theme.spacing(3),
    paddingBottom: 'max(env(safe-area-inset-bottom), 24px)',
    minHeight: '100%',
    width: '100%'
  },
  menuItem: {
    textTransform: 'capitalize'
  },
  itemIcon: {
    minWidth: 'fit-content',
    marginRight: theme.spacing(1)
  },
  button: {
    minHeight: 48,
    textTransform: 'none',
    borderRadius: theme.shape.borderRadius * 1.34
  },
  geoEventOption: {
    display: 'flex',
    // alignItems: 'center',
    height: 38
  },
  geoEventIcon: {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1.5)
  }
}));
