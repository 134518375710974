import { Container, Grid, Typography, Button, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import VectorLogo from '../../components/VectorLogo';
import HeaderBar from '../../components/HeaderBar';
import PageLayout from '../../components/PageLayout';

const useStyles = makeStyles()((theme) => {
  const { palette } = theme;
  return {
    root: {
      backgroundColor: palette.common.white,
      overflow: 'auto',
      width: '100%'
    },
    container: {
      height: '100%'
    },
    button: {
      borderRadius: theme.shape.borderRadius * 2,
      padding: theme.spacing(2),
      fontWeight: theme.typography.fontWeightMedium,
      height: 48
    }
  };
});

export const Content = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('anonymousPhotoSubmitted.title')}</title>
      </Helmet>
      <div className={classes.root}>
        <Container maxWidth="sm" className={classes.container}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Box sx={{ textAlign: 'center' }}>
                <VectorLogo sx={{ paddingTop: 3 }} />
              </Box>

              <Typography variant="h3" align="center">
                {t('anonymousPhotoSubmitted.success')}
              </Typography>
            </Grid>

            <Grid item>
              <Typography paragraph>
                {t('anonymousPhotoSubmitted.description')}
              </Typography>
              <Typography paragraph>
                {t('anonymousPhotoSubmitted.warning')}
              </Typography>
            </Grid>

            <Grid item>
              <Button
                fullWidth
                className={classes.button}
                to="/"
                component={Link}
              >
                {t('anonymousPhotoSubmitted.button')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

const AnonymousPhotoSubmitted = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PageLayout data-cname="AnonymousPhotoSubmittedPage">
      {{
        content: <Content />,
        headerBar: (
          <HeaderBar action="/" title={t('anonymousPhotoSubmitted.title')} />
        )
      }}
    </PageLayout>
  );
};

AnonymousPhotoSubmitted.defaultProps = {
  isFirst: true
};

export default AnonymousPhotoSubmitted;
