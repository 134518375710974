import { useRouteMatch } from 'react-router-dom';

type LocationGeoEventParams = {
  locationId?: string;
};

export const useSelectedLocationGeoEventId = (): number | undefined => {
  // Using `useRouteMatch()` instead of `useParams()` since we're not always inside the Location <Router>
  const match = useRouteMatch<LocationGeoEventParams>('/location/:locationId');
  const locationIdStr = match?.params.locationId;

  return locationIdStr ? parseInt(locationIdStr, 10) : undefined;
};
