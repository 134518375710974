import { useEffect, ReactNode, useContext } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import InfiniteScroll from 'react-infinite-scroll-component';
import { focusManager, useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { API, CacheAPI } from 'api';
import { Report, ReportMedia } from 'shared/types';
import { MAIN_REFETCH_INTERVAL_MS } from 'components/Map/constants';
import { LoadingAndErrors } from '../LoadingAndErrors';
import ReportCard from './ReportCard';
import { useAuthState, useCacheState } from '../../state';
import { MapEntityDrawerContext } from '../Map/MapEntityDrawer';

type ReportListProps = {
  geoEventId: number;
};

const fetchReports = (
  geoEventId: number,
  { limit, offset }: { limit: number; offset: number },
  cacheBusterTs: number | null
  // todo: react-query function type returns can be addressed after react-query 5 upgrade
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) => {
  const baseUrl = `reports/?geo_event_id=${geoEventId}&is_moderated=true&is_active=true&limit=${limit}&offset=${offset}`;
  if (cacheBusterTs) {
    return CacheAPI.get(`${baseUrl}&ts=${cacheBusterTs}`);
  }
  return API.get(baseUrl);
};

const useStyles = makeStyles()((theme) => ({
  loadingSpinner: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3)
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}));

const PAGINATION_LIMIT = 20;

const LoadingSpinner = (): JSX.Element => {
  const { classes } = useStyles();
  return (
    <Box className={classes.loadingSpinner}>
      <LoadingAndErrors isLoading />
    </Box>
  );
};

const getReportMedia = (media: ReportMedia[]): ReportMedia | null =>
  media.length ? media[0] : null;

const ReportList = (props: ReportListProps): JSX.Element | null => {
  const { geoEventId } = props;
  const { classes } = useStyles();
  const {
    permissions: { canReport }
  } = useAuthState();
  const { cacheBusterTs } = useCacheState();
  const { t } = useTranslation();
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up('tablet'));
  const { scrollElementRef } = useContext(MapEntityDrawerContext);

  // Because we use history.goBack from the report form - we need to set focus on the page to ensure
  //   we refetch the infiniteReports query and reporters see their new report.
  // Otherwise the query uses the disk cache and does not refetch, even with modifications to gcTime/staleTime
  useEffect(() => {
    if (canReport) {
      focusManager.setFocused(true);
    }
  }, [canReport]);

  const query = useInfiniteQuery({
    queryKey: ['infiniteReports', geoEventId, cacheBusterTs],
    queryFn: ({ pageParam }) =>
      fetchReports(
        geoEventId,
        { limit: PAGINATION_LIMIT, offset: pageParam },
        cacheBusterTs
      ),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage.data.next;
    },
    refetchInterval: MAIN_REFETCH_INTERVAL_MS
  });

  const { fetchNextPage, hasNextPage, data, isLoading } = query;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const reports = (
    data?.pages.flatMap((page) => page.data.results) ?? []
  ).filter(Boolean) as Report[];

  if (!reports.length) return null;

  /**
   * Re-render the infinite scroll element when the scrollable target changes to make
   * sure the "next" callback function is called. It's safe to ignore this warning
   * "...are trying to pass scrollableTarget but it is null. This might happen because
   * the element may not have been added to DOM yet."
   */

  const key = isLargeMediaQuery
    ? 'reports-list-desktop'
    : 'reports-list-mobile';

  return (
    <InfiniteScroll
      key={key}
      dataLength={reports.length}
      next={fetchNextPage}
      hasMore={!!hasNextPage}
      loader={<LoadingSpinner />}
      scrollableTarget={scrollElementRef?.current as ReactNode}
      // Prevent InfiniteScroll default style clipping
      // off box shadow in our child container
      style={{ overflow: 'inherit' }}
      endMessage={
        (data?.pages ?? [])?.length > 1 && (
          <Typography
            gutterBottom
            variant="body2"
            component="p"
            color="textSecondary"
            align="center"
          >
            {t('geoEvent.reports.bottomReached')}
          </Typography>
        )
      }
    >
      {reports.map((report) => {
        const { id, media: mediaList } = report;
        const media = getReportMedia(mediaList);

        return (
          <ReportCard
            key={id}
            media={media}
            report={report}
            className={classes.borderBottom}
          />
        );
      })}
    </InfiniteScroll>
  );
};

export default ReportList;
