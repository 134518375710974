import 'shared/bitmap-polyfill';

// @ts-expect-error
// eslint-disable-next-line import/no-extraneous-dependencies
import hasOwn from 'object.hasown'; // Shim for Object.hasOwn() required by iOS 15

export const applyPolyfills = (): void => {
  if (!Object.hasOwn) {
    hasOwn.shim();
  }
};
