import { LayerProps } from 'react-map-gl/maplibre';

const addVisible = (style: LayerProps, visible: boolean): LayerProps => {
  // add the visibility prop to a pre-existing style based on the visible boolean
  const visibilityValue = visible ? 'visible' : 'none';
  // @ts-ignore
  const { layout = {} } = style;
  return {
    ...style,
    // @ts-ignore
    layout: {
      ...layout,
      visibility: visibilityValue
    }
  };
};

export default addVisible;
