import { ReactEventHandler } from 'react';

type ResizedImageProps = {
  alt: string;
  className?: string;
  s3Path: string;
  loading: 'eager' | 'lazy';
};

const getSrcSmall = (s3Path: string): string =>
  `${import.meta.env.VITE_BASE_MEDIA_URL_RESIZED}${s3Path}`;

const getSrcLarge = (s3Path: string): string =>
  `${import.meta.env.VITE_BASE_MEDIA_URL}${s3Path}`;

const ResizedImage = (props: ResizedImageProps): JSX.Element => {
  const { alt, s3Path, ...otherProps } = props;

  const handleLoadError: ReactEventHandler<HTMLImageElement> = (event) => {
    const currentSrc = event.currentTarget.src;

    // Prevent infinite loop if both thumb and original fail by only fixing thumbnails
    if (
      !currentSrc.includes(
        import.meta.env.VITE_BASE_MEDIA_URL_RESIZED as string
      )
    ) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    event.currentTarget.src = getSrcLarge(s3Path);
  };

  return (
    <img
      alt={alt}
      onError={handleLoadError}
      src={getSrcSmall(s3Path)}
      {...otherProps}
    />
  );
};

export default ResizedImage;
