import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Link
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import OnboardingLayout from 'components/OnboardingLayout';
import useOnboardingState from 'state/useOnboardingState';

const useStyles = makeStyles()((theme) => ({
  list: {
    listStyleType: 'disc'
  },
  listItem: {
    display: 'list-item',
    marginLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  howItWorks: {
    marginTop: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold
  },
  link: {
    fontWeight: 'bold'
  }
}));

const WelcomeContent = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { notificationsEnabled } = useOnboardingState();

  const handleGetStarted = (): void => {
    if (notificationsEnabled) {
      history.push('/onboarding_notifications');
      return;
    }
    history.push('/permissions');
  };

  return (
    <OnboardingLayout
      title={t('welcome.title')}
      footerText={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <b>{t('welcome.footer')}</b>
          </Grid>
          <Grid item xs={12}>
            <Trans i18nKey="welcome.tos">
              By clicking Get Started, you are agreeing to our
              <Link
                className={classes.link}
                target="blank"
                href="https://www.watchduty.org/legal/eula"
                underline="always"
              >
                Terms of Service
              </Link>
              and
              <Link
                className={classes.link}
                target="blank"
                href="https://www.watchduty.org/legal/privacy-policy"
                underline="always"
              >
                Privacy Policy
              </Link>
            </Trans>
          </Grid>
        </Grid>
      }
      buttonTitle={t('welcome.button')}
      onClick={handleGetStarted}
    >
      <Grid item xs={12}>
        <Typography>
          <Trans i18nKey="welcome.description">
            We are a <b>501(c)(3) nonprofit</b> providing real-time alerting and
            monitoring of wildfires in the American West.
          </Trans>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" className={classes.howItWorks}>
          {t('welcome.howItWorks.title')}
        </Typography>
        <List dense disablePadding className={classes.list}>
          <ListItem disableGutters className={classes.listItem}>
            <ListItemText
              primary={t('welcome.howItWorks.item1')}
              primaryTypographyProps={{ variant: 'body1' }}
            />
          </ListItem>
          <ListItem disableGutters className={classes.listItem}>
            <ListItemText
              primary={t('welcome.howItWorks.item2')}
              primaryTypographyProps={{ variant: 'body1' }}
            />
          </ListItem>
        </List>
      </Grid>
    </OnboardingLayout>
  );
};

export default WelcomeContent;
