import { useMemo } from 'react';
import {
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useHistory } from 'react-router-dom';
import { ChildLocation, LocationType } from 'shared/types';
import { LocationTypes } from '../../../constants';

type GeoEventLocationsProps = {
  locations: ChildLocation[];
};

type LocationSection = {
  locationType: LocationType;
  locations: ChildLocation[];
};

type LocationsListProps = {
  header: string;
  locations: ChildLocation[];
};

const mapLocationTypeToTransKey = {
  [LocationTypes.evacShelter]: 'location.types.shelter',
  [LocationTypes.animalShelter]: 'location.types.animalShelter',
  [LocationTypes.info]: 'location.types.info',
  [LocationTypes.general]: 'location.types.general',
  [LocationTypes.food]: 'location.types.food'
};

const useStyles = makeStyles()((theme) => ({
  list: {
    paddingTop: 0
  },
  listSubheader: {
    padding: theme.spacing(3, 2, 2),
    lineHeight: 1,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  listItem: {
    marginRight: theme.spacing(1)
  },
  locationTitle: {
    fontWeight: theme.typography.fontWeightMedium
  },
  textOneLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const LocationsList = (props: LocationsListProps): JSX.Element => {
  const { header, locations } = props;
  const { classes, cx } = useStyles();
  const history = useHistory();

  const sortedLocations = useMemo(
    () =>
      locations.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      ),
    [locations]
  );

  return (
    <List
      subheader={
        <ListSubheader
          color="inherit"
          className={classes.listSubheader}
          disableSticky
        >
          <Typography variant="h3">{header}</Typography>
        </ListSubheader>
      }
    >
      {sortedLocations.map((location) => (
        <ListItemButton
          key={location.id}
          divider
          onClick={() => history.push(`/location/${location.id}`)}
        >
          <ListItemText
            primary={location.name}
            secondary={location.address}
            primaryTypographyProps={{
              className: cx(classes.locationTitle, classes.textOneLine)
            }}
            secondaryTypographyProps={{
              variant: 'body1',
              color: 'textPrimary',
              className: classes.textOneLine
            }}
            className={classes.listItem}
          />
          <ChevronRightIcon />
        </ListItemButton>
      ))}
    </List>
  );
};

const GeoEventLocations = (props: GeoEventLocationsProps): JSX.Element => {
  const { locations } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const locationsByType = useMemo(
    () =>
      locations.reduce((sections, location) => {
        if (!sections[location.data.locationType]) {
          // eslint-disable-next-line no-param-reassign
          sections[location.data.locationType] = {
            locationType: location.data.locationType,
            locations: [location]
          };
        } else {
          sections[location.data.locationType]?.locations.push(location);
        }
        return sections;
      }, {} as Partial<Record<LocationType, LocationSection>>),
    [locations]
  );

  return (
    <List className={classes.list}>
      {!!locationsByType.evac_shelter?.locations.length && (
        <LocationsList
          header={t(mapLocationTypeToTransKey[LocationTypes.evacShelter])}
          locations={locationsByType.evac_shelter.locations}
        />
      )}
      {!!locationsByType.animal_shelter?.locations.length && (
        <LocationsList
          header={t(mapLocationTypeToTransKey[LocationTypes.animalShelter])}
          locations={locationsByType.animal_shelter.locations}
        />
      )}
      {!!locationsByType.info?.locations.length && (
        <LocationsList
          header={t(mapLocationTypeToTransKey[LocationTypes.info])}
          locations={locationsByType.info.locations}
        />
      )}
      {!!locationsByType.general?.locations.length && (
        <LocationsList
          header={t(mapLocationTypeToTransKey[LocationTypes.general])}
          locations={locationsByType.general.locations}
        />
      )}
      {!!locationsByType.food?.locations.length && (
        <LocationsList
          header={t(mapLocationTypeToTransKey[LocationTypes.food])}
          locations={locationsByType.food.locations}
        />
      )}
    </List>
  );
};

export default GeoEventLocations;
