import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Button, Container, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import HeaderBar from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import useLocationSearchParams from 'hooks/useLocationSearchParams';
import { API } from 'api';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { useAuthState } from 'state';
import { MapLayers } from 'components/Map/constants';
import useMapLayersState from 'state/useMapLayersState';
import { registerDeviceWithWatchDuty } from 'shared/device';
import useIapState from 'state/useIapState';
import { VerifyPaymentData } from 'shared/types';
import { ANALYTICS_EVENTS, logEvent } from 'shared/analytics';

const verifyPurchaseApi = async (data: {
  clientToken: string;
}): Promise<VerifyPaymentData> => {
  const response = await API.post<
    { clientToken: string },
    AxiosResponse<VerifyPaymentData>
  >('recurly_integration/payment_verification/', data);
  return response.data;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    borderRadius: theme.shape.borderRadius * 2,
    fontWeight: theme.typography.fontWeightMedium,
    minHeight: 48,
    marginTop: theme.spacing(2)
  }
}));

const Content = (): JSX.Element => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const queryParams = useLocationSearchParams();
  const { isAuthenticated, loginSuccess } = useAuthState();
  const { setMapLayerVisibility } = useMapLayersState();
  const { resetIapState } = useIapState();
  const queryClient = useQueryClient();

  const clientToken = queryParams.get('clientToken');
  const isMembershipUpgrade = queryParams.get('upgrade') === 'true';

  const enableFlightTrackerLayer = useCallback((): void => {
    setMapLayerVisibility(MapLayers.FLIGHT_TRACKER, true);
  }, [setMapLayerVisibility]);

  const {
    mutate: verifyPurchase,
    isPending: verifyingPurchase,
    error
  } = useMutation({
    mutationFn: verifyPurchaseApi,
    onSuccess: (data) => {
      resetIapState();
      loginSuccess(data.key, data.user, true);
      enableFlightTrackerLayer();
      logEvent({
        name: ANALYTICS_EVENTS.SUPPORT.SUCCESS_MEMBERSHIP,
        params: {}
      });
      // associate user with token
      const params = { user: data.user };
      registerDeviceWithWatchDuty(params);
    }
  });

  useEffect(() => {
    if (!isAuthenticated && clientToken) {
      verifyPurchase({ clientToken });
      // remove query params
      window.history.replaceState({}, document.title, '/');
    }
  }, [clientToken, isAuthenticated, verifyPurchase]);

  useEffect(() => {
    if (!clientToken && isAuthenticated) {
      resetIapState();
      enableFlightTrackerLayer();
      queryClient.invalidateQueries({ queryKey: ['membership-status'] });
    }
  }, [
    clientToken,
    enableFlightTrackerLayer,
    queryClient,
    resetIapState,
    isAuthenticated
  ]);

  if (verifyingPurchase || error) {
    return (
      <LoadingAndErrors
        isLoading={verifyingPurchase}
        errorMessage={error ? t('common.unknownError') : undefined}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('membershipSuccess.title')}</title>
      </Helmet>

      <div className={classes.root}>
        <Container maxWidth="sm" className={classes.container}>
          <Box className={classes.contentContainer}>
            <Typography variant="h2" align="center" sx={{ marginBottom: 4 }}>
              <b>{t('membershipSuccess.subtitle')}</b>
            </Typography>

            <Typography align="center" sx={{ marginBottom: 3 }}>
              {t(
                `membershipSuccess.thanks.${
                  isMembershipUpgrade ? 'pro' : 'regular'
                }`
              )}
            </Typography>

            {!!clientToken && (
              <Typography align="center">
                {t('membershipSuccess.message')}
              </Typography>
            )}
          </Box>

          <Button
            fullWidth
            className={classes.button}
            component={Link}
            to={
              isMembershipUpgrade
                ? '/?membershipUpgrade=true'
                : '/?membershipSuccess=true'
            }
            replace
          >
            {t('membershipSuccess.button')}
          </Button>
        </Container>
      </div>
    </>
  );
};

const MembershipIapSuccess = (): JSX.Element => {
  return (
    <PageLayout data-cname="MembershipIapSuccessPage" showBanner={false}>
      {{
        content: <Content />,
        headerBar: <HeaderBar action="noControls" hideAction />
      }}
    </PageLayout>
  );
};

export default MembershipIapSuccess;
