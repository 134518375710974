import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { uniqBy } from 'lodash-es';
import { useAuthState } from 'state';
import useMapPlacesState from 'state/useMapPlacesState';
import { API } from 'api';
import { replaceAtIndex } from 'shared/utils';
import { PlaceLocation } from '../shared/types';
import usePrevious from './usePrevious';

type UseMapPlacesProps = {
  sync?: boolean;
};

type MapPlacesAPIData = {
  version: number;
  updatedAt: number;
  places: PlaceLocation[];
};

type UseMapPlacesHookReturn = {
  places: PlaceLocation[];
  addMapPlace: (placeLocation: PlaceLocation) => void;
  deleteMapPlace: (placeId: number) => void;
  updateMapPlace: (placeLocation: PlaceLocation) => void;
};

const MAP_PLACES_VERSION = 1;

const fetchMapPlaces = async (): Promise<MapPlacesAPIData | null> => {
  const response = await API.get('users/places/');
  const data = response.data?.data as MapPlacesAPIData;
  if (!data || data.version > MAP_PLACES_VERSION) {
    return null;
  }
  return data;
};

const updateMapPlaces = async (update: MapPlacesAPIData): Promise<void> => {
  await API.put('users/places/', update);
};

const useMapPlaces = (
  props: UseMapPlacesProps = {}
): UseMapPlacesHookReturn => {
  const { sync = false } = props;
  const { isAuthenticated } = useAuthState();
  const {
    updatedAt: localUpdatedAt,
    places: localPlaces,
    setMapPlaces: localSetMapPlaces,
    addMapPlace: localAddMapPlace,
    deleteMapPlace: localDeleteMapPlace,
    updateMapPlace: localUpdateMapPlace
  } = useMapPlacesState();
  // Keeps track of the mounted state of the component. Helps to prevent unnecessary syncing operations when "sync" prop is true.
  const mountedRef = useRef(false);

  /**
   * Keeps track of the syncing state of the component. Helps to prevent unnecessary
   * syncing operations when places data changes and a syncing operation is already active.
   */
  const syncingRef = useRef(false);

  const query = useQuery({
    queryKey: ['mapPlaces'],
    queryFn: fetchMapPlaces,
    staleTime: 1000 * 60 * 30, // 30 minutes
    gcTime: 1000 * 60 * 35, // 35 minutes - must be bigger than staleTime
    enabled: isAuthenticated
  });

  const mutation = useMutation({ mutationFn: updateMapPlaces });

  const places = useMemo(() => {
    // If the user does not have any data, or he is not authenticated
    if (!query.data) return localPlaces;

    // If the API data is 'newer' than the local data
    if (query.data.updatedAt > localUpdatedAt) {
      return query.data.places;
    }

    // If the local data is 'newer' than the API data
    if (localUpdatedAt > query.data.updatedAt) {
      return localPlaces;
    }

    // Combine the local and the API data, making sure to keep unique places by id
    return uniqBy(query.data.places.concat(localPlaces), 'id');
  }, [localPlaces, localUpdatedAt, query.data]);

  const loading = query.isLoading || query.isRefetching;
  const prevLoading = usePrevious(loading);

  const placesLength = places.length;
  const prevPlacesLength = usePrevious(placesLength);

  // Updates API places data, and optionally syncs the local data
  const syncPlaces = useCallback(
    async ({
      locationPlaces,
      localUpdate = true,
      updatedAt = Date.now()
    }: {
      locationPlaces: PlaceLocation[];
      localUpdate?: boolean;
      updatedAt?: number;
    }) => {
      mutation.mutate(
        { version: MAP_PLACES_VERSION, places: locationPlaces, updatedAt },
        {
          onSettled: () => {
            syncingRef.current = false;
          }
        }
      );
      if (localUpdate) localSetMapPlaces(locationPlaces, updatedAt);
    },
    [localSetMapPlaces, mutation]
  );

  /**
   * Runs when the user is authenticated, whenever we fetch data from the API
   * (fetching has finished), if we have new places data and if there's not
   * an active syncing operation.
   */
  useEffect(() => {
    if (
      isAuthenticated &&
      prevLoading &&
      !loading &&
      (prevPlacesLength !== placesLength ||
        (query.data === null && !!placesLength)) &&
      !syncingRef.current
    ) {
      syncingRef.current = true;
      syncPlaces({ locationPlaces: places });
    }
  }, [
    isAuthenticated,
    prevLoading,
    loading,
    prevPlacesLength,
    placesLength,
    syncPlaces,
    places,
    query.data
  ]);

  /**
   * Triggers a sync if the user is authenticated and "sync" prop is true;
   * currently only used on the "Places" page
   */
  useEffect(() => {
    if (isAuthenticated && sync && !mountedRef.current && !query.isLoading) {
      mountedRef.current = true;
      query.refetch();
    }
  }, [isAuthenticated, query, sync]);

  const addMapPlace = useCallback(
    (placeLocation: PlaceLocation) => {
      const updatedAt = Date.now();

      if (isAuthenticated) {
        syncPlaces({
          locationPlaces: [placeLocation].concat(places),
          localUpdate: false,
          updatedAt
        });
      }

      localAddMapPlace(placeLocation, updatedAt);
    },
    [isAuthenticated, localAddMapPlace, syncPlaces, places]
  );

  const deleteMapPlace = useCallback(
    (placeId: number) => {
      const updatedAt = Date.now();

      if (isAuthenticated) {
        syncPlaces({
          locationPlaces: places.filter((place) => place.id !== placeId),
          localUpdate: false,
          updatedAt
        });
      }

      localDeleteMapPlace(placeId, updatedAt);
    },
    [isAuthenticated, localDeleteMapPlace, syncPlaces, places]
  );

  const updateMapPlace = useCallback(
    (placeLocation: PlaceLocation) => {
      const placeIdx = places.findIndex(
        (place) => place.id === placeLocation.id
      );

      if (placeIdx < 0) return;

      const updatedAt = Date.now();

      if (isAuthenticated) {
        syncPlaces({
          locationPlaces: replaceAtIndex(places, placeIdx, placeLocation),
          localUpdate: false,
          updatedAt
        });
      }

      localUpdateMapPlace(placeLocation, updatedAt);
    },
    [isAuthenticated, localUpdateMapPlace, syncPlaces, places]
  );

  return {
    places,
    addMapPlace,
    deleteMapPlace,
    updateMapPlace
  };
};

export default useMapPlaces;
