import { useMemo } from 'react';
import { GeoEvent, NotificationType } from 'shared/types';
import { getCustomEvacDescriptionFlags } from 'shared/utils';
import { FormValues, Status } from '../types';

const useInitialValues = (
  geoEvent: GeoEvent | undefined,
  initialAddress: string,
  initialStatus: Status,
  initialPrescribed: boolean,
  initialLatitude: number | undefined,
  initialLongitude: number | undefined
): FormValues => {
  return useMemo(() => {
    const customEvacDescriptionFlags = getCustomEvacDescriptionFlags(geoEvent);

    return {
      name: geoEvent?.name || '',
      address: geoEvent?.address || initialAddress || '',
      regions: geoEvent?.regions || [],
      status: initialStatus,
      notificationType: geoEvent?.notificationType || NotificationType.Silent,
      acreage: geoEvent?.data.acreage ?? null,
      containment: geoEvent?.data.containment ?? null,
      isFps: !!geoEvent?.data.isFps,
      isPrescribed: initialPrescribed,
      evacuationOrders: geoEvent?.data.evacuationOrders || '',
      evacuationWarnings: geoEvent?.data.evacuationWarnings || '',
      evacuationNotes: geoEvent?.data.evacuationNotes || '',
      evacuationAdvisories: geoEvent?.data.evacuationAdvisories || '',
      reportNotificationType: NotificationType.Normal,
      reportMessage: '',
      lat: geoEvent?.lat || initialLatitude || 0,
      lng: geoEvent?.lng || initialLongitude || 0,
      prescribedDateStartLocal: geoEvent?.data.prescribedDateStartLocal
        ? new Date(geoEvent.data.prescribedDateStartLocal)
        : null,
      activeEvacuations:
        !!(
          geoEvent?.data.evacuationOrders ||
          geoEvent?.data.evacuationWarnings ||
          geoEvent?.data.evacuationNotes ||
          geoEvent?.data.evacuationAdvisories
        ) || (geoEvent?.evacZoneStatuses?.length ?? 0) > 0,
      evacZoneStatuses: geoEvent?.evacZoneStatuses ?? [],
      customOrders: customEvacDescriptionFlags.customOrders,
      customWarnings: customEvacDescriptionFlags.customWarnings,
      customAdvisories: customEvacDescriptionFlags.customAdvisories,
      asset: null,
      media: null,
      fileType: '',
      embedUrl: '',
      mediaLat: '',
      mediaLng: '',
      mediaAz: 0,
      links: geoEvent?.data.links ?? []
    };
  }, [
    geoEvent,
    initialAddress,
    initialStatus,
    initialPrescribed,
    initialLatitude,
    initialLongitude
  ]);
};

export default useInitialValues;
