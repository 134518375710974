import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { Map } from 'maplibre-gl';
import { createRoot, Root } from 'react-dom/client';
import watchdutyTheme from 'theme';

abstract class BaseMapboxGLController<T> {
  constructor(props: T) {
    this.update(props);
  }

  container: HTMLDivElement | undefined;

  root: Root | undefined;

  map: Map | undefined;

  props: T = {} as T;

  update(props: T): void {
    this.props = props;
  }

  onAdd(map: Map): HTMLDivElement {
    this.map = map;
    this.container = document.createElement('div');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.root = createRoot(this.container!);
    this.render();
    return this.container;
  }

  onRemove(): void {
    this.container?.parentNode?.removeChild(this.container);
    this.container = undefined;
    this.root = undefined;
    this.map = undefined;
  }

  render(): void {
    this.container &&
      this.root &&
      this.root.render(
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={watchdutyTheme}>{this.content()}</ThemeProvider>
        </StyledEngineProvider>
      );
  }

  abstract content(): JSX.Element;
}

export default BaseMapboxGLController;
