import { useMemo } from 'react';
import { Layer, Source } from 'react-map-gl/maplibre';
import useMapLayersState from 'state/useMapLayersState';
import { MapLayerAttributions } from '../constants';

// This layer creates an empty source whose job is to display all
// of the layer attributions from layers that are visible.
//
// This is necessary because all of the overlay layers are technically
// part of the map, as far as the mapping package is concerned, even
// when not visible. This preserves their ordering, regardless of visibility.
// Visibility is controlled by showing the full data or an empty dataset.
//
// Whenever the set of visible map layers changes, this component re-renders
// and adds a new empty source layer with the correct attribution.
const AttributionLayer = (): JSX.Element => {
  const { mapLayers } = useMapLayersState();
  const id = `attribution-${mapLayers.toString()}`;

  const attributions = useMemo(
    () =>
      mapLayers
        .map((layer) => MapLayerAttributions[layer] ?? null)
        .filter((layer) => layer)
        .filter((value, index, self) => self.indexOf(value) === index),
    [mapLayers]
  );

  return (
    <Source
      key={id}
      id={id}
      type="geojson"
      data={{ type: 'FeatureCollection', features: [] }}
      attribution={attributions.join(' | ')}
    >
      <Layer type="symbol" />
    </Source>
  );
};

export default AttributionLayer;
