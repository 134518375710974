import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { MapLocation } from 'shared/types';

type SelectedLocationState = {
  selectedLocation: MapLocation | null;
};

type UseSelectedLocationState = {
  selectedLocation: MapLocation | null;
  setSelectedLocation: (location: MapLocation | null) => void;
};

const DEFAULT_SELECTED_LOCATION_STATE: SelectedLocationState = {
  selectedLocation: null
};

const selectedLocationStateAtom = atom({
  key: 'SELECTED_LOCATION_STATE',
  default: DEFAULT_SELECTED_LOCATION_STATE
});

const useSelectedLocationState = (): UseSelectedLocationState => {
  const [selectedLocationState, setSelectedLocationState] = useRecoilState(
    selectedLocationStateAtom
  );

  const setSelectedLocation = useCallback(
    (location: MapLocation | null) => {
      setSelectedLocationState({ selectedLocation: location });
    },
    [setSelectedLocationState]
  );

  return {
    selectedLocation: selectedLocationState.selectedLocation,
    setSelectedLocation
  };
};

export default useSelectedLocationState;
