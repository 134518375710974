import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import PageLayout from 'components/PageLayout';
import PermissionsContent from './PermissionsContent';

export const Content = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('permissions.title')}</title>
      </Helmet>
      <PermissionsContent />
    </>
  );
};

const Permissions = (): JSX.Element => {
  return (
    <PageLayout data-cname="PermissionsPage" data-testid="permissionsPage">
      {{
        content: <Content />,
        headerBar: <></>
      }}
    </PageLayout>
  );
};

export default Permissions;
