import { ReactNode } from 'react';
import { Box, Button, Collapse, List, Stack, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

type RegionListProps = {
  children: ReactNode;
  name: string;
  onToggleCollapse?: () => void;
  expanded?: boolean;
};

const useStyles = makeStyles()((theme) => ({
  subheader: {
    lineHeight: theme.typography.pxToRem(26),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height: 56,
    borderBottom: `1px solid ${theme.palette.divider}`,
    justifyContent: 'flex-start'
  }
}));

const RegionList = (props: RegionListProps): JSX.Element => {
  const { children, name, onToggleCollapse, expanded } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Button
        variant="text"
        fullWidth
        onClick={onToggleCollapse}
        className={classes.subheader}
        aria-labelledby={`region-list-subheader-${name}`}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          {expanded ? (
            <ExpandMoreIcon sx={{ color: 'text.primary' }} />
          ) : (
            <ChevronRightIcon sx={{ color: 'text.primary' }} />
          )}
          <Typography
            variant="h3"
            color="textPrimary"
            fontWeight="bold"
            id={`region-list-subheader-${name}`}
          >
            {name}
          </Typography>
        </Stack>
      </Button>
      <Collapse in={expanded}>
        <List aria-label={t('notificationSettingsRegionAdd.countiesList')}>
          {children}
        </List>
      </Collapse>
    </Box>
  );
};

export default RegionList;
