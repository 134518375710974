type TextLogoProps = {
  className?: string;
};

const TextLogo = (props: TextLogoProps): JSX.Element => {
  const { className } = props;
  return (
    <svg
      width="372"
      height="38"
      className={className}
      viewBox="0 0 372 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M221.168 1.37158V36.6011H236.231C246.9 36.6011 253.199 30.0093 253.199 18.73C253.199 7.45068 246.925 1.37158 236.231 1.37158H221.168ZM231.52 9.59912H234.767C239.723 9.59912 242.652 12.7974 242.652 18.73C242.652 25.2241 239.942 28.3735 234.767 28.3735H231.52V9.59912Z" />
      <path d="M271.604 1.37158H261.252V24.0522C261.252 32.0845 267.478 37.2114 276.779 37.2114C286.081 37.2114 292.307 32.0845 292.307 24.0522V1.37158H281.955V22.9048C281.955 26.6157 280.124 28.6421 276.779 28.6421C273.435 28.6421 271.604 26.6157 271.604 22.9048V1.37158Z" />
      <path d="M320.476 36.6011V9.59912H329.753V1.37158H300.847V9.59912H310.124V36.6011H320.476Z" />
      <path d="M358.972 36.6011V25.4438L371.008 1.37158H360.192L354.016 15.6538H353.82L347.644 1.37158H336.584L348.62 25.4438V36.6011H358.972Z" />
      <path d="M24.6006 16.4351H24.7959L30.0449 36.6011H39.8105L48.8926 1.37158H38.1992L34.1465 23.6372H33.9512L28.7021 1.37158H20.6943L15.4453 23.6372H15.25L11.1973 1.37158H0.503906L9.58594 36.6011H19.3516L24.6006 16.4351Z" />
      <path d="M77.1348 36.6011H88.1699L76.6709 1.37158H63.6826L52.1836 36.6011H62.7305L64.5615 29.5942H75.3037L77.1348 36.6011ZM69.835 9.50146H70.0303L73.4238 22.2944H66.4414L69.835 9.50146Z" />
      <path d="M141.726 37.2114C151.223 37.2114 158.083 31.1812 158.132 22.3921H148.22C147.902 26.4692 145.314 29.0815 141.75 29.0815C137.453 29.0815 134.792 25.3218 134.792 18.9497C134.792 12.6509 137.478 8.89111 141.726 8.89111C145.314 8.89111 147.927 11.5522 148.171 15.5562H158.083C158.059 6.81592 151.027 0.76123 141.726 0.76123C130.959 0.76123 124.245 7.35303 124.245 18.9741C124.245 30.5952 130.935 37.2114 141.726 37.2114Z" />
      <path d="M199.168 36.6011V1.37158H188.816V14.7505H176.414V1.37158H166.062V36.6011H176.414V22.978H188.816V36.6011H199.168Z" />
      <path d="M109.991 36.6011V9.59912H119.269V1.37158H90.3623V9.59912H99.6396V36.6011H109.991Z" />
    </svg>
  );
};
export default TextLogo;
