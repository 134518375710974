import { Box, CardMedia } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type MapStyleCheckIconProps = {
  url: string;
  checked?: boolean;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    width: 72,
    height: 72,
    borderRadius: theme.shape.borderRadius * 2,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  activeBackground: {
    backgroundColor: theme.palette.primary.main
  },
  media: {
    width: 68,
    height: 68
  }
}));

const MapStyleCheckIcon = (props: MapStyleCheckIconProps): JSX.Element => {
  const { url, checked } = props;
  const { classes, cx } = useStyles();

  return (
    <Box className={cx(classes.container, checked && classes.activeBackground)}>
      <CardMedia image={url} className={classes.media} />
    </Box>
  );
};

export default MapStyleCheckIcon;
