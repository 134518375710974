import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';
import { MapDrawerEntity } from 'shared/types';

type MapDrawerEntityState = {
  mapDrawerEntity: MapDrawerEntity | null;
};

type UseMapDrawerEntityState = {
  mapDrawerEntity: MapDrawerEntity | null;
  setMapDrawerEntity: (mapDrawerEntity: MapDrawerEntity | null) => void;
};

const DEFAULT_MAP_DRAWER_ENTITY_STATE: MapDrawerEntityState = {
  mapDrawerEntity: null
};

export const mapDrawerEntityAtom = atom({
  key: 'DEFAULT_MAP_DRAWER_ENTITY_STATE',
  default: DEFAULT_MAP_DRAWER_ENTITY_STATE
});

export const useMapDrawerEntityState = (): UseMapDrawerEntityState => {
  const [mapDrawerEntityState, setMapDrawerEntityState] =
    useRecoilState(mapDrawerEntityAtom);
  const history = useHistory();
  const location = useLocation();

  const setMapDrawerEntity = useCallback(
    (mapDrawerEntity: MapDrawerEntity | null) => {
      if (location.pathname !== '/') {
        history.push('/');
      }
      setMapDrawerEntityState({ mapDrawerEntity });
    },
    [history, location.pathname, setMapDrawerEntityState]
  );

  return {
    mapDrawerEntity: mapDrawerEntityState.mapDrawerEntity,
    setMapDrawerEntity
  };
};
