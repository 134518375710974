import { makeStyles } from 'tss-react/mui';
import Skeleton from '@mui/material/Skeleton';
import { useMemo, useState } from 'react';

type VideoEmbedProps = {
  embedUrl: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  IFrameComponent: (props: any) => JSX.Element;
};

const SKELETON_ANIMATION = 'wave';

const EmbedLoadingStatus = {
  LOADING: 0,
  SUCCESS: 1,
  ERROR: 2
};

const useStyles = makeStyles()((theme) => ({
  videoEmbed: {
    width: '100%',
    height: '100%',
    aspectRatio: '16 / 9',
    minHeight: 272, // ~ (GEO_EVENT_DRAWER_WIDTH - 32) / (16 / 9) - scroll bar width
    [theme.breakpoints.down('tablet')]: {
      minHeight: Math.floor((window.innerWidth - 32) / (16 / 9))
    },
    '& iframe': {
      border: 'none',
      height: 'inherit',
      overflow: 'hidden',
      width: 'inherit'
    }
  }
}));

const VideoEmbed = (props: VideoEmbedProps): JSX.Element => {
  const { embedUrl, IFrameComponent } = props;
  const { classes } = useStyles();
  const [readyStatus, setReadyStatus] = useState(EmbedLoadingStatus.LOADING);

  const embedDisplay =
    readyStatus === EmbedLoadingStatus.LOADING ? 'none' : 'inherit';

  const iframe = useMemo(
    () => (
      <IFrameComponent
        src={embedUrl}
        style={{ display: embedDisplay }}
        onLoad={() => setReadyStatus(EmbedLoadingStatus.SUCCESS)}
        onError={() => setReadyStatus(EmbedLoadingStatus.ERROR)}
      />
    ),
    [IFrameComponent, embedUrl, embedDisplay]
  );

  return (
    <div className={classes.videoEmbed}>
      {readyStatus === EmbedLoadingStatus.LOADING && (
        <Skeleton
          animation={SKELETON_ANIMATION}
          variant="rectangular"
          height="100%"
        />
      )}
      {iframe}
    </div>
  );
};

export default VideoEmbed;
