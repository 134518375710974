import axios from 'axios';
import { API } from 'api';
import { AirCraft } from 'shared/types';
import applyCaseMiddleware from 'axios-case-converter';
import {
  AircraftTraceData,
  FirefightingAircraft,
  AirCraftTrace
} from './types';
import { chunkArray } from './utils';

// Bitmasks for each flag
const START_NEW_LEG_BIT_FLAG = 2; // Binary: 0010

// Maximum number of hex codes in a single GET request.
//  This is a limitation of GET request max length
const MAX_HEX_CODES = 225;

const AdsbListApi = applyCaseMiddleware(
  axios.create({
    baseURL: import.meta.env.VITE_AIRCRAFT_LIST_ENDPOINT
  })
);

const AdsbTraceApi = applyCaseMiddleware(
  axios.create({
    baseURL: import.meta.env.VITE_AIRCRAFT_TRACE_ENDPOINT
  })
);

export const fetchFirefightingAircrafts = async (): Promise<
  FirefightingAircraft[]
> => {
  const res = await API.get('aircraft/');
  return res.data.map((aircraft: FirefightingAircraft) => ({
    ...aircraft,
    hexCode: aircraft.hexCode.toLowerCase()
  }));
};

export const fetchActiveAircrafts = async (
  airCraft: FirefightingAircraft[]
): Promise<AirCraft[]> => {
  // fetch aircraft by batched hexcode requests in parallel
  const hexCodes = airCraft.map((item) => item.hexCode);
  const hexCodeChunks = chunkArray<string>(hexCodes, MAX_HEX_CODES);
  const fetches = hexCodeChunks.map((hexCodeChunk) =>
    AdsbListApi.get(`?hexList=${hexCodeChunk.join(',')}`)
  );
  const results = await Promise.all(fetches);
  return results
    .map((res) => res.data.aircraft)
    .flat()
    .filter(Boolean);
};

export const fetchAircraftsByHexCode = async (
  hexList: string
): Promise<AirCraft[]> => {
  const res = await AdsbListApi.get(`?hexList=${hexList}`);
  return res.data.aircraft ?? [];
};

// Iterate through the trace data and truncate based on the current flight leg
const truncateToCurrentLeg = (trace: AirCraftTrace[]): AirCraftTrace[] => {
  if (!trace || !trace.length) {
    return trace;
  }
  for (let i = trace.length - 1; i > 0; i -= 1) {
    // eslint-disable-next-line no-bitwise
    const isNewLegStartFlag = (trace[i][6] & START_NEW_LEG_BIT_FLAG) > 0;
    if (isNewLegStartFlag) {
      return trace.slice(-1 * (trace.length - i));
    }
  }
  // no new leg found, return full trace
  return trace;
};
// combine these two traces, ensuring that the recent trace data is prioritized by timestamp
const appendFullToRecent = (
  recentTrace: AirCraftTrace[],
  recentStartTime: number,
  fullTrace: AirCraftTrace[],
  fullStartTime: number
): AirCraftTrace[] => {
  // seconds since epoch
  const oldestDateRecentTrace = recentTrace[0][0] + recentStartTime;
  const oldTrace = fullTrace.filter(
    (trace) => trace[0] + fullStartTime < oldestDateRecentTrace
  );
  return oldTrace.concat(recentTrace);
};

const mergeTraces = (
  recent: AircraftTraceData,
  full: AircraftTraceData
): AircraftTraceData => {
  // Merge the two traces and cut off at the point that the airplane was on the ground.
  const truncatedFullTrace = truncateToCurrentLeg(full.trace);
  // eslint-disable-next-line no-param-reassign
  recent.trace = appendFullToRecent(
    recent.trace,
    recent.timestamp,
    truncatedFullTrace,
    full.timestamp
  );
  return recent;
};

export const fetchAircraftTraces = async (
  hexCode: string
): Promise<AircraftTraceData> => {
  const fetches = [];
  fetches.push(
    AdsbTraceApi.get<AircraftTraceData>(
      `/${hexCode.substring(4)}/trace_full_${hexCode}.json`
    )
  );
  fetches.push(
    AdsbTraceApi.get<AircraftTraceData>(
      `/${hexCode.substring(4)}/trace_recent_${hexCode}.json`
    )
  );
  const [fullTrace, recentTrace] = await Promise.all(fetches);
  return mergeTraces(recentTrace.data, fullTrace.data);
};
