import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory
} from 'react-router-dom';
import AnonymousPhotoSubmitted from 'pages/AnonymousPhotoSubmitted';
import Account from 'pages/Account';
import Home from 'pages/Home';
import { LegacyIncidentToGeoEventRedirector } from 'pages/LegacyIncidentToGeoEventRedirector';
import LocationServicesDisabled from 'pages/LocationServicesDisabled';
import Login from 'pages/Login';
import ResetPassword from 'pages/ResetPassword';
import ResetPasswordConfirmation from 'pages/ResetPasswordConfirmation';
import SetPassword from 'pages/SetPassword';
import ViewReport from 'pages/Report';
import NotificationSettings from 'pages/NotificationSettings';
import NotificationSettingsRegionAdd from 'pages/NotificationSettingsRegionAdd';
import Welcome from 'pages/Welcome';
import Permissions from 'pages/Permissions';
import OnboardingNotifications from 'pages/OnboardingNotifications';
import useOnboardingState from 'state/useOnboardingState';
import EditAutomatedGeoEvent from 'pages/EditAutomatedGeoEvent';
import WildFireGeoEventEmbed from 'pages/WildFireGeoEventEmbed';
import UserProfile from 'pages/UserProfile';
import MessagesInbox from 'pages/MessagesInbox';
import MessageDetail from 'pages/MessageDetail';
import MapSearchResults from 'pages/MapSearchResults';
import Places from 'pages/Places';
import AddPlace from 'pages/AddPlace';
import EditPlace from 'pages/EditPlace';
import GeoEventModerationMap from 'pages/GeoEventModerationMap';
import MembershipAccountInformation from 'pages/MembershipAccountInformation';
import MembershipBillingInformation from 'pages/MembershipBillingInformation';
import MembershipSuccess from 'pages/MembershipSuccess';
import ModerateReport from 'pages/ModerateReport';
import AddIncidentReport from 'pages/AddIncidentReport';
import { useAuthState } from 'state';
import EditReport from 'pages/EditReport';
import CreateEditGeoEvent from 'pages/CreateEditGeoEvent';
import TestNotifications from 'pages/TestNotifications';
import MembershipCheckout from 'pages/MembershipCheckout';
import MembershipIapSuccess from 'pages/MembershipIapSuccess';
import Donate from 'pages/Donate';
import DonationSuccess from 'pages/DonationSuccess';
import CreateEditLocation from 'pages/CreateEditLocation';
import PageNotFound from 'pages/PageNotFound';
import Passthrough from 'pages/Passthrough';
import PhotoSubmission from 'pages/PhotoSubmission';
import SupportUs from 'pages/SupportUs';
import DonateAccountInformation from 'pages/DonateAccountInformation';
import DonateBillingInformation from 'pages/DonateBillingInformation';
import { FullscreenCamera } from 'pages/FullscreenCamera';
import { isMobile } from 'shared/utils';
import { useEffect } from 'react';

const Routes = (): JSX.Element => {
  const { onboardUser, onboardCompleted } = useOnboardingState();
  const {
    isAuthenticated,
    permissions: { canReport }
  } = useAuthState();
  const { hash } = useLocation();
  const myHistory = useHistory();

  const isOnboarding = isMobile() && !onboardCompleted && onboardUser;
  const HomeComponent = isOnboarding ? Welcome : Home;

  // Handle old routes for backwards compatibility (removed July 2024)
  useEffect(() => {
    if (hash.startsWith('#/i/') || hash.startsWith('#/location/')) {
      myHistory.push(hash.replace('#/', ''));
    }
  }, [hash, myHistory]);

  return (
    <>
      <Switch>
        <Route exact path="/" component={HomeComponent} />
        <Route
          path="/camera/:cameraId/fullscreen"
          component={FullscreenCamera}
        />
        <Route path="/camera" component={HomeComponent} />
        <Route path="/@:coordinates" component={Home} />
        <Route exact path="/incident/create" component={CreateEditGeoEvent} />
        <Route
          exact
          path="/i/:geoEventId/embed"
          component={WildFireGeoEventEmbed}
        />
        <Route
          exact
          path="/i/:geoEventId/edit"
          component={EditAutomatedGeoEvent}
        />
        <Route exact path="/i/:geoEventId/reports/:id" component={ViewReport} />
        <Route path="/i" component={HomeComponent} />
        <Route
          exact
          path="/incident/:incidentId"
          component={LegacyIncidentToGeoEventRedirector}
        />
        <Route
          exact
          path="/incident/:geoEventId/edit"
          component={CreateEditGeoEvent}
        />
        <Route
          exact
          path="/incident/:incidentId/reports/:id"
          component={ViewReport}
        />
        <Route
          exact
          path="/incident/:geoEventId/reports/:reportId/edit"
          component={EditReport}
        />
        <Route path="/permissions" component={Permissions} />
        <Route
          path="/onboarding_notifications"
          component={OnboardingNotifications}
        />
        <Route exact path="/notifications" component={NotificationSettings} />
        <Route exact path="/notifications/test" component={TestNotifications} />
        <Route
          exact
          path="/notifications/add_counties"
          component={(): JSX.Element => (
            <NotificationSettingsRegionAdd onboarding={isOnboarding} />
          )}
        />
        <Route path="/moderation" component={GeoEventModerationMap} />
        <Route path="/reports/:reportId/moderate" component={ModerateReport} />

        <Route path="/login" component={Login} />
        <Route path="/account" component={Account} />
        <Route path="/reset_password" component={ResetPassword} />
        <Route path="/set_password" component={SetPassword} />
        <Route
          path="/reset_password_confirmation"
          component={ResetPasswordConfirmation}
        />
        <Route
          path="/anonymous_photo_submitted"
          component={AnonymousPhotoSubmitted}
        />
        <Route
          path="/add_report/:geoEventId"
          render={(props): JSX.Element =>
            canReport ? (
              <AddIncidentReport {...props} />
            ) : (
              <PhotoSubmission {...props} />
            )
          }
        />
        <Route path="/add_report/" component={PhotoSubmission} />
        <Route path="/location/create" component={CreateEditLocation} />
        <Route
          path="/location/:locationId/edit"
          component={CreateEditLocation}
        />
        <Route path="/location/" component={HomeComponent} />
        <Route
          path="/location_services_disabled"
          component={LocationServicesDisabled}
        />
        <Route path="/profile/:userId" component={UserProfile} />
        <Route exact path="/inbox" component={MessagesInbox} />
        <Route path="/inbox/:messageId" component={MessageDetail} />
        <Route path="/search_results" component={MapSearchResults} />
        <Route path="/places" component={Places} />
        <Route path="/add_place" component={AddPlace} />
        <Route path="/edit_place/:placeId" component={EditPlace} />
        <Route
          path="/membership/account_info"
          component={MembershipAccountInformation}
        />
        <Route
          path="/membership/billing_info"
          component={MembershipBillingInformation}
        />
        <Route path="/membership/success" component={MembershipSuccess} />
        <Route path="/membership/checkout" component={MembershipCheckout} />
        <Route
          path="/membership/iap_success"
          component={MembershipIapSuccess}
        />
        <Route exact path="/donate" component={Donate} />
        <Route
          path="/donate/account_info"
          component={DonateAccountInformation}
        />
        <Route
          path="/donate/billing_info"
          component={DonateBillingInformation}
        />
        <Route path="/donate/success" component={DonationSuccess} />
        <Route
          path="/internal/passthrough/api/v1/:route*"
          render={(props): JSX.Element =>
            isAuthenticated ? (
              <Passthrough {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route path="/support_us/:plan?" component={SupportUs} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </>
  );
};

export default Routes;
