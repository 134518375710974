import { forwardRef } from 'react';
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import useMapLayersState from 'state/useMapLayersState';
import { MapLayers } from '../constants';
import AirLegend from './AirLegend';
import FireLegend from './FireLegend';
import MapCoverageLegend from './MapCoverageLegend';
import SatelliteLegend from './SatelliteLegend';
import AircraftLegend from './AircraftLegend';
import RadioTowersLegend from './RadioTowersLegend';
import EvacZonesLegend from './EvacZonesLegend';
import LocationsLegend from './LocationsLegend';
import CamerasLegend from './CamerasLegend';

const useStyles = makeStyles()((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 4,
    right: 4,
    zIndex: 1
  },
  contentContainer: {
    paddingTop: 16,
    paddingBottom: 16,
    '& .MuiFormControl-root': {
      width: '100%'
    },
    [theme.breakpoints.down(480)]: {
      paddingTop: 0
    }
  },
  mobileHandle: {
    height: 6,
    width: 80,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.grey[300],
    marginTop: 8
  }
}));

const LegendDrawerContent = forwardRef<HTMLDivElement>(
  (_, ref): JSX.Element => {
    const { classes } = useStyles();
    const theme = useTheme();
    const isPhone = useMediaQuery(theme.breakpoints.down('phone'));
    const { mapLayers, setDrawerOpen } = useMapLayersState();

    return (
      <div style={{ position: 'relative' }} ref={ref}>
        {!isPhone && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => setDrawerOpen(false)}
            color="inherit"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}

        <Box className={classes.contentContainer}>
          <FireLegend />

          <LocationsLegend />

          <EvacZonesLegend />

          {mapLayers.includes(MapLayers.FLIGHT_TRACKER) && <AircraftLegend />}

          {(mapLayers.includes(MapLayers.VIIRS) ||
            mapLayers.includes(MapLayers.MODIS)) && <SatelliteLegend />}

          {mapLayers.includes(MapLayers.PURPLE_AIR) && <AirLegend />}

          {mapLayers.includes(MapLayers.RADIO_TOWERS) && <RadioTowersLegend />}

          {mapLayers.includes(MapLayers.CAMERAS) && <CamerasLegend />}
        </Box>

        <Box className={classes.contentContainer}>
          <MapCoverageLegend />
        </Box>
      </div>
    );
  }
);

LegendDrawerContent.displayName = 'LegendDrawerContent';

export default LegendDrawerContent;
