import { useCallback, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import useLocationSearchParams from 'hooks/useLocationSearchParams';
import { registerDeviceWithWatchDuty } from 'shared/device';
import { promptUserToRateApp } from 'shared/utils';
import { SUCCESS_MSG, ERROR_MSG, useAuthState, useSnackbarState } from 'state';
import useMapLayersState from 'state/useMapLayersState';
import { VerifyPaymentData } from 'shared/types';
import { MapLayers } from 'components/Map/constants';
import { API } from 'api';
import { ANALYTICS_EVENTS, logEvent } from 'shared/analytics';

const verifyPayment = async (data: {
  clientToken: string;
}): Promise<VerifyPaymentData> => {
  const response = await API.post<
    { clientToken: string },
    AxiosResponse<VerifyPaymentData>
  >('recurly_integration/payment_verification/', data);
  return response.data;
};

/**
 * We need to handle external payment success through URL params in cases where the membership/donation
 * flow happens outside of the native app in the device's browser, where we don't have access to
 * app's state.
 */
export const useHandleExternalPaymentSuccess = (): { isPending: boolean } => {
  const { t } = useTranslation();
  const { loginSuccess, hasActiveMembership } = useAuthState();
  const { setMapLayerVisibility } = useMapLayersState();
  const { setSnackbar } = useSnackbarState();
  const queryParams = useLocationSearchParams();
  const queryClient = useQueryClient();

  const clientToken = queryParams.get('clientToken');
  const donationSuccess = queryParams.get('donationSuccess') === 'true';
  const transaction = queryParams.get('transaction');
  const membershipSuccess = queryParams.get('membershipSuccess') === 'true';
  const membershipUpgrade = queryParams.get('membershipUpgrade') === 'true';

  const enableFlightTrackerLayer = useCallback((): void => {
    setMapLayerVisibility(MapLayers.FLIGHT_TRACKER, true);
  }, [setMapLayerVisibility]);

  const { isPending, mutate } = useMutation({
    mutationFn: verifyPayment,
    onSuccess: async (data) => {
      const isDonation = transaction === 'donation';
      const successMessage = isDonation
        ? t('donationSuccess.snackbarMessage')
        : t('membershipSuccess.snackbarMessage');
      loginSuccess(data.key, data.user, !isDonation);
      setSnackbar(successMessage, SUCCESS_MSG);
      if (!isDonation) {
        enableFlightTrackerLayer();
      }
      logEvent({
        name: isDonation
          ? ANALYTICS_EVENTS.SUPPORT.SUCCESS_DONATION
          : ANALYTICS_EVENTS.SUPPORT.SUCCESS_MEMBERSHIP,
        params: {}
      });
      // associate user with token
      const params = { user: data.user };
      registerDeviceWithWatchDuty(params);
      await promptUserToRateApp();
    },
    onError: () => {
      setSnackbar(t('common.unknownError'), ERROR_MSG);
    }
  });

  useEffect(() => {
    if (clientToken) {
      // Anonymous user has become a member or made a donation.
      mutate({ clientToken });
      // remove query params
      window.history.replaceState({}, document.title, '/');
    }
  }, [clientToken, mutate]);

  useEffect(() => {
    if (donationSuccess) {
      (async (): Promise<void> => {
        // A user has made a donation.
        await promptUserToRateApp();
        // remove query params
        window.history.replaceState({}, document.title, '/');
      })();
    }
  }, [donationSuccess]);

  useEffect(() => {
    if (membershipSuccess) {
      (async (): Promise<void> => {
        setSnackbar(t('membershipSuccess.snackbarMessage'), SUCCESS_MSG);
        enableFlightTrackerLayer();
        if (!hasActiveMembership) {
          // Existing user has become a member/Membership registration on android.
          // We need to invalidate the membership status query to ensure that
          // the user's membership status is updated and they can access members features.
          await queryClient.invalidateQueries({
            queryKey: ['membership-status']
          });
        }
        await promptUserToRateApp();
        // remove query params
        window.history.replaceState({}, document.title, '/');
      })();
    }
  }, [
    enableFlightTrackerLayer,
    hasActiveMembership,
    membershipSuccess,
    queryClient,
    setSnackbar,
    t
  ]);

  useEffect(() => {
    if (membershipUpgrade) {
      (async (): Promise<void> => {
        // A user has upgraded their basic membership to a pro membership.
        // We need to invalidate the membership status query to ensure that
        // the user's membership status is updated and they can access pro features.
        await queryClient.invalidateQueries({
          queryKey: ['membership-status']
        });
        await promptUserToRateApp();
        // remove query params
        window.history.replaceState({}, document.title, '/');
      })();
    }
  }, [membershipUpgrade, queryClient]);

  return { isPending };
};
