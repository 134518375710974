import { ResponsibilityAreaId } from './types';

export const RESPONSIBILITY_AREAS_SOURCE_LAYER = 'sra';
export const RESPONSIBILITY_AREAS_LAYER_ID = 'responsibility-areas-layer';

export const RESPONSIBILITY_AREAS = {
  FRA: { id: ResponsibilityAreaId.FRA, color: 'rgba(78, 7, 1, 0.40)' },
  SRA: { id: ResponsibilityAreaId.SRA, color: 'rgba(0, 40, 104, 0.40)' },
  LRA: { id: ResponsibilityAreaId.LRA, color: 'rgba(229, 160, 0, 0.40)' }
};

export const RESPONSIBILITY_AREAS_OPACITY = 0.5;

export const MIN_ZOOM_VISIBILITY = 8;
