export const SOURCE_ID = 'private-land-ownership';

export const SOURCE_DATA_POLYGONS_LAYER_ID = 'polygons';
export const SOURCE_DATA_POINTS_LAYER_ID = 'points';

export const FILL_LAYER_ID = 'private-land-ownership-fill';

export const BORDER_LAYER_ID = 'private-land-ownership-border';

export const LABEL_LAYER_ID = 'private-land-ownership-label';

export const MIN_ZOOM_VISIBILITY = 13;
