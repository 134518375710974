import { CircularProgress } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

type LoaderProps = {
  disableBackground?: boolean;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    width: 76,
    height: 76,
    borderRadius: 76,
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const Loader = (props: LoaderProps): JSX.Element => {
  const { disableBackground = false } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={disableBackground ? undefined : classes.container}>
        <CircularProgress color="primary" size={44} />
      </div>
    </div>
  );
};

export default Loader;
