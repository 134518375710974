import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  root: {
    width: 343,
    height: 32,
    position: 'absolute',
    top: 16,
    left: 16,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius * 1.5,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.down(480)]: {
      width: 'calc(100% - 104px)'
    }
  },
  rootFullView: {
    position: 'relative',
    minWidth: 'calc(100% - 52px)',
    width: '100%',
    height: 52,
    boxShadow: 'none',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    top: 'unset',
    left: 'unset'
  },
  rootFormField: {
    position: 'relative',
    width: '100%',
    height: 56,
    boxShadow: 'none',
    top: 'unset',
    left: 'unset',
    backgroundColor: 'inherit'
  },
  rootDrawerSearch: {
    width: '100%',
    borderColor: 'unset',
    borderRadius: 'unset',
    boxShadow: 'none',
    top: 0,
    position: 'sticky',
    zIndex: theme.zIndex.drawer + 1,
    padding: theme.spacing(3, 2, 1),
    backgroundColor: theme.palette.background.paper,
    height: 'fit-content',
    [theme.breakpoints.down('tablet')]: {
      paddingTop: theme.spacing(0),
      position: 'fixed',
      top: 24,
      left: 0
    }
  },
  rootDrawerSearchWithShadow: {
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
  },
  rootOpen: {
    borderTopLeftRadius: theme.shape.borderRadius * 1.5,
    borderTopRightRadius: theme.shape.borderRadius * 1.5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  inputRoot: {
    width: '100%',
    height: 32,
    padding: '0px 10px !important'
  },
  inputRootFullView: {
    height: 52
  },
  inputRootFormField: {
    height: 56
  },
  inputRootDrawerSearch: {
    backgroundColor: theme.palette.grey[300],
    borderColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius * 1.34
  },
  input: {
    width: '100%',
    height: '100%',
    padding: '0px 8px !important',
    fontSize: theme.typography.subtitle1.fontSize
  },
  inputFullView: {
    padding: '0px 8px !important',
    fontSize: theme.typography.body1.fontSize
  },
  inputFormField: {
    padding: '0px 8px !important',
    fontSize: theme.typography.body1.fontSize
  },
  popperFullView: {
    top: '16px !important',
    left: '0 !important',
    width: '100% !important',
    height: '100%'
  },
  popperDrawerSearch: {
    position: 'relative !important' as 'relative',
    width: '100% !important',
    transform: 'unset !important',
    zIndex: theme.zIndex.drawer - 1
  },
  paper: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: theme.shape.borderRadius * 1.5,
    borderBottomRightRadius: theme.shape.borderRadius * 1.5,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    borderTopWidth: 1.5,
    borderTopColor: theme.palette.grey[300],
    borderTopStyle: 'solid'
  },
  paperFullView: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: 'none',
    borderTopWidth: 0,
    height: '100%'
  },
  paperFormField: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopWidth: 0
  },
  paperDrawerSearch: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: 'none',
    borderTopWidth: 0,
    height: '100%',
    [theme.breakpoints.down('tablet')]: {
      marginTop: theme.spacing(10)
    }
  },
  listboxFullView: {
    maxHeight: 'calc(100% - 76px - env(safe-area-inset-top))'
  },
  listboxDrawerSearch: {
    maxHeight: '100%'
  },
  noOptions: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium
  },
  option: {
    padding: theme.spacing(1)
  },
  optionDrawerSearch: {
    padding: '16px !important'
  },
  hide: {
    display: 'none'
  },
  icon: {
    width: 16,
    height: 16,
    color: theme.palette.grey[600]
  },
  iconFullView: {
    width: 24,
    height: 24
  },
  itemIcon: {
    width: 24,
    height: 24,
    color: 'rgb(99, 147, 242)'
  },
  itemIconDrawerSearch: {
    width: 32,
    height: 32,
    marginLeft: theme.spacing(-0.5)
  },
  geoEventIcon: {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  pointer: {
    cursor: 'pointer'
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  itemText: {
    marginTop: 0,
    marginBottom: 0,
    lineHeight: 1.18,
    flex: 1
  },
  itemSecondary: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  itemSecondaryText: {
    flex: 1,
    paddingRight: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));
