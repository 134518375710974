import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layer, Source, useMap } from 'react-map-gl/maplibre';
import { useQuery } from '@tanstack/react-query';
import { usePoisState } from 'state/usePoisState';
import usePrevious from 'hooks/usePrevious';
import { AircraftDetails } from 'shared/types';
import { isEqual } from 'lodash-es';
import {
  aircraftsToGeoJson,
  firefightAirCraftsToObject,
  mergeDataToCache
} from './utils';
import {
  fetchAircraftTraces,
  fetchAircraftsByHexCode,
  fetchFirefightingAircrafts,
  fetchActiveAircrafts
} from './api';
import {
  ADSB_FETCH_INTERVAL_MS,
  DEFAULT_LAYER_TIMEOUT_MS,
  AIRCRAFT_LOCATION_REFRESH_INTERVAL_MS,
  AIRCRAFT_TRACE_REFRESH_INTERVAL_MS,
  TRACE_SOURCE_ID,
  TRACE_LAYER_ID
} from './constants';
import {
  AircraftCache,
  AircraftCacheNoData,
  LayerFeature,
  FirefightingAircraft
} from './types';
import { traceStyle } from './layerStyles';
import { AircraftIconMarkers } from './AircraftIconMarkers';
import { AircraftDialogContent } from '../../AircraftDialogContent';

type AircraftsLayerProps = {
  visible: boolean;
};

const AircraftsLayer = (props: AircraftsLayerProps): JSX.Element | null => {
  const { visible } = props;
  const { current: map } = useMap();
  const { selectedPoi, setSelectedPoi, clearSelectedPois } = usePoisState();
  const [selectedAircraftHexCode, setSelectedAircraftHexCode] = useState('');

  // this acts as a local cache of the active aircrafts query- with us updating the values within it but keeping the reference
  // to planes that are no longer included in the ADSB response for periods of time.
  const [activePlanesCache, setActivePlanesCache] =
    useState<AircraftCacheNoData>({});

  // this acts as a local cache of the aircraft final data - with us updating the values within it but keeping the reference
  // to planes that are no longer included in the ADSB response for periods of time.
  const [planePositionCache, setPlanePositionCache] = useState<AircraftCache>(
    {}
  );

  const selectedAircraftPoi =
    selectedPoi?.type === 'aircraft' ? selectedPoi : null;
  const prevSelectedAircraftPoi = usePrevious(selectedAircraftPoi);

  useEffect(() => {
    if (prevSelectedAircraftPoi && !selectedAircraftPoi) {
      setSelectedAircraftHexCode('');
    }
  }, [prevSelectedAircraftPoi, selectedAircraftPoi]);

  const handleClick = useCallback(
    async (item: LayerFeature) => {
      if (!map) return;
      setSelectedAircraftHexCode(item.properties.hex);

      const aircraft = {
        name: item.properties.name,
        model: item.properties.model,
        tailNum: item.properties.tailNum,
        type: item.properties.type,
        hex: item.properties.hex,
        altitude: item.properties.altitude,
        speed: item.properties.speed
      };

      setSelectedPoi({
        type: 'aircraft',
        id: aircraft.hex,
        PoiDialogContent: () => <AircraftDialogContent aircraft={aircraft} />
      });
    },
    [map, setSelectedPoi]
  );

  const dbQuery = useQuery({
    queryKey: ['firefighting-aircrafts', visible],
    queryFn: () => (visible ? fetchFirefightingAircrafts() : []),
    staleTime: DEFAULT_LAYER_TIMEOUT_MS
  });

  const dbData = useMemo(() => dbQuery.data || [], [dbQuery.data]);

  const activeDataQuery = useQuery({
    queryKey: ['activeAircraft'],
    queryFn: () => fetchActiveAircrafts(dbData),
    refetchInterval: ADSB_FETCH_INTERVAL_MS,
    // If visible and we have the data for aircrafts from our db fetched
    enabled: visible && !!dbData.length
  });
  const { data: activeData } = activeDataQuery;

  useEffect(() => {
    if (!activeData) return;
    setActivePlanesCache((cached) =>
      mergeDataToCache(activeData, cached, false)
    );
  }, [activeData]);

  const hexList = useMemo(
    () => Object.keys(activePlanesCache),
    [activePlanesCache]
  );
  const activeMetadata = useMemo(() => {
    if (!dbData.length || !hexList) return {};

    const dbAircrafts = firefightAirCraftsToObject(dbData);
    return hexList.reduce((acc, hex) => {
      const aircraftMetadata = dbAircrafts[hex];
      if (aircraftMetadata) {
        // eslint-disable-next-line no-param-reassign
        acc[hex] = aircraftMetadata;
      }
      return acc;
    }, {} as Record<string, FirefightingAircraft>);
  }, [hexList, dbData]);

  const filteredAircraftsQuery = useQuery({
    queryKey: ['filteredAircrafts'],
    queryFn: () => fetchAircraftsByHexCode(hexList.join(',')),
    refetchInterval: AIRCRAFT_LOCATION_REFRESH_INTERVAL_MS,
    // If visible and we have active aircrafts hex codes
    enabled: visible && !!hexList.length
  });

  useEffect(() => {
    if (!filteredAircraftsQuery.data) {
      return;
    }
    setPlanePositionCache((cached) =>
      mergeDataToCache(filteredAircraftsQuery.data, cached, true)
    );
  }, [filteredAircraftsQuery.data]);

  const aircraftTracesQuery = useQuery({
    queryKey: ['aircraftTraces', selectedAircraftHexCode],
    queryFn: () => fetchAircraftTraces(selectedAircraftHexCode),
    // We need to refetch the aircraft traces for the selected aircraft,
    // at the same interval as the filtered aircrafts query
    refetchInterval: AIRCRAFT_TRACE_REFRESH_INTERVAL_MS,
    enabled: !!selectedAircraftHexCode
  });

  const sourceData = useMemo(() => {
    const aircraftPositions = Object.values(planePositionCache).map(
      (item) => item.data
    );
    return aircraftsToGeoJson(
      aircraftPositions,
      activeMetadata,
      selectedAircraftHexCode
    );
  }, [planePositionCache, activeMetadata, selectedAircraftHexCode]);

  const selectedAircraftPoiInSource = useMemo(() => {
    if (!selectedAircraftHexCode) return undefined;
    return sourceData.features.find(
      (feat) => feat.properties.hex === selectedAircraftHexCode
    );
  }, [selectedAircraftHexCode, sourceData.features]);

  const currentAircraftDetails = useRef<AircraftDetails | null>(null);

  // This updates the point of interest popup with live altitude/speed data
  useEffect(() => {
    if (!selectedAircraftPoiInSource || !selectedAircraftPoi) return;
    const item = selectedAircraftPoiInSource;

    const aircraft: AircraftDetails = {
      name: item.properties.name,
      model: item.properties.model,
      tailNum: item.properties.tailNum,
      type: item.properties.type,
      hex: item.properties.hex,
      altitude: item.properties.altitude,
      speed: item.properties.speed
    };

    if (isEqual(aircraft, currentAircraftDetails.current)) return;

    currentAircraftDetails.current = aircraft;

    setSelectedPoi({
      type: 'aircraft',
      id: aircraft.hex,
      PoiDialogContent: () => <AircraftDialogContent aircraft={aircraft} />
    });
  }, [selectedAircraftPoiInSource, selectedAircraftPoi, setSelectedPoi]);

  useEffect(() => {
    if (
      !selectedAircraftPoi ||
      prevSelectedAircraftPoi !== selectedAircraftPoi
    ) {
      return;
    }

    // Source data was re-fetch and selected poi is no longer included
    if (!selectedAircraftPoiInSource) {
      clearSelectedPois();
      setSelectedAircraftHexCode('');
    }
  }, [
    selectedAircraftPoiInSource,
    selectedAircraftPoi,
    clearSelectedPois,
    prevSelectedAircraftPoi
  ]);

  const traceData = useMemo(() => {
    if (!aircraftTracesQuery.data || !selectedAircraftHexCode) {
      return {
        type: 'Feature' as const,
        properties: {},
        geometry: {
          type: 'LineString' as const,
          coordinates: []
        }
      };
    }

    const aircraftLat =
      selectedAircraftPoiInSource?.geometry?.coordinates[1] ?? 0;
    const aircraftLng =
      selectedAircraftPoiInSource?.geometry?.coordinates[0] ?? 0;

    const coordinates = (aircraftTracesQuery.data.trace ?? []).map(
      ([_, lat, lng]) => [lng, lat]
    );

    if (aircraftLat && aircraftLng) {
      coordinates.push([aircraftLng, aircraftLat]);
    }

    return {
      type: 'Feature' as const,
      properties: {},
      geometry: {
        type: 'LineString' as const,
        coordinates
      }
    };
  }, [
    aircraftTracesQuery.data,
    selectedAircraftHexCode,
    selectedAircraftPoiInSource?.geometry?.coordinates
  ]);

  return (
    <>
      <Source
        id={TRACE_SOURCE_ID}
        type="geojson"
        data={traceData}
        maxzoom={12}
        lineMetrics
      >
        <Layer id={TRACE_LAYER_ID} {...traceStyle} />
      </Source>

      <AircraftIconMarkers data={sourceData} onClick={handleClick} />
    </>
  );
};

export default AircraftsLayer;
