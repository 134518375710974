import { useQuery } from '@tanstack/react-query';
import { useDebounceValue } from 'usehooks-ts';
import { useQueryParams } from '../useQueryParms';
import {
  MapSearchURLParams,
  SearchResult,
  SearchSource,
  UseMapSearch,
  UseMapSearchProps
} from './useMapSearch.types';

const SEARCH_DELAY_MS = 500;

const fetchSource = async <T>(
  source: SearchSource<T>,
  searchQuery: string,
  proximity: string
): Promise<SearchResult[]> => {
  try {
    const rawResults = await source.fetchFn(searchQuery, proximity);
    return rawResults.map((result) => {
      const base = {
        sourceId: source.sourceId,
        id: source.getId(result),
        primaryText: source.getPrimaryText(result),
        secondaryText: source.getSecondaryText(result),
        tertiaryText: source.getTertiaryText?.(result),
        avatar: source.getAvatar(result)
      };

      if (source.action === 'click') {
        return {
          ...base,
          action: 'click',
          onClick: () => source.onClick(result)
        };
      }
      return {
        ...base,
        action: 'link',
        href: source.getHref(result)
      };
    });
  } catch (e) {
    return [];
  }
};

const runSourceSearches = async <T>(
  sources: SearchSource<T>[],
  searchQuery: string,
  proximity: string
): Promise<SearchResult[]> => {
  const searchPromises = sources.map((source) =>
    fetchSource(source, searchQuery, proximity)
  );

  const results = await Promise.all(searchPromises);
  return results.flat();
};

export const useMapSearch = <T>(props: UseMapSearchProps<T>): UseMapSearch => {
  const { proximity, sources } = props;
  const [{ search: searchQuery }] = useQueryParams<MapSearchURLParams>();

  const [debouncedSearchQuery] = useDebounceValue(searchQuery, SEARCH_DELAY_MS);

  const sourceIds = sources.map((source) => source.sourceId).sort();

  const { data, isLoading, isError } = useQuery({
    // Unique query key per sources and query
    queryKey: [`map-search-${sourceIds.join(',')}-${debouncedSearchQuery}`],
    queryFn: () => runSourceSearches(sources, debouncedSearchQuery!, proximity),
    enabled: Boolean(debouncedSearchQuery),
    staleTime: 0, // always hit the server for searches
    gcTime: 1
  });

  return {
    searchQuery: debouncedSearchQuery || '',
    isLoading,
    isError,
    searchResults: data || []
  };
};
