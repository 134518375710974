import { ListItemText, ListItem } from '@mui/material';
import { NotificationSetting } from 'shared/types';

type RegionItemProps = {
  regionSetting: NotificationSetting;
};

const RegionItem = (props: RegionItemProps): JSX.Element => {
  const { regionSetting } = props;
  const { displayName } = regionSetting.region;

  return (
    <ListItem alignItems="flex-start">
      <ListItemText primary={displayName} />
    </ListItem>
  );
};

export default RegionItem;
