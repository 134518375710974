import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbarState } from 'state';
import { ABORT_SHARE_ERROR_STRING } from '../constants';

type UseClipboardType = {
  copied: boolean;
  reset: () => void;
  copyText: (coordinates: string) => Promise<void>;
};

const useClipboard = (dialogTitle?: string): UseClipboardType => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const { setSnackbar } = useSnackbarState();

  const copyText = async (coordinates: string): Promise<void> => {
    if (!Capacitor.isNativePlatform()) {
      navigator.clipboard.writeText(coordinates);
      setCopied(true);
      return;
    }

    try {
      await Share.share({
        text: coordinates,
        dialogTitle
      });
    } catch (error) {
      // iOS will throw an error if aborted
      if ((error as Error)?.message === ABORT_SHARE_ERROR_STRING) {
        return;
      }

      setSnackbar(t('common.unknownErrorTryAgain'), 'error');
    }
  };

  return {
    copied,
    reset: () => {
      setCopied(false);
    },
    copyText
  };
};

export default useClipboard;
