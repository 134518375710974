import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import { useMutation } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import HeaderBar from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import { getDeviceInfo } from 'state/localStorageTyped';
import RecurlyBillingInformation from 'components/RecurlyBillingInformation';
import useDonationState from 'state/useDonationState';
import {
  ProcessDonationPaymentTokenData,
  ProcessInitialDonationPaymentTokenData,
  SavePaymentMethodData
} from 'shared/types';
import { API } from 'api';
import { usePaymentApi } from 'hooks/usePaymentApi';

const Content = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    email,
    firstName,
    lastName,
    clientToken,
    optInToUpdates,
    donationAmount,
    donationRenews,
    verificationToken,
    userId,
    updatePaymentMethod
  } = useDonationState();
  const history = useHistory();

  const { processDonationPaymentMutation } = usePaymentApi<
    ProcessInitialDonationPaymentTokenData | ProcessDonationPaymentTokenData
  >({
    onSuccess: () => {
      history.push('/donate/success');
    }
  });

  const savePaymentMethodMutation = useMutation<
    void,
    Error,
    SavePaymentMethodData,
    unknown
  >({
    mutationFn: async (data) => {
      await API.post('recurly_integration/update_billing_info/', data);
    },
    onSuccess: () => {
      history.push('/donate/account_info');
    }
  });

  return (
    <>
      <Helmet>
        <title>{t('donationBillingInformation.title')}</title>
      </Helmet>
      <RecurlyProvider
        publicKey={import.meta.env.VITE_RECURLY_PUBLIC_TOKEN as string}
      >
        <Elements>
          <RecurlyBillingInformation<
            | ProcessInitialDonationPaymentTokenData
            | ProcessDonationPaymentTokenData
          >
            transactionType="donation"
            initialState={{
              email,
              firstName,
              lastName,
              clientToken,
              optInToUpdates,
              transactionAmount: donationAmount,
              donationRenews,
              verificationToken,
              userId,
              updatePaymentMethod
            }}
            processPaymentMutation={processDonationPaymentMutation}
            savePaymentMethodMutation={savePaymentMethodMutation}
          />
        </Elements>
      </RecurlyProvider>
    </>
  );
};

const DonateBillingInformation = (): JSX.Element => {
  const deviceInfo = getDeviceInfo();
  const isMobilePlatform = !!deviceInfo?.isMobilePlatform;

  return (
    <PageLayout data-cname="DonationBillingInformationPage" showBanner={false}>
      {{
        content: <Content />,
        headerBar: (
          <HeaderBar action={isMobilePlatform ? 'noControls' : 'back'} />
        )
      }}
    </PageLayout>
  );
};

export default DonateBillingInformation;
