import { useCallback, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Link as MuiLink
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, Link } from 'react-router-dom';
import * as Yup from 'yup';
import 'cordova-plugin-purchase';
import { useMutation } from '@tanstack/react-query';
import { red } from '@mui/material/colors';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import useIapState, {
  IapCheckoutDetails,
  MEMBERSHIP_DESCRIPTION_TRANS_KEYS,
  MEMBERSHIP_TITLE_TRANS_KEYS
} from 'state/useIapState';
import { API } from 'api';
import { useAuthState, useSnackbarState } from 'state';
import { ProcessMembershipUpgradeData } from 'shared/types';
import { recurlyOnErrorHandler } from 'shared/recurlyUtils';

type ProcessIapPurchaseData = {
  clientToken: string;
  externalAccountCode: string;
  email: string;
  firstName: string;
  lastName: string;
  optInToUpdates: boolean;
  isRecurring: boolean;
  planCode: string;
};

type FormValues = Omit<
  IapCheckoutDetails,
  'clientToken' | 'externalAccountCode' | 'autoRenew'
>;

const VALIDATE_EMAIL_API = '/recurly_integration/membership_validation/';

const processIapPurchaseApi = async (
  data: ProcessIapPurchaseData
): Promise<void> => {
  await API.post('recurly_integration/iap_create/', data);
};

const upgradeMembershipApi = async (
  data: ProcessMembershipUpgradeData
): Promise<void> => {
  await API.post('recurly_integration/upgrade_membership/', data);
};

const handleValidatorSuccess = (
  callback: CdvPurchase.Callback<CdvPurchase.Validator.Response.Payload>,
  transaction: CdvPurchase.Validator.Response.NativeTransaction,
  productId: string
): void => {
  callback({
    ok: true,
    data: {
      id: productId,
      latest_receipt: true,
      transaction
    }
  });
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(3),
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)'
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 42
  },
  button: {
    borderRadius: theme.shape.borderRadius * 2,
    fontWeight: theme.typography.fontWeightMedium,
    minHeight: 48
  },
  alert: {
    backgroundColor: '#FDECEA',
    color: '#E25345'
  }
}));

const MembershipCheckoutContent = (): JSX.Element => {
  const {
    checkoutDetails,
    products,
    selectedProductId,
    plan,
    loading,
    setCheckoutDetails,
    setLoading
  } = useIapState();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { setSnackbar } = useSnackbarState();
  const { user } = useAuthState();

  const validationSchema = Yup.object({
    firstName: Yup.string().required(
      t('membershipCheckout.inputs.firstName.required')
    ),
    lastName: Yup.string().required(
      t('membershipCheckout.inputs.lastName.required')
    ),
    email: Yup.string()
      .email(t('membershipCheckout.inputs.email.invalid'))
      .required(t('membershipCheckout.inputs.email.required')),
    optInToUpdates: Yup.boolean()
  });

  const { control, formState, handleSubmit, getValues, setError } =
    useForm<FormValues>({
      resolver: yupResolver(validationSchema),
      defaultValues: {
        firstName: user?.firstName || checkoutDetails.firstName || '',
        lastName: user?.lastName || checkoutDetails.lastName || '',
        email: user?.email || checkoutDetails.email || '',
        optInToUpdates: checkoutDetails.optInToUpdates || false
      },
      mode: 'onBlur'
    });

  const isMembershipUpgrade = user?.supporterStatus?.planType === 'regular';

  const { mutate: processIapPurchase } = useMutation({
    mutationFn: processIapPurchaseApi
  });

  const { mutate: upgradeMembership } = useMutation({
    mutationFn: upgradeMembershipApi
  });

  const onTransactionApproved = useCallback(
    (transaction: CdvPurchase.Transaction) => {
      if (
        // 'finished' transactions also fire this event up
        transaction.state !== CdvPurchase.TransactionState.APPROVED ||
        // On every app start on iOS, you receive a transaction with the transactionId 'appstore.application'
        // The app itself is considered a transaction. https://github.com/j3k0/cordova-plugin-purchase/issues/1428#issuecomment-1730879536
        transaction.transactionId ===
          CdvPurchase.AppleAppStore.APPLICATION_VIRTUAL_TRANSACTION_ID
      ) {
        return;
      }

      transaction.verify();
    },
    []
  );

  const onTransactionVerified = useCallback(
    (receipt: CdvPurchase.VerifiedReceipt) => {
      receipt.finish();
    },
    []
  );

  const onTransactionFinished = useCallback(
    (transaction: CdvPurchase.Transaction) => {
      let url = '/membership/iap_success';
      if (isMembershipUpgrade) {
        url += '?upgrade=true';
      } else if (!user) {
        url += `?clientToken=${checkoutDetails.clientToken}`;
      }
      history.push(url);
    },
    [checkoutDetails.clientToken, history, isMembershipUpgrade, user]
  );

  const onStoreError = useCallback(
    (err: CdvPurchase.IError) => {
      setLoading(false);
      if (!err.message.includes('cancel')) {
        setSnackbar(err.message, 'error');
      }
    },
    [setLoading, setSnackbar]
  );

  const handleValidatorError = useCallback(
    (
      callback: CdvPurchase.Callback<CdvPurchase.Validator.Response.Payload>,
      err: Error & { response?: { status: number } }
    ) => {
      setLoading(false);
      recurlyOnErrorHandler(err, setSnackbar, t);
      callback({ ok: false, status: err?.response?.status || 400 });
    },
    [setLoading, setSnackbar, t]
  );

  const createIapPurchase = useCallback(
    (
      receipt: CdvPurchase.Validator.Request.Body,
      callback: CdvPurchase.Callback<CdvPurchase.Validator.Response.Payload>
    ) => {
      const transaction =
        receipt.transaction as CdvPurchase.Validator.Response.NativeTransaction;

      if (isMembershipUpgrade) {
        upgradeMembership(
          {
            verificationToken: checkoutDetails.verificationToken,
            userId: user?.id,
            upgradePlanCode: selectedProductId,
            fromIap: true
          },
          {
            onSuccess: () =>
              handleValidatorSuccess(callback, transaction, selectedProductId),
            onError: (err) => handleValidatorError(callback, err)
          }
        );

        return;
      }

      const formValues = getValues();

      if (!formValues.email || !formValues.firstName || !formValues.lastName) {
        // This happens when there's an "approved" transaction that hasn't been processed
        // and the iap state has been reset. It should only be possible when some unexpected
        // error happened during the purchase flow like old implementation not finishing
        // transactions correctly, app uninstalls when a transaction could not be processed
        // (due to network conditions app crash, etc.), etc.
        callback({ ok: false, status: 400 });
        return;
      }

      processIapPurchase(
        {
          clientToken: checkoutDetails.clientToken,
          externalAccountCode: checkoutDetails.externalAccountCode,
          email: formValues.email,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          optInToUpdates: formValues.optInToUpdates,
          isRecurring: true,
          planCode: selectedProductId
        },
        {
          onSuccess: () =>
            handleValidatorSuccess(callback, transaction, selectedProductId),
          onError: (err) => handleValidatorError(callback, err)
        }
      );
    },
    [
      checkoutDetails.clientToken,
      checkoutDetails.externalAccountCode,
      checkoutDetails.verificationToken,
      getValues,
      handleValidatorError,
      isMembershipUpgrade,
      processIapPurchase,
      selectedProductId,
      upgradeMembership,
      user?.id
    ]
  );

  useEffect(() => {
    CdvPurchase.store
      .when()
      .approved(onTransactionApproved)
      .verified(onTransactionVerified)
      .finished(onTransactionFinished);

    CdvPurchase.store.error(onStoreError);

    // Set ios app account token
    CdvPurchase.store.applicationUsername = checkoutDetails.externalAccountCode;

    // Create iap purchase on api before finishing transaction
    CdvPurchase.store.validator = createIapPurchase;
  }, [
    checkoutDetails.externalAccountCode,
    createIapPurchase,
    onStoreError,
    onTransactionApproved,
    onTransactionFinished,
    onTransactionVerified
  ]);

  const handlePurchase = async (values: FormValues): Promise<void> => {
    if (!isMembershipUpgrade) {
      // verify email is not already a member
      const urlEncodedEmail = encodeURIComponent(values.email);

      // We are validating if the email presented is already a member. The purchase flow should also validate,
      // but we have seen this validation return with an error and the payment still go through. Unclear how.
      const {
        data: { hasActiveMembership }
      } = await API.get(`${VALIDATE_EMAIL_API}?email=${urlEncodedEmail}`, {
        responseType: 'json'
      });

      if (hasActiveMembership) {
        setError('email', {
          message: t('membershipCheckout.registeredEmail'),
          type: 'registered'
        });
        return;
      }
    }

    const product = CdvPurchase.store.get(
      selectedProductId,
      CdvPurchase.Platform.APPLE_APPSTORE
    );
    const offer = product?.getOffer();

    if (offer) {
      setCheckoutDetails(values);
      offer.order();
      setLoading(true);
    }
  };

  if (loading) {
    return <LoadingAndErrors isLoading />;
  }

  return (
    <div className={classes.root}>
      <Container
        maxWidth="sm"
        className={classes.container}
        component="form"
        noValidate
        onSubmit={handleSubmit(handlePurchase)}
      >
        <Grid
          container
          spacing={2}
          direction="column"
          sx={{ flex: 1, marginBottom: 3 }}
        >
          <Grid item>
            <Typography variant="h2" sx={{ marginBottom: 1 }}>
              <b>{t('membershipCheckout.sections.account')}</b>
            </Typography>
          </Grid>

          {formState.errors.email &&
            formState.errors.email.type === 'registered' && (
              <Grid item>
                <Alert icon={false} severity="error" className={classes.alert}>
                  <Typography variant="body2" fontWeight="medium" paragraph>
                    {formState.errors.email.message}
                  </Typography>

                  <MuiLink
                    to="/login"
                    component={Link}
                    color="inherit"
                    fontWeight="bold"
                    underline="always"
                    variant="body2"
                  >
                    {t('membershipCheckout.login')}
                  </MuiLink>
                </Alert>
              </Grid>
            )}

          {(!user?.firstName || !user?.lastName) && (
            <Grid item>
              <Grid container spacing={2}>
                {!user?.firstName && (
                  <Grid item xs={6}>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field, fieldState }): JSX.Element => {
                        const { ref, ...muiFieldProps } = field;
                        return (
                          <TextField
                            id="field-control-firstName"
                            label={t(
                              'membershipCheckout.inputs.firstName.label'
                            )}
                            fullWidth
                            {...muiFieldProps}
                            inputRef={ref}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            required
                          />
                        );
                      }}
                    />
                  </Grid>
                )}

                {!user?.lastName && (
                  <Grid item xs={6}>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field, fieldState }): JSX.Element => {
                        const { ref, ...muiFieldProps } = field;
                        return (
                          <TextField
                            id="field-control-lastName"
                            label={t(
                              'membershipCheckout.inputs.lastName.label'
                            )}
                            fullWidth
                            {...muiFieldProps}
                            inputRef={ref}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            required
                          />
                        );
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}

          <Grid item>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }): JSX.Element => {
                const { ref, ...muiFieldProps } = field;
                return (
                  <TextField
                    id="field-control-email"
                    label={t('membershipCheckout.inputs.email.label')}
                    fullWidth
                    type="email"
                    {...muiFieldProps}
                    inputRef={ref}
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error?.type !== 'registered'
                        ? fieldState.error?.message
                        : ''
                    }
                    required
                    sx={
                      fieldState.error?.type === 'registered'
                        ? { input: { color: red[500] } }
                        : undefined
                    }
                    disabled={!!user?.email}
                  />
                );
              }}
            />
          </Grid>

          {!user && (
            <Grid item>
              <Controller
                name="optInToUpdates"
                control={control}
                render={({ field }): JSX.Element => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={field.name}
                          checked={field.value}
                          onChange={(event) =>
                            field.onChange(event.target.checked)
                          }
                          className={classes.checkbox}
                        />
                      }
                      label={t(
                        'membershipCheckout.inputs.optInToUpdates.label'
                      )}
                    />
                  );
                }}
              />
            </Grid>
          )}

          <Grid item>
            <Box sx={{ marginTop: 1 }}>
              <Typography variant="h3">
                <b>{t(MEMBERSHIP_TITLE_TRANS_KEYS[plan])}</b>
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Typography fontWeight="medium">
              {t(MEMBERSHIP_DESCRIPTION_TRANS_KEYS[plan])}
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              fontWeight="medium"
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <span>{t('membershipIntroduction.total')}</span>
              <span>
                {/* Recommended to display prices directly from the product in the appstore */}
                {products[selectedProductId]?.price}
              </span>
            </Typography>
          </Grid>

          {isMembershipUpgrade && (
            <Grid item>
              <Typography variant="body2" sx={{ marginTop: 1 }}>
                {t('membershipCheckout.upgrade')}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Button className={classes.button} fullWidth type="submit">
          {t('membershipCheckout.button')}
        </Button>
      </Container>
    </div>
  );
};

export default MembershipCheckoutContent;
