import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef
} from 'react';
import { Capacitor } from '@capacitor/core';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { defer } from 'lodash-es';
import { generateClientToken } from 'shared/utils';
import { useAuthState, useSnackbarState } from 'state';
import { API } from 'api';
import { MembershipPlan, User } from 'shared/types';
import useMembershipState from 'state/useMembershipState';
import useIapState from 'state/useIapState';
import { ANALYTICS_EVENTS, logEvent } from 'shared/analytics';
import { useMembershipManagement } from 'hooks/useMembershipManagement';
import { AppStoreLinks, MEMBERSHIP_PLANS } from '../../constants';

type MembershipPlanContextType = {
  continueOnWeb: boolean;
  verifyingToken: boolean;
  verificationError: Error | null;
  getNextLink: (plan: MembershipPlan) => string;
  handleNext: () => void;
  updatePlan: (plan: MembershipPlan) => void;
  selectedPlan: MembershipPlan;
};

type MembershipPlanProviderProps = {
  children: ReactNode;
};

const { VITE_BASE_WEB_URL } = import.meta.env;

const MEMBERSHIP_PLAN_TO_ANALYTIC_EVENT = {
  [MEMBERSHIP_PLANS.yearly]: ANALYTICS_EVENTS.CLICKED.MEMBERSHIP_REGULAR,
  [MEMBERSHIP_PLANS.proYearly]: ANALYTICS_EVENTS.CLICKED.MEMBERSHIP_PRO_YEARLY,
  [MEMBERSHIP_PLANS.proMonthly]: ANALYTICS_EVENTS.CLICKED.MEMBERSHIP_PRO_MONTHLY
} as const;

const buildLinkParams = (input: {
  plan: MembershipPlan;
  user: User | null;
  isSupporter: boolean;
  clientToken: string;
  isMembershipUpgrade: boolean;
}): string => {
  const { plan, user, isSupporter, clientToken, isMembershipUpgrade } = input;
  const params = new URLSearchParams({ plan });
  if (user) {
    params.append('email', encodeURIComponent(user.email));
    user.firstName && params.append('firstName', user.firstName);
    user.lastName && params.append('lastName', user.lastName);
  }
  if (user && isSupporter) {
    params.append('userId', user.id.toString());
    params.append('verificationToken', clientToken);
  } else {
    params.append('clientToken', clientToken);
  }
  if (isMembershipUpgrade) {
    params.append('upgrade', 'true');
  }
  return params.toString();
};

const getBecomeAMemberLink = (input: {
  clientToken: string;
  isSupporter: boolean;
  user: User | null;
  plan: MembershipPlan;
  isMembershipUpgrade: boolean;
  isIosSubscription: boolean;
  osPlatform: string;
}): string => {
  const {
    clientToken,
    isSupporter,
    user,
    plan,
    isMembershipUpgrade,
    isIosSubscription,
    osPlatform
  } = input;
  const params = buildLinkParams({
    plan,
    user,
    isSupporter,
    clientToken,
    isMembershipUpgrade
  });
  if (osPlatform === 'ios') {
    if (isMembershipUpgrade && !isIosSubscription) {
      return `${VITE_BASE_WEB_URL}membership/account_info?${params}`;
    }
    return '/membership/checkout';
  }
  if (isMembershipUpgrade && isIosSubscription) {
    // TODO: https://trello.com/c/gYMN3eLD/1577-a-user-who-has-purchased-via-iap-and-attempts-to-upgrade-on-web-is-directed-back-to-app?filter=label:Pro%20Membership
    return AppStoreLinks.ios;
  }
  if (osPlatform !== 'android') {
    return '/membership/account_info';
  }
  return `${VITE_BASE_WEB_URL}membership/account_info?${params}`;
};

const MembershipPlanContext =
  createContext<MembershipPlanContextType | undefined>(undefined);

export const MembershipPlanProvider = (
  props: MembershipPlanProviderProps
): JSX.Element => {
  const { children } = props;
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbarState();
  const { user, isSupporter } = useAuthState();
  const { plan: membershipPlan, updateMembershipState } = useMembershipState();
  const {
    plan: iapPlan,
    setSelectedProductId,
    setCheckoutDetails
  } = useIapState();
  const { isIosSubscription } = useMembershipManagement();
  const history = useHistory();

  // this is used as either a verificationToken or a clientToken depending if they are already a supporter
  const tokenRef = useRef<string>(generateClientToken());
  if (!tokenRef.current) {
    tokenRef.current = generateClientToken();
  }
  const osPlatform = Capacitor.getPlatform();

  const isIOS = osPlatform === 'ios';
  const isAndroid = osPlatform === 'android';

  const isIosButNotIosSubscription = isIOS && !isIosSubscription;
  const isIosSubscriptionButNotIos = isIosSubscription && !isIOS;

  const isMembershipUpgrade = user?.supporterStatus?.planType === 'regular';

  const continueOnWeb =
    isAndroid ||
    (isMembershipUpgrade &&
      (isIosButNotIosSubscription || isIosSubscriptionButNotIos));

  const {
    mutate: saveVerificationToken,
    isPending: verifyingToken,
    error: verificationError
  } = useMutation({
    mutationFn: async (userId: number) => {
      await API.post('recurly_integration/one_time_donation_token/', {
        userId,
        verificationToken: tokenRef.current
      });
    },
    onError: () => {
      setSnackbar(t('common.unknownErrorTryAgain'), 'error');
    }
  });

  const userId = user?.id;

  useEffect(() => {
    if (isSupporter && userId) {
      saveVerificationToken(userId);
    }
  }, [saveVerificationToken, isSupporter, userId]);

  const handleNext = useCallback((): void => {
    if (continueOnWeb) {
      defer(() => history.push('/'));
      return;
    }

    if (isIOS) {
      if (!isMembershipUpgrade) return;
      setCheckoutDetails({ verificationToken: tokenRef.current });
      return;
    }

    if (user && isSupporter) {
      updateMembershipState({
        email: user.email,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        existingUser: true,
        verificationToken: tokenRef.current,
        userId: user.id
      });
    } else {
      updateMembershipState({
        clientToken: tokenRef.current,
        existingUser: false
      });
    }
  }, [
    continueOnWeb,
    isIOS,
    user,
    isSupporter,
    history,
    isMembershipUpgrade,
    setCheckoutDetails,
    updateMembershipState
  ]);

  const getNextLink = useCallback(
    (plan: MembershipPlan): string =>
      getBecomeAMemberLink({
        clientToken: tokenRef.current,
        isSupporter,
        user,
        plan,
        isMembershipUpgrade,
        isIosSubscription,
        osPlatform
      }),
    [isIosSubscription, isMembershipUpgrade, isSupporter, osPlatform, user]
  );

  const updatePlan = useCallback(
    (plan: MembershipPlan) => {
      logEvent({ name: MEMBERSHIP_PLAN_TO_ANALYTIC_EVENT[plan] });
      if (isIOS) {
        setSelectedProductId(plan);
        return;
      }
      updateMembershipState({ plan });
    },
    [isIOS, setSelectedProductId, updateMembershipState]
  );

  const selectedPlan = isIOS ? iapPlan : membershipPlan;

  const value = {
    continueOnWeb,
    verifyingToken,
    verificationError,
    getNextLink,
    handleNext,
    updatePlan,
    selectedPlan
  };

  return (
    <MembershipPlanContext.Provider value={value}>
      {children}
    </MembershipPlanContext.Provider>
  );
};

export const useMembershipPlan = (): MembershipPlanContextType => {
  const context = useContext(MembershipPlanContext);
  if (context === undefined) {
    throw new Error(
      'useMembershipPlan must be used within a MembershipPlanProvider'
    );
  }
  return context;
};
