import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { AxiosResponse } from 'axios';
import { Region } from 'shared/types';
import { useAuthState } from 'state';

const fetchRegions = (): Promise<AxiosResponse<Region[]>> =>
  API.get<Region[]>(`regions`);

const fetchRegionsNoFilter = (): Promise<AxiosResponse<Region[]>> =>
  API.get<Region[]>(`regions?no_filter=true`);

const useRegionsQuery = (
  noFilter: boolean = false
): UseQueryResult<AxiosResponse<Region[]> | undefined> => {
  const {
    permissions: { isInternalUser, canReport }
  } = useAuthState();

  const query = useQuery({
    queryKey: ['regions', noFilter],
    queryFn: noFilter ? fetchRegionsNoFilter : fetchRegions,
    staleTime: 1000 * 60 * 60 * 48, // 48 hours
    gcTime: 1000 * 60 * 60 * 49, // 49 hours
    enabled: isInternalUser || canReport
  });

  return query;
};

export default useRegionsQuery;
