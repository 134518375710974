import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MouseEvent, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Browser } from '@capacitor/browser';
import { makeStyles } from 'tss-react/mui';
import useMapLayersState from 'state/useMapLayersState';
import { MapLayers } from 'components/Map/constants';
import {
  AlertCamera,
  useAlertCamera,
  useAlertCameras
} from '../../hooks/useAlertCameras';
import { LoadingAndErrors } from '../LoadingAndErrors';
import { MapEntityDrawerHeader } from '../Map/MapEntityDrawerHeader';
import CameraPlayer from './CameraPlayer';
import {
  getCameraAttributionImage,
  getRelatedCameras,
  isCameraOffline,
  isTimelapseSupported
} from './CameraDetails.utils';
import { OfflineMessage } from './OfflineMessage';
import { CameraPreview } from './CameraPreview';
import { CameraFullScreenButton } from './CameraFullScreenButton';

const attributionToLogoUrl = {
  UCSD: '/images/camera-providers/alertca.png',
  ALERTWEST: '/images/camera-providers/alertwest.png',
  ALERTWILDFIRE: '/images/camera-providers/alertwildfire.png'
};

const useStyles = makeStyles<{ isTablet: boolean }>()((theme, { isTablet }) => {
  const columnsCount = isTablet ? 4 : 3;
  return {
    cameraGrid: {
      display: 'grid',
      gridColumnGap: theme.spacing(2),
      gridRowGap: theme.spacing(2),
      gridTemplateColumns: `repeat(${columnsCount}, 1fr)`
    }
  };
});

export const CameraDrawerDetails = (): JSX.Element => {
  const { cameraId } = useParams<{ cameraId: string }>();
  const { t } = useTranslation();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('tablet'));
  const { classes } = useStyles({ isTablet });
  const { mapLayers, setMapLayerVisibility } = useMapLayersState();

  const isCamerasLayerEnabled = mapLayers.includes(MapLayers.CAMERAS);

  // Enable the cameras layer if not on already so the user sees the camera icon/viewshed on the map
  useEffect(() => {
    if (!isCamerasLayerEnabled) {
      setMapLayerVisibility(MapLayers.CAMERAS, true);
    }
  }, [isCamerasLayerEnabled, setMapLayerVisibility]);

  const {
    alertCamera,
    isLoading: isLoadingInstance,
    error: instanceError
  } = useAlertCamera({ id: cameraId, refetchInterval: 1000 * 15 });

  const {
    alertCameras: allCameras,
    isLoading: isLoadingAll,
    error: allErrors
  } = useAlertCameras({ enabled: true });

  if (isLoadingInstance || isLoadingAll) {
    return <LoadingAndErrors isLoading />;
  }

  if (instanceError || allErrors || !alertCamera || !allCameras) {
    return <LoadingAndErrors />;
  }

  const relatedCameras = getRelatedCameras(alertCamera, allCameras, t);

  const cameraOffline = isCameraOffline(alertCamera.imageTimestamp);

  const attributionImage = getCameraAttributionImage(
    alertCamera,
    attributionToLogoUrl
  );

  const handleClickAttribution = async (
    event: MouseEvent<HTMLAnchorElement>
  ): Promise<void> => {
    event.preventDefault();

    await Browser.open({
      url: alertCamera.cameraUrl,
      presentationStyle: 'popover'
    });
  };

  return (
    <>
      <Stack gap={2} margin={2} marginTop={0}>
        <MapEntityDrawerHeader>{alertCamera.name}</MapEntityDrawerHeader>
        {cameraOffline ? (
          <OfflineMessage camera={alertCamera} />
        ) : (
          <Stack spacing={1}>
            {isTimelapseSupported(alertCamera) ? (
              <CameraPlayer
                // key prop needed to force component to remount when cameraId
                // changes so the component's local state is reset; useful when
                // switching between related cameras
                key={cameraId}
                cameraId={cameraId}
                previewUrl={alertCamera.imageUrl}
                previewDate={alertCamera.imageTimestamp}
                isPtz={alertCamera.hasPtz}
              />
            ) : (
              <CameraPreview url={alertCamera.imageUrl}>
                <Box alignSelf="flex-end">
                  <CameraFullScreenButton cameraId={cameraId} />
                </Box>
              </CameraPreview>
            )}
          </Stack>
        )}
        {relatedCameras.length > 1 && (
          <>
            <Typography
              id="additional-cameras-header"
              variant="body1"
              component="h4"
              fontWeight="bold"
              marginBottom={-1}
            >
              {t('alertCameras.additionalCameras')}
            </Typography>
            <ToggleButtonGroup
              value={alertCamera.id}
              variant="watchduty-gap"
              aria-labelledby="additional-cameras-header"
              className={classes.cameraGrid}
            >
              {relatedCameras?.map((camera: AlertCamera) => (
                <ToggleButton
                  color="primary"
                  key={camera.id}
                  value={camera.id}
                  size="large"
                  component={Link}
                  to={`/camera/${camera.id}`}
                >
                  {camera.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </>
        )}
        {attributionImage && (
          <>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              textAlign="center"
              textTransform="uppercase"
              marginTop={1}
            >
              {t('alertCameras.operatedBy')}
            </Typography>
            <a
              href={alertCamera.cameraUrl}
              target="_blank"
              rel="noreferrer"
              style={{ textAlign: 'center' }}
              onClick={handleClickAttribution}
            >
              <img
                src={attributionImage}
                alt={alertCamera.attribution}
                style={{ maxWidth: '100%' }}
              />
            </a>
          </>
        )}
      </Stack>
    </>
  );
};
