import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PlaceAddress } from 'shared/types';
import { abbrState, removeAddressCountry } from 'shared/utils';

type PlaceDialogContentProps = {
  id: number;
  name: string;
  address: PlaceAddress;
};

const useStyles = makeStyles()((theme) => ({
  title: {
    padding: theme.spacing(2, 6, 0, 2),
    lineHeight: '1 !important'
  },
  content: {
    padding: theme.spacing(0, 2)
  },
  description: {
    margin: 0
  },
  actions: {
    justifyContent: 'flex-start'
  },
  link: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.palette.accent.main,
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: 0,
    marginLeft: theme.spacing(-0.75),
    pointerEvents: 'auto'
  }
}));

export const PlaceDialogContent = (
  props: PlaceDialogContentProps
): JSX.Element => {
  const { classes } = useStyles();
  const { id, name, address } = props;
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <Typography component="span" variant="body2">
          <b>{name}</b>
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText
          id="alert-dialog-description"
          color="textPrimary"
          className={classes.description}
          variant="body2"
        >
          {address.name}
          <br />
          {abbrState(removeAddressCountry(address?.formattedAddress ?? ''))}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          to={`/edit_place/${id}#allow-back`}
          component={Link}
          className={classes.link}
          variant="text"
        >
          {t('common.edit')}
        </Button>
      </DialogActions>
    </>
  );
};
