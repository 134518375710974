import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';

const DEFAULT_PERSIST_CACHE_TIME = 1000 * 60 * 60 * 24; // 24 hrs

type CacheableQueryClientProviderProps = {
  children: React.ReactNode;
};

export const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage
});

export const persistedQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: DEFAULT_PERSIST_CACHE_TIME
    }
  }
});

persistQueryClient({
  queryClient: persistedQueryClient,
  persister: localStoragePersister,
  maxAge: 1000 * 60 * 60 * 24 * 30 // 30 days
});

export const unpersistedQueryClient = new QueryClient();

export const CacheableQueryClientProvider = (
  props: CacheableQueryClientProviderProps
): JSX.Element => {
  const { children } = props;

  return (
    <QueryClientProvider client={persistedQueryClient}>
      <QueryClientProvider client={unpersistedQueryClient}>
        {children}
      </QueryClientProvider>
    </QueryClientProvider>
  );
};
