import { useCallback } from 'react';
import { Layer } from 'react-map-gl/maplibre';
import { PrivateLandOwnershipEntity } from 'shared/types';
import { useMapDrawerEntityState } from 'state/useMapDrawerEntityState';
import {
  getSelectedPatternStyle,
  getSelectedStrokeStyle
} from 'components/Map/utils';
import {
  layerBorderStyle,
  layerFillStyle,
  layerLabelStyle
} from './layerStyles';
import VisibilityToggledSource from '../VisibilityToggledSource';
import getWatchdutyTileUrl from '../getWatchdutyTileUrl';
import { useMapLayerEvents } from '../useMapLayerEvents';
import {
  FILL_LAYER_ID,
  SOURCE_ID,
  SOURCE_DATA_POLYGONS_LAYER_ID
} from './constants';
import { PrivateLandOwnershipContent } from './PrivateLandOwnershipContent';

type PrivateLandOwnershipLayerProps = {
  visible: boolean;
};

type FeatureProperties = {
  owner?: string;
  address: string;
  mailadd?: string;
  struct: boolean;
  usps_vacancy_date: string;
  zoning_type: string;
  zoning_subtype: string;
  lat: string;
  lon: string;
  fema_flood_zone: string;
  fema_flood_zone_data_date: string;
  fema_flood_zone_subtype: string;
  ll_bldg_count: number;
  ll_gisacre: number;
  ll_last_refresh: string;
  ll_updated_at: string;
};

// some addresses display "NONE" for the street number
const removeNone = (address: string): string =>
  address.replace(/none/i, '').replace(/ {2}/i, '');

export const PrivateLandOwnershipLayer = (
  props: PrivateLandOwnershipLayerProps
): JSX.Element => {
  const { visible } = props;
  const { mapDrawerEntity, setMapDrawerEntity } = useMapDrawerEntityState();

  useMapLayerEvents<FeatureProperties>({
    layerId: FILL_LAYER_ID,
    onClick: useCallback(
      (geojsonFeatures) => {
        const feature = geojsonFeatures[0];

        const entity: PrivateLandOwnershipEntity = {
          id: feature.id as number,
          owner: feature.properties.owner || '-',
          address: removeNone(feature.properties.address || '-'),
          taxAddress: removeNone(feature.properties.mailadd || '-'),
          lat: feature.properties.lat,
          lng: feature.properties.lon,
          acres: feature.properties.ll_gisacre,
          buildings: feature.properties.ll_bldg_count,
          zoningType: feature.properties.zoning_type,
          zoningSubtype: feature.properties.zoning_subtype,
          femaFloodZone: feature.properties.fema_flood_zone,
          femaFloodZoneSubtype: feature.properties.fema_flood_zone_subtype,
          femaFloodZoneDate: feature.properties.fema_flood_zone_data_date
        };

        setMapDrawerEntity({
          id: entity.id,
          type: 'privateLandOwnership',
          Content: () => <PrivateLandOwnershipContent {...entity} />
        });
      },
      [setMapDrawerEntity]
    )
  });

  const selectedId =
    (mapDrawerEntity?.type === 'privateLandOwnership' && mapDrawerEntity?.id) ||
    '';

  const selectedFillStyle = getSelectedPatternStyle({
    idKey: ['id'],
    otherLayerProps: { 'source-layer': SOURCE_DATA_POLYGONS_LAYER_ID },
    selectedId
  });

  const selectedStrokeStyle = getSelectedStrokeStyle({
    idKey: ['id'],
    otherLayerProps: { 'source-layer': SOURCE_DATA_POLYGONS_LAYER_ID },
    strokeStyle: 'solid',
    selectedId
  });

  return (
    <VisibilityToggledSource
      url={getWatchdutyTileUrl('land_ownership_private')}
      visible={visible}
      id={SOURCE_ID}
    >
      <Layer {...layerFillStyle} />
      <Layer {...layerBorderStyle} />
      <Layer {...layerLabelStyle} />
      <Layer {...selectedFillStyle} />
      <Layer {...selectedStrokeStyle} />
    </VisibilityToggledSource>
  );
};
