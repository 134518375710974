import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import HeaderBar from '../../components/HeaderBar';
import PageLayout from '../../components/PageLayout';
import LoginForm from './LoginForm';

export const Content = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('login.title')}</title>
      </Helmet>
      <LoginForm />
    </>
  );
};

const Login = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PageLayout data-cname="LoginPage">
      {{
        content: <Content />,
        headerBar: <HeaderBar action="/" title={t('login.title')} />
      }}
    </PageLayout>
  );
};

export default Login;
