import { List, ListSubheader, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { getResponsiveFontSize } from 'shared/utils';
import LegendListItem from './LegendListItem';

const useStyles = makeStyles()((theme) => ({
  title: {
    fontVariant: 'all-small-caps',
    fontSize: getResponsiveFontSize(theme.typography.pxToRem(20))
  },
  iconColor: {
    width: 24,
    height: 24,
    borderRadius: 24
  },
  subheader: {
    lineHeight: theme.typography.pxToRem(16)
  }
}));

const AirLegend = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <List
      disablePadding
      dense
      subheader={
        <ListSubheader disableSticky className={classes.subheader}>
          <Typography
            variant="h6"
            component="h3"
            className={classes.title}
            color="textPrimary"
          >
            {t('map.legends.air.title')}
          </Typography>
          <Typography variant="caption">
            {t('map.legends.air.subtitle')}
          </Typography>
        </ListSubheader>
      }
    >
      <LegendListItem
        primaryText={t('map.legends.air.legends.less50.title')}
        secondary={t('map.legends.air.legends.less50.subtitle')}
        iconColor="#64DB4B"
      />
      <LegendListItem
        primaryText={t('map.legends.air.legends.less100.title')}
        secondary={t('map.legends.air.legends.less100.subtitle')}
        iconColor="#FFFD3A"
      />
      <LegendListItem
        primaryText={t('map.legends.air.legends.less150.title')}
        secondary={t('map.legends.air.legends.less150.subtitle')}
        iconColor="#FF721F"
      />
      <LegendListItem
        primaryText={t('map.legends.air.legends.less200.title')}
        secondary={t('map.legends.air.legends.less200.subtitle')}
        iconColor="#FE0013"
      />
      <LegendListItem
        primaryText={t('map.legends.air.legends.less300.title')}
        secondary={t('map.legends.air.legends.less300.subtitle')}
        iconColor="#823989"
      />
      <LegendListItem
        primaryText={t('map.legends.air.legends.more300.title')}
        secondary={t('map.legends.air.legends.more300.subtitle')}
        iconColor="#720120"
      />
    </List>
  );
};

export default AirLegend;
