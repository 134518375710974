import { useCallback } from 'react';
import { Capacitor } from '@capacitor/core';
import { differenceInMonths, differenceInHours } from 'date-fns';
import { useAuthState, useSupportUsBannerAdState } from 'state';
import { getLastPushNotificationTimestamp } from 'state/localStorage';
import useOnboardingState from 'state/useOnboardingState';

type UseSupportUsDialog = {
  open: boolean;
  handleClose: () => void;
};

const FIVE_MINUTES_MS = 1000 * 60 * 5; // 5 minutes

const MIN_APP_USAGE_HOURS = 48;

export const useSupportUsDialog = (): UseSupportUsDialog => {
  const {
    isSupporter,
    permissions: { isInternalUser }
  } = useAuthState();
  const { onboardedAt } = useOnboardingState();
  const { popupLastShownTimestamp, updatePopupLastShownTimestamp } =
    useSupportUsBannerAdState();

  const lastPushNotificationTimestamp = getLastPushNotificationTimestamp();

  const getOpen = (): boolean => {
    if (isSupporter || isInternalUser) {
      return false;
    }

    if (popupLastShownTimestamp) {
      const monthsDifference = Math.abs(
        differenceInMonths(new Date(), new Date(popupLastShownTimestamp))
      );

      if (monthsDifference < 1) {
        return false;
      }
    }

    if (Capacitor.isNativePlatform()) {
      if (!onboardedAt) {
        return false;
      }

      const appUsageInHours = Math.abs(
        differenceInHours(new Date(), new Date(onboardedAt), {
          roundingMethod: 'round'
        })
      );

      if (appUsageInHours < MIN_APP_USAGE_HOURS) {
        return false;
      }

      const pushNotifInLast5Mins =
        Date.now() - (lastPushNotificationTimestamp || 0) <= FIVE_MINUTES_MS;

      if (pushNotifInLast5Mins) {
        return false;
      }
    }

    return true;
  };

  const handleClose = useCallback((): void => {
    updatePopupLastShownTimestamp();
  }, [updatePopupLastShownTimestamp]);

  return { open: getOpen(), handleClose };
};
