import { Feature, FeatureCollection, Point, Polygon } from 'geojson';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl/maplibre';
import { DataDrivenPropertyValueSpecification, Map } from 'maplibre-gl';
import { subDays } from 'date-fns';
import LatLon from 'geodesy/latlon-spherical';
import { useHistory, useParams } from 'react-router-dom';
import { usePoisState } from 'state/usePoisState';
import { useAuthState } from 'state';
import {
  CameraTimeLapseFrameDetails,
  useAlertCameraPlayerState
} from 'state/useAlertCameraPlayerState';
import {
  AlertCamera,
  AlertCameraGroup,
  useAlertCameras
} from '../../../hooks/useAlertCameras';
import addVisible from '../../../shared/addVisible';
import {
  CameraArrow,
  CameraArrowOffline,
  CameraArrowSelected,
  CameraPTZBase,
  CameraPTZBaseOffline,
  CameraPTZBaseSelected,
  CameraStationaryBase,
  CameraStationaryBaseOffline,
  CameraStationaryBaseSelected
} from '../Icons';
import { MapboxFeature, useMapLayerEvents } from './useMapLayerEvents';
import { useCenterMap } from '../../IncidentsMap/CenterMap';

type CamerasLayerProps = {
  visible: boolean;
  interactive?: boolean;
};

type CameraBaseFeatureProperties = Partial<AlertCamera> & {
  icon: string;
  iconFocused: string;
  childCameraIds?: string[];
  azDeg: number;
};

type CameraFeatureProperties = AlertCamera & {
  cameraGroupId: string;
  icon: string;
  iconFocused: string;
};

type GenericCameraFeatureProperties =
  | CameraBaseFeatureProperties
  | CameraFeatureProperties
  | AlertCamera;

export type CameraGeoJson = Feature<
  Point | Polygon,
  GenericCameraFeatureProperties
>;

const noop = (): void => {};

const getViewShedDistance = (fov: number): number => {
  // No FOV will be larger than 180 (the cams max out at ~63 deg).
  const scaleFactor = Math.pow(180 - fov, 2);
  const scaleConstant = 0.5;
  return scaleConstant * scaleFactor;
};

const MIN_ZOOM_VISIBILITY_PTZ = 8;
const MIN_ZOOM_VISIBILITY_STATIONARY = 10;
const MAX_ZOOM_VISIBILITY_PUBLIC_USER = 15;
const PTZ_SOURCE_ID = 'alert-cameras-ptz';
const PTZ_ARROWS_SOURCE_ID = 'alert-cameras-ptz-arrows';
const STATIONARY_SOURCE_ID = 'alert-cameras-stationary';
const PTZ_ICONS_LAYER_ID = 'alert-camera-icons-ptz';
const PTZ_ARROW_ICONS_LAYER_ID = 'alert-camera-icons-ptz-arrows';
const STATIONARY_ICONS_LAYER_ID = 'alert-camera-icons-stationary';
const PTZ_VIEWSHED_LAYER_FILL_ID = 'alert-camera-viewshed-fill-ptz';
const PTZ_VIEWSHED_LAYER_STROKE_ID = 'alert-camera-viewshed-stroke-ptz';
const STATIONARY_VIEWSHED_SOURCE_ID = 'alert-cameras-viewsheds-stationary';
const PTZ_VIEWSHED_SOURCE_ID = 'alert-cameras-viewsheds-ptz';
const STATIONARY_VIEWSHED_LAYER_FILL_ID =
  'alert-camera-viewshed-fill-stationary';
const STATIONARY_VIEWSHED_LAYER_STROKE_ID =
  'alert-camera-viewshed-stroke-stationary';
const VIEWSHED_FILL_OPACITY = 0;
const VIEWSHED_STROKE_OPACITY = 0;
const VIEWSHED_FILL_OPACITY_SELECTED = 0.33;
const VIEWSHED_STROKE_OPACITY_SELECTED = 1;

const getIconImageLayout = (
  activeCameraId: string = ''
): DataDrivenPropertyValueSpecification<string> => {
  return [
    'match',
    ['get', 'id'],
    ['literal', activeCameraId],
    ['get', 'iconFocused'],
    ['get', 'icon']
  ];
};

// All users get the same zoom levels for the icons, but non-internal users
// will have the icons hide at zoom levels above `MAX_ZOOM_VISIBILITY_PUBLIC_USER`.
const iconZoomExpression: DataDrivenPropertyValueSpecification<number> = [
  'interpolate',
  ['linear'],
  ['zoom'],
  7,
  0.3,
  11,
  0.5,
  13,
  0.6,
  15,
  0.75
];

// Stationary / traffic cams get even bigger as the user zooms in, since they start
// off rather small.
const stationaryIconZoomExpression: DataDrivenPropertyValueSpecification<number> =
  [...iconZoomExpression, 17, 1.0];

const getBaseIconStyles = (
  cameraType: 'stationary' | 'ptz',
  isInternalUser: boolean,
  activeCameraId?: string
): LayerProps => ({
  type: 'symbol',
  minzoom:
    cameraType === 'ptz'
      ? MIN_ZOOM_VISIBILITY_PTZ
      : MIN_ZOOM_VISIBILITY_STATIONARY,
  ...(isInternalUser || cameraType === 'stationary'
    ? {}
    : { maxzoom: MAX_ZOOM_VISIBILITY_PUBLIC_USER }),
  layout: {
    'icon-image': getIconImageLayout(activeCameraId),
    'icon-allow-overlap': true,
    'icon-ignore-placement': false,
    'icon-size':
      cameraType === 'ptz' ? iconZoomExpression : stationaryIconZoomExpression
  }
});

const getArrowIconStyles = (
  cameraType: 'stationary' | 'ptz',
  isInternalUser: boolean,
  activeCameraId?: string
): LayerProps => ({
  type: 'symbol',
  minzoom:
    cameraType === 'ptz'
      ? MIN_ZOOM_VISIBILITY_PTZ
      : MIN_ZOOM_VISIBILITY_STATIONARY,
  ...(isInternalUser ? {} : { maxzoom: 15 }),
  layout: {
    'icon-image': getIconImageLayout(activeCameraId),
    'icon-allow-overlap': true,
    'icon-anchor': 'bottom',
    'icon-ignore-placement': false,
    'icon-size': iconZoomExpression,
    'icon-offset': [
      'interpolate',
      ['linear'],
      ['zoom'],
      7,
      ['literal', [0, -16]],
      13,
      ['literal', [0, -24]]
    ],
    'icon-rotate': ['get', 'azDeg']
  }
});

const getPaintOpacityForActiveId = (
  activeCameraId?: string
): DataDrivenPropertyValueSpecification<number> | number =>
  activeCameraId
    ? [
        'case',
        ['==', ['get', 'id'], ['literal', activeCameraId]],
        VIEWSHED_FILL_OPACITY_SELECTED,
        VIEWSHED_FILL_OPACITY
      ]
    : 0;

const getViewshedFillStyles = (
  type: 'ptz' | 'stationary',
  isInternalUser: boolean,
  activeCameraId?: string
): LayerProps => ({
  type: 'fill',
  layout: {},
  minzoom:
    type === 'ptz' ? MIN_ZOOM_VISIBILITY_PTZ : MIN_ZOOM_VISIBILITY_STATIONARY,
  ...(isInternalUser ? {} : { maxzoom: 15 }),
  paint: {
    'fill-color': '#3388FF',
    'fill-opacity': getPaintOpacityForActiveId(activeCameraId)
  }
});

const getViewshedStrokeStyles = (
  type: 'ptz' | 'stationary',
  isInternalUser: boolean,
  activeCameraId?: string
): LayerProps => ({
  type: 'line',
  layout: {},
  ...(isInternalUser ? {} : { maxzoom: 15 }),
  minzoom:
    type === 'ptz' ? MIN_ZOOM_VISIBILITY_PTZ : MIN_ZOOM_VISIBILITY_STATIONARY,
  paint: {
    'line-color': '#3388FF',
    'line-width': ['interpolate', ['linear'], ['zoom'], 8, 2, 18, 6],
    'line-opacity': getPaintOpacityForActiveId(activeCameraId)
  }
});

const isCameraOffline = (timestamp: Date): boolean =>
  timestamp < subDays(new Date(), 1);

export const getBaseCameraIcon = (
  cameraGroup: AlertCameraGroup,
  isSelected: boolean
): string => {
  const { hasPtz } = cameraGroup;
  const isOffline = cameraGroup.cameras.every((camera) =>
    isCameraOffline(camera.imageTimestamp)
  );

  if (isSelected) {
    return hasPtz
      ? CameraPTZBaseSelected.name
      : CameraStationaryBaseSelected.name;
  }

  if (isOffline) {
    return hasPtz
      ? CameraPTZBaseOffline.name
      : CameraStationaryBaseOffline.name;
  }

  return hasPtz ? CameraPTZBase.name : CameraStationaryBase.name;
};

export const getCameraArrowIcon = (
  camera: AlertCamera,
  isSelected: boolean
): string => {
  const isOffline = isCameraOffline(camera.imageTimestamp);

  if (isSelected) {
    return CameraArrowSelected.name;
  }

  if (isOffline) {
    return CameraArrowOffline.name;
  }

  return CameraArrow.name;
};

const getViewShedPolygonCoordinates = (
  alertCamera: AlertCamera,
  currentTimelapseFrame?: CameraTimeLapseFrameDetails | null
): Polygon['coordinates'] => {
  const pos = new LatLon(alertCamera.latlng.lat, alertCamera.latlng.lng);
  // If there is a timelapse frame, use the az and fov from that frame.
  const az = currentTimelapseFrame?.azDeg || alertCamera.azDeg;
  const fov = currentTimelapseFrame?.fovDeg || alertCamera.fovDeg;
  const numCurvePoints = 20; // number of points on the "end" of the viewshed.

  // Create a GeoJSON polygon that consists of the camera's location, two points
  // on either side of it's field-of-view a given distance D away.
  const d = getViewShedDistance(fov);

  const points = [];
  const increments = fov / (numCurvePoints - 1);
  for (let i = 0; i < numCurvePoints; i += 1) {
    const angle = az + fov / 2 - i * increments;
    const p = pos.destinationPoint(d, angle);
    points.push([p.lng, p.lat]);
  }

  // GeoJSON polygon outer rings are right-wound (points go clockwise).
  return [[[pos.lng, pos.lat], ...points, [pos.lng, pos.lat]]];
};

export const toGeojsonFeatures = (
  cameraGroup: AlertCameraGroup
): { baseIcons: CameraGeoJson[]; arrowIcons: CameraGeoJson[] } => {
  const { lat, lng } = cameraGroup.latlng;
  const camerasHaveAzimuth = cameraGroup.cameras.some((camera) =>
    Boolean(camera.azDeg)
  );

  const baseIcon: CameraGeoJson = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [lng, lat]
    },
    properties: {
      id: cameraGroup.id,
      azDeg: 0,
      ...(!camerasHaveAzimuth && cameraGroup.cameras[0]),
      ...(camerasHaveAzimuth && {
        childCameraIds: cameraGroup.cameras.map((camera) => camera.id)
      }),
      icon: getBaseCameraIcon(cameraGroup, false),
      iconFocused: getBaseCameraIcon(cameraGroup, true)
    }
  };

  if (!camerasHaveAzimuth) {
    return { baseIcons: [baseIcon], arrowIcons: [] };
  }

  const ptzArrowIcons: CameraGeoJson[] = cameraGroup.cameras.map(
    (alertCamera) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [alertCamera.latlng.lng, alertCamera.latlng.lat]
      },
      properties: {
        ...alertCamera,
        icon: getCameraArrowIcon(alertCamera, false),
        iconFocused: getCameraArrowIcon(alertCamera, true)
      }
    })
  );

  return { baseIcons: [baseIcon], arrowIcons: ptzArrowIcons };
};

export const toGeojsonViewshedFeatures = (
  cameraGroup: AlertCameraGroup,
  currentTimelapseFrame?: CameraTimeLapseFrameDetails | null
): CameraGeoJson[] => {
  const camerasHaveAzimuth = cameraGroup.cameras.some((camera) =>
    Boolean(camera.azDeg)
  );
  if (!camerasHaveAzimuth) return [];

  const viewShedPolygons: CameraGeoJson[] = cameraGroup.cameras.map(
    (alertCamera) => ({
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: getViewShedPolygonCoordinates(
          alertCamera,
          currentTimelapseFrame
        )
      },
      properties: {
        ...alertCamera
      }
    })
  );

  return [...viewShedPolygons];
};

const getCameraIdFromFeature = (
  cameraProperties: GenericCameraFeatureProperties
): string => {
  if ('childCameraIds' in cameraProperties) {
    const childIds = JSON.parse(
      // maplibre unfortunately stringifies nested objects/arrays
      cameraProperties.childCameraIds as unknown as string
    );
    return childIds[0];
  }

  return cameraProperties.id!;
};

const getCameraFromGroup = (
  cameraGroup: AlertCameraGroup,
  id: string
): AlertCamera | undefined =>
  cameraGroup.cameras.find(
    (camera) => camera.id === id || camera.cameraGroupId === id
  );

const generateViewshedGeojson = (
  alertCameraGroups: AlertCameraGroup[],
  viewshedType: 'stationary' | 'ptz',
  activeCameraId?: string,
  hoveredCameraId?: string | null,
  currentTimelapseFrame?: CameraTimeLapseFrameDetails | null
): FeatureCollection<Point | Polygon, GenericCameraFeatureProperties> => {
  // Give priority to the camera the user is hovering over, relative to the one
  // they clicked on.
  const viewshedCameraId = hoveredCameraId || activeCameraId;

  // Exit early if there's nothing to highlight
  if (!viewshedCameraId) {
    return {
      type: 'FeatureCollection',
      features: []
    };
  }

  // Get the camera group that has the viewshed
  const viewshedCameraGroup: AlertCameraGroup | undefined = alertCameraGroups
    // filter out any of the wrong camera types
    .filter((cameraGroup) =>
      viewshedType === 'stationary' ? !cameraGroup.hasPtz : cameraGroup.hasPtz
    )
    // filter out camera groups that don't contain the camera ID we want
    .find((cameraGroup) => getCameraFromGroup(cameraGroup, viewshedCameraId));

  return {
    type: 'FeatureCollection',
    features: viewshedCameraGroup
      ? // If a hovered camera is active, don't pass on the timelapse frame even if
        // one is set. This is to prevent the viewshed from changing based on the
        // metadata of the timelapse frame for the hovered camera (which is different)
        // from the one playing timelapse.
        toGeojsonViewshedFeatures(
          viewshedCameraGroup,
          hoveredCameraId ? undefined : currentTimelapseFrame
        )
      : []
  };
};

export const AlertCamerasLayer = (props: CamerasLayerProps): JSX.Element => {
  const { visible, interactive = true } = props;
  const { alertCameraGroups = [], alertCameras = [] } = useAlertCameras({
    enabled: visible,
    withRealtimeUpdates: true
  });
  const { timelapseFrame, playerStatus } = useAlertCameraPlayerState();
  const history = useHistory();
  const { clearSelectedPois } = usePoisState();
  const { cameraId: activeCameraId } = useParams<{ cameraId?: string }>();
  const {
    permissions: { isInternalUser }
  } = useAuthState();
  const [hoveredCameraId, setHoveredCameraId] = useState<string | null>(null);

  // On mobile, tapping a camera also fires the hover event, which sets
  // `hoveredCameraId`. On desktop, the camera viewshed should show a newly-hovered
  // camera's viewshed even if the timelapse player is playing, so the `hoveredCameraId`
  // takes precendence in viewshed display.
  //
  // To avoid `hoveredCameraId` persisting and showing the "live" viewshed of a camera
  // after timelapse playback has initiated, reset it whenever the player status changes
  // to "playing".
  useEffect(() => {
    if (playerStatus === 'playing') {
      setHoveredCameraId(null);
    }
  }, [playerStatus]);

  const handleClickIcon = useCallback(
    (features: MapboxFeature<GenericCameraFeatureProperties>[]) => {
      const featureProperties = features[0].properties;
      const cameraId = getCameraIdFromFeature(featureProperties);
      clearSelectedPois();
      history.push(`/camera/${cameraId}`);
    },
    [history, clearSelectedPois]
  );

  const handleHover = useCallback(
    (features: MapboxFeature<GenericCameraFeatureProperties>[], map: Map) => {
      const { properties } = features[0];
      if (activeCameraId === properties.id) {
        return;
      }

      const currentCameraGroupHover = alertCameraGroups.find((cameraGroup) =>
        getCameraFromGroup(cameraGroup, properties.id!)
      );
      currentCameraGroupHover && setHoveredCameraId(currentCameraGroupHover.id);

      const selectedCameraGroupIds =
        'cameraGroupId' in properties
          ? [properties.id, properties.cameraGroupId]
          : [properties.id];
      const selectedChildCameraIds =
        'childCameraIds' in properties
          ? JSON.parse(properties.childCameraIds as unknown as string)
          : [];

      const selectedIds = [
        ...selectedCameraGroupIds,
        ...selectedChildCameraIds
      ];

      [
        PTZ_ICONS_LAYER_ID,
        PTZ_ARROW_ICONS_LAYER_ID,
        STATIONARY_ICONS_LAYER_ID
      ].forEach((layerId) => {
        map.setLayoutProperty(layerId, 'icon-image', [
          'case',
          ['in', ['get', 'id'], ['literal', selectedIds]],
          ['get', 'iconFocused'],
          ['get', 'icon']
        ]);
      });

      [STATIONARY_VIEWSHED_LAYER_FILL_ID, PTZ_VIEWSHED_LAYER_FILL_ID].forEach(
        (layerId) => {
          map.setPaintProperty(layerId, 'fill-opacity', [
            'case',
            ['in', ['get', 'id'], ['literal', selectedIds]],
            VIEWSHED_FILL_OPACITY_SELECTED,
            VIEWSHED_FILL_OPACITY
          ]);
        }
      );

      [
        STATIONARY_VIEWSHED_LAYER_STROKE_ID,
        PTZ_VIEWSHED_LAYER_STROKE_ID
      ].forEach((layerId) => {
        map.setPaintProperty(layerId, 'line-opacity', [
          'case',
          ['in', ['get', 'id'], ['literal', selectedIds]],
          VIEWSHED_STROKE_OPACITY_SELECTED,
          VIEWSHED_STROKE_OPACITY
        ]);
      });
    },
    [activeCameraId, alertCameraGroups]
  );

  const handleHoverOff = useCallback(
    (map: Map) => {
      setHoveredCameraId(null);

      [
        PTZ_ICONS_LAYER_ID,
        PTZ_ARROW_ICONS_LAYER_ID,
        STATIONARY_ICONS_LAYER_ID
      ].forEach((layerId) => {
        map.setLayoutProperty(
          layerId,
          'icon-image',
          getIconImageLayout(activeCameraId)
        );
      });

      [STATIONARY_VIEWSHED_LAYER_FILL_ID, PTZ_VIEWSHED_LAYER_FILL_ID].forEach(
        (layerId) => {
          map.setPaintProperty(
            layerId,
            'fill-opacity',
            getPaintOpacityForActiveId(activeCameraId)
          );
        }
      );

      [
        STATIONARY_VIEWSHED_LAYER_STROKE_ID,
        PTZ_VIEWSHED_LAYER_STROKE_ID
      ].forEach((layerId) => {
        map.setPaintProperty(
          layerId,
          'line-opacity',
          getPaintOpacityForActiveId(activeCameraId)
        );
      });
    },
    [activeCameraId]
  );

  useMapLayerEvents<GenericCameraFeatureProperties>({
    layerId: PTZ_ICONS_LAYER_ID,
    onClick: interactive ? handleClickIcon : noop,
    onHover: interactive ? handleHover : undefined,
    onHoverOff: interactive ? handleHoverOff : undefined
  });
  useMapLayerEvents<GenericCameraFeatureProperties>({
    layerId: PTZ_ARROW_ICONS_LAYER_ID,
    onClick: interactive ? handleClickIcon : noop,
    onHover: interactive ? handleHover : undefined,
    onHoverOff: interactive ? handleHoverOff : undefined
  });
  useMapLayerEvents<GenericCameraFeatureProperties>({
    layerId: STATIONARY_ICONS_LAYER_ID,
    onClick: interactive ? handleClickIcon : noop,
    onHover: interactive ? handleHover : undefined,
    onHoverOff: interactive ? handleHoverOff : undefined
  });

  // do not modify the geojson based on selectedState or it will have to re-render.
  const geojsonPtz = useMemo(() => {
    const symbolFeatures = alertCameraGroups
      .filter((camera) => camera.hasPtz)
      .map((camera) => toGeojsonFeatures(camera))
      .reduce(
        (acc, current) => {
          const { baseIcons, arrowIcons } = current;
          return {
            baseIcons: acc.baseIcons.concat(baseIcons),
            arrowIcons: acc.arrowIcons.concat(arrowIcons)
          };
        },
        { baseIcons: [], arrowIcons: [] }
      );
    return {
      baseIcons: {
        type: 'FeatureCollection',
        features: symbolFeatures.baseIcons
      },
      arrowIcons: {
        type: 'FeatureCollection',
        features: symbolFeatures.arrowIcons
      }
    };
  }, [alertCameraGroups]);

  const geojsonStationary = useMemo(
    () => ({
      type: 'FeatureCollection',
      features: alertCameraGroups
        .filter((camera) => !camera.hasPtz)
        .map((camera) => toGeojsonFeatures(camera))
        .reduce((acc, current) => {
          const { baseIcons } = current;
          return acc.concat(baseIcons);
        }, [] as CameraGeoJson[])
    }),
    [alertCameraGroups]
  );

  // This geojson will be built after clicking or hovering on a camera
  const geojsonViewshedStationary: FeatureCollection<
    Point | Polygon,
    GenericCameraFeatureProperties
  > = useMemo(() => {
    return generateViewshedGeojson(
      alertCameraGroups,
      'stationary',
      activeCameraId,
      hoveredCameraId,
      timelapseFrame
    );
  }, [hoveredCameraId, activeCameraId, alertCameraGroups, timelapseFrame]);

  const geojsonViewshedPtz: FeatureCollection<
    Point | Polygon,
    GenericCameraFeatureProperties
  > = useMemo(() => {
    return generateViewshedGeojson(
      alertCameraGroups,
      'ptz',
      activeCameraId,
      hoveredCameraId,
      timelapseFrame
    );
  }, [activeCameraId, alertCameraGroups, hoveredCameraId, timelapseFrame]);

  const activeCameraLatLng = useMemo(() => {
    if (!activeCameraId) {
      return null;
    }

    const activeCamera = alertCameras.find(
      (camera) => camera.id === activeCameraId
    );
    if (!activeCamera) {
      return null;
    }

    return activeCamera.latlng;
  }, [activeCameraId, alertCameras]);

  useCenterMap({
    latLng: activeCameraLatLng,
    drawerIsOpen: true,
    defaultSnapPointPct: 0.7
  });

  return (
    <>
      <Source id={STATIONARY_SOURCE_ID} type="geojson" data={geojsonStationary}>
        <Layer
          id={STATIONARY_ICONS_LAYER_ID}
          {...addVisible(
            getBaseIconStyles('stationary', isInternalUser, activeCameraId),
            visible
          )}
        />
      </Source>
      <Source id={PTZ_SOURCE_ID} type="geojson" data={geojsonPtz.baseIcons}>
        <Layer
          id={PTZ_ICONS_LAYER_ID}
          {...addVisible(
            getBaseIconStyles('ptz', isInternalUser, activeCameraId),
            visible
          )}
        />
      </Source>
      <Source
        id={PTZ_ARROWS_SOURCE_ID}
        type="geojson"
        data={geojsonPtz.arrowIcons}
      >
        <Layer
          id={PTZ_ARROW_ICONS_LAYER_ID}
          {...addVisible(
            getArrowIconStyles('ptz', isInternalUser, activeCameraId),
            visible
          )}
        />
      </Source>
      <Source
        id={STATIONARY_VIEWSHED_SOURCE_ID}
        type="geojson"
        data={geojsonViewshedStationary}
      >
        <Layer
          id={STATIONARY_VIEWSHED_LAYER_FILL_ID}
          {...addVisible(
            getViewshedFillStyles('stationary', isInternalUser, activeCameraId),
            visible
          )}
        />
        <Layer
          id={STATIONARY_VIEWSHED_LAYER_STROKE_ID}
          {...addVisible(
            getViewshedStrokeStyles(
              'stationary',
              isInternalUser,
              activeCameraId
            ),
            visible
          )}
        />
      </Source>
      <Source
        id={PTZ_VIEWSHED_SOURCE_ID}
        type="geojson"
        data={geojsonViewshedPtz}
      >
        <Layer
          id={PTZ_VIEWSHED_LAYER_FILL_ID}
          {...addVisible(
            getViewshedFillStyles('ptz', isInternalUser, activeCameraId),
            visible
          )}
        />
        <Layer
          id={PTZ_VIEWSHED_LAYER_STROKE_ID}
          {...addVisible(
            getViewshedStrokeStyles('ptz', isInternalUser, activeCameraId),
            visible
          )}
        />
      </Source>
    </>
  );
};
