import { TFunction } from 'i18next';
import { subDays } from 'date-fns';
import { AlertCamera } from 'hooks/useAlertCameras';

export const getSimplifiedCameraNames = (
  cameras: AlertCamera[],
  t: TFunction
): AlertCamera[] => {
  const defaultName = t('alertCameras.main');

  const firstCameraCharacters = cameras[0].name.split('');

  const commonCharacters = firstCameraCharacters.reduce((acc, character) => {
    const isCommon = cameras.every((camera) =>
      camera.name.startsWith(acc + character)
    );

    return isCommon ? acc + character : acc;
  }, '');

  if (commonCharacters.trim() === '') {
    return cameras;
  }

  const simplified = cameras.map((camera) => {
    const simpleName =
      camera.name.replace(commonCharacters, '').trim() || defaultName;
    return {
      ...camera,
      name: simpleName
    };
  });

  if (simplified.filter((c) => c.name === defaultName).length > 1) {
    return cameras;
  }

  return simplified.map((camera) => ({
    ...camera,
    name: camera.name
      .replace('(', '')
      .replace(')', '')
      .replace('[', '')
      .replace(']', '')
      .trim()
  }));
};

export const getRelatedCameras = (
  camera: AlertCamera,
  allCameras: AlertCamera[],
  t: TFunction
): AlertCamera[] => {
  const relatedCameras = allCameras.filter(
    (c) =>
      c.approximateLatlng.lat === camera.approximateLatlng.lat &&
      c.approximateLatlng.lng === camera.approximateLatlng.lng
  );

  return getSimplifiedCameraNames(relatedCameras, t).sort(
    (a: AlertCamera, b: AlertCamera) => a.name.localeCompare(b.name)
  );
};

export const getCameraAttributionImage = (
  camera: AlertCamera,
  imgUrls: Record<string, string>
): string | null => {
  const attributionImageUrl = imgUrls[camera.attribution.toUpperCase()];

  if (attributionImageUrl) {
    return attributionImageUrl;
  }

  if (camera.hasPtz && camera.provider === 'alertwest') {
    return imgUrls.ALERTWEST;
  }

  return null;
};

export const isCameraOffline = (timestamp: Date): boolean =>
  timestamp.getTime() < subDays(new Date(), 1).getTime();

export const isTimelapseSupported = (camera: AlertCamera): boolean =>
  camera.provider === 'alertwest';
