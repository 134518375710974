import {
  Box,
  DialogContent,
  DialogTitle,
  Link,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import format from 'date-fns/format';
import { RedFlagWarning } from 'shared/types';

type RFWDialogContentProps = {
  redFlagWarning: RedFlagWarning;
};

type InfoRowProps = {
  title: string;
  date: string;
  titleId?: string;
  titleClassName?: string;
};

const useStyles = makeStyles()((theme) => ({
  title: {
    padding: theme.spacing(2, 6, 1, 2),
    lineHeight: '1 !important'
  },
  content: {
    padding: theme.spacing(0, 4, 2, 2),
    maxWidth: 375,
    lineHeight: '18px'
  },
  description: {
    margin: theme.spacing(0, 0, 1)
  },
  infoTitle: {
    fontVariant: 'all-small-caps'
  },
  link: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.accent.main,
    fontVariant: 'all-small-caps',
    pointerEvents: 'auto',
    fontWeight: theme.typography.fontWeightBold
  }
}));

const formatTimestamp = (timestamp: number): string => {
  const date = new Date(timestamp);
  const combiner = i18n.t('common.at');

  const timeZoneName = date
    .toLocaleString(undefined, {
      timeZoneName: 'short'
    })
    .split(' ')
    .reverse()[0];

  return `${format(date, `E, MMM d '${combiner}' h:mm a`)} ${timeZoneName}`;
};

const InfoRow = (props: InfoRowProps): JSX.Element => {
  const { title, date, titleId, titleClassName } = props;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: 96 }}>
        <Typography
          variant="body2"
          color="textSecondary"
          id={titleId}
          className={titleClassName}
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="body2">{date}</Typography>
      </Box>
    </Box>
  );
};

export const RFWDialogContent = (props: RFWDialogContentProps): JSX.Element => {
  const { redFlagWarning } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <Typography component="span" variant="body2">
          <b>{t('map.poi.redFlagWarning.title')}</b>
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <InfoRow
          title={t('map.poi.redFlagWarning.starting')}
          date={formatTimestamp(redFlagWarning.startTimestamp)}
          titleId="alert-dialog-description"
          titleClassName={classes.infoTitle}
        />

        <InfoRow
          title={t('map.poi.redFlagWarning.ending')}
          date={formatTimestamp(redFlagWarning.endTimestamp)}
          titleClassName={classes.infoTitle}
        />

        <InfoRow
          title={t('map.poi.redFlagWarning.updated')}
          date={formatTimestamp(redFlagWarning.updatedTimestamp)}
          titleClassName={classes.infoTitle}
        />

        <Box sx={{ marginTop: 1 }}>
          <Link
            href={redFlagWarning.link}
            target="_blank"
            underline="none"
            className={classes.link}
          >
            {t('map.poi.redFlagWarning.readMore')}
          </Link>
        </Box>
      </DialogContent>
    </>
  );
};
