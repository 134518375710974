import { IconButton } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Link } from 'react-router-dom';

type CameraFullScreenButtonProps = {
  cameraId: string;
  disabled?: boolean;
};

export const CameraFullScreenButton = (
  props: CameraFullScreenButtonProps
): JSX.Element => {
  const { cameraId, disabled = false } = props;

  return (
    <IconButton
      component={Link}
      to={`/camera/${cameraId}/fullscreen`}
      disabled={disabled}
    >
      <FullscreenIcon fontSize="medium" sx={{ color: 'text.primary' }} />
    </IconButton>
  );
};
