import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Link as MuiLink
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useMap } from 'react-map-gl/maplibre';
import { MapPin } from 'shared/types';
import { parseCoordinate } from 'shared/utils';
import Tooltip from 'components/Tooltip';
import { useAuthState } from 'state';
import { API } from 'api';
import useClipboard from 'hooks/useClipboard';

type MapPinDialogContentProps = {
  mapPin: MapPin;
};

type LatLngMetadata = {
  region: {
    id: number;
    displayName: string;
    state: string;
    name: string; // AKA "slug"
  } | null;
  slackChannel: string;
};

const fetchMetadata = (
  lat: number,
  lng: number
): Promise<AxiosResponse<LatLngMetadata>> =>
  API.get<LatLngMetadata>(`/lat_lng/metadata/?lat=${lat}&lng=${lng}`);

const useStyles = makeStyles()((theme) => ({
  title: {
    padding: theme.spacing(2, 6, 0.5, 2),
    lineHeight: '1.2 !important',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  actions: {
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(1)
  },
  link: {
    color: theme.palette.accent.main,
    fontWeight: theme.typography.fontWeightBold,
    padding: '2px 0px',
    textDecoration: 'underline'
  },
  button: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.accent.main,
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(1)
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export const MapPinDialogContent = (
  props: MapPinDialogContentProps
): JSX.Element => {
  const { mapPin } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { current: map } = useMap();
  const {
    permissions: { isInternalUser }
  } = useAuthState();

  const metaDataQuery = useQuery({
    queryKey: ['metadata', mapPin.lat, mapPin.lng],
    queryFn: () => fetchMetadata(mapPin.lat, mapPin.lng),
    enabled: isInternalUser && !!mapPin.lat && !!mapPin.lng
  });

  const coordinates = `${parseCoordinate(mapPin.lat)}, ${parseCoordinate(
    mapPin.lng
  )}`;

  const { copied, copyText, reset } = useClipboard();

  const { isLoading, data } = metaDataQuery;

  const countyName = data?.data.region?.displayName ?? '';
  const slackChannel = data?.data.slackChannel;

  const createRoutePathParams = new URLSearchParams({
    lat: mapPin.lat.toString(),
    lng: mapPin.lng.toString(),
    zoom: map?.getZoom().toString() ?? ''
  });

  return (
    <Box sx={{ position: 'relative', minHeight: 116 }}>
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <Typography component="span" variant="body2">
          <b>{countyName}</b>
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <Tooltip
          title={t('common.copied')}
          open={copied}
          placement="top"
          onClose={reset}
          leaveDelay={200}
          sx={{ '.MuiTooltip-tooltip': { marginBottom: '0px !important' } }}
        >
          <MuiLink
            component="button"
            variant="body2"
            onClick={() => copyText(coordinates)}
            className={classes.link}
          >
            {coordinates}
          </MuiLink>
        </Tooltip>

        {slackChannel && (
          <MuiLink
            href={`https://watchduty.slack.com/channels/${slackChannel}/`}
            target="_blank"
            variant="body2"
            className={classes.link}
          >
            {`#${slackChannel}`}
          </MuiLink>
        )}
      </DialogContent>

      <DialogActions className={classes.actions}>
        <MuiLink
          to={`/incident/create?${createRoutePathParams.toString()}`}
          component={Link}
          variant="subtitle1"
          className={classes.button}
          underline="none"
        >
          {t('map.poi.mapPin.button')}
        </MuiLink>
      </DialogActions>

      {isLoading && (
        <Box className={classes.loaderContainer}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
