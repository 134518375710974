import { HTMLAttributes, ReactNode } from 'react';
import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { LocationOn as LocationOnIcon } from '@mui/icons-material';
import { getTimePass } from 'shared/utils';
import { MapIcon } from '../types';
import useStyles from './styles';

type SearchOptionItemProps = {
  optionName: string;
  primaryText: ReactNode;
  secondaryText: string;
  drawerItem: boolean;
  optionProps?: HTMLAttributes<HTMLLIElement>;
  imgAlt?: string;
  icon?: MapIcon;
  className?: string;
  lastUpdatedAt?: string;
};

/**
 * @deprecated by `<SearchResultRow />` in `useMapSearch()`
 */
const SearchOptionItem = (props: SearchOptionItemProps): JSX.Element => {
  const {
    optionName,
    primaryText,
    secondaryText,
    drawerItem,
    optionProps = {},
    imgAlt,
    icon,
    className,
    lastUpdatedAt
  } = props;
  const { classes, cx } = useStyles();

  return (
    <ListItemButton
      component="li"
      {...optionProps}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        padding: drawerItem ? 2 : 1,
        minHeight: drawerItem ? 67 : undefined
      }}
      divider={drawerItem}
      className={className}
      aria-label={optionName}
    >
      <ListItemIcon sx={{ minWidth: drawerItem ? 40 : 32 }}>
        {icon ? (
          <Box
            className={classes.geoEventIcon}
            sx={{ width: drawerItem ? 4 : 3 }}
          >
            <img
              src={icon.data}
              alt={imgAlt}
              width={drawerItem ? 24 : 16}
              height="auto"
            />
          </Box>
        ) : (
          <LocationOnIcon
            className={cx(
              classes.itemIcon,
              drawerItem && classes.itemIconDrawerSearch
            )}
          />
        )}
      </ListItemIcon>
      <ListItemText
        primary={primaryText}
        secondary={
          <Box className={classes.itemSecondary}>
            <Typography
              color="textSecondary"
              variant="subtitle1"
              className={classes.itemSecondaryText}
            >
              {secondaryText}
            </Typography>
            {lastUpdatedAt && (
              <Typography variant="body2" color="textPrimary">
                {getTimePass(lastUpdatedAt)}
              </Typography>
            )}
          </Box>
        }
        secondaryTypographyProps={{ component: 'div' }}
        className={classes.itemText}
      />
    </ListItemButton>
  );
};

export default SearchOptionItem;
