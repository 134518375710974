import { Menu, MenuItem } from '@mui/material';
import Tooltip from 'components/Tooltip';

import { ContextMenuCoordinatesType } from 'hooks/useContextMenu';
import useClipboard from 'hooks/useClipboard';
import { useTranslation } from 'react-i18next';
import { parseCoordinate } from 'shared/utils';

type DropPinContextMenuType = {
  dropPinHandler: () => void;
  ctxMenuClose: () => void;
  contextMenuCoordinates: ContextMenuCoordinatesType | null;
};

const DropPinContextMenu = (props: DropPinContextMenuType): JSX.Element => {
  const { contextMenuCoordinates, ctxMenuClose, dropPinHandler } = props;
  const { t } = useTranslation();

  const latLng = contextMenuCoordinates
    ? `${parseCoordinate(contextMenuCoordinates.lat)}, ${parseCoordinate(
        contextMenuCoordinates.lng
      )}`
    : '';

  const { copied, copyText, reset } = useClipboard();

  return (
    <Menu
      open={contextMenuCoordinates !== null}
      onClose={ctxMenuClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenuCoordinates !== null
          ? {
              top: contextMenuCoordinates.mouseY,
              left: contextMenuCoordinates.mouseX
            }
          : undefined
      }
    >
      <Tooltip
        title={t('common.copied')}
        open={copied}
        placement="top"
        onClose={reset}
        leaveDelay={200}
      >
        <MenuItem onClick={() => copyText(latLng)}>{latLng}</MenuItem>
      </Tooltip>
      <MenuItem onClick={dropPinHandler}>
        {t('map.contextMenu.dropPinBtnTitle')}
      </MenuItem>
    </Menu>
  );
};

export default DropPinContextMenu;
