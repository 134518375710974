import { API, MapBoxAPI } from 'api';
import { GeoEvent, MapSearchApi, MapSearchResult } from 'shared/types';

type GoogleCoordsData = {
  coords: [lng: number, lat: number];
  formattedAddress: string;
};

const AUTOCOMPLETE_RADIUS = 500;

/** @deprecated by `getGoogleCoords()` in `useMapSearch()` */
export const getGoogleCoords = async (
  placeId: string
): Promise<GoogleCoordsData | null> => {
  if (!window.google) {
    return null;
  }

  const geocoder = new window.google.maps.Geocoder();
  const { results } = await geocoder.geocode({ placeId });

  const place = results[0];

  if (!place) {
    return null;
  }

  return {
    coords: [place.geometry.location.lng(), place.geometry.location.lat()],
    formattedAddress: place.formatted_address
  };
};

/** @deprecated by `fetchGoogleMapsSearch()` in `useMapSearch()` */
const fetchGoogleAutocompleteResults = async (
  query: string,
  proximity: string
): Promise<MapSearchResult[]> => {
  if (!window.google) {
    return [];
  }

  const mapsService = new window.google.maps.places.AutocompleteService();

  const [lng, lat] = proximity.split(',').map((e) => parseFloat(e.trim()));

  const googleMapsPlacesRequest: google.maps.places.AutocompletionRequest = {
    location: new window.google.maps.LatLng(lat, lng),
    radius: AUTOCOMPLETE_RADIUS,
    input: query,
    region: 'us'
  };

  const { predictions } = await mapsService.getPlacePredictions(
    googleMapsPlacesRequest
  );

  if (!predictions) {
    return [];
  }

  return predictions.map((result) => ({
    id: result.place_id,
    type: 'Feature',
    text: result.structured_formatting.main_text,
    placeName: result.description,
    center: [] // Placeholder, this will be updated for the selected place
  }));
};

/** @deprecated by `fetchMapboxSearch()` in `useMapSearch()` */
export const fetchLocations = async (
  term: string,
  proximity: string,
  searchApi: MapSearchApi
): Promise<MapSearchResult[]> => {
  if (searchApi === 'google') {
    return fetchGoogleAutocompleteResults(term, proximity);
  }

  const res = await MapBoxAPI.get<{
    type: 'FeatureCollection';
    query: string[];
    features: MapSearchResult[];
  }>(`/${encodeURIComponent(term)}.json`, {
    params: {
      access_token: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN ?? '',
      proximity,
      types:
        'country,region,district,postcode,locality,place,address,poi,neighborhood',
      language: 'en',
      limit: '5',
      country: 'us'
    }
  });

  return res.data.features;
};

/** @deprecated by `fetchGeoEventsForSearch()` in `useMapSearch()` */
export const fetchGeoEvents = async (term: string): Promise<GeoEvent[]> => {
  const params = new URLSearchParams({
    q: term,
    limit: '10',
    reporter_managed_only: 'false'
  });
  const res = await API.get(`geo_events/search?${params.toString()}`);
  return res.data.results;
};
