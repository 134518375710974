import { useCallback, useMemo } from 'react';
import { Layer, LayerProps, Source, useMap } from 'react-map-gl/maplibre';
import { Feature, Geometry } from 'geojson';
import { PlaceAddress, PlaceLocation } from 'shared/types';
import useMapLayersState from 'state/useMapLayersState';
import { usePoisState } from 'state/usePoisState';
import { FONT_NAMES, PLACE_LABELS_SOURCE_ID } from '../styles/constants';
import { LOCATION_ZOOM_LEVEL, PlaceMapMarkerLabelColor } from '../constants';
import { getPlaceIcon } from '../Icons';
import { PlaceDialogContent } from '../PlaceDialogContent';
import { MapboxFeature, useMapLayerEvents } from './useMapLayerEvents';

type PlaceLabelsLayerProps = {
  places: PlaceLocation[];
};

type PlaceFeatureProperties = {
  id: number;
  name: string;
  icon: string;
  address: string;
};

const LABELS_MIN_ZOOM = 7;

const LABELS_LAYER_ID = 'place-labels';
const PIN_ICONS_LAYER_ID = 'place-pin-icons';
const PLACE_ICONS_LAYER_ID = 'place-icons';

const pinIconStyle: LayerProps = {
  type: 'symbol',
  layout: {
    'icon-image': 'PlaceLocationPin',
    'icon-allow-overlap': true,
    'icon-anchor': 'bottom',
    'icon-ignore-placement': false
  },
  paint: {
    'icon-opacity': 1
  }
};

const placeIconStyle: LayerProps = {
  type: 'symbol',
  layout: {
    'icon-image': ['get', 'icon'],
    'icon-allow-overlap': true,
    'icon-anchor': 'bottom',
    'icon-ignore-placement': false,
    'icon-offset': [0, -12]
  },
  paint: {
    'icon-opacity': 1,
    'icon-color': '#000000'
  }
};

const labelStyleCommon: LayerProps = {
  type: 'symbol' as const,
  minzoom: LABELS_MIN_ZOOM,
  layout: {
    'text-font': FONT_NAMES.regular,
    'text-field': [
      'format' as const,
      ['get', 'name'],
      {
        'text-font': ['literal', FONT_NAMES.bold]
      }
    ],
    'text-anchor': 'top',
    'text-justify': 'auto'
  },
  paint: {
    'text-halo-width': 1.8,
    'text-translate': [0, -2]
  }
};

const labelStyleLight: LayerProps = {
  ...labelStyleCommon,
  paint: {
    ...labelStyleCommon.paint,
    'text-color': PlaceMapMarkerLabelColor,
    'text-halo-color': 'rgba(255,255,255,0.8)'
  }
};

const labelStyleDark: LayerProps = {
  ...labelStyleCommon,
  paint: {
    ...labelStyleCommon.paint,
    'text-color': '#ffffff',
    'text-halo-color': 'rgba(0,0,0,0.8)'
  }
};

const toGeojsonFeature = (
  place: PlaceLocation
): Feature<Geometry, PlaceFeatureProperties> => {
  return {
    type: 'Feature' as const,
    geometry: {
      type: 'Point' as const,
      coordinates: [
        place.address.coordinates.longitude,
        place.address.coordinates.latitude
      ]
    },
    properties: {
      id: place.id,
      name: place.name,
      icon: getPlaceIcon(place.icon).name,
      address: JSON.stringify(place.address)
    }
  };
};

export const PlacesLayer = (
  props: PlaceLabelsLayerProps
): JSX.Element | null => {
  const { places } = props;
  const { setSelectedPoi } = usePoisState();
  const { default: map } = useMap();
  const { isLightBaseLayer } = useMapLayersState();
  const labelStyle = isLightBaseLayer ? labelStyleLight : labelStyleDark;

  const handleClick = useCallback(
    (geoJsonFeatures: MapboxFeature<PlaceFeatureProperties>[]) => {
      const place = geoJsonFeatures[0].properties;
      const address = JSON.parse(place.address) as PlaceAddress;

      map?.easeTo({
        center: [address.coordinates.longitude, address.coordinates.latitude],
        zoom: LOCATION_ZOOM_LEVEL
      });

      setSelectedPoi({
        type: 'place',
        PoiDialogContent: () => (
          <PlaceDialogContent
            id={place.id}
            name={place.name}
            address={address}
          />
        )
      });
    },
    [map, setSelectedPoi]
  );

  useMapLayerEvents<PlaceFeatureProperties>({
    layerId: PIN_ICONS_LAYER_ID,
    onClick: handleClick
  });

  const geojson = useMemo(
    () => ({
      type: 'FeatureCollection' as const,
      features: places.map((place) => toGeojsonFeature(place))
    }),
    [places]
  );

  return (
    <Source id={PLACE_LABELS_SOURCE_ID} type="geojson" data={geojson}>
      <Layer id={PIN_ICONS_LAYER_ID} {...pinIconStyle} />
      <Layer id={PLACE_ICONS_LAYER_ID} {...placeIconStyle} />
      <Layer id={LABELS_LAYER_ID} {...labelStyle} />
    </Source>
  );
};
