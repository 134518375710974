import { RefObject, useCallback, useEffect, useMemo } from 'react';
import { focusManager, useQueryClient } from '@tanstack/react-query';
import { MapProvider } from 'react-map-gl/maplibre';
import {
  useAuthState,
  useCacheState,
  useMapState,
  useScrollPositionState
} from 'state';
import {
  EvacZone,
  GeoEvent,
  GeoEventEvacZoneStatus,
  LayerEvacZone,
  Location,
  LatLng
} from 'shared/types';
import { usePoisState } from 'state/usePoisState';
import { isMobile } from 'shared/utils';
import { IncidentMapStateUpdate } from 'state/useMapState';
import LocationsLayer from 'components/Map/layers/LocationsLayer';
import { useSelectedWildfireGeoEventId } from 'hooks/useSelectedWildfireGeoEventId';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import useGeoEventQuery from 'hooks/useGeoEventQuery';
import { MapPinLocation } from 'components/Map/Icons';
import useGeoEvents from 'hooks/useGeoEvents';
import useSearchDrawerState from 'state/useSearchDrawerState';
import useActiveEvacZonesQuery from 'hooks/useActiveEvacZonesQuery';
import useReportsQuery from 'hooks/useReportsQuery';
import useContextMenu from 'hooks/useContextMenu';
import useMapLongPress from 'hooks/useMapLongPress';
import useSelectedLocationState from 'state/useSelectedLocationState';
import ElectricLinesLayer from 'components/Map/layers/TransmissionLinesLayer/ElectricLinesLayer';
import GasPipelineLayer from 'components/Map/layers/TransmissionLinesLayer/GasPipelineLayer';
import { Route, Switch } from 'react-router-dom';
import useGeoEventEvacZonesQuery from 'hooks/useGeoEventEvacZonesQuery';
import { AlertCamerasLayer } from 'components/Map/layers/AlertCamerasLayer';
import { PrivateLandOwnershipLayer } from 'components/Map/layers/PrivateLandOwnershipLayer';
import { SEARCH_DRAWER_WIDTH } from '../../constants';
import Map from '../Map';
import GeoEventsLayer from '../Map/layers/GeoEventsLayer';
import { SearchDrawer } from './SearchDrawer';
import { CenterMap } from './CenterMap';
import { MapPinDialogContent } from '../Map/MapPinDialogContent';
import DropPinContextMenu from './DropPinContextMenu';
import useMapLayersState from '../../state/useMapLayersState';
import { MapLayers } from '../Map/constants';
import ClearTimeoutMap from './ClearTimeoutMap';
import { DrawerRefContent } from '../Map/MapEntityDrawer';
import { StructuredEvacuationsViewLayer } from '../Map/layers/StructuredEvacuationsLayer';

type IncidentsMapProps = {
  drawerRef: RefObject<DrawerRefContent | null>;
  initialLat?: number;
  initialLng?: number;
  initialZoom?: number;
};

const useStyles = makeStyles<{ open: boolean }>()((theme, { open }) => ({
  root: {
    flex: 1,
    display: 'flex'
  },
  mapContainer: {
    flexGrow: 1,
    position: 'relative'
  },
  mapContainerLargeFormat: {
    flexGrow: 1,
    position: 'relative',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: -SEARCH_DRAWER_WIDTH,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginRight: 0
    })
  }
}));

const IncidentsMap = (props: IncidentsMapProps): JSX.Element => {
  const { drawerRef, initialLat, initialLng, initialZoom } = props;
  const {
    incidentMapState,
    updateIncidentMapState,
    resetReportMapState,
    setActiveMapBounds
  } = useMapState();
  const { reset: resetScrollState } = useScrollPositionState();
  const {
    permissions: { canReport, isInternalUser },
    showMembershipProFeatures
  } = useAuthState();
  const { cacheBusterTs } = useCacheState();
  const lat = initialLat || incidentMapState.center.lat;
  const lng = initialLng || incidentMapState.center.lng;
  const center = useMemo(() => ({ lat, lng }), [lat, lng]);
  const { selectedPoi, setSelectedPoi } = usePoisState();
  const selectedGeoEventId = useSelectedWildfireGeoEventId();
  const isMobileApp = isMobile();
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up('tablet'));
  const { open } = useSearchDrawerState();
  const { classes } = useStyles({ open });
  const { selectedLocation, setSelectedLocation } = useSelectedLocationState();
  const mapLayersState = useMapLayersState();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isInternalUser && initialLat && initialLng) {
      const mapPin = {
        lat: initialLat,
        lng: initialLng
      };

      setSelectedPoi({
        coordinates: mapPin,
        type: 'mapPin',
        PoiDialogContent: () => <MapPinDialogContent mapPin={mapPin} />
      });
    }
  }, [isInternalUser, initialLat, initialLng, setSelectedPoi]);

  // Because we use history.goBack from the addIncident form - we need to set focus on the page to ensure
  // we refetch the tiles query and reporters see the newly created incident.
  // Otherwise the query uses the disk cache and does not refetch, even with modifications to gcTime/staleTime
  useEffect(() => {
    if (canReport) {
      focusManager.setFocused(true);
    }
  }, [canReport]);

  const { allGeoEvents, wildfireEvents, locations } = useGeoEvents();
  const { geoEvent: selectedGeoEvent } =
    useGeoEventQuery<GeoEvent | Location>();

  // use the list data to speed up the re-center behavior when you get to the map so we don't have to wait for the
  // second query to possibly finish
  const selectedLatLng: LatLng | null = useMemo(() => {
    const listGeoEvent = allGeoEvents.find(
      (item) => item?.id === selectedGeoEventId
    );
    if (listGeoEvent) {
      return { lat: listGeoEvent.lat, lng: listGeoEvent.lng };
    }
    if (selectedGeoEvent) {
      return { lat: selectedGeoEvent.lat, lng: selectedGeoEvent.lng };
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGeoEventId, selectedGeoEvent]);

  const activeEvacZonesQuery = useActiveEvacZonesQuery();

  const reportsQuery = useReportsQuery(selectedGeoEventId);

  const reports = reportsQuery.data?.data || [];

  const selectedGeoEventEvacZonesQuery = useGeoEventEvacZonesQuery(
    selectedGeoEvent as GeoEvent
  );

  const activeEvacZones: GeoEventEvacZoneStatus[] = useMemo(
    () => activeEvacZonesQuery.data?.data ?? [],
    [activeEvacZonesQuery.data?.data]
  );

  const geoEventEvacZones: EvacZone[] = useMemo(
    () => selectedGeoEventEvacZonesQuery.data?.data ?? [],
    [selectedGeoEventEvacZonesQuery.data?.data]
  );

  // we want to ensure that we are using the most up to data for statuses that can be returned from either
  //    useActiveEvacZonesQuery or geoEvent.evacZoneStatuses, with a preference for the data
  //    from geoEvent.evacZoneStatuses. If the data exists in geoEvent.evacZoneStatuses and not in evacZonesQuery
  //    then we should invalidate the querycache for the useActiveEvacZonesQuery to ensure that the main map
  //    view also reflects this when moving out of the selected incident state.
  //  Note: should this pulled out into a hook or made part of useActiveEvacZonesQuery.
  //  Note: this has a side-effect (cacheInvalidation)
  const evacuationZones: GeoEventEvacZoneStatus[] = useMemo(() => {
    const geoEventStatuses = selectedGeoEvent?.evacZoneStatuses || [];
    let shouldPerformCacheInvalidation = false;
    const allUids = Array.from(
      new Set([
        ...activeEvacZones.map((zone) => zone.evacZone.uid),
        ...geoEventStatuses.map((zone) => zone.evacZone.uid)
      ])
    );
    const combined = allUids.map((uid) => {
      const selectedGeoEventEvacZone = geoEventStatuses.find(
        (zone) => zone.evacZone.uid === uid
      );
      const activeEvacZone = activeEvacZones.find(
        (zone) => zone.evacZone.uid === uid
      );
      // if this is a new zone not returned in the evac_status fetch, we should perform cache invalidation
      if (selectedGeoEventEvacZone && !activeEvacZone) {
        shouldPerformCacheInvalidation = true;
      }
      //  or if the statuses don't match - assume evac_statuses is now out of date as well
      if (
        selectedGeoEventEvacZone &&
        activeEvacZone &&
        selectedGeoEventEvacZone.status !== activeEvacZone.status
      ) {
        shouldPerformCacheInvalidation = true;
      }

      return selectedGeoEventEvacZone || activeEvacZone;
    });
    if (shouldPerformCacheInvalidation) {
      queryClient.invalidateQueries({
        queryKey: ['active-evacuation-zones']
      });
    }
    return combined.filter(
      (zoneOrUndefined) => !!zoneOrUndefined
    ) as GeoEventEvacZoneStatus[];
  }, [selectedGeoEvent, activeEvacZones, queryClient]);

  const evacZones: LayerEvacZone[] = useMemo(() => {
    const { activeZones, inactiveZones } = geoEventEvacZones.reduce(
      (geoEventZones, evacZone) => {
        const isZoneActive = !!evacuationZones.find(
          (aZ) =>
            aZ.geoEventId === selectedGeoEventId &&
            aZ.evacZone.id === evacZone.id
        );

        if (isZoneActive) {
          geoEventZones.activeZones.push(evacZone);
        } else {
          geoEventZones.inactiveZones.push(evacZone);
        }

        return geoEventZones;
      },
      { activeZones: [] as EvacZone[], inactiveZones: [] as EvacZone[] }
    );

    if (activeZones.length > 0) {
      // We display geoevent inactive evac zones when selected
      return [
        ...inactiveZones.map((zone) => ({
          uid: zone.uid,
          style: zone.region.evacZoneStyle
        })),
        ...evacuationZones.map((zone) => ({
          uid: zone.evacZone.uid,
          status: zone.status,
          style: zone.evacZone.regionEvacZoneStyle
        }))
      ];
    }

    return evacuationZones.map((zone) => ({
      uid: zone.evacZone.uid,
      status: zone.status,
      style: zone.evacZone.regionEvacZoneStyle
    }));
  }, [evacuationZones, geoEventEvacZones, selectedGeoEventId]);

  // Resetting ReportMap State so that it re-centers correctly
  // when viewing the report map.
  useEffect(() => {
    resetReportMapState();
    resetScrollState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update incident map state when the user pan / zooms the map
  // and set tiles so we re-query for new geo-events
  const onViewportChange = useCallback(
    (viewport: IncidentMapStateUpdate) => {
      updateIncidentMapState(viewport);
      setActiveMapBounds(viewport.bounds);
    },
    [updateIncidentMapState, setActiveMapBounds]
  );

  const handleMapClick = useCallback(
    (e: maplibregl.MapLayerMouseEvent) => {
      if (selectedLocation) {
        setSelectedLocation(null);
      }
    },
    [selectedLocation, setSelectedLocation]
  );

  const handleDropPin = useCallback(
    (lngLatLan: number, lngLatLng: number): void => {
      const mapPin = {
        lat: lngLatLan,
        lng: lngLatLng
      };

      setSelectedPoi({
        coordinates: mapPin,
        type: 'mapPin',
        PoiDialogContent: () => <MapPinDialogContent mapPin={mapPin} />
      });
    },
    [setSelectedPoi]
  );

  const { ctxMenuClose, contextMenuCoordinates, openContextMenu } =
    useContextMenu();

  const { onTouchStart, clearMobileTimeout } = useMapLongPress({
    handleDropPin
  });

  const zoom = useMemo(
    () => initialZoom || incidentMapState.zoom,
    [incidentMapState.zoom, initialZoom]
  );

  // in the case of an inactive geo event linked from google search or direct link, we're no longer
  // returning that data in the list view, so we manually add this icon onto the map
  const showHiddenWildFire =
    selectedGeoEvent &&
    selectedGeoEvent.geoEventType === 'wildfire' &&
    !selectedGeoEvent.isVisible;

  const showHiddenLocation =
    selectedGeoEvent &&
    selectedGeoEvent.geoEventType === 'location' &&
    !selectedGeoEvent.isVisible;

  // It's memoized because is used inside 'CenterMap' component useEffect
  const mapPinLatLng: LatLng | null = useMemo(() => {
    const mapPin = selectedPoi?.type === 'mapPin' ? selectedPoi : null;
    if (!mapPin) {
      return null;
    }

    if (!mapPin.coordinates) {
      return null;
    }

    return {
      lat: mapPin.coordinates.lat,
      lng: mapPin.coordinates.lng
    };
  }, [selectedPoi]);

  const containerClass = isLargeMediaQuery
    ? classes.mapContainerLargeFormat
    : classes.mapContainer;

  return (
    <MapProvider>
      <Box className={classes.root}>
        <Box
          className={containerClass}
          onMouseDown={
            isMobileApp ? undefined : () => drawerRef.current?.minimize()
          }
          onTouchStart={
            isMobileApp ? () => drawerRef.current?.minimize() : undefined
          }
          onContextMenu={(e) => {
            // It's been added to prevent opening native context menu when MUI menu was open.
            e.preventDefault();
          }}
        >
          <Map
            center={center}
            zoom={zoom}
            onViewportChange={onViewportChange}
            onContextMenu={openContextMenu}
            onTouchStart={onTouchStart}
            onClick={handleMapClick}
          >
            <StructuredEvacuationsViewLayer
              visible={evacZones.length > 0}
              evacZones={evacZones}
              cacheBuster={
                evacZones.length > 0 && cacheBusterTs
                  ? cacheBusterTs
                  : undefined
              }
            />
            {showMembershipProFeatures && (
              <>
                <ElectricLinesLayer
                  visible={mapLayersState.mapLayers.includes(
                    MapLayers.ELECTRICAL_LINES
                  )}
                />
                <GasPipelineLayer
                  visible={mapLayersState.mapLayers.includes(
                    MapLayers.GAS_PIPELINES
                  )}
                />
                <PrivateLandOwnershipLayer
                  visible={mapLayersState.mapLayers.includes(
                    MapLayers.PRIVATE_LAND_OWNERSHIP
                  )}
                />
              </>
            )}
            <Switch>
              <Route
                path="/camera/:cameraId"
                render={() => (
                  <AlertCamerasLayer
                    visible={mapLayersState.mapLayers.includes(
                      MapLayers.CAMERAS
                    )}
                  />
                )}
              />
              <Route
                path="/"
                render={() => (
                  <AlertCamerasLayer
                    visible={mapLayersState.mapLayers.includes(
                      MapLayers.CAMERAS
                    )}
                  />
                )}
              />
            </Switch>
            {locations && (
              <LocationsLayer
                locations={locations}
                selectedGeoEventId={selectedGeoEventId}
              />
            )}
            {wildfireEvents && (
              <GeoEventsLayer
                geoEvents={wildfireEvents}
                isFadable
                selectedGeoEventId={selectedGeoEventId}
              />
            )}
            {showHiddenWildFire && (
              <Map.WildFireGeoEventMarker
                geoEvent={selectedGeoEvent as unknown as GeoEvent}
              />
            )}
            {showHiddenLocation && (
              <Map.LocationMarker
                location={selectedGeoEvent as unknown as Location}
              />
            )}
            <Map.Markers locations={reports} type="media" />
            {mapPinLatLng && (
              <Map.Marker
                icon={MapPinLocation}
                position={mapPinLatLng}
                scale={1.5}
                anchor="center"
              />
            )}
            <Map.MapEvents onViewportChange={onViewportChange} />
            <CenterMap
              zoomIn
              latLng={selectedLatLng}
              drawerIsOpen
              defaultSnapPointPct={0.47}
            />
            <CenterMap
              latLng={mapPinLatLng}
              drawerIsOpen={Boolean(drawerRef.current?.isOpen())}
              defaultSnapPointPct={0.47}
            />
            <ClearTimeoutMap clearMobileTimeout={clearMobileTimeout} />
            <DropPinContextMenu
              dropPinHandler={() => {
                handleDropPin(
                  contextMenuCoordinates!.lat,
                  contextMenuCoordinates!.lng
                );
                ctxMenuClose();
              }}
              ctxMenuClose={ctxMenuClose}
              contextMenuCoordinates={contextMenuCoordinates}
            />
          </Map>
        </Box>

        <SearchDrawer />
      </Box>
    </MapProvider>
  );
};

export default IncidentsMap;
