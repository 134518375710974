import * as Sentry from '@sentry/capacitor';
import i18n from 'i18next';
import moment from 'moment';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import localizationES from 'moment/locale/es';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import localizationZH from 'moment/locale/zh-cn';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import localizationTL from 'moment/locale/tl-ph';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import localizationVI from 'moment/locale/vi';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import localizationEN from 'moment/locale/en-gb';

export type Language = 'en' | 'es' | 'tl' | 'vi' | 'zh';

const localeOptions: Record<Language, Locale> = {
  en: localizationEN,
  es: localizationES,
  tl: localizationTL,
  vi: localizationVI,
  zh: localizationZH
};

export const DEFAULT_LOCALE = 'en';

export const setLanguage = async (language: Language): Promise<void> => {
  try {
    await i18n.changeLanguage(language);
    moment.updateLocale(language, localeOptions[language]);
  } catch (err) {
    Sentry.captureException(err);
  }
};
