import { useEffect, useState } from 'react';
import { Typography, Link as MuiLink } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import {
  AppUpdate,
  AppUpdateAvailability
} from '@capawesome/capacitor-app-update';
import { RouteComponentProps } from 'react-router-dom';
import useUpgradeMessageState from 'state/useUpgradeMessageState';
import Loader from 'components/Loader';
import HeaderBar from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import {
  SUCCESS_MSG,
  useAuthState,
  useCacheState,
  useSnackbarState
} from 'state';
import { getDeviceInfo } from 'state/localStorageTyped';
import { getValidCoordinateParams } from 'shared/utils';
import HomeContent from './HomeContent';
import { useHandleExternalPaymentSuccess } from './useHandleExternalPaymentSuccess';

const isUpgradeAvailable = async (): Promise<boolean> => {
  if (import.meta.env.VITE_SHOW_UPGRADE_MESSAGE === 'true') {
    return true;
  }

  const deviceInfo = getDeviceInfo();
  if (!deviceInfo || deviceInfo.isWeb) {
    return false;
  }

  const updateInfo = await AppUpdate.getAppUpdateInfo();
  return (
    updateInfo.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE
  );
};

const UpgradeCTA = (): JSX.Element => {
  const { t } = useTranslation();
  const { reset: resetSnackbar } = useSnackbarState();

  const openAppStoreAndResetSnackBar = async (): Promise<void> => {
    await AppUpdate.openAppStore();
    resetSnackbar();
  };
  return (
    <MuiLink
      onClick={openAppStoreAndResetSnackBar}
      sx={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
    >
      <Typography paragraph variant="body2">
        <b>{t('home.upgrade.message')}</b>
      </Typography>
      <Typography variant="body2">
        <Trans i18nKey="home.upgrade.cta">
          Tap to <b>Update Now</b>.
        </Trans>
      </Typography>
    </MuiLink>
  );
};

const Home = (
  props: RouteComponentProps<{ coordinates: string }>
): JSX.Element => {
  const { cacheBusterTs } = useCacheState();
  const { setSnackbar } = useSnackbarState();
  const {
    permissions: { isInternalUser }
  } = useAuthState();
  const [shouldShowUpgradeMessage, setShownUpgradeMessage] =
    useUpgradeMessageState();
  const [hasUpgrade, setHasUpgrade] = useState(false);
  const { isPending } = useHandleExternalPaymentSuccess();

  // If coordinates are in the URL, then center the map there. Otherwise, use the standard default map center.
  const {
    match: {
      params: { coordinates }
    }
  } = props;
  const { latitudeParam, longitudeParam, zoomLevelParam } =
    getValidCoordinateParams(coordinates) || {};

  useEffect(() => {
    const getUpgradeInternal = async (): Promise<void> => {
      const result = await isUpgradeAvailable();
      setHasUpgrade(result);
    };
    getUpgradeInternal();
  }, []);

  // we haven't shown them the upgrade alert in seven days, user hasn't received a push notif in past 5 minutes
  // and app store upgrade exists
  const showUpgradeMessage =
    shouldShowUpgradeMessage && !cacheBusterTs && hasUpgrade;

  const upgradeCTAComponent = UpgradeCTA();

  useEffect(() => {
    if (!showUpgradeMessage) {
      return;
    }
    setSnackbar(upgradeCTAComponent, SUCCESS_MSG, null);
    setShownUpgradeMessage();
  }, [
    upgradeCTAComponent,
    showUpgradeMessage,
    setShownUpgradeMessage,
    setSnackbar
  ]);

  return (
    <PageLayout data-cname="HomePage" data-testid="homePage">
      {{
        content: (
          <>
            <HomeContent
              initialLat={latitudeParam}
              initialLng={longitudeParam}
              initialZoom={zoomLevelParam}
            />
            {isPending && <Loader />}
          </>
        ),
        headerBar: <HeaderBar showSupportUs={!isInternalUser} />
      }}
    </PageLayout>
  );
};

export default Home;
