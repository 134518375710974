import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import { useHistory } from 'react-router-dom';
import { getDeviceInfo } from 'state/localStorageTyped';
import HeaderBar from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import RecurlyAccountInformation from 'components/RecurlyAccountInformation';
import useDonationState, { DonationState } from 'state/useDonationState';
import useLocationSearchParams from 'hooks/useLocationSearchParams';
import {
  ProcessDonationPaymentTokenData,
  ProcessInitialDonationPaymentTokenData,
  ProcessPaymentState
} from 'shared/types';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import usePaymentMethodQuery from 'hooks/usePaymentMethodQuery';
import { setAnalyticsUser } from 'shared/analytics';
import { usePaymentApi } from 'hooks/usePaymentApi';

const getInitialState = (
  donationState: DonationState,
  params: { email: string; firstName?: string; lastName?: string }
): ProcessPaymentState => {
  const {
    clientToken,
    donationAmount,
    donationRenews,
    optInToUpdates,
    email,
    firstName,
    lastName,
    existingUser,
    verificationToken,
    userId
  } = donationState;

  return {
    email: email || params.email,
    firstName: firstName || params.firstName || '',
    lastName: lastName || params.lastName || '',
    clientToken,
    optInToUpdates,
    transactionAmount: donationAmount,
    donationRenews,
    existingUser,
    verificationToken,
    userId
  };
};

const Content = (): JSX.Element => {
  const { t } = useTranslation();
  const params = useLocationSearchParams();
  const history = useHistory();

  const emailParam = decodeURIComponent(params.get('email') || '');
  const donationAmountParam = parseFloat(params.get('donationAmount') ?? '0');
  const donationRenewsParam = params.get('donationRenews') === 'true';
  const clientTokenParam = params.get('clientToken') || undefined;
  const verificationTokenParam = params.get('verificationToken') || undefined;
  const userIdParam = params.get('userId')
    ? parseInt(params.get('userId') as string, 10)
    : undefined;

  useEffect(() => {
    // set the user id for analytics when purchase membership flow continues on web
    if (userIdParam) {
      setAnalyticsUser({ userId: userIdParam.toString() });
    }
  }, [userIdParam]);

  const stateUpdateData: Partial<DonationState> | undefined = useMemo(() => {
    return clientTokenParam || verificationTokenParam
      ? {
          donationAmount: donationAmountParam,
          donationRenews: donationRenewsParam,
          clientToken: clientTokenParam,
          verificationToken: verificationTokenParam,
          userId: userIdParam,
          existingUser: !!emailParam,
          fromMobile: true
        }
      : undefined;
  }, [
    clientTokenParam,
    donationAmountParam,
    donationRenewsParam,
    emailParam,
    userIdParam,
    verificationTokenParam
  ]);

  const { updateDonationState, ...donationState } = useDonationState({
    stateUpdateData
  });

  const initialState = getInitialState(donationState, {
    email: emailParam,
    firstName: params.get('firstName') || undefined,
    lastName: params.get('lastName') || undefined
  });

  const userId = donationState.userId || userIdParam;
  const verificationToken =
    donationState.verificationToken || verificationTokenParam;

  const { data: savedPaymentMethod, isLoading: isLoadingPaymentMethod } =
    usePaymentMethodQuery({ userId, verificationToken });

  const { processDonationPaymentMutation } = usePaymentApi<
    ProcessInitialDonationPaymentTokenData | ProcessDonationPaymentTokenData
  >({
    onSuccess: () => {
      history.push('/donate/success');
    }
  });

  if (isLoadingPaymentMethod) {
    return <LoadingAndErrors isLoading />;
  }

  /**
   * IAP membership accounts return an object with all properties set to "null"
   */
  const paymentMethod =
    savedPaymentMethod && Object.values(savedPaymentMethod).some(Boolean)
      ? savedPaymentMethod
      : undefined;

  return (
    <>
      <Helmet>
        <title>{t('donationAccountInformation.title')}</title>
      </Helmet>

      <RecurlyProvider publicKey={import.meta.env.VITE_RECURLY_PUBLIC_TOKEN}>
        <Elements>
          <RecurlyAccountInformation<
            | ProcessInitialDonationPaymentTokenData
            | ProcessDonationPaymentTokenData
          >
            transactionType="donation"
            initialState={initialState}
            updateState={updateDonationState}
            processPaymentMutation={processDonationPaymentMutation}
            paymentMethod={paymentMethod}
          />
        </Elements>
      </RecurlyProvider>
    </>
  );
};

const DonateAccountInformation = (): JSX.Element => {
  const deviceInfo = getDeviceInfo();
  const isMobilePlatform = !!deviceInfo?.isMobilePlatform;

  return (
    <PageLayout data-cname="DonationAccountInformationPage" showBanner={false}>
      {{
        content: <Content />,
        headerBar: (
          <HeaderBar action={isMobilePlatform ? 'noControls' : 'back'} />
        )
      }}
    </PageLayout>
  );
};

export default DonateAccountInformation;
