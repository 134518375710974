import { Layer, LayerProps } from 'react-map-gl/maplibre';
import { usePoisState } from 'state/usePoisState';
import { useCallback } from 'react';
import ArcGisLayer from './ArcGisLayer';
import addVisible from '../../../shared/addVisible';
import { FILL_PATTERN_LAYER_MIN_ZOOM } from '../constants';
import { RFWDialogContent } from '../RFWDialogContent';
import { useMapLayerEvents } from './useMapLayerEvents';
import { MapLayerProps } from '../types';
import { getSelectedPatternStyle, getSelectedStrokeStyle } from '../utils';
import { BEFORE_LAYER_ID } from '../styles/constants';

type RedFlagWarningsProperties = {
  End_: number;
  Event: 'Red Flag Warning';
  OBJECTID: number;
  Start: number;
  Updated: number;
  Link: string;
};

const FEATURE_LAYER_URL =
  'https://services9.arcgis.com/RHVPKKiFTONKtxq3/ArcGIS/rest/services/NWS_Watches_Warnings_v1/FeatureServer/6';

const FIELDS = ['Event', 'Start', 'End_', 'Updated', 'Link'];
const RFW_FILL_LAYER_ID = 'rfw-fill-layer';
const RFW_STROKE_LAYER_ID = 'rfw-stroke-layer';
const RFW_SELECTED_FILL_LAYER_ID = 'rfw-selected-fill-layer';
const RFW_FILL_PATTERN_LAYER_ID = 'rfw-fill-pattern-layer';
const RFW_SELECTED_STROKE_LAYER_ID = 'rfw-selected-stroke-layer';

const fillStyle: LayerProps = {
  type: 'fill',
  paint: {
    'fill-color': '#FA6FB8',
    'fill-opacity': 0.3
  }
};

const patternFillStyle: LayerProps = {
  type: 'fill',
  minzoom: FILL_PATTERN_LAYER_MIN_ZOOM,
  paint: {
    'fill-pattern': 'RedFlagWarningPattern'
  }
};

const strokeStyle: LayerProps = {
  type: 'line',
  paint: {
    'line-color': '#FA6FB8',
    'line-opacity': 1,
    'line-width': ['interpolate', ['linear'], ['zoom'], 8, 1.25, 18, 3.25],
    'line-dasharray': [2, 1]
  }
};

const RedFlagWarningsLayer = (props: MapLayerProps): JSX.Element => {
  const { visible } = props;
  const { selectedPoi, setSelectedPoi } = usePoisState();

  useMapLayerEvents<RedFlagWarningsProperties>({
    layerId: RFW_FILL_LAYER_ID,
    onClick: useCallback(
      (geoJsonFeatures) => {
        const { properties } = geoJsonFeatures[0];

        const redFlagWarning = {
          id: properties.OBJECTID,
          startTimestamp: properties.Start,
          // eslint-disable-next-line no-underscore-dangle
          endTimestamp: properties.End_,
          updatedTimestamp: properties.Updated,
          link: properties.Link
        };

        setSelectedPoi({
          type: 'rfw',
          id: redFlagWarning.id,
          PoiDialogContent: () => (
            <RFWDialogContent redFlagWarning={redFlagWarning} />
          )
        });
      },
      [setSelectedPoi]
    )
  });

  const selectedRFWId = selectedPoi?.type === 'rfw' ? selectedPoi.id || 0 : 0;

  const selectedFillStyle = getSelectedPatternStyle({
    idKey: 'OBJECTID',
    selectedId: selectedRFWId
  });

  const selectedStrokeStyle = getSelectedStrokeStyle({
    idKey: 'OBJECTID',
    selectedId: selectedRFWId
  });

  return (
    <ArcGisLayer
      visible={visible}
      src={FEATURE_LAYER_URL}
      where={`Event = 'Red Flag Warning'`}
      fields={FIELDS}
      staleTime={60 * 60 * 1000} // 60 minutes
    >
      <Layer
        id={RFW_FILL_LAYER_ID}
        beforeId={BEFORE_LAYER_ID}
        {...addVisible(fillStyle, visible)}
      />
      <Layer
        id={RFW_FILL_PATTERN_LAYER_ID}
        beforeId={BEFORE_LAYER_ID}
        {...addVisible(patternFillStyle, visible)}
      />
      <Layer
        id={RFW_STROKE_LAYER_ID}
        beforeId={BEFORE_LAYER_ID}
        {...addVisible(strokeStyle, visible)}
      />
      <Layer
        id={RFW_SELECTED_FILL_LAYER_ID}
        beforeId={BEFORE_LAYER_ID}
        {...addVisible(selectedFillStyle, visible)}
      />
      <Layer
        id={RFW_SELECTED_STROKE_LAYER_ID}
        beforeId={BEFORE_LAYER_ID}
        {...addVisible(selectedStrokeStyle, visible)}
      />
    </ArcGisLayer>
  );
};

export default RedFlagWarningsLayer;
