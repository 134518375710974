// This detects if the map is not rendering properly per the investigation on
// https://trello.com/c/kmVx4ZZS/1198-base-map-doesnt-always-load-blank-or-white
//
// Out of two observed instances of the bug appearing, the HTML canvas that is used
// to get image data for map style sprites (highway shields, icons, etc) was empty
// both times.
//
// This hook detects if this canvas is indeed empty.

import { useEffect, useRef, useState } from 'react';
import useAuthState from 'state/useAuthState';
import * as Sentry from '@sentry/capacitor';

const CHECK_INTERVAL_MS = 1000;
const SPRITE_NAME_FOR_TEST = 'airport';

const isSpriteCanvasBlank = (map: maplibregl.Map): boolean => {
  const { images } = map.style.imageManager;
  const spriteData = images[SPRITE_NAME_FOR_TEST]?.spriteData;
  if (!spriteData) {
    Sentry.captureMessage('map-render-bug-no-sprite-data-found');
    return false;
  }
  const { context } = spriteData;

  // For performance reasons, check only the contents of the one sprite within
  // the sprite canvas. This assumes that the sprite canvas is either fully
  // blank or fully populated with sprite data.
  const imageData = context.getImageData(
    spriteData.x,
    spriteData.y,
    spriteData.width,
    spriteData.height
  );
  // It's faster to check each pixel vs each channel in each pixel.
  const uint32Array = new Uint32Array(imageData.data.buffer);
  return !uint32Array.some((value) => value !== 0);
};

const sendMessageToSentry = (): void => {
  Sentry.captureMessage('map-render-bug-detected');
};

// This will only run for internal users until the approach is validated.
export const useMapRenderBugDetector = (
  map: maplibregl.Map | undefined
): boolean => {
  const intervalRef = useRef<NodeJS.Timer>();
  const [bugDetected, setBugDetected] = useState(false);
  const [bugSelfHealed, setBugSelfHealed] = useState(false);
  const {
    permissions: { isInternalUser }
  } = useAuthState();

  // Set up an interval to check on the map's health.
  useEffect(() => {
    if (!isInternalUser || !map) {
      return () => {};
    }
    intervalRef.current = setInterval(() => {
      if (isSpriteCanvasBlank(map)) {
        if (!bugDetected) {
          setBugDetected(true);
          sendMessageToSentry();
        }
      } else if (bugDetected && !bugSelfHealed) {
        // This should never happen, but log it so we can check our assumption: the sprite
        // canvas has somehow re-populated itself, and we expect this never to happen without
        // a full app reload.
        Sentry.captureMessage(
          'map-render-bug-detected-then-sprite-repopulated'
        );

        // Only log this Sentry message once per app reload.
        setBugSelfHealed(true);
      }
    }, CHECK_INTERVAL_MS);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isInternalUser, map, bugDetected, bugSelfHealed]);

  return bugDetected;
};
