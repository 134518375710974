import { MATCH_URL_YOUTUBE } from 'shared/embedHelpers';
import { IFrameProps } from 'shared/types';

const YouTubeIFrame = (props: IFrameProps): JSX.Element => {
  const { src, ...iframeProps } = props;
  const [, videoId] = src.match(MATCH_URL_YOUTUBE) ?? [];
  const embedSrc = `https://www.youtube.com/embed/${videoId}`;

  return (
    <iframe
      src={embedSrc}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...iframeProps}
      allowFullScreen
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      title="YouTube Video"
      scrolling="no"
    />
  );
};

export default YouTubeIFrame;
