import { List, ListSubheader, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { getResponsiveFontSize } from 'shared/utils';
import {
  ActiveFireIcon,
  ActiveFire50Icon,
  ActiveFire75Icon,
  ContainedFireIcon,
  InactiveFireIcon,
  SilentActiveFireIcon,
  ActivePrescribedFireIcon,
  PhotoSubmissionIcon,
  ScheduledFireIcon
} from '../Icons';
import LegendListItem from './LegendListItem';

const useStyles = makeStyles()((theme) => ({
  title: {
    fontVariant: 'all-small-caps',
    fontSize: getResponsiveFontSize(theme.typography.pxToRem(20))
  },
  icon: {
    marginRight: theme.spacing(0),
    width: theme.spacing(4.5),
    minWidth: theme.spacing(4.5)
  }
}));

const FireLegend = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <List
      disablePadding
      dense
      subheader={
        <ListSubheader disableSticky>
          <Typography
            variant="h6"
            component="h3"
            className={classes.title}
            color="textPrimary"
          >
            {t('map.legends.fires.title')}
          </Typography>
        </ListSubheader>
      }
    >
      <LegendListItem
        iconSrc={ActiveFireIcon.data}
        primaryText={t('map.legends.fires.legends.less50.title')}
        secondary={t('map.legends.fires.legends.less50.subtitle')}
      />
      <LegendListItem
        iconSrc={ActiveFire50Icon.data}
        primaryText={t('map.legends.fires.legends.more50.title')}
        secondary={t('map.legends.fires.legends.more50.subtitle')}
      />
      <LegendListItem
        iconSrc={ActiveFire75Icon.data}
        primaryText={t('map.legends.fires.legends.more70.title')}
        secondary={t('map.legends.fires.legends.more70.subtitle')}
      />
      <LegendListItem
        iconSrc={ContainedFireIcon.data}
        primaryText={t('map.legends.fires.legends.contained.title')}
        secondary={t('map.legends.fires.legends.contained.subtitle')}
      />
      <LegendListItem
        iconSrc={InactiveFireIcon.data}
        primaryText={t('map.legends.fires.legends.inactive.title')}
        secondary={t('map.legends.fires.legends.inactive.subtitle')}
      />
      <LegendListItem
        iconSrc={SilentActiveFireIcon.data}
        primaryText={t('map.legends.fires.legends.silent.title')}
        secondary={t('map.legends.fires.legends.silent.subtitle')}
      />
      <LegendListItem
        iconSrc={ActivePrescribedFireIcon.data}
        primaryText={t('map.legends.fires.legends.prescribed.title')}
        secondary={t('map.legends.fires.legends.prescribed.subtitle')}
      />
      <LegendListItem
        iconSrc={ScheduledFireIcon.data}
        primaryText={t('map.legends.fires.legends.prescribedScheduled.title')}
        secondary={t('map.legends.fires.legends.prescribedScheduled.subtitle')}
      />
      <LegendListItem
        iconSrc={PhotoSubmissionIcon.data}
        primaryText={t('map.legends.fires.legends.photo.title')}
        secondary={t('map.legends.fires.legends.photo.subtitle')}
      />
    </List>
  );
};

export default FireLegend;
