import { Layer, LayerProps } from 'react-map-gl/maplibre';
import useMapLayersState from 'state/useMapLayersState';
import { BEFORE_LAYER_ID, FONT_NAMES } from '../styles/constants';
import getWatchdutyTileUrl from './getWatchdutyTileUrl';
import VisibilityToggledSource from './VisibilityToggledSource';
import { MapLayerProps } from '../types';

const years = 5;
const thisYear = new Date().getFullYear();
const fiveYearsAgo = thisYear - years;

const historicalFireFillStyle = (isDark: boolean): LayerProps => ({
  id: 'historic-perimeters-fill',
  type: 'fill',
  'source-layer': 'perimeters',
  paint: {
    'fill-color': isDark ? '#FFFFFF' : '#777777',
    'fill-opacity': [
      'interpolate',
      ['linear'],
      ['to-number', ['get', 'year']],
      fiveYearsAgo,
      0.2,
      thisYear,
      isDark ? 0.5 : 0.6
    ]
  }
});

const historicalFireLabelStyle = (isDark: boolean): LayerProps => ({
  id: 'historic-perimeters-label',
  type: 'symbol',
  'source-layer': 'perimeters',
  minzoom: 10,
  layout: {
    'text-anchor': 'center',
    'text-field': ['concat', ['get', 'name'], '\n', ['get', 'year']],
    'text-font': FONT_NAMES.bold,
    'text-justify': 'center',
    'text-letter-spacing': 0.2,
    'text-size': ['interpolate', ['linear'], ['zoom'], 8, 8, 20, 30]
  },
  paint: {
    'text-color': isDark ? '#ffffff' : '#000000',
    'text-halo-color': isDark ? '#333333' : '#eeeeee',
    'text-halo-width': 1.5
  }
});

const HistoricalFireLayer = (props: MapLayerProps): JSX.Element => {
  const { visible } = props;
  const { isDarkBaseLayer } = useMapLayersState();
  const perimeterStyle = historicalFireFillStyle(isDarkBaseLayer);
  const labelStyle = historicalFireLabelStyle(isDarkBaseLayer);

  return (
    <VisibilityToggledSource
      url={getWatchdutyTileUrl('historical_perimeters')}
      visible={visible}
    >
      <Layer beforeId={BEFORE_LAYER_ID} {...perimeterStyle} />
      <Layer beforeId={BEFORE_LAYER_ID} {...labelStyle} />
    </VisibilityToggledSource>
  );
};

export default HistoricalFireLayer;
