import { ReportAsset } from 'shared/types';

import { Status } from './types';

export const statusOptions: { label: string; value: Status }[] = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' }
];

export const assetOptions: { transLabelKey: string; value: ReportAsset }[] = [
  {
    transLabelKey: 'addIncidentReport.inputs.asset.options.image',
    value: 'image'
  },
  {
    transLabelKey: 'addIncidentReport.inputs.asset.options.social',
    value: 'socialEmbed'
  }
];
