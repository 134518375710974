import { ReactNode, useCallback, useMemo, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { BBox as BBoxType, point } from '@turf/helpers';
import distance from '@turf/distance';
import circle from '@turf/circle';
import bbox from '@turf/bbox';
import { GeoEvent } from 'shared/types';
import { DISABLE_MAP_LAYERS_FOR_FORMS } from 'components/Map/constants';
import { API, CacheAPI } from 'api';
import { useCacheState, useMapState } from 'state';
import { IncidentMapStateUpdate } from 'state/useMapState';
import { FitBoundsOptions, LngLat, LngLatBounds } from 'maplibre-gl';
import Map, { DEFAULT_VIEW_STATE } from '../Map';

type GeoEventReportsMapProps = {
  geoEvent: GeoEvent;
  hiddenMapPixels: number | null;
  noControls?: boolean;
  style?: React.CSSProperties;
  noMarkers?: boolean;
  zoomControlEnabled?: boolean;
  layersControlEnabled?: boolean;
  children?: ReactNode;
};

type CoordsPoint = [number, number];

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fetchReports = (geoEventId: number, cacheBusterTs: number | null) => {
  const baseUrl = `reports/?geo_event_id=${geoEventId}&is_moderated=true&is_active=true&has_lat_lng=true`;
  if (cacheBusterTs) {
    return CacheAPI.get(`${baseUrl}&ts=${cacheBusterTs}`);
  }
  return API.get(baseUrl);
};

const getFarthestDistance = (
  center: CoordsPoint,
  coords: CoordsPoint[]
): number => {
  let farthestDistance = -1;

  coords.forEach((coord) => {
    const distanceKm = distance(point(center), point(coord));

    if (distanceKm > farthestDistance) {
      farthestDistance = distanceKm;
    }
  });

  return farthestDistance;
};

const calculateBoundingBox = (
  center: CoordsPoint,
  radius: number
): BBoxType => {
  const circlePolygon = circle(center, radius);
  const boundingBox = bbox(circlePolygon);
  return boundingBox;
};

const GeoEventReportsMap = (props: GeoEventReportsMapProps): JSX.Element => {
  const {
    geoEvent,
    noControls,
    style,
    hiddenMapPixels,
    noMarkers,
    zoomControlEnabled,
    layersControlEnabled,
    children
  } = props;
  const { id: geoEventId } = geoEvent;
  const { reportMapState, updateReportMapState, updateGeoEventMapState } =
    useMapState();
  const { cacheBusterTs } = useCacheState();

  useEffect(() => {
    updateGeoEventMapState({ lat: geoEvent.lat, lng: geoEvent.lng });
  }, [geoEvent.lat, geoEvent.lng, updateGeoEventMapState]);

  const { data, isLoading } = useQuery({
    queryKey: ['reports', geoEventId, cacheBusterTs],
    queryFn: () => fetchReports(geoEventId, cacheBusterTs)
  });
  const reports = useMemo(() => {
    if (!isLoading && data) {
      const { data: reportsData } = data;
      return reportsData;
    }
    return [];
  }, [data, isLoading]);

  const onViewportChange = useCallback(
    (viewport: IncidentMapStateUpdate): void => {
      updateReportMapState({ currentGeoEventId: geoEventId, ...viewport });
    },
    [geoEventId, updateReportMapState]
  );

  const hasLocations = geoEvent.childGeoEvents.length > 0;

  const bbounds = useMemo(() => {
    if (!hasLocations) {
      const coordinates = new LngLat(geoEvent.lng, geoEvent.lat);

      const bounds = LngLatBounds.fromLngLat(coordinates);

      if (bounds.isEmpty()) return undefined;

      return bounds;
    }

    const geoEventCoords: CoordsPoint = [geoEvent.lng, geoEvent.lat];
    const childrenCoords = geoEvent.childGeoEvents.map(
      (childEvent) => [childEvent.lng, childEvent.lat] as CoordsPoint
    );

    const radius = getFarthestDistance(geoEventCoords, childrenCoords);

    const boundingBox = calculateBoundingBox(geoEventCoords, radius);

    const coords = [
      new LngLat(boundingBox[0], boundingBox[1]),
      new LngLat(boundingBox[2], boundingBox[3])
    ];

    const lngLatBounds = coords.reduce(
      (b, coord) => b.extend(coord),
      LngLatBounds.fromLngLat(coords[0])
    );

    return lngLatBounds;
  }, [geoEvent.childGeoEvents, geoEvent.lat, geoEvent.lng, hasLocations]);

  // I'm not sure why we need to divide by 4 - it seems like half would be the correct amount?
  const bottomOffset =
    hiddenMapPixels && hiddenMapPixels !== 0 ? -hiddenMapPixels / 4 : 0;

  const center = {
    lat: geoEvent.lat || reportMapState.center.lat,
    lng: geoEvent.lng || reportMapState.center.lng
  };

  const fitBoundsOptions: FitBoundsOptions | undefined = bbounds
    ? {
        padding: Math.max(Math.abs(bottomOffset) / 2, 8),
        maxZoom: hasLocations
          ? DEFAULT_VIEW_STATE.maxZoom
          : DEFAULT_VIEW_STATE.zoom,
        animate: false,
        offset: [0, bottomOffset]
      }
    : undefined;

  return (
    <Map
      id="geo-event-reports-map"
      bounds={bbounds}
      fitBoundsOptions={fitBoundsOptions}
      center={center}
      zoom={reportMapState.zoom}
      noControls={noControls}
      style={style}
      disableMapLayers={DISABLE_MAP_LAYERS_FOR_FORMS}
      zoomControlEnabled={zoomControlEnabled}
      layersControlEnabled={layersControlEnabled}
    >
      <Map.WildFireGeoEventMarker geoEvent={geoEvent} />
      {!noMarkers && <Map.Markers locations={reports} type="media" />}
      <Map.MapEvents onViewportChange={onViewportChange} />
      {children}
    </Map>
  );
};

export default GeoEventReportsMap;
