import { Chip, ChipProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

export type UserStatusChipType = 'internal' | 'members' | 'pro';

type UserStatusChipProps = {
  type: UserStatusChipType;
};

const translationKeys: Record<UserStatusChipType, string> = {
  internal: 'internalChip.label',
  members: 'membersChip.label',
  pro: 'proChip.label'
};

const useStyles = makeStyles()((theme) => ({
  root: {
    borderRadius: 4,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.subtitle1.fontSize,
    fontVariant: 'small-caps',
    textTransform: 'lowercase',
    height: '16px',
    padding: '1px 6px 2px',
    lineHeight: 1,
    '& span': {
      padding: 0
    }
  }
}));

const UserStatusChip = ({
  type,
  ...props
}: UserStatusChipProps & ChipProps): JSX.Element => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const chipTitle = t(translationKeys[type]);

  return (
    <Chip
      {...props}
      label={chipTitle}
      color={type === 'internal' ? 'error' : 'primary'}
      className={classes.root}
      component="span"
      data-testid={`${type}-chip`}
    />
  );
};

export default UserStatusChip;
