import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { isMobile } from 'shared/utils';
import { localStorageEffect } from './localStorage';

const DEFAULT_STATE = {
  shown: 0
};

const FOURTEEN_DAYS = 60 * 60 * 24 * 1000 * 14;

const shouldShowMessage = (shown: number): boolean => {
  if (!isMobile()) {
    return false;
  }

  if (
    import.meta.env.VITE_ENV !== 'production' &&
    import.meta.env.VITE_SHOW_UPGRADE_MESSAGE !== 'true'
  ) {
    return false;
  }

  if (shown === 0) {
    return true;
  }

  const nowMs = new Date().getTime();
  if (nowMs - shown > FOURTEEN_DAYS) {
    return true;
  }
  return false;
};

const upgradeMessageStateAtom = atom({
  key: 'UPGRADE_MESSAGE',
  default: DEFAULT_STATE,
  effects: [localStorageEffect('UPGRADE_LAST_SHOWN')]
});

const useUpgradeMessageState = (): [boolean, () => void] => {
  const [upgradeMessageState, setUpgradeMessageState] = useRecoilState(
    upgradeMessageStateAtom
  );

  const setShown = useCallback(() => {
    const shownTime = new Date().getTime();
    setUpgradeMessageState({ shown: shownTime });
  }, [setUpgradeMessageState]);

  const shouldShow = shouldShowMessage(upgradeMessageState.shown);

  return [shouldShow, setShown];
};

export default useUpgradeMessageState;
