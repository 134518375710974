import { useContext, useState } from 'react';
import {
  Box,
  List,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
  SvgIconTypeMap
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Sticky from 'react-sticky-el';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import moment from 'moment';
import { PrivateLandOwnershipEntity } from 'shared/types';
import { MapEntityDrawerContext } from 'components/Map/MapEntityDrawer';
import { MapEntityDrawerHeader } from 'components/Map/MapEntityDrawerHeader';
import { getResponsiveFontSize } from 'shared/utils';
import { TabPanel, a11yProps } from 'components/TabPanel';

type ListItemProps = {
  label: string;
  primaryText: string;
  secondaryText?: string;
  Icon?: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
    muiName: string;
  };
};

const TAB_PANEL_ID_PREFIX = 'land-ownership';

const ListItem = (props: ListItemProps): JSX.Element => {
  const { label, primaryText, secondaryText, Icon = LocationOnIcon } = props;
  const theme = useTheme();

  return (
    <MuiListItem divider>
      <ListItemText
        primary={
          <Typography
            color="secondary"
            fontWeight="bold"
            sx={{
              fontVariant: 'all-small-caps',
              fontSize: getResponsiveFontSize(theme.typography.pxToRem(18))
            }}
          >
            {label}
          </Typography>
        }
        secondary={
          <>
            <Typography color="textPrimary" fontWeight="medium">
              {primaryText || '-'}
            </Typography>
            {!!secondaryText && (
              <Typography color="textPrimary">{secondaryText}</Typography>
            )}
          </>
        }
        secondaryTypographyProps={{ component: 'div' }}
      />
      <ListItemIcon sx={{ minWidth: 'fit-content', color: 'text.primary' }}>
        <Icon />
      </ListItemIcon>
    </MuiListItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  stickyEl: {
    background: theme.palette.background.paper,
    zIndex: 1,
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 'calc(100% - 8px)',
      bottom: 0,
      left: 4,
      zIndex: -1,
      boxShadow: '0px 0px 4px 4px rgba(0, 0, 0, 0.25)'
    }
  },
  tabs: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    minHeight: 32
  },
  tabsIndicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: 'calc(100% - 32px)',
      backgroundColor: theme.palette.primary.main
    }
  },
  tab: {
    color: theme.palette.text.primary,
    fontSize: getResponsiveFontSize(theme.typography.pxToRem(18)),
    fontVariant: 'all-small-caps',
    fontWeight: theme.typography.fontWeightMedium,
    padding: '0 16px',
    minHeight: 32,
    display: 'inline-flex',
    flexDirection: 'row'
  },
  tabSelected: {
    fontWeight: theme.typography.fontWeightBold,
    color: `${theme.palette.text.primary} !important`
  }
}));

export const PrivateLandOwnershipContent = (
  props: PrivateLandOwnershipEntity
): JSX.Element => {
  const {
    owner,
    address,
    taxAddress,
    lat,
    lng,
    acres,
    buildings,
    zoningType,
    zoningSubtype,
    femaFloodZone,
    femaFloodZoneSubtype,
    femaFloodZoneDate
  } = props;
  const [activeTab, setActiveTab] = useState(0);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { scrollElementRef } = useContext(MapEntityDrawerContext);
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up(660), {
    defaultMatches: !Capacitor.isNativePlatform()
  });

  return (
    <Box>
      <Sticky
        scrollElement={scrollElementRef?.current as HTMLElement}
        stickyClassName={classes.stickyEl}
        stickyStyle={{
          paddingTop: isLargeMediaQuery ? 8 : 0
        }}
      >
        <Box
          sx={{
            paddingX: 2,
            paddingBottom: 1,
            background: theme.palette.background.paper
          }}
        >
          <MapEntityDrawerHeader>{owner}</MapEntityDrawerHeader>
          <Typography variant="body2" color="textSecondary">
            {address}
          </Typography>
        </Box>

        <Tabs
          variant="fullWidth"
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          aria-label="incident tabs"
          indicatorColor="primary"
          classes={{
            root: classes.tabs,
            indicator: classes.tabsIndicator
          }}
          TabIndicatorProps={{ children: <span /> }}
        >
          <Tab
            label={t('privateLandOwnership.tabs.overview')}
            {...a11yProps(TAB_PANEL_ID_PREFIX, 0)}
            classes={{
              root: classes.tab,
              selected: classes.tabSelected
            }}
          />
          <Tab
            label={t('privateLandOwnership.tabs.info')}
            {...a11yProps(TAB_PANEL_ID_PREFIX, 1)}
            classes={{
              root: classes.tab,
              selected: classes.tabSelected
            }}
          />
        </Tabs>
      </Sticky>

      <TabPanel value={activeTab} index={0} idPrefix={TAB_PANEL_ID_PREFIX}>
        <List>
          {address !== taxAddress && (
            <ListItem
              label={t('privateLandOwnership.overview.taxAddress')}
              primaryText={owner}
              secondaryText={taxAddress}
            />
          )}
          <ListItem
            label={t('privateLandOwnership.overview.coordinates')}
            primaryText={`${lat}, ${lng}`}
          />
          <ListItem
            label={t('privateLandOwnership.overview.acres')}
            primaryText={acres.toFixed(1)}
          />
        </List>
      </TabPanel>

      <TabPanel value={activeTab} index={1} idPrefix={TAB_PANEL_ID_PREFIX}>
        <List>
          <ListItem
            label={t('privateLandOwnership.info.buildings')}
            primaryText={buildings.toString()}
            Icon={LanguageIcon}
          />
          <ListItem
            label={t('privateLandOwnership.info.zoningType')}
            primaryText={zoningType}
          />
          <ListItem
            label={t('privateLandOwnership.info.zoningSubtype')}
            primaryText={zoningSubtype}
          />
          <ListItem
            label={t('privateLandOwnership.info.femaFloodZone')}
            primaryText={femaFloodZone}
          />
          <ListItem
            label={t('privateLandOwnership.info.femaFloodZoneSubtype')}
            primaryText={femaFloodZoneSubtype}
          />
          <ListItem
            label={t('privateLandOwnership.info.femaFloodZoneDate')}
            primaryText={moment(femaFloodZoneDate).format('MMMM D, YYYY')}
          />
        </List>
      </TabPanel>
    </Box>
  );
};
