import { useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Switch,
  FormControlLabel,
  MenuItem,
  Typography,
  useMediaQuery
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Map from 'components/Map';
import { VisuallyBusyMapLayers } from 'components/Map/constants';
import { GeoEvent } from 'shared/types';
import { API } from 'api';
import { useSnackbarState } from 'state';

type EditAutomatedGeoEventFormProps = {
  geoEvent: GeoEvent;
};

type Status = 'inactive' | 'active';

type FormValues = {
  status: Status;
  reporterManaged: boolean;
};

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const updateGeoEvent = (geoEvent: GeoEvent) =>
  API.put(`geo_events/${geoEvent.id}`, geoEvent);

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    overflow: 'auto'
  },
  mapContainer: {
    width: '100%',
    position: 'sticky',
    top: 0,
    display: 'flex'
  },
  card: {
    position: 'relative',
    minHeight: '100%',
    width: '100%'
  },
  form: {
    width: '100%'
  }
}));

const validationSchema = Yup.object({
  status: Yup.string(),
  reporterManaged: Yup.boolean()
});

const EditAutomatedGeoEventForm = (
  props: EditAutomatedGeoEventFormProps
): JSX.Element => {
  const { geoEvent } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbarState();
  const history = useHistory();

  const { lat, lng, isActive, reporterManaged, name } = geoEvent;

  const initialValues = useMemo(
    () => ({
      status: isActive ? 'active' : ('inactive' as Status),
      reporterManaged
    }),
    [isActive, reporterManaged]
  );

  const { control, formState, handleSubmit, reset, watch } =
    useForm<FormValues>({
      resolver: yupResolver(validationSchema),
      defaultValues: initialValues,
      mode: 'onBlur'
    });

  useEffect(() => {
    // Initial values changed, reset the form to reflect these changes.
    reset(initialValues);
  }, [initialValues, reset]);

  const updateGeoEventMutation = useMutation({
    mutationFn: (data: GeoEvent) => updateGeoEvent(data)
  });

  const handleGeoEventUpdate: SubmitHandler<FormValues> = async (
    values: FormValues
  ) => {
    try {
      await updateGeoEventMutation.mutateAsync({
        ...geoEvent,
        isActive: values.status === 'active',
        reporterManaged: values.reporterManaged
      });
      setSnackbar('Incident edited!', 'success');
      // If the user got here from a link in Slack, there won't be any history to
      // go back to.
      // @ts-expect-error length property does exist
      if (history.length === 1) {
        history.push('/');
      } else {
        // @ts-expect-error goBack property does exist
        history.goBack();
      }
    } catch (error) {
      setSnackbar('An error occurred.  Please try again', 'error');
    }
  };

  const status = watch('status');

  return (
    <form
      className={classes.form}
      noValidate
      onSubmit={handleSubmit(handleGeoEventUpdate)}
    >
      <Box
        className={classes.container}
        sx={{
          flexDirection: isLargeMediaQuery ? 'row' : 'column'
        }}
      >
        <Box
          className={classes.mapContainer}
          sx={{ height: isLargeMediaQuery ? '100%' : '60vh' }}
        >
          <Map
            center={{ lat, lng }}
            cursor="crosshair"
            disableMapLayers={VisuallyBusyMapLayers}
          >
            <Map.WildFireGeoEventMarker
              geoEvent={{ ...geoEvent, isActive: status === 'active' }}
            />
          </Map>
        </Box>

        <Box
          sx={{ width: '100%', height: isLargeMediaQuery ? '100%' : '40vh' }}
        >
          <Card
            className={classes.card}
            sx={{ borderRadius: isLargeMediaQuery ? 0 : 'unset' }}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h3">{name}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field, fieldState }) => {
                      const { ref, ...muiFieldProps } = field;
                      return (
                        <TextField
                          select
                          id="geoevent-status"
                          label={t('editGeoEvent.status')}
                          fullWidth
                          {...muiFieldProps}
                          size="small"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          required
                          disabled={formState.isSubmitting}
                        >
                          <MenuItem value="active">
                            {t('editGeoEvent.active')}
                          </MenuItem>
                          <MenuItem value="inactive">
                            {t('editGeoEvent.inactive')}
                          </MenuItem>
                        </TextField>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    name="reporterManaged"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={field.value}
                              onChange={(event) => {
                                field.onChange(event.target.checked);
                              }}
                            />
                          }
                          label={t('editGeoEvent.reporterManaged')}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    fullWidth
                    type="submit"
                    size="large"
                    disabled={!formState.isValid || formState.isSubmitting}
                  >
                    {/* When we upgrade to MUI 5, we can use a <LoadingButton> here */}
                    {t('common.submit')}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </form>
  );
};

export default EditAutomatedGeoEventForm;
