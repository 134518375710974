import { LocationTypes } from '../../../constants';
import { LocationType, Status } from './types';

export const mapLocationTypeToTransKey = {
  [LocationTypes.evacShelter]: 'createEditLocation.types.shelter',
  [LocationTypes.animalShelter]: 'createEditLocation.types.animalShelter',
  [LocationTypes.info]: 'createEditLocation.types.info',
  [LocationTypes.general]: 'createEditLocation.types.general',
  [LocationTypes.food]: 'createEditLocation.types.food'
};

export const typeOptions: { transKey: string; value: LocationType }[] = [
  {
    transKey: 'createEditLocation.types.shelter',
    value: LocationTypes.evacShelter
  },
  {
    transKey: 'createEditLocation.types.animalShelter',
    value: LocationTypes.animalShelter
  },
  { transKey: 'createEditLocation.types.info', value: LocationTypes.info },
  {
    transKey: 'createEditLocation.types.general',
    value: LocationTypes.general
  },
  { transKey: 'createEditLocation.types.food', value: LocationTypes.food }
];

export const statusOptions: { transKey: string; value: Status }[] = [
  { transKey: 'common.active', value: 'active' },
  { transKey: 'common.inactive', value: 'inactive' }
];
