import {
  MATCH_URL_TWITTER,
  MATCH_URL_YOUTUBE,
  MATCH_URL_FACEBOOK,
  MATCH_URL_FACEBOOK_WATCH
} from 'shared/embedHelpers';
import TweetEmbed from './TweetEmbed';
import VideoEmbed from './VideoEmbed';
import YouTubeIFrame from './YoutubeIFrame';
import FacebookIFrame from './FacebookIFrame';

type CardEmbedProps = {
  embedUrl: string;
};

const CardEmbed = (props: CardEmbedProps): JSX.Element | null => {
  const { embedUrl } = props;

  if (embedUrl.match(MATCH_URL_TWITTER)) {
    return <TweetEmbed tweetUrl={embedUrl} />;
  }

  if (embedUrl.match(MATCH_URL_YOUTUBE)) {
    return (
      <VideoEmbed
        embedUrl={embedUrl}
        // eslint-disable-next-line react/jsx-props-no-spreading
        IFrameComponent={(iframeProps): JSX.Element => (
          <YouTubeIFrame {...iframeProps} />
        )}
      />
    );
  }

  if (
    embedUrl.match(MATCH_URL_FACEBOOK) ||
    embedUrl.match(MATCH_URL_FACEBOOK_WATCH)
  ) {
    return (
      <VideoEmbed
        embedUrl={embedUrl}
        // eslint-disable-next-line react/jsx-props-no-spreading
        IFrameComponent={(iframeProps): JSX.Element => (
          <FacebookIFrame {...iframeProps} />
        )}
      />
    );
  }

  return null;
};

export default CardEmbed;
