import {
  Checkbox,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { NotificationSetting } from 'shared/types';
import { NotificationTypes } from '../../constants';

type RegionListItemProps = {
  onChange: (newRegionSetting: NotificationSetting) => void;
  regionSetting: NotificationSetting;
};

const useStyles = makeStyles()(() => ({
  icon: {
    alignSelf: 'flex-start',
    minWidth: 24
  }
}));

const RegionListItem = (props: RegionListItemProps): JSX.Element => {
  const { onChange, regionSetting } = props;
  const { displayName } = regionSetting.region;
  const { setting } = regionSetting;
  const { classes } = useStyles();

  const handleChange = (): void => {
    onChange(regionSetting);
  };

  const isChecked = setting !== NotificationTypes.OFF.key;

  return (
    <ListItemButton
      onClick={handleChange}
      aria-labelledby={displayName}
      sx={{ height: 40, paddingLeft: 3 }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <ListItemIcon className={classes.icon}>
          <Checkbox checked={isChecked} tabIndex={-1} sx={{ padding: 0 }} />
        </ListItemIcon>
        <ListItemText primary={displayName} id={displayName} />
      </Stack>
    </ListItemButton>
  );
};

export default RegionListItem;
