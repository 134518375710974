import { capitalize } from 'lodash-es';
import { LayerEvacZone, EvacZoneStatus, Poi } from 'shared/types';
import { EvacZoneStatuses, EvacZoneStyles } from '../../../../constants';
import { StyleStatusGrouping } from './types';
import getWatchdutyTileUrl from '../getWatchdutyTileUrl';
import { SOURCE_ID } from './constants';

export const getCountyNameFromSlug = (countySlug: string): string => {
  const [name] = countySlug.split('-');
  let countyName = '';
  name.split('_').forEach((nameItem) => {
    countyName += `${capitalize(nameItem)} `;
  });
  return countyName.trim();
};

// !!! Important - this is deprecated and only exists to still be called in StructuredEvacuationsEditLayer.tsx
//  the basic logic of this is flawed unless we are also comparing the county slug or some other identifier
/**
 * An evacuation zone is a subzone of another evacuation zone if its "uid" includes this other
 * zone's "uid". This means that a subzone uid pattern is "zone-uid" + "subzone-unique-uid-part".
 * @param zoneUId string
 * @param evacZones array of evacuation zones
 * @returns layer evacuation subzone
 */
/** @deprecated */
export const possiblyCreateSplitZone = (
  zoneUId: string,
  evacZones: LayerEvacZone[]
): LayerEvacZone | null => {
  for (let i = 0; i < evacZones.length; i += 1) {
    const evacZone = evacZones[i];
    if (zoneUId !== evacZone.uid && zoneUId.startsWith(evacZone.uid)) {
      return {
        uid: zoneUId,
        status: evacZone.status,
        style: evacZone.style
      };
    }
  }
  return null;
};

export const switchZoneStatus = (
  currentStatus?: EvacZoneStatus
): EvacZoneStatus | undefined => {
  const statuses: EvacZoneStatus[] = [
    EvacZoneStatuses.advisories,
    EvacZoneStatuses.warnings,
    EvacZoneStatuses.orders
  ];
  const currentIndex = currentStatus ? statuses.indexOf(currentStatus) : -1;
  const nextIndex = currentIndex + 1 > statuses.length ? 0 : currentIndex + 1;
  return statuses[nextIndex];
};

export const createGroupings = (
  evacZones: LayerEvacZone[]
): StyleStatusGrouping => {
  const groups: StyleStatusGrouping = {
    [EvacZoneStyles.default]: {
      [EvacZoneStatuses.warnings]: [],
      [EvacZoneStatuses.advisories]: [],
      [EvacZoneStatuses.orders]: []
    },
    [EvacZoneStyles.readySetGo]: {
      [EvacZoneStatuses.warnings]: [],
      [EvacZoneStatuses.advisories]: [],
      [EvacZoneStatuses.orders]: []
    },
    [EvacZoneStyles.reverseColors]: {
      [EvacZoneStatuses.warnings]: [],
      [EvacZoneStatuses.advisories]: [],
      [EvacZoneStatuses.orders]: []
    },
    [EvacZoneStyles.withLevels]: {
      [EvacZoneStatuses.warnings]: [],
      [EvacZoneStatuses.advisories]: [],
      [EvacZoneStatuses.orders]: []
    }
  };

  evacZones.forEach((zone) => {
    if (!zone.status) {
      return;
    }
    const style = zone.style || EvacZoneStyles.default;
    groups[style][zone.status].push(zone.uid);
  });
  return groups;
};

export const getTilesUrl = (cacheBuster?: number | undefined): string => {
  return `${getWatchdutyTileUrl(SOURCE_ID)}${
    cacheBuster ? `?ts=${cacheBuster}` : ''
  }`;
};

export const getSelectedEvacZoneUId = (selectedPoi?: Poi): string => {
  return (
    (selectedPoi?.type === 'evacZone' && selectedPoi.id) ||
    ''
  ).toString();
};
