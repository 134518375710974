import { Layer, LayerProps } from 'react-map-gl/maplibre';
import { useCallback } from 'react';
import addVisible from '../../../shared/addVisible';
import ArcGisLayer from './ArcGisLayer';
import { usePoisState } from '../../../state/usePoisState';
import { PowerOutageDialogContent } from '../PowerOutageDialogContent';
import { MapLayerProps, PowerOutageFeatureProperties } from '../types';
import { useMapLayerEvents } from './useMapLayerEvents';
import { getSelectedPatternStyle, getSelectedStrokeStyle } from '../utils';
import { powerOutagePropertiesToDialogProps } from '../PowerOutageDialogContent.utils';
import { BEFORE_LAYER_ID } from '../styles/constants';

const MIN_ZOOM_VISIBILITY = 9;
const FILL_LAYER_ID = 'power-outages-area-fill';

const FEATURE_LAYER_URL =
  'https://services.arcgis.com/BLN4oKB0N1YSgvY8/ArcGIS/rest/services/Power_Outages_(View)/FeatureServer/1';

const patternFillStyle: LayerProps = {
  type: 'fill',
  minzoom: MIN_ZOOM_VISIBILITY,
  paint: {
    'fill-pattern': 'PowerOutagePattern'
  }
};

const strokeStyle: LayerProps = {
  type: 'line',
  minzoom: MIN_ZOOM_VISIBILITY,
  paint: {
    'line-color': '#F7FB2DBF',
    'line-width': ['interpolate', ['linear'], ['zoom'], 8, 1.25, 18, 3.25]
  }
};

export const PowerOutageAreaLayer = (props: MapLayerProps): JSX.Element => {
  const { visible } = props;
  const { selectedPoi, setSelectedPoi } = usePoisState();

  useMapLayerEvents<PowerOutageFeatureProperties>({
    layerId: FILL_LAYER_ID,
    onClick: useCallback(
      (geoJsonFeatures) => {
        const { properties } = geoJsonFeatures[0];

        setSelectedPoi({
          type: 'powerOutage',
          id: properties.OBJECTID,
          PoiDialogContent: () => {
            return (
              <PowerOutageDialogContent
                {...powerOutagePropertiesToDialogProps(properties)}
              />
            );
          }
        });
      },
      [setSelectedPoi]
    )
  });

  const selectedID =
    selectedPoi?.type === 'powerOutage' ? selectedPoi.id || '' : '';

  const selectedFillStyle = getSelectedPatternStyle({
    idKey: 'OBJECTID',
    otherLayerProps: { minzoom: MIN_ZOOM_VISIBILITY },
    selectedId: selectedID
  });

  const selectedStrokeStyle = getSelectedStrokeStyle({
    idKey: 'OBJECTID',
    otherLayerProps: { minzoom: MIN_ZOOM_VISIBILITY },
    selectedId: selectedID
  });

  return (
    <ArcGisLayer
      visible={visible}
      src={FEATURE_LAYER_URL}
      fields={[
        'Cause',
        'EstimatedRestoreDate',
        'ImpactedCustomers',
        'StartDate',
        'UtilityCompany'
      ]}
      precision={4}
    >
      <Layer
        id={FILL_LAYER_ID}
        beforeId={BEFORE_LAYER_ID}
        {...addVisible(patternFillStyle, visible)}
      />
      <Layer beforeId={BEFORE_LAYER_ID} {...addVisible(strokeStyle, visible)} />
      <Layer
        beforeId={BEFORE_LAYER_ID}
        {...addVisible(selectedFillStyle, visible)}
      />
      <Layer
        beforeId={BEFORE_LAYER_ID}
        {...addVisible(selectedStrokeStyle, visible)}
      />
    </ArcGisLayer>
  );
};
