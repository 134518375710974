import { useMemo } from 'react';
import { useQueries } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { API, CacheAPI } from 'api';
import { TARGET_ZOOM } from 'components/IncidentsMap/constants';
import { WildfireListItem, LocationListItem } from 'shared/types';
import { useAuthState, useCacheState, useMapState } from 'state';
import { getTilePairsFromBounds } from 'components/IncidentsMap/tile_utils';
import { MAIN_REFETCH_INTERVAL_MS } from 'components/Map/constants';
import { useSelectedWildfireGeoEventId } from './useSelectedWildfireGeoEventId';

type UseGeoEventsProps = {
  enabled?: boolean;
};

type UseGeoEvents = {
  allGeoEvents: (WildfireListItem | LocationListItem | undefined)[];
  wildfireEvents: WildfireListItem[];
  locations: LocationListItem[];
};

const GEO_EVENTS_URL = 'geo_events/?is_relevant=true';

const getStaleTime = (canReport: boolean): number | undefined => {
  // don't cache anything for reporters on the main map
  if (canReport) {
    return undefined;
  }
  return MAIN_REFETCH_INTERVAL_MS;
};

const getRefetchInterval = (
  canReport: boolean,
  hasSelectedGeoEvent: boolean
): number | false => {
  if (canReport) {
    // 30 seconds
    return 30 * 1000;
  }
  if (hasSelectedGeoEvent) {
    return false;
  }
  return MAIN_REFETCH_INTERVAL_MS;
};

const fetchGeoEvents = (
  x: number,
  y: number,
  cacheBusterTs: number | null
): Promise<AxiosResponse<WildfireListItem | LocationListItem>> => {
  const params = new URLSearchParams({
    zyx: `${TARGET_ZOOM},${y},${x}`,
    geo_event_types: '*'
  });
  if (cacheBusterTs) {
    params.append('ts', cacheBusterTs.toString());
  }
  if (cacheBusterTs) {
    return CacheAPI.get<WildfireListItem | LocationListItem>(
      `${GEO_EVENTS_URL}&${params.toString()}`
    );
  }
  return API.get<WildfireListItem | LocationListItem>(
    `${GEO_EVENTS_URL}&${params.toString()}`
  );
};

const useGeoEvents = (props: UseGeoEventsProps = {}): UseGeoEvents => {
  const { enabled = true } = props;
  const { activeMapBounds } = useMapState();
  const tiles = getTilePairsFromBounds(activeMapBounds);
  const { cacheBusterTs } = useCacheState();
  const {
    permissions: { canReport }
  } = useAuthState();
  const selectedGeoEventId = useSelectedWildfireGeoEventId();

  const queries = tiles.map((tile) => {
    return {
      queryKey: ['geoEvents', tile[0], tile[1], cacheBusterTs],
      queryFn: () => fetchGeoEvents(tile[0], tile[1], cacheBusterTs),
      staleTime: getStaleTime(canReport),
      refetchInterval: () =>
        getRefetchInterval(canReport, !!selectedGeoEventId),
      enabled
    };
  });

  const results = useQueries({ queries });

  const data = useMemo(
    () =>
      results.filter((e) => e.isSuccess).flatMap((result) => result.data?.data),
    [results]
  );

  const { wildfireEvents, locations } = useMemo(() => {
    const fireEvents = data.filter(
      (item): item is WildfireListItem => item?.geoEventType === 'wildfire'
    );

    const locationEvents = data.filter(
      (item): item is LocationListItem => item?.geoEventType === 'location'
    );

    return {
      wildfireEvents: fireEvents,
      locations: locationEvents
    };
  }, [data]);

  return { allGeoEvents: data, wildfireEvents, locations };
};

export default useGeoEvents;
