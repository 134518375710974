import * as Sentry from '@sentry/capacitor';
import { SerializedBounds } from 'shared/types';
import { TARGET_ZOOM } from './constants';
import { Tile } from './types';

const lngLatToTile = (lng: number, lat: number, zoom: number): Tile => {
  // todo: does maplibre-gl have this as a public api - it definitely does this internally
  const n = 2 ** zoom;
  // https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames
  const xTile = Math.floor(((lng + 180) / 360) * n);
  const yTile = Math.floor(
    ((1 -
      Math.log(
        Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)
      ) /
        Math.PI) /
      2) *
      n
  );
  return [xTile, yTile];
};

export const getTilePairsFromBounds = (bounds?: SerializedBounds): Tile[] => {
  // return an array of all tiles within the given bounds
  if (!bounds) {
    return [];
  }
  // bounds is either a LngLatBounds object or if its coming from recoil it's been serialized to {_ne, {}, _sw: {}}
  // eslint-disable-next-line no-underscore-dangle
  const ne = bounds._ne || bounds._northEast;
  // eslint-disable-next-line no-underscore-dangle
  const sw = bounds._sw || bounds._southWest;
  if (!ne || !sw) {
    Sentry.captureMessage('No lng or lat found on bounds', {
      extra: { bounds }
    });
    return [];
  }
  const neTile = lngLatToTile(ne.lng, ne.lat, TARGET_ZOOM);
  const swTile = lngLatToTile(sw.lng, sw.lat, TARGET_ZOOM);
  // always at least one map tile even if its the same across the entire viewport
  const xRange = Array.from(
    { length: neTile[0] - swTile[0] + 1 },
    (_, idx) => idx
  );
  const yRange = Array.from(
    { length: swTile[1] - neTile[1] + 1 },
    (_, idx) => idx
  );
  return xRange.reduce((tiles: Tile[], idx: number) => {
    const xTile = swTile[0] + idx;
    const pairs: Tile[] = yRange.map((idxInner) => [
      xTile,
      neTile[1] + idxInner
    ]);
    return tiles.concat(pairs);
  }, []);
};
