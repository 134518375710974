import { useCallback, useEffect } from 'react';
import { Resetter, atom, useRecoilState, useResetRecoilState } from 'recoil';
import { LOCAL_STORAGE_KEY, localStorageEffect } from './localStorage';

export type DonationState = {
  donationAmount: number;
  donationRenews: boolean;
  optInToUpdates: boolean;
  existingUser: boolean;
  email: string;
  firstName: string;
  lastName: string;
  fromMobile: boolean;
  updatePaymentMethod: boolean;
  clientToken?: string;
  verificationToken?: string;
  userId?: number;
};

type UseDonationState = DonationState & {
  updateDonationState: (newState: Partial<DonationState>) => void;
  resetDonationState: Resetter;
};

type UseDonationStateProps = {
  stateUpdateData?: Partial<DonationState>;
};

const DEFAULT_DONATION_STATE: DonationState = {
  donationAmount: 0,
  donationRenews: false,
  optInToUpdates: false,
  existingUser: false,
  email: '',
  firstName: '',
  lastName: '',
  fromMobile: false,
  updatePaymentMethod: false
};

const donationStateAtom = atom({
  key: LOCAL_STORAGE_KEY.DONATION_STATE,
  default: DEFAULT_DONATION_STATE,
  effects: [localStorageEffect(LOCAL_STORAGE_KEY.DONATION_STATE)]
});

const useDonationState = (
  props: UseDonationStateProps = {}
): UseDonationState => {
  const { stateUpdateData } = props;
  const resetDonationState = useResetRecoilState(donationStateAtom);
  const [donationState, setDonationState] = useRecoilState(donationStateAtom);

  const updateDonationState = useCallback(
    (newState: Partial<DonationState>) => {
      setDonationState((prevState) => ({ ...prevState, ...newState }));
    },
    [setDonationState]
  );

  useEffect(() => {
    if (stateUpdateData) {
      updateDonationState(stateUpdateData);
    }
  }, [stateUpdateData, updateDonationState]);

  return {
    ...donationState,
    updateDonationState,
    resetDonationState
  };
};

export default useDonationState;
