export const ELECTRICAL_MAPTILES_DIRECTORY = 'infrastructure_electrical';
export const GAS_MAPTILES_DIRECTORY = 'infrastructure_gas';

export const ELECTRICAL_MAP_LAYER_ID = 'electric-lines-lines';
export const ELECTRICAL_MAP_INTERACTIVE_LAYER_ID =
  'electric-lines-lines-interactive';

export const GAS_MAP_LAYER_ID = 'gas-pipelines-lines';
export const GAS_MAP_INTERACTIVE_LAYER_ID = 'gas-pipelines-lines-interactive';

export const ELECTRICAL_SOURCE_DATA_LAYER = 'electrical';
export const GAS_SOURCE_DATA_LAYER = 'gas';

export const ELECTRICAL_COLOR = '#F19437';
export const GAS_PIPILINE_COLOR = '#6393F2';
export const ACTIVE_LINE_COLOR = '#52E8EB';

export const MIN_ZOOM_VISIBILITY = 10;
