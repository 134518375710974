import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import HeaderBar from '../../components/HeaderBar';
import PageLayout from '../../components/PageLayout';
import SetPasswordForm from './SetPasswordForm';

export const Content = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('setPassword.title')}</title>
      </Helmet>
      <SetPasswordForm />
    </>
  );
};

const SetPassword = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PageLayout data-cname="SetPasswordPage">
      {{
        content: <Content />,
        headerBar: <HeaderBar action="/" title={t('setPassword.title')} />
      }}
    </PageLayout>
  );
};

export default SetPassword;
