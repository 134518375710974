import { Typography, Box, Button, Link, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Rating from '@mui/material/Rating';
import CloseIcon from '@mui/icons-material/Close';
import VectorLogo from 'components/VectorLogo';
import { useTranslation } from 'react-i18next';
import { getDeviceInfo } from 'state/localStorageTyped';
import useDownloadAppBannerState from 'state/useDownloadAppBannerState';
import { ANALYTICS_EVENTS, logEvent } from 'shared/analytics';
import { AppStoreLinks } from '../../constants';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: theme.zIndex.drawer + 10
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: theme.spacing(2)
    }
  },
  closeButton: {
    marginLeft: theme.spacing(-1.5),
    marginRight: theme.spacing(0.5)
  },
  closeIcon: {
    fontSize: 16
  },
  logoContainer: {
    width: 54,
    height: 54,
    borderRadius: theme.shape.borderRadius * 0.67,
    boxShadow: theme.shadows[2],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    marginTop: theme.spacing(0.5)
  },
  title: {
    fontSize: 12
  },
  subtitle: {
    fontSize: 11
  },
  ratingContainer: {
    marginTop: 8,
    display: 'flex',
    alignItems: 'center'
  },
  rating: {
    marginRight: theme.spacing(1),
    fontSize: 18
  },
  ratingLabel: {
    fontSize: 14
  },
  button: {
    borderRadius: theme.shape.borderRadius * 1.34,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 16
  }
}));

const DownloadAppBanner = (): JSX.Element | null => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const deviceInfo = getDeviceInfo();
  const { showBanner, setBannerShown } = useDownloadAppBannerState();

  if (!showBanner) return null;
  const storeLink =
    deviceInfo?.operatingSystem === 'android'
      ? AppStoreLinks.android
      : AppStoreLinks.iosBannerCampaign;

  const logClick = (): void => {
    logEvent({
      name: ANALYTICS_EVENTS.CLICKED.DOWNLOAD_APP_BANNER,
      params: {}
    });
  };

  const closeClick = (): void => {
    logEvent({
      name: ANALYTICS_EVENTS.CLICKED.DOWNLOAD_APP_BANNER_CLOSE,
      params: {}
    });
    setBannerShown();
  };

  return (
    <div className={classes.root} data-testid="download-app-banner">
      <div className={classes.infoContainer}>
        <IconButton
          color="inherit"
          className={classes.closeButton}
          onClick={closeClick}
          aria-label="Close banner"
          size="large"
        >
          <CloseIcon fontSize="small" className={classes.closeIcon} />
        </IconButton>

        <div className={classes.logoContainer}>
          <VectorLogo size={46} className={classes.logo} />
        </div>

        <div>
          <Typography className={classes.title}>
            <b>{t('downloadAppBanner.title')}</b>
          </Typography>

          <Typography className={classes.subtitle}>
            {t(`downloadAppBanner.subtitle.${deviceInfo?.operatingSystem}`)}
          </Typography>

          <Box className={classes.ratingContainer}>
            <Rating
              name="app-rating"
              value={4.8}
              readOnly
              size="small"
              className={classes.rating}
              data-testid="app-rating"
            />
            <Typography variant="body2" className={classes.ratingLabel}>
              <b>{t('downloadAppBanner.rating')}</b>
            </Typography>
          </Box>
        </div>
      </div>

      <Button
        className={classes.button}
        component={Link}
        underline="none"
        href={storeLink}
        target="_blank"
        onClick={logClick}
      >
        {t('downloadAppBanner.button')}
      </Button>
    </div>
  );
};

export default DownloadAppBanner;
