import { List, ListSubheader, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getResponsiveFontSize } from 'shared/utils';
import { makeStyles } from 'tss-react/mui';
import LegendListItem from './LegendListItem';
import {
  CameraPTZWithArrow,
  CameraStationaryBase,
  CameraStationaryWithArrow
} from '../Icons';

const useStyles = makeStyles()((theme) => ({
  title: {
    fontVariant: 'all-small-caps',
    fontSize: getResponsiveFontSize(theme.typography.pxToRem(20)),
    paddingBottom: theme.spacing(1)
  }
}));

const CamerasLegend = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <List
      disablePadding
      dense
      subheader={
        <ListSubheader disableSticky>
          <Typography
            variant="h6"
            component="h3"
            className={classes.title}
            color="textPrimary"
          >
            {t('map.legends.cameras.title')}
          </Typography>
        </ListSubheader>
      }
    >
      <LegendListItem
        iconSrc={CameraPTZWithArrow.data}
        iconSize={18}
        primaryText={t('map.legends.cameras.legends.ptz.title')}
        secondary={t('map.legends.cameras.legends.ptz.subtitle')}
      />
      <LegendListItem
        iconSrc={CameraStationaryWithArrow.data}
        iconSize={16}
        primaryText={t('map.legends.cameras.legends.stationary.title')}
        secondary={t('map.legends.cameras.legends.stationary.subtitle')}
      />
      <LegendListItem
        iconSrc={CameraStationaryBase.data}
        iconSize={14}
        primaryText={t('map.legends.cameras.legends.stationaryNoHeading.title')}
        secondary={t(
          'map.legends.cameras.legends.stationaryNoHeading.subtitle'
        )}
      />
    </List>
  );
};

export default CamerasLegend;
