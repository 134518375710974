import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import useAppInfo from 'hooks/useAppInfo';
import { getPushTokenFromLocalStorage } from 'state/localStorage';
import { getResponsiveFontSize } from 'shared/utils';

const useStyles = makeStyles()((theme) => ({
  subtitle: {
    fontSize: getResponsiveFontSize(theme.typography.pxToRem(12)),
    lineHeight: getResponsiveFontSize(theme.typography.pxToRem(14))
  }
}));

const DebugData = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { appVersion } = useAppInfo();

  const pushToken = getPushTokenFromLocalStorage();
  // at 200k push token's the last 7 chars are unique, lets do 8 to avoid collisions
  const shortPushToken = pushToken?.slice(-8);
  return (
    <Typography className={classes.subtitle}>
      {/* eslint-disable-next-line i18next/no-literal-string */}
      {!!appVersion && <span>v{appVersion}</span>}
      {shortPushToken && (
        <span>
          &nbsp;
          {t('drawerNav.device')} {shortPushToken}
        </span>
      )}
    </Typography>
  );
};

export default DebugData;
