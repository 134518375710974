import {
  Button,
  DialogActions,
  DialogTitle,
  Typography,
  Link,
  DialogContent
} from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { ExternalGeoEvent } from '../../../shared/types';
import { BaseContent } from './BaseContent';
import { PulsepointContent } from './PulsepointContent';
import { ExternalGeoNameMap } from '../../../constants';

type ExternalGeoEventDialogContentProps = {
  externalGeoEvent: ExternalGeoEvent;
};

const useStyles = makeStyles()((theme) => ({
  title: {
    padding: theme.spacing(2, 6, 0, 2),
    lineHeight: '1 !important'
  },
  actions: {
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 2, 0, 2)
  },
  link: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.palette.accent.main,
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: 0,
    pointerEvents: 'auto'
  }
}));

// mapping of externalSource type to DialogContent, anything not provided here defaults to BaseDialogContent
const externalSourceContentMap = {
  pulsepoint: PulsepointContent
};

export const ExternalGeoEventDialogContent = (
  props: ExternalGeoEventDialogContentProps
): JSX.Element => {
  const { classes } = useStyles();
  const { externalGeoEvent } = props;
  const { externalSource, data } = externalGeoEvent;
  const { t } = useTranslation();

  // permalink isn't guaranteed, backoff to the channel in that case
  const backToSlackLink =
    externalGeoEvent.permalinkUrl ||
    `https://watchduty.slack.com/app_redirect?channel=${externalGeoEvent.channel}`;

  const ContentComponent =
    externalSourceContentMap[externalSource] || BaseContent;
  const sourceName = ExternalGeoNameMap[externalSource];

  return (
    <>
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <Typography component="div" variant="body2">
          <b>{sourceName}</b>
        </Typography>
        <Typography component="div" variant="subtitle1">
          {data.address}
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ paddingLeft: 3, paddingBottom: 1 }}>
        <ContentComponent externalGeoEvent={externalGeoEvent} />
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          href={backToSlackLink}
          target="_blank"
          component={Link}
          className={classes.link}
          variant="text"
        >
          {t('externalGeoEvents.viewInSlack')}
        </Button>
      </DialogActions>
    </>
  );
};
