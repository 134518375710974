import { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ANALYTICS_EVENTS, logEvent } from 'shared/analytics';
import HeartImg from '../../assets/banner-heart.svg?react';
import DarkHeartImg from '../../assets/dark-banner-heart.svg?react';

type SupportUsBannerProps = {
  mode?: 'light' | 'dark';
  geoEventId?: number;
  onClose: () => void;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: 8
  },
  img: {
    width: 36,
    height: 33
  },
  button: {
    borderRadius: 8,
    minHeight: 38,
    pointerEvents: 'auto'
  }
}));

export const SupportUsBanner = (props: SupportUsBannerProps): JSX.Element => {
  const { mode = 'light', geoEventId, onClose } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (geoEventId) {
      logEvent({
        name: ANALYTICS_EVENTS.VIEWED.INCIDENT_BANNER,
        params: {}
      });
      logEvent({
        name: `${ANALYTICS_EVENTS.VIEWED.INCIDENT_BANNER}_${geoEventId}`,
        params: {}
      });
    } else {
      logEvent({
        name: ANALYTICS_EVENTS.VIEWED.MAP_BANNER,
        params: {}
      });
    }
  }, [geoEventId]);

  const handleClose = (): void => {
    onClose();

    if (geoEventId) {
      logEvent({
        name: ANALYTICS_EVENTS.CLICKED.INCIDENT_BANNER_CLOSE,
        params: {}
      });
      logEvent({
        name: `${ANALYTICS_EVENTS.CLICKED.INCIDENT_BANNER_CLOSE}_${geoEventId}`,
        params: {}
      });
    } else {
      logEvent({
        name: ANALYTICS_EVENTS.CLICKED.MAP_BANNER_CLOSE,
        params: {}
      });
    }
  };

  const handleClick = (): void => {
    onClose();

    if (geoEventId) {
      logEvent({
        name: ANALYTICS_EVENTS.CLICKED.INCIDENT_BANNER_WAYS_TO_GIVE,
        params: {}
      });
      logEvent({
        name: `${ANALYTICS_EVENTS.CLICKED.INCIDENT_BANNER_WAYS_TO_GIVE}_${geoEventId}`,
        params: {}
      });
    } else {
      logEvent({
        name: ANALYTICS_EVENTS.CLICKED.MAP_BANNER_WAYS_TO_GIVE,
        params: {}
      });
    }
  };

  const isDarkMode = mode === 'dark';
  const backgroundColor = isDarkMode ? 'text.primary' : 'background.paper';
  const textColor = isDarkMode ? 'background.paper' : 'text.primary';
  const Img = isDarkMode ? DarkHeartImg : HeartImg;

  return (
    <Paper
      elevation={0}
      className={classes.root}
      sx={{ backgroundColor }}
      data-testid="support-us-banner"
    >
      <Stack direction="row" alignItems="center" spacing={2} marginBottom={2}>
        <Img className={classes.img} />
        <Box>
          {/* explicit font size so text does not scale with device's settings */}
          <Typography fontWeight="bold" sx={{ color: textColor, fontSize: 16 }}>
            {t('supportUsBanner.title')}
          </Typography>
          <Typography variant="body2" sx={{ color: textColor, fontSize: 14 }}>
            {t('supportUsBanner.description')}
          </Typography>
        </Box>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Button
          fullWidth
          className={classes.button}
          variant="text"
          color="inherit"
          sx={{ color: textColor, fontSize: 14 }}
          onClick={handleClose}
        >
          {t('supportUsBanner.notNow')}
        </Button>
        <Button
          fullWidth
          className={classes.button}
          component={Link}
          to="/support_us"
          onClick={handleClick}
          sx={{ fontSize: 16, fontWeight: 'bold' }}
        >
          {t('supportUsBanner.waysToGive')}
        </Button>
      </Stack>
    </Paper>
  );
};
