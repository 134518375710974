import Helmet from 'react-helmet';
import PageLayout from 'components/PageLayout';
import { useTranslation } from 'react-i18next';
import WelcomeContent from './WelcomeContent';

export const Content = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('welcome.title')}</title>
      </Helmet>
      <WelcomeContent />
    </>
  );
};

const Welcome = (): JSX.Element => {
  return (
    <PageLayout data-cname="WelcomePage" data-testid="welcomePage">
      {{
        content: <Content />,
        headerBar: <></>
      }}
    </PageLayout>
  );
};

export default Welcome;
