/* eslint-disable i18next/no-literal-string */
import { Container, Grid, Typography, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Helmet from 'react-helmet';
import { Capacitor } from '@capacitor/core';
import { getResponsiveFontSize } from 'shared/utils';
import HeaderBar from '../../components/HeaderBar';
import PageLayout from '../../components/PageLayout';
import VectorLogo from '../../components/VectorLogo';

const useStyles = makeStyles()((theme) => {
  const { palette, typography } = theme;
  return {
    root: {
      backgroundColor: palette.common.white,
      overflow: 'auto',
      width: '100%'
    },
    error: {
      fontSize: getResponsiveFontSize(typography.pxToRem(20))
    },
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };
});

export const Content = (): JSX.Element => {
  const { classes } = useStyles();

  return (
    <>
      <Helmet>
        <title>Location Services</title>
      </Helmet>
      <div className={classes.root}>
        <Container maxWidth="sm">
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box className={classes.logoContainer}>
                <VectorLogo sx={{ paddingTop: 3 }} />
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="h6" align="center" className={classes.error}>
                An Error Occurred
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" align="center">
                Watch Duty needs your location information when uploading
                photos. Our team uses this information to paint a picture of
                what is really happening on the ground.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" align="center">
                <br />
                <b>To enable location services:</b>
                <br />
                {Capacitor.getPlatform() === 'ios' ? (
                  <>
                    Open Settings &gt; Privacy &gt; Location Services &gt; Watch
                    Duty
                  </>
                ) : (
                  <>
                    Open Settings &gt; Apps &amp; Notifications &gt; See all
                    apps &gt; Watch Duty &gt; Permissions
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

const LocationServicesDisabled = (): JSX.Element => (
  <PageLayout data-cname="LocationServicesDisabledPage">
    {{
      content: <Content />,
      headerBar: <HeaderBar action="back" title="Location Services" />
    }}
  </PageLayout>
);

export default LocationServicesDisabled;
