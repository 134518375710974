import { TFunction } from 'i18next';
import { RecurlyError } from '@recurly/recurly-js';
import { formatToUSDollars } from 'shared/utils';

type CardErrorFieldLabels = {
  number: string;
  cvv: string;
  expired: string;
};

const getCardErrorFieldLabels = (t: TFunction): CardErrorFieldLabels => ({
  number: t('recurlyBillingInformation.errors.number'),
  cvv: t('recurlyBillingInformation.errors.cvv'),
  expired: t('recurlyBillingInformation.errors.expired')
});

export const getButtonTitle = (
  isMembershipTransaction: boolean,
  updatePaymentMethod: boolean,
  donationAmount: number,
  donationRenews: boolean,
  t: TFunction
): string => {
  if (updatePaymentMethod) {
    return t('common.save');
  }
  if (isMembershipTransaction) {
    return t('recurlyBillingInformation.membershipButton');
  }
  if (donationRenews) {
    return t('recurlyBillingInformation.monthlyDonationButton', {
      amount: formatToUSDollars(donationAmount)
    });
  }
  return t('recurlyBillingInformation.donationButton', {
    amount: formatToUSDollars(donationAmount)
  });
};

// NOTE: It seems that Recurly does not return "CVV" validation errors before sending the request to the server.
export const getCreditCardValidationErrors = (
  error: RecurlyError,
  t: TFunction
): string[] => {
  if (
    error.code === 'validation' &&
    error.details &&
    error.details.length > 0
  ) {
    const fieldToLabel = getCardErrorFieldLabels(t);

    return [
      ...new Set(
        error.details
          .map((detail: { field: string; messages: string[] }) => {
            if (['first_name', 'last_name'].includes(detail.field)) {
              return null;
            }

            const labelKey = ['month', 'year'].includes(detail.field)
              ? 'expired'
              : detail.field;

            const errorMessage = fieldToLabel[labelKey];

            if (errorMessage) {
              return errorMessage;
            }

            return null;
          })
          .filter(Boolean)
      )
    ];
  }
  return [];
};
