import { useRef } from 'react';
import { Drawer, useMediaQuery, Backdrop, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { useTranslation } from 'react-i18next';
import useMapLayersState from 'state/useMapLayersState';
import DrawerContent from './DrawerContent';
import {
  LAYERS_DRAWER_WIDTH,
  MOBILE_LAYER_DRAWER_HEIGHT_USER
} from './constants';

const useStyles = makeStyles()((theme) => ({
  drawer: {
    width: LAYERS_DRAWER_WIDTH
  },
  drawerPaper: {
    paddingTop: 'calc(64px + env(safe-area-inset-top))',
    paddingBottom: `env(safe-area-inset-bottom)`,
    width: LAYERS_DRAWER_WIDTH,
    zIndex: theme.zIndex.drawer + 2
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  }
}));

const LayersDrawer = (): JSX.Element => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('phone'));
  const { drawerOpen, setDrawerOpen } = useMapLayersState();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  const handleClose = (): void => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView();
    }

    setDrawerOpen(false);
  };

  if (isPhone) {
    return (
      <BottomSheet
        id="layers-bottom-sheet"
        open={drawerOpen}
        onDismiss={handleClose}
        skipInitialTransition
        snapPoints={(): number[] => [MOBILE_LAYER_DRAWER_HEIGHT_USER]}
        expandOnContentDrag
        blocking
        header
        scrollLocking
        aria-label={t('map.layers.accessibility.layersDrawer')}
      >
        <DrawerContent ref={contentRef} />
      </BottomSheet>
    );
  }

  return (
    <>
      <Backdrop
        open={drawerOpen}
        onClick={handleClose}
        className={classes.backdrop}
      />

      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper
        }}
        anchor="right"
        open={drawerOpen}
        onClose={handleClose}
        variant="persistent"
        PaperProps={{
          'aria-label': t('map.layers.accessibility.layersDrawer')
        }}
      >
        <DrawerContent />
      </Drawer>
    </>
  );
};

export default LayersDrawer;
