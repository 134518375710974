import { LONG_PRESS_TIME } from 'components/IncidentsMap/constants';
import { useCallback, useRef } from 'react';

import { useAuthState } from 'state';

type UseMapLongPressType = {
  onTouchStart: (e: maplibregl.MapLayerTouchEvent) => void;
  clearMobileTimeout: () => void;
};

type MapLongPressPropsType = {
  handleDropPin: (lat: number, lng: number) => void;
};

const useMapLongPress = (props: MapLongPressPropsType): UseMapLongPressType => {
  const { handleDropPin } = props;
  const {
    permissions: { isInternalUser }
  } = useAuthState();

  const longPressTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearMobileTimeout = useCallback((): void => {
    if (!isInternalUser) {
      return;
    }
    clearTimeout(longPressTimeout.current!);
  }, [longPressTimeout, isInternalUser]);

  const onTouchStart = (e: maplibregl.MapLayerTouchEvent): void => {
    if (!isInternalUser) {
      return;
    }
    if (e.originalEvent.touches.length > 1) {
      return;
    }
    longPressTimeout.current = setTimeout(() => {
      handleDropPin(e.lngLat.lat, e.lngLat.lng);
    }, LONG_PRESS_TIME);
  };

  return {
    onTouchStart,
    clearMobileTimeout
  };
};

export default useMapLongPress;
