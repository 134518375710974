import { addHours, subDays } from 'date-fns';

// Returns a datetime string for Esri database queries rounded to the nearest hour.
// Rounding to the nearest hour is intended to result in queries that result in more
// cache hits.
const getDatetimeString = (date: Date): string =>
  `TIMESTAMP '${date.getUTCFullYear()}-${
    date.getUTCMonth() + 1
  }-${date.getUTCDate()} ${date.getUTCHours()}:00:00'`;

// Returns a pair of datetime strings for Esri database queries, both rounded to the nearest hour.
//
// Returns: [~24 hours ago, ~1 hour from now]
//
// See: https://www.esri.com/arcgis-blog/products/api-rest/data-management/querying-feature-services-date-time-queries/
const get24hDatetimeRange = (): string[] => {
  const now = new Date();
  const soon = addHours(now, 1);
  const yesterday = subDays(now, 1);
  return [getDatetimeString(yesterday), getDatetimeString(soon)];
};

export default get24hDatetimeRange;
