import { useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { CircularProgress } from '@mui/material';
import { getAspectRatio } from './CameraPlayer.utils';

type CameraImageProps = {
  url: string;
  loading?: boolean;
};

const useStyles = makeStyles()((theme) => ({
  imageContainer: {
    position: 'relative',
    width: '100%',
    backgroundColor: theme.palette.grey[300],
    overflow: 'hidden'
  },
  image169: {
    paddingTop: '56.25%' /* 16:9 aspect ratio */
  },
  image43: {
    paddingTop: '75%' /* 4:3 aspect ratio */
  },
  image: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export const CameraImage = (props: CameraImageProps): JSX.Element => {
  const { url, loading = false } = props;
  const [aspectRatio, setAspectRatio] = useState<number>(16 / 9);
  const { classes, cx } = useStyles();
  const isAspectRatioSetRef = useRef(false);

  return (
    <div
      className={cx(
        classes.imageContainer,
        aspectRatio !== 16 / 9 ? classes.image43 : classes.image169
      )}
    >
      <img
        alt=""
        src={url}
        className={classes.image}
        onLoad={(event) => {
          if (!isAspectRatioSetRef.current) {
            isAspectRatioSetRef.current = true;
            setAspectRatio(getAspectRatio(event.target as HTMLImageElement));
          }
        }}
      />
      {loading && (
        <div className={classes.loaderContainer}>
          <CircularProgress size={48} />
        </div>
      )}
    </div>
  );
};
