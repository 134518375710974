import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import HeaderBar from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import { useAuthState } from 'state';
import Unauthorized from 'components/Unauthorized';
import ModerationMap from './ModerationMap';

const Content = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('moderation.title')}</title>
      </Helmet>
      <ModerationMap />
    </>
  );
};

const GeoEventModerationMapPage = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    permissions: { canModerate }
  } = useAuthState();

  if (!canModerate) {
    return (
      <PageLayout data-cname="CreateEditIncidentPage">
        {{
          content: <Unauthorized />,
          headerBar: (
            <HeaderBar
              title={t('unauthorized.title')}
              action="back"
              hideAction
            />
          )
        }}
      </PageLayout>
    );
  }

  return (
    <PageLayout data-cname="GeoEventModerationPage">
      {{
        content: <Content />,
        headerBar: <HeaderBar title={t('moderation.title')} />
      }}
    </PageLayout>
  );
};

export default GeoEventModerationMapPage;
