import {
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Chip,
  Stack
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { getResponsiveFontSize } from 'shared/utils';

type LayerGroupItemProps = {
  titleKey: string;
  subtitleKey: string;
  totalLayers: number;
  activeLayers: number;
  onClick: () => void;
};

const useStyles = makeStyles()((theme) => ({
  chip: {
    borderRadius: 4,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: getResponsiveFontSize('0.6875rem'),
    textTransform: 'uppercase',
    height: 'fit-content',
    padding: '2.5px 6px',
    lineHeight: 1,
    '& span': {
      padding: 0,
      color: theme.palette.background.paper
    }
  }
}));

export const LayerGroupItem = (props: LayerGroupItemProps): JSX.Element => {
  const { titleKey, subtitleKey, totalLayers, activeLayers, onClick } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <ListItemButton divider onClick={onClick} aria-labelledby={titleKey}>
      <ListItemText
        primary={
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            component="span"
          >
            <Typography component="span" id={titleKey}>
              {t(titleKey)}
            </Typography>
            <Chip
              label={t('map.layers.activeItems', {
                count: activeLayers,
                total: totalLayers
              })}
              className={classes.chip}
              component="span"
              sx={{ bgcolor: activeLayers > 0 ? '#509F4E' : '#999999' }}
            />
          </Stack>
        }
        primaryTypographyProps={{ variant: 'body1' }}
        secondary={t(subtitleKey)}
        secondaryTypographyProps={{ variant: 'subtitle1' }}
      />
      <ListItemSecondaryAction>
        <ChevronRightIcon sx={{ color: 'text.primary' }} />
      </ListItemSecondaryAction>
    </ListItemButton>
  );
};
