import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layer, MapGeoJSONFeature, useMap } from 'react-map-gl/maplibre';
import { isEqual } from 'lodash-es';
import {
  EvacZonePoi,
  EvacZoneStatus,
  LayerEvacZone,
  LayerZoneFeature
} from 'shared/types';
import usePrevious from 'hooks/usePrevious';
import useMapLayersState from 'state/useMapLayersState';
import { EventData } from 'mapbox-gl';
import { usePoisState } from 'state/usePoisState';
import { EvacZoneDialogContent } from 'components/Map/EvacZoneDialogContent';
import VisibilityToggledSource from '../VisibilityToggledSource';
import {
  SOURCE_ID,
  SOURCE_LAYER,
  EVAC_ZONES_EDIT_FILL_LAYER_ID,
  INACTIVE_ZONES_LAYER_MIN_ZOOM
} from './constants';
import {
  possiblyCreateSplitZone,
  switchZoneStatus,
  getCountyNameFromSlug,
  getTilesUrl,
  getSelectedEvacZoneUId
} from './utils';
import {
  getEvacAdvisoriesGreenLayerStyle,
  getEvacAdvisoriesBlueLayerStyle,
  getEvacOrdersLayerStyle,
  getEvacWarningsLayerStyle,
  getEvacReverseAdvisoriesLayerStyle,
  getEvacReverseOrdersLayerStyle,
  getFillLayerStyle,
  getLabelLayerStyle,
  getPerimeterLayerStyle,
  getPaintPropertyOpacity
} from './layerStyles';
import { getSelectedPatternStyle, getSelectedStrokeStyle } from '../../utils';
import {
  EVAC_ZONE_FILL_PATTERNS,
  EVAC_ZONE_LAYER_COLORS,
  EvacZoneStyles
} from '../../../../constants';
import { BEFORE_LAYER_ID } from '../../styles/constants';

type StructuredEvacuationsEditLayerProps = {
  visible: boolean;
  evacZones: LayerEvacZone[];
  mode: 'view' | 'edit';
  cursorStyle?: string;
  onClick?: (zoneFeature: LayerZoneFeature, status?: EvacZoneStatus) => void;
  cacheBuster?: number;
};

/*
 * This layer is just for edit now - but was originally for 3 modes (edit/view/all).
 * This is a temporary state until we can clean this version up. It has been left mostly untouched via the refactor
 * into the view/all layers.
 */
const StructuredEvacuationsEditLayer = (
  props: StructuredEvacuationsEditLayerProps
): JSX.Element => {
  const {
    visible,
    evacZones,
    cursorStyle = 'auto',
    onClick,
    cacheBuster,
    mode
  } = props;
  const { current: map } = useMap();
  const mapSourceLoadedRef = useRef(false);
  const [evacSubZones, setEvacSubZones] = useState<LayerEvacZone[]>([]);
  const prevEvacZones = usePrevious(evacZones);
  const mapInteractedRef = useRef(false);
  const { mapBaseLayer: mapStyle } = useMapLayersState();
  const prevMapStyle = usePrevious(mapStyle);
  const [reloadZonesWithStatus, setReloadZonesWithStatus] = useState(false);
  const { selectedPoi, setSelectedPoi, clearSelectedPois } = usePoisState();

  const updateMapZonesStatus = useCallback(
    (zones: LayerEvacZone[], action: 'add' | 'remove') => {
      zones.forEach((zone) => {
        if (!zone.status) {
          return;
        }
        if (action === 'add') {
          const zoneStyle = zone.style || EvacZoneStyles.default;
          const color = EVAC_ZONE_LAYER_COLORS[zoneStyle][zone.status];
          const pattern = EVAC_ZONE_FILL_PATTERNS[zoneStyle][zone.status];
          map?.setFeatureState(
            { id: zone.uid, source: SOURCE_ID, sourceLayer: SOURCE_LAYER },
            { status: zone.status, color, pattern }
          );
        } else {
          map?.removeFeatureState({
            id: zone.uid,
            source: SOURCE_ID,
            sourceLayer: SOURCE_LAYER
          });
        }
      });
    },
    [map]
  );

  useEffect(() => {
    setReloadZonesWithStatus(true);
  }, []);

  useEffect(() => {
    if (prevMapStyle && prevMapStyle !== mapStyle) {
      // We cannot update the map's feature state here, map is loading new style.
      // We use a piece of state to trigger a re-render (async operation).
      setReloadZonesWithStatus(true);
    }
  }, [prevMapStyle, mapStyle, evacZones.length]);

  const reloadEvacZonesWithStatus = useCallback(() => {
    // Make this operation only once.
    setReloadZonesWithStatus(false);
    const evacZonesWithStatus = evacZones.filter((zone) => !!zone.status);
    updateMapZonesStatus(evacZonesWithStatus, 'add');
  }, [evacZones, updateMapZonesStatus]);

  useEffect(() => {
    if (reloadZonesWithStatus) {
      // Reload evac zones with status - map has applied new style.
      reloadEvacZonesWithStatus();
    }
  }, [reloadEvacZonesWithStatus, reloadZonesWithStatus]);

  const loadEvacZonesWithStatus = useCallback(() => {
    const prevZonesWithStatus =
      prevEvacZones?.filter((zone) => !!zone.status) ?? [];

    const evacZonesWithStatus = evacZones.filter((zone) => !!zone.status);

    if (
      isEqual(prevZonesWithStatus, evacZonesWithStatus) &&
      mapSourceLoadedRef.current
    ) {
      // evacZones prop hasn't changed and zones have already been loaded
      return;
    }

    // evacZones prop has changed

    if (mapSourceLoadedRef.current) {
      // remove zone with status if they have already been loaded
      const zonesToRemove = [...prevZonesWithStatus];
      if (evacSubZones.length > 0) {
        // also remove subzones, if any exist
        zonesToRemove.concat(evacSubZones);
      }

      updateMapZonesStatus(zonesToRemove, 'remove');
    }

    // add evac zones with status to map
    updateMapZonesStatus(evacZonesWithStatus, 'add');

    const features = map?.querySourceFeatures(SOURCE_ID, {
      sourceLayer: SOURCE_LAYER
    });

    const subEvacZones = (features ?? []).reduce((subZones, feature) => {
      const featureZoneUId = feature.properties?.zone_slug;
      if (featureZoneUId) {
        const subZoneWithStatus = possiblyCreateSplitZone(
          featureZoneUId,
          evacZonesWithStatus
        );
        if (subZoneWithStatus) subZones.push(subZoneWithStatus);
      }
      return subZones;
    }, [] as LayerEvacZone[]);

    if (subEvacZones.length > 0) {
      // add subzones to map
      updateMapZonesStatus(subEvacZones, 'add');
      // save reference to subzones
      setEvacSubZones(subEvacZones);
    }

    // mark map source as 'loaded'
    mapSourceLoadedRef.current = true;
  }, [prevEvacZones, evacZones, updateMapZonesStatus, map, evacSubZones]);

  const mapSource = map?.getSource(SOURCE_ID);

  useEffect(() => {
    if (
      !visible ||
      !mapSource ||
      evacZones.length === 0 ||
      mapInteractedRef.current
    ) {
      return;
    }
    loadEvacZonesWithStatus();
  }, [evacZones, loadEvacZonesWithStatus, mapSource, visible]);

  const handleClick = useCallback(
    (event: EventData) => {
      mapInteractedRef.current = true;

      // ensure in edit mode, other POIs are not opening up.
      clearSelectedPois();

      const feature = (event.features as MapGeoJSONFeature[])[0];
      if (!feature) return;

      const evacZone = evacZones.find((zone) => zone.uid === feature.id);

      if (
        map &&
        map.getZoom() < INACTIVE_ZONES_LAYER_MIN_ZOOM &&
        !evacZone?.status
      ) {
        // Invisible inactive evac zones remain clickable because they are only hidden using "opacity: 0".
        // Early return to avoid selecting them.
        return;
      }

      if (!evacZone) {
        const poiDialogData: EvacZonePoi = {
          uid: feature.properties.zone_slug,
          displayName: feature.properties.zone_name,
          county: getCountyNameFromSlug(feature.properties.county_slug),
          status: feature.state.status,
          source: feature.properties.source_type,
          style: EvacZoneStyles.default
        };
        setSelectedPoi({
          type: 'evacZone',
          id: feature.id,
          PoiDialogContent: () => (
            <EvacZoneDialogContent evacZone={poiDialogData} />
          )
        });
        return;
      }

      if (mode === 'edit') {
        const currentStatus = feature.state.status;
        const status = switchZoneStatus(currentStatus);
        const zoneStyle = evacZone.style || EvacZoneStyles.default;
        const color = status
          ? EVAC_ZONE_LAYER_COLORS[zoneStyle][status]
          : undefined;
        const pattern = status
          ? EVAC_ZONE_FILL_PATTERNS[zoneStyle][status]
          : undefined;
        map?.setFeatureState(feature, { status, color, pattern });
        if (onClick && feature.properties?.zone_slug) {
          onClick(feature.properties.zone_slug, status);
        }
        return;
      }

      if (onClick && feature.properties?.zone_slug) {
        onClick({
          uid: feature.properties.zone_slug,
          name: feature.properties.zone_name,
          county: getCountyNameFromSlug(feature.properties.county_slug),
          status: feature.state.status,
          sourceType: feature.properties.source_type
        });
      }
    },
    [evacZones, map, mode, onClick, setSelectedPoi, clearSelectedPois]
  );

  const handleSetCursorPointer = useCallback(() => {
    const canvas = map?.getCanvas();
    if (!canvas) return;
    canvas.style.cursor = 'pointer';
  }, [map]);

  const handleSetCursorDefault = useCallback(() => {
    const canvas = map?.getCanvas();
    if (!canvas) return;
    canvas.style.cursor = cursorStyle;
  }, [cursorStyle, map]);

  useEffect(() => {
    map?.on('click', EVAC_ZONES_EDIT_FILL_LAYER_ID, handleClick);
    map?.on(
      'mouseenter',
      EVAC_ZONES_EDIT_FILL_LAYER_ID,
      handleSetCursorPointer
    );
    map?.on(
      'mouseleave',
      EVAC_ZONES_EDIT_FILL_LAYER_ID,
      handleSetCursorDefault
    );

    return () => {
      map?.off('click', EVAC_ZONES_EDIT_FILL_LAYER_ID, handleClick);
      map?.off(
        'mouseenter',
        EVAC_ZONES_EDIT_FILL_LAYER_ID,
        handleSetCursorPointer
      );
      map?.off(
        'mouseleave',
        EVAC_ZONES_EDIT_FILL_LAYER_ID,
        handleSetCursorDefault
      );
    };
  }, [handleClick, handleSetCursorDefault, handleSetCursorPointer, map]);

  const evacZoneUIds = useMemo(() => {
    const zones = evacZones;
    if (evacSubZones.length > 0) zones.concat(evacSubZones);
    return zones.map((zone) => zone.uid);
  }, [evacSubZones, evacZones]);

  const selectedEvacZoneUId = getSelectedEvacZoneUId(selectedPoi);
  const selectedFillStyle = getSelectedPatternStyle({
    idKey: 'zone_slug',
    otherLayerProps: {
      'source-layer': SOURCE_LAYER,
      paint: {
        'fill-opacity': getPaintPropertyOpacity(INACTIVE_ZONES_LAYER_MIN_ZOOM)
      }
    },
    selectedId: selectedEvacZoneUId
  });

  const selectedStrokeStyle = getSelectedStrokeStyle({
    idKey: 'zone_slug',
    otherLayerProps: {
      'source-layer': SOURCE_LAYER,
      paint: {
        'line-opacity': getPaintPropertyOpacity(INACTIVE_ZONES_LAYER_MIN_ZOOM)
      }
    },
    selectedId: selectedEvacZoneUId
  });

  return (
    <VisibilityToggledSource
      url={getTilesUrl(cacheBuster)}
      visible={visible}
      promoteId="zone_slug"
      // Do not unset `volatile` ever for this layer.
      volatile
      id={SOURCE_ID}
    >
      <Layer beforeId={BEFORE_LAYER_ID} {...getFillLayerStyle(evacZoneUIds)} />
      <Layer
        beforeId={BEFORE_LAYER_ID}
        {...getEvacAdvisoriesGreenLayerStyle(evacZoneUIds)}
      />
      <Layer
        beforeId={BEFORE_LAYER_ID}
        {...getEvacAdvisoriesBlueLayerStyle(evacZoneUIds)}
      />
      <Layer
        beforeId={BEFORE_LAYER_ID}
        {...getEvacWarningsLayerStyle(evacZoneUIds)}
      />
      <Layer
        beforeId={BEFORE_LAYER_ID}
        {...getEvacOrdersLayerStyle(evacZoneUIds)}
      />
      <Layer
        beforeId={BEFORE_LAYER_ID}
        {...getEvacReverseAdvisoriesLayerStyle(evacZoneUIds)}
      />
      <Layer
        beforeId={BEFORE_LAYER_ID}
        {...getEvacReverseOrdersLayerStyle(evacZoneUIds)}
      />
      <Layer {...getPerimeterLayerStyle(evacZoneUIds)} />
      <Layer beforeId={BEFORE_LAYER_ID} {...selectedFillStyle} />
      <Layer {...selectedStrokeStyle} />
      <Layer {...getLabelLayerStyle(evacZoneUIds)} />
    </VisibilityToggledSource>
  );
};

export default StructuredEvacuationsEditLayer;
