import { SetterOrUpdater, atom, useRecoilState } from 'recoil';
import { AppInfo } from '@capacitor/app';
import { localStorageEffect, LOCAL_STORAGE_KEY } from './localStorage';

type UseAppInfoState = {
  appId: string;
  appName: string;
  appBuild: string;
  appVersion: string;
  setAppInfoState: SetterOrUpdater<AppInfo>;
};

const DEFAULT_APP_INFO_STATE: AppInfo = {
  id: '',
  name: '',
  build: '',
  version: ''
};

const appInfoAtom = atom({
  key: LOCAL_STORAGE_KEY.APP_INFO,
  default: DEFAULT_APP_INFO_STATE,
  effects: [localStorageEffect(LOCAL_STORAGE_KEY.APP_INFO)]
});

const useAppInfoState = (): UseAppInfoState => {
  const [appInfoState, setAppInfoState] = useRecoilState(appInfoAtom);

  return {
    appId: appInfoState.id,
    appName: appInfoState.name,
    appBuild: appInfoState.build,
    appVersion: appInfoState.version,
    setAppInfoState
  };
};

export default useAppInfoState;
