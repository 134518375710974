import { MutableRefObject, UIEvent, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Resetter } from 'recoil';
import { useScrollPositionState } from '../state';

type ScrollPosition = {
  scrollPosition: number;
  scrollHeight: number;
  handleScroll: (event: UIEvent) => void;
  resetScrollPosition: Resetter;
};

const useScrollPosition = (
  elementRef: MutableRefObject<Element | undefined>,
  pageId?: string
): ScrollPosition => {
  const {
    scrollPosition,
    scrollHeight,
    setScrollPosition,
    setScrollHeight,
    reset
  } = useScrollPositionState(pageId);
  const history = useHistory();

  const handleScroll = useCallback((): void => {
    const element = elementRef.current;

    if (!element) return;

    // can also access this by e.target.scrollTop
    const { scrollTop } = element;
    setScrollPosition(scrollTop);
  }, [elementRef, setScrollPosition]);

  useEffect(() => {
    const unregisterListener = history.listen(() => {
      if (elementRef.current) {
        const { scrollHeight: height, scrollTop } = elementRef.current;
        setScrollHeight(height);
        setScrollPosition(scrollTop);
      }
    });

    const handleResize = (): void => {
      if (elementRef.current) {
        const { scrollTop } = elementRef.current;
        setScrollPosition(scrollTop);
        setScrollHeight(0);
      }
    };

    window.addEventListener('resize', handleResize);

    if (elementRef.current && scrollHeight > 0 && scrollPosition > 0) {
      // eslint-disable-next-line no-param-reassign
      elementRef.current.scrollTop = scrollPosition;
    }

    return () => {
      unregisterListener();
      window.removeEventListener('resize', handleResize);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef]);

  return {
    scrollPosition,
    scrollHeight,
    handleScroll,
    resetScrollPosition: reset
  };
};

export default useScrollPosition;
