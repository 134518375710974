import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';

type DrawerNavState = {
  open: boolean;
};

type UseDrawerNavState = {
  toggleOpen: () => void;
  open: boolean;
};

const DEFAULT_DRAWER_NAV_STATE: DrawerNavState = {
  open: false
};

const drawerNavAtom = atom({
  key: 'DRAWER_NAV_STATE',
  default: DEFAULT_DRAWER_NAV_STATE
});

const useDrawerNavState = (): UseDrawerNavState => {
  const [drawerNavState, setDrawerNavState] = useRecoilState(drawerNavAtom);

  const toggleOpen = useCallback(() => {
    setDrawerNavState((prevState) => ({ ...prevState, open: !prevState.open }));
  }, [setDrawerNavState]);

  return {
    ...drawerNavState,
    toggleOpen
  };
};

export default useDrawerNavState;
