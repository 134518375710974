/* eslint-disable i18next/no-literal-string */
import { Alert, Theme, ButtonBase, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GeoEvent } from 'shared/types';
import { makeStyles } from 'tss-react/mui';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

type GeoEventEvacBtnProps = {
  geoEvent: GeoEvent;
  handleTop: () => void;
};

const useStyles = makeStyles()((theme: Theme) => ({
  alert: {
    paddingBottom: 3,
    paddingTop: 3,
    width: '100%'
  },
  btn: {
    borderRadius: 12,
    width: '100%'
  },
  evacAlertIcon: {
    position: 'absolute',
    right: 12,
    top: 8,
    color: theme.palette.text.primary
  }
}));

const GeoEventEvacBtn = (props: GeoEventEvacBtnProps): JSX.Element | null => {
  const { geoEvent, handleTop } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  const {
    data: {
      evacuationOrders,
      evacuationWarnings,
      evacuationAdvisories,
      evacuationNotes
    }
  } = geoEvent;

  if (
    !evacuationOrders &&
    !evacuationWarnings &&
    !evacuationNotes &&
    !evacuationAdvisories
  ) {
    return null;
  }

  return (
    <Box sx={{ padding: '8px 16px' }}>
      <ButtonBase role="button" onClick={handleTop} className={classes.btn}>
        <Alert severity="error" className={classes.alert}>
          <Typography fontWeight={500} variant="body1" color="black">
            {t('geoEvent.evacBtnTitle')}
          </Typography>
          <ExpandLessIcon color="secondary" className={classes.evacAlertIcon} />
        </Alert>
      </ButtonBase>
    </Box>
  );
};

export default GeoEventEvacBtn;
