import { DialogContent, Grid, SxProps, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import UserStatusChip from './UserStatusChip';
import { getDateFormatted } from '../../shared/utils';

export type RadioTowerDialogContentProps = {
  editDate: Date;
  name: string;
  channels: Array<string>;
};

export const RadioTowerDialogContent = (
  props: RadioTowerDialogContentProps
): JSX.Element => {
  const { t } = useTranslation();

  const { editDate, name, channels } = props;

  const channelsList = useMemo(
    () =>
      channels.map((c) => {
        // Split the string, which combines both the channel name and tone number, into
        // two separate strings.
        const result = new RegExp(/^(.+)\s+((?:tone |T)\d+)$/).exec(c);
        if (result) {
          return {
            channel: result[1],
            tone: result[2]
          };
        }

        return {
          channel: c,
          tone: null
        };
      }),
    [channels]
  );

  const editDateFormatted = getDateFormatted(editDate.toISOString(), {
    includeYear: true
  });

  const attributeNameSx: SxProps = { fontVariant: 'all-small-caps' };

  return (
    <>
      <DialogContent sx={{ padding: 2 }}>
        <Typography variant="body2" sx={{ marginBottom: 1 }}>
          <b>{name}</b>
          <UserStatusChip type="pro" sx={{ marginLeft: 1 }} />
        </Typography>
        {channelsList.map(({ channel, tone }) => (
          <Grid key={channel} container alignItems="baseline">
            <Grid item xs={3}>
              <Typography variant="body1" color="grayText" sx={attributeNameSx}>
                {t('map.poi.radioTowers.channel')}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{channel}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1" color="grayText" sx={attributeNameSx}>
                {t('map.poi.radioTowers.tone')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2">{tone}</Typography>
            </Grid>
          </Grid>
        ))}
        <Typography variant="subtitle1" color="grayText" sx={{ marginTop: 1 }}>
          {t('map.poi.radioTowers.updated')}
          {': '}
          {editDateFormatted}
        </Typography>
      </DialogContent>
    </>
  );
};
