import { useRouteMatch } from 'react-router-dom';

type WildfireGeoEventParams = {
  geoEventId?: string;
};

export const useSelectedWildfireGeoEventId = (): number | undefined => {
  // Using `useRouteMatch()` instead of `useParams()` since we're not always inside the GeoEvent <Router>
  const match = useRouteMatch<WildfireGeoEventParams>('/i/:geoEventId');
  const geoEventIdStr = match?.params.geoEventId;

  return geoEventIdStr ? parseInt(geoEventIdStr, 10) : undefined;
};
