import { Box, Card, CardContent, Grid, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { GeoEvent } from 'shared/types';
import GeoEventReportsMap from 'components/GeoEventReportsMap';
import EmbedGeoEventInfo from './EmbedGeoEventInfo';

type EmbedGeoEventProps = {
  geoEvent: GeoEvent;
};

const EMBED_WIDTH = 700;
const EMBED_HEADERBAR_HEIGHT = 64; // MUI standard header size
const EMBED_HEIGHT = 368 - EMBED_HEADERBAR_HEIGHT;

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden'
  },
  mapContainer: {
    height: '100%',
    position: 'sticky',
    top: 0,
    display: 'flex'
  },
  contentContainer: {
    height: '100%',
    width: '100%',
    maxWidth: '532px',
    minWidth: '0px'
  },
  card: {
    borderRadius: 0,
    minHeight: '100%',
    position: 'relative',
    width: '100%',
    zIndex: theme.zIndex.drawer - 1,
    [theme.breakpoints.up(660)]: {
      paddingTop: theme.spacing(1)
    }
  },
  cardContent: {
    paddingTop: '0px',
    paddingLeft: '0px',
    paddingRight: '0px'
  },
  contentPadding: {
    padding: theme.spacing(0, 2, 2)
  }
}));

const EmbedGeoEvent = (props: EmbedGeoEventProps): JSX.Element => {
  const { geoEvent } = props;
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Box
        // not entirely clear why we need the additional width but when screenshot is taken - there was
        // a gap where the map was not filled out without this padding
        sx={{ width: `${EMBED_WIDTH + 220 / 2}px` }}
        className={classes.mapContainer}
      >
        <GeoEventReportsMap
          geoEvent={geoEvent}
          hiddenMapPixels={null}
          noControls
          noMarkers
          style={{ height: `${EMBED_HEIGHT}px` }}
        />
      </Box>

      <Box className={classes.contentContainer}>
        <Card
          className={classes.card}
          sx={{ overflow: 'auto', maxHeight: '100%' }}
        >
          <CardContent className={classes.cardContent}>
            <Grid container>
              <Grid item className={classes.contentPadding}>
                <EmbedGeoEventInfo geoEvent={geoEvent} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default EmbedGeoEvent;
