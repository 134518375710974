import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';

/*
 * live = showing the latest camera image with automatic refresh
 * playing = playing a timelapse
 * paused = paused in timelapse mode
 */
type PlayerStatus = 'live' | 'playing' | 'paused';

export type CameraTimeLapseFrameDetails = {
  imageTimestamp: string;
  imageUrl: string;
  azDeg: number | null;
  fovDeg: number | null;
};

type UseAlertCameraPlayerStateReturn = {
  playerStatus: PlayerStatus;
  setPlayerStatus: (status: PlayerStatus) => void;
  timelapseFrame: CameraTimeLapseFrameDetails | null;
  setTimelapseFrame: (frame: CameraTimeLapseFrameDetails | null) => void;
};

const cameraStatusAtom = atom<PlayerStatus>({
  key: 'ALERT_CAMERA_PLAYER_STATUS_STATE',
  default: 'live'
});

const cameraTimelapseFrameAtom = atom<CameraTimeLapseFrameDetails | null>({
  key: 'ALERT_CAMERA_TIMELAPSE_FRAME_STATE',
  default: null
});

export const useAlertCameraPlayerState =
  (): UseAlertCameraPlayerStateReturn => {
    const [playerStatus, setPlayerStatus] =
      useRecoilState<PlayerStatus>(cameraStatusAtom);
    const [timelapseFrame, setTimelapseFrame] =
      useRecoilState<CameraTimeLapseFrameDetails | null>(
        cameraTimelapseFrameAtom
      );

    // Whenever the player status changes to 'live', clear the timelapse frame so
    // that it does not pollute the viewshed display for any other cameras whose
    // playback is started.
    useEffect(() => {
      if (playerStatus === 'live') {
        setTimelapseFrame(null);
      }
    }, [playerStatus, setTimelapseFrame]);

    return {
      playerStatus,
      setPlayerStatus,
      // For extra security, never return a timelapse frame if the player is in live mode.
      timelapseFrame: playerStatus === 'live' ? null : timelapseFrame,
      setTimelapseFrame
    };
  };
