import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';

type ThermalHotspotTimeGuideProps = {
  size?: 'small' | 'medium';
};

const useStyles = makeStyles()((theme) => ({
  timeGuide: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0)
  },
  timeGuideBar: {
    background: 'linear-gradient(90deg, #F33A3A 0%, #FF9B05 100%)',
    height: 10,
    borderRadius: 10,
    flex: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

const ThermalHotspotTimeGuide = (
  props: ThermalHotspotTimeGuideProps
): JSX.Element => {
  const { size = 'small' } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const textVariant = size === 'small' ? 'caption' : 'body2';
  const width = size === 'small' ? '90%' : '100%';

  return (
    <Box className={classes.timeGuide} sx={{ width }} component="span">
      <Typography variant={textVariant} color="textSecondary" component="span">
        {t('map.legends.recent')}
      </Typography>
      <Box className={classes.timeGuideBar} component="span" />
      <Typography variant={textVariant} color="textSecondary" component="span">
        {t('map.legends.dayAgo')}
      </Typography>
    </Box>
  );
};

export default ThermalHotspotTimeGuide;
