import { Capacitor } from '@capacitor/core';
import { DefaultValue } from 'recoil';
import { User } from 'shared/types';

export type LocalStorageKey =
  typeof LOCAL_STORAGE_KEY[keyof typeof LOCAL_STORAGE_KEY];

export const LOCAL_STORAGE_KEY = {
  ACCESSKEY: 'ACCESSKEY', // denormalized into AUTH_STATE
  AUTH_STATE: 'AUTH_STATE',
  USER: 'USER',
  DEVICE_INFO: 'DEVICE_INFO',
  PUSH_TOKEN: 'PUSH_TOKEN',
  MAP_STATE: 'MAP_STATE',
  MAP_LAYERS: 'MAP_LAYERS',
  MAP_BASE_LAYER: 'MAP_BASE_LAYER',
  MAP_LAYERS_DRAWER: 'MAP_LAYERS_DRAWER',
  LAST_KNOWN_GEOLOCATION: 'LAST_KNOWN_GEOLOCATION',
  UPLOAD_IMAGE_COUNT: 'UPLOAD_IMAGE_COUNT',
  ONBOARDING_STATE: 'ONBOARDING_STATE',
  APP_INFO: 'APP_INFO',
  MAP_PLACES: 'MAP_PLACES',
  SNACKBAR: 'SNACKBAR_STATE',
  PLACES_SEARCHBAR: 'PLACES_SEARCHBAR',
  MEMBERSHIP_STATE: 'MEMBERSHIP_STATE',
  HAS_WEB_GL_2: 'HAS_WEB_GL_2',
  INBOX_LAST_FETCHED_DATE: 'INBOX_LAST_FETCHED_DATE',
  GEO_EVENTS_BANNER_ADD: 'GEO_EVENTS_BANNER_ADD',
  LAST_PUSH_NOTIFICATION_TIMESTAMP: 'LAST_PUSH_NOTIFICATION_TIMESTAMP',
  IAP_STATE: 'IAP_STATE',
  DONATION_STATE: 'DONATION_STATE',
  CACHE: 'CACHE',
  SUPPORT_US_BANNER_AD: 'SUPPORT_US_BANNER_AD'
};

export const SESSION_STORAGE_KEY = {
  MAPBOX_SEARCH_SESSION_TOKEN: 'MAPBOX_SEARCH_SESSION_TOKEN'
};

export const LOGOUT_KEEP_LOCAL_STORAGE_KEYS = [
  LOCAL_STORAGE_KEY.ONBOARDING_STATE
];

export const getData = <T>(key: string, defaultValue?: T): T | undefined => {
  try {
    const data = localStorage.getItem(key);
    if (data === null) {
      return defaultValue;
    }
    return JSON.parse(data);
  } catch (err) {
    return defaultValue;
  }
};

export const saveData = <T>(key: string, data: T): null => {
  try {
    const serializedData = JSON.stringify(data);

    if (!data) {
      localStorage.removeItem(key);
      return null;
    }

    localStorage.setItem(key, serializedData);
  } catch (err) {
    return null;
  }
  return null;
};

/*
  Auth Get & Set
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveAuth = (headers: Record<string, any>): void => {
  saveData(LOCAL_STORAGE_KEY.ACCESSKEY, headers?.key || null);
};

export const getAccessKey = (): string | null => {
  const accessToken = getData<string>(LOCAL_STORAGE_KEY.ACCESSKEY);
  if (!accessToken) {
    return null;
  }
  return accessToken;
};

/*
  Push Token Get & Set
*/
export const savePushTokenToLocalStorage = (token: string | null): void => {
  saveData(LOCAL_STORAGE_KEY.PUSH_TOKEN, token || null);
};

export const getPushTokenFromLocalStorage = (): string | null => {
  if (
    import.meta.env.VITE_DEBUG_PUSH_TOKEN &&
    Capacitor.getPlatform() === 'ios'
  ) {
    // Only needed for iOS - causes e2e tests to fail if enabled for android
    return import.meta.env.VITE_DEBUG_PUSH_TOKEN;
  }

  const token = getData<string>(LOCAL_STORAGE_KEY.PUSH_TOKEN);
  if (token) {
    return token;
  }

  // As developers we need a hack to develop in the browser
  // this way you can pass in `push_token` as a requst param
  // and it will get saved to localStorage
  const params = new URL(document.location.toString()).searchParams;
  const devPushToken = params.get('push_token');
  if (devPushToken) {
    savePushTokenToLocalStorage(devPushToken);
    return devPushToken;
  }

  if (!token) {
    return null;
  }
  return token;
};

/**
 * Returns the OLD user object.  The way to access this
 * now is though useAuthState
 *
 * @deprecated
 *
 * @returns user
 */
export const getUserData = (): User | null => {
  const user = getData<User>(LOCAL_STORAGE_KEY.USER);
  if (!user) {
    return null;
  }
  return user;
};

/**
 * Clear Data
 */
export const clearDataForLogout = (): void => {
  sessionStorage.clear();
  const toRemove = Object.values(LOCAL_STORAGE_KEY).filter(
    (key) => !LOGOUT_KEEP_LOCAL_STORAGE_KEYS.includes(key)
  );
  localStorage.removeItem('REACT_QUERY_OFFLINE_CACHE');
  toRemove.forEach((key) => {
    localStorage.removeItem(key);
  });
};

/**
 *
 * @param {key} key of item to remove
 */
export const removeItem = (key: string): void => {
  localStorage.removeItem(key);
};

type StorageEffect<T> = {
  setSelf: (
    param:
      | T
      | DefaultValue
      | Promise<T | DefaultValue>
      | ((param: T | DefaultValue) => T | DefaultValue)
  ) => void;
  onSet: (
    param: (newValue: T, oldValue: T | DefaultValue, isReset: boolean) => void
  ) => void;
};

// https://recoiljs.org/docs/guides/atom-effects/
export const localStorageEffect =
  <T>(key: string, merge?: (value: T) => T) =>
  ({ setSelf, onSet }: StorageEffect<T>) => {
    const savedValue = localStorage.getItem(key);

    if (savedValue != null) {
      let value = JSON.parse(savedValue);
      if (merge) value = merge(value);
      setSelf(value);
    }

    onSet((newValue: T, _, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const setLastPushNotificationTimestamp = (): void => {
  saveData(LOCAL_STORAGE_KEY.LAST_PUSH_NOTIFICATION_TIMESTAMP, {
    timestamp: Date.now()
  });
};

export const getLastPushNotificationTimestamp = (): number => {
  return (
    getData<{ timestamp: number }>(
      LOCAL_STORAGE_KEY.LAST_PUSH_NOTIFICATION_TIMESTAMP
    )?.timestamp || 0
  );
};
