import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { API, CacheAPI } from 'api';
import { GeoEventEvacZoneStatus } from 'shared/types';
import { useCacheState } from 'state';
import { AxiosResponse } from 'axios';

const fetchActiveEvacZones = async (
  cacheBusterTs: number | null
): Promise<AxiosResponse<GeoEventEvacZoneStatus[]>> => {
  if (cacheBusterTs) {
    return CacheAPI.get<GeoEventEvacZoneStatus[]>(
      `evac_zones/statuses/?ts=${cacheBusterTs}`
    );
  }
  return API.get<GeoEventEvacZoneStatus[]>('evac_zones/statuses');
};

const useActiveEvacZonesQuery = (): UseQueryResult<
  AxiosResponse<GeoEventEvacZoneStatus[]> | undefined
> => {
  const { cacheBusterTs } = useCacheState();

  const query = useQuery({
    queryKey: ['active-evacuation-zones', cacheBusterTs],
    queryFn: () => fetchActiveEvacZones(cacheBusterTs),
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 6 // 6 minutes - must be bigger than staleTime
  });

  return query;
};

export default useActiveEvacZonesQuery;
