import { Box, Paper, Stack, Typography } from '@mui/material';
import VectorLogo from 'components/VectorLogo';
import { truncateString, removeNewLines } from 'shared/utils';

const PREVIEW_MESSAGE_TRUNCATE_LENGTH = 160;

export type PushNotificationPreviewProps = {
  title: string;
  body: string;
};

export const PushNotificationPreview = (
  props: PushNotificationPreviewProps
): JSX.Element => {
  const { title, body } = props;
  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: (theme) => theme.palette.grey[200],
        padding: 1
      }}
    >
      <Stack direction="row" spacing={2}>
        <Paper
          elevation={0}
          sx={{
            width: 38,
            height: 38,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <VectorLogo size={30} />
        </Paper>
        <Box sx={{ overflow: 'hidden' }}>
          <Typography
            variant="body2"
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              fontWeight: 'bold'
            }}
          >
            {title}
          </Typography>

          <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
            {truncateString(
              removeNewLines(body),
              PREVIEW_MESSAGE_TRUNCATE_LENGTH
            )}
          </Typography>
        </Box>
      </Stack>
    </Paper>
  );
};
