import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from 'tss-react/mui';
import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { useAuthState } from 'state';
import useInboxMessages from 'hooks/useInboxMessages';
import { getResponsiveFontSize } from 'shared/utils';

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const fetchModerationReportsCount = () =>
  API.get(`reports/count/?is_moderated=false&is_active=true&has_lat_lng=true`);

const useStyles = makeStyles()((theme) => ({
  icon: {
    width: 24, // hard-coding to prevent iOS Dynamic Type from resizing this
    height: 24
  },
  badge: {
    fontSize: getResponsiveFontSize(theme.typography.pxToRem(12))
  }
}));

const MenuBadge = (): JSX.Element => {
  const {
    permissions: { canModerate }
  } = useAuthState();
  const { classes } = useStyles();
  const { unreadMessages } = useInboxMessages();

  const { data } = useQuery({
    queryKey: ['moderationReportsCount'],
    queryFn: fetchModerationReportsCount,
    enabled: canModerate
  });
  const count = (data?.data?.count || 0) + unreadMessages;

  return (
    <Badge
      badgeContent={count}
      color="error"
      classes={{ badge: classes.badge }}
    >
      <MenuIcon className={classes.icon} />
    </Badge>
  );
};

export default MenuBadge;
