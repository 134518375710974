import { DEFAULT_VIEW_STATE } from 'components/Map';
import { useEffect } from 'react';
import { useMap } from 'react-map-gl/maplibre';
import { useMediaQuery, useTheme } from '@mui/material';
import { getBounds } from '../../shared/utils';
import { LatLng } from '../../shared/types';
import { MAP_ENTITY_DRAWER_WIDTH } from '../Map/MapEntityDrawer';
import { SEARCH_CARD_MIN_SNAP_PCT, SEARCH_DRAWER_WIDTH } from '../../constants';

export type UseCenterMapProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  zoomIn?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  latLng: LatLng | null;
  // eslint-disable-next-line react/no-unused-prop-types
  drawerIsOpen?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  defaultSnapPointPct: number;
};

const getXOffsetForDesktop = (
  isLargeMediaQuery: boolean,
  drawerIsOpen: boolean
): number => {
  if (!isLargeMediaQuery) {
    return 0;
  }

  if (drawerIsOpen) {
    return -Math.round((MAP_ENTITY_DRAWER_WIDTH - SEARCH_DRAWER_WIDTH) / 2);
  }
  return 0;
};

const getYOffsetForMobile = (
  isLargeMediaQuery: boolean,
  drawerIsOpen: boolean,
  defaultSnapPointPct: number
): number => {
  if (isLargeMediaQuery) {
    return 0;
  }
  if (drawerIsOpen) {
    return -Math.round((window.innerHeight * defaultSnapPointPct) / 2);
  }

  return -Math.round((window.innerHeight * SEARCH_CARD_MIN_SNAP_PCT) / 2);
};

export const useCenterMap = (props: UseCenterMapProps): void => {
  const { drawerIsOpen = false, latLng, zoomIn, defaultSnapPointPct } = props;
  const { current: map } = useMap();
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up('tablet'));

  const xOffset = getXOffsetForDesktop(isLargeMediaQuery, drawerIsOpen);
  const yOffset = getYOffsetForMobile(
    isLargeMediaQuery,
    drawerIsOpen,
    defaultSnapPointPct
  );

  useEffect(() => {
    if (!map || !latLng) return;

    const bbounds = getBounds(latLng.lat, latLng.lng);
    if (!bbounds) return;

    const zoom = zoomIn
      ? Math.max(DEFAULT_VIEW_STATE.zoom, map.getZoom())
      : map.getZoom();

    setTimeout(() => {
      map.fitBounds(bbounds, {
        padding: 8,
        offset: [xOffset, yOffset],
        zoom,
        linear: true
      });
    }, 0);
  }, [zoomIn, latLng, map, xOffset, yOffset]);
};

export const CenterMap = (props: UseCenterMapProps): null => {
  useCenterMap(props);
  return null;
};
