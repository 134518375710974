import { useCallback } from 'react';
import { Resetter, atom, useRecoilState, useResetRecoilState } from 'recoil';
import { MapSearchResult } from 'shared/types';
import { LOCAL_STORAGE_KEY } from './localStorage';

type MapSearchState = {
  results: MapSearchResult[];
  value: MapSearchResult | null;
  pristine: boolean;
};

type UseMapSearchState = {
  setSearchResults: (results: MapSearchResult[]) => void;
  setSelectedValue: (value: MapSearchResult | null) => void;
  reset: Resetter;
  setFormPristine: (pristine: boolean) => void;
  results: MapSearchResult[];
  value: MapSearchResult | null;
  pristine: boolean;
};

export const DEFAULT_MAP_SEARCH_STATE: MapSearchState = {
  results: [],
  value: null,
  pristine: true
};

export const mapSearchStateAtom = atom({
  key: LOCAL_STORAGE_KEY.PLACES_SEARCHBAR,
  default: DEFAULT_MAP_SEARCH_STATE
});

export const useMapSearchState = (): UseMapSearchState => {
  const resetMapSearchState = useResetRecoilState(mapSearchStateAtom);
  const [mapSearchState, setMapSearchState] =
    useRecoilState(mapSearchStateAtom);

  const setSearchResults = useCallback(
    (results: MapSearchResult[]) => {
      setMapSearchState((prev) => ({ ...prev, results }));
    },
    [setMapSearchState]
  );

  const setSelectedValue = useCallback(
    (value: MapSearchResult | null) => {
      setMapSearchState((prev) => ({ ...prev, value }));
    },
    [setMapSearchState]
  );

  const setFormPristine = useCallback(
    (pristine: boolean) => {
      setMapSearchState((prev) => ({ ...prev, pristine }));
    },
    [setMapSearchState]
  );

  return {
    ...mapSearchState,
    setSearchResults,
    setSelectedValue,
    reset: resetMapSearchState,
    setFormPristine
  };
};
