import { getData, LOCAL_STORAGE_KEY } from './localStorage';
import { WDDeviceInfo, WDDeviceInfoStored } from '../shared/types';

export const getDeviceInfo = (): WDDeviceInfo | null => {
  const info = getData<WDDeviceInfoStored>(LOCAL_STORAGE_KEY.DEVICE_INFO);
  if (!info) {
    return null;
  }
  const { platform, operatingSystem } = info;
  const isMobile = platform !== 'web';
  // We can be on "web" but on a mobile device
  const isMobilePlatform = ['ios', 'android'].includes(operatingSystem ?? '');
  return {
    ...info,
    isWeb: platform === 'web',
    isMobile,
    isMobilePlatform
  };
};
