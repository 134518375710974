import { useEffect } from 'react';
import { ControlPosition, useControl } from 'react-map-gl/maplibre';
import { Map } from 'maplibre-gl';
import { LayersOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { MapDrawerContentType } from 'state/useMapLayersState';
import MapControlButton from './MapControlButton';
import BaseMapboxGLController from './BaseMapboxGLController';

type LayersControlProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  setDrawerOpen: (open: boolean, content?: MapDrawerContentType) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  position?: ControlPosition;
};

const LayersControlContent = (props: LayersControlProps): JSX.Element => {
  const { setDrawerOpen } = props;
  const { t } = useTranslation();

  return (
    <MapControlButton
      text={t('map.controls.layers')}
      onClick={() => setDrawerOpen(true, 'layers')}
      Icon={LayersOutlined}
    />
  );
};

class MapboxGLLayersController extends BaseMapboxGLController<
  LayersControlProps & { map?: Map }
> {
  content = (): JSX.Element => (
    <LayersControlContent map={this.map} {...this.props} />
  );
}

const LayersControl = (props: LayersControlProps): null => {
  const { position } = props;
  const controller = useControl(() => new MapboxGLLayersController(props), {
    position
  });

  controller.update(props);
  useEffect(() => controller.render(), [controller, props]);
  return null;
};

export default LayersControl;
