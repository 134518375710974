import { ChangeEvent, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { EvacZone, EvacZoneStatus, FormEvacZoneStatus } from 'shared/types';
import { useSnackbarState } from 'state';
import { useMap } from 'react-map-gl/maplibre';
import {
  SOURCE_ID,
  SOURCE_LAYER
} from 'components/Map/layers/StructuredEvacuationsLayer';
import { Capacitor } from '@capacitor/core';
import GrayButton from 'components/GrayButton';
import {
  EVAC_ZONE_FILL_PATTERNS,
  EVAC_ZONE_LAYER_COLORS,
  EvacZoneStatuses,
  EvacZoneStyles
} from '../../constants';
import MultiSelectField from './MultiSelectField';

type TwoSideMultiSelectProps = {
  evacZoneStatus: EvacZoneStatus;
  availableZones: EvacZone[];
  selectedZones: FormEvacZoneStatus[];
  upgradeable?: boolean;
  downgradeable?: boolean;
};

const getZoneUpdatedStatus = (
  action: 'downgrade' | 'upgrade',
  currentStatus: EvacZoneStatus
): EvacZoneStatus => {
  if (action === 'upgrade') {
    if (currentStatus === EvacZoneStatuses.advisories) {
      return EvacZoneStatuses.warnings;
    }
    if (currentStatus === EvacZoneStatuses.warnings) {
      return EvacZoneStatuses.orders;
    }
  } else if (action === 'downgrade') {
    if (currentStatus === EvacZoneStatuses.orders) {
      return EvacZoneStatuses.warnings;
    }
    if (currentStatus === EvacZoneStatuses.warnings) {
      return EvacZoneStatuses.advisories;
    }
  }
  return currentStatus;
};

const getUpdatedUIds = (prevUIds: string[], uId: string): string[] => {
  if (prevUIds.includes(uId)) {
    return prevUIds.filter((prevUId) => prevUId !== uId);
  }
  return [...prevUIds, uId];
};

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr) 56px minmax(0, 1fr)',
    gap: 16
  },
  selectSection: {
    minHeight: 250
  },
  multiSelect: {
    width: '100%',
    borderRadius: theme.shape.borderRadius * 1.34
  },
  select: {
    width: '100%',
    height: '100% !important',
    minHeight: 215,
    padding: theme.spacing(2, 0),
    paddingRight: '0px !important'
  },
  available: {
    minHeight: 235
  },
  option: {
    padding: '6px 12px'
  },
  multiSelectBox: {
    width: '100%',
    minHeight: 250,
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: theme.shape.borderRadius * 1.34,
    padding: theme.spacing(1, 0)
  },
  actions: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  button: {
    fontSize: theme.typography.subtitle1.fontSize,
    width: 56,
    minWidth: 56,
    height: 56,
    padding: 0,
    borderRadius: theme.shape.borderRadius * 1.34,
    margin: theme.spacing(1, 0),
    fontVariant: 'all-small-caps',
    flexDirection: 'column',
    '& > span': {
      marginLeft: 0,
      marginRight: 0,
      '& svg': {
        fontSize: '24px !important'
      }
    }
  },
  textButton: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: '#FF5722',
    textDecoration: 'underline',
    textTransform: 'none',
    marginLeft: theme.spacing(1)
  }
}));

const TwoSideMultiSelect = (props: TwoSideMultiSelectProps): JSX.Element => {
  const {
    evacZoneStatus,
    availableZones,
    selectedZones,
    upgradeable,
    downgradeable
  } = props;
  const [zoneUIds, setZoneUIds] = useState<string[]>([]);
  const [selectedZoneUIds, setSelectedZonesUIds] = useState<string[]>([]);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbarState();
  const { setValue, watch } = useFormContext();
  const { default: map } = useMap();

  const evacZoneStatuses = watch('evacZoneStatuses') as FormEvacZoneStatus[];

  const handleZonesChange = (
    event: ChangeEvent<HTMLSelectElement> | { zoneUId: string }
  ): void => {
    if ('zoneUId' in event) {
      setZoneUIds((prev) => getUpdatedUIds(prev, event.zoneUId));
      return;
    }
    const { options } = event.target;
    const value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setZoneUIds(value);
  };

  const handleSelectedZonesChange = (
    event: ChangeEvent<HTMLSelectElement> | { zoneUId: string }
  ): void => {
    if ('zoneUId' in event) {
      setSelectedZonesUIds((prev) => getUpdatedUIds(prev, event.zoneUId));
      return;
    }
    const { options } = event.target;
    const value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSelectedZonesUIds(value);
  };

  const updateZonesOnMap = (
    zones: FormEvacZoneStatus[],
    remove?: boolean
  ): void => {
    if (!map) return;

    zones.forEach(({ evacZone, status }) => {
      const zoneStyle = evacZone.regionEvacZoneStyle || EvacZoneStyles.default;
      const zoneStatus = remove ? undefined : status;
      const color = zoneStatus
        ? EVAC_ZONE_LAYER_COLORS[zoneStyle][zoneStatus]
        : undefined;
      const pattern = zoneStatus
        ? EVAC_ZONE_FILL_PATTERNS[zoneStyle][zoneStatus]
        : undefined;
      map.setFeatureState(
        { id: evacZone.uid, source: SOURCE_ID, sourceLayer: SOURCE_LAYER },
        { status: zoneStatus, color, pattern }
      );
    });
  };

  const handleAdd = (): void => {
    const updatedZones = availableZones.reduce((zones, zone) => {
      if (zoneUIds.includes(zone.uid)) {
        zones.push({
          evacZone: { ...zone, regionEvacZoneStyle: zone.region.evacZoneStyle },
          status: evacZoneStatus
        });
      }
      return zones;
    }, [] as FormEvacZoneStatus[]);

    setZoneUIds([]);
    setValue('evacZoneStatuses', [...evacZoneStatuses, ...updatedZones]);
    updateZonesOnMap(updatedZones);
  };

  const handleUpdate = (action: 'downgrade' | 'upgrade'): void => {
    if (selectedZoneUIds.length === 0) return;

    const updatedZones = selectedZones.reduce((zones, zone) => {
      if (selectedZoneUIds.includes(zone.evacZone.uid)) {
        const updatedZone = {
          ...zone,
          status: getZoneUpdatedStatus(action, zone.status)
        };
        if (!zone.prevStatus) {
          updatedZone.prevStatus = zone.status;
        }
        zones.push(updatedZone);
      }
      return zones;
    }, [] as FormEvacZoneStatus[]);

    setSelectedZonesUIds([]);
    setValue('evacZoneStatuses', [
      ...evacZoneStatuses.filter(
        (zone) => !selectedZoneUIds.includes(zone.evacZone.uid)
      ),
      ...updatedZones
    ]);
    updateZonesOnMap(updatedZones);
  };

  const handleRemove = (): void => {
    const { updatedZones, removedZones } = evacZoneStatuses.reduce(
      (zones, zone) => {
        if (selectedZoneUIds.includes(zone.evacZone.uid)) {
          zones.removedZones.push(zone);
        } else {
          zones.updatedZones.push(zone);
        }
        return zones;
      },
      {
        updatedZones: [] as FormEvacZoneStatus[],
        removedZones: [] as FormEvacZoneStatus[]
      }
    );

    setSelectedZonesUIds([]);
    setValue('evacZoneStatuses', updatedZones);
    updateZonesOnMap(removedZones, true);
  };

  const handleCopyToClipboard = (): void => {
    const text = selectedZones
      .map((zone) => zone.evacZone.displayName)
      .join('\n');
    navigator.clipboard.writeText(text);
    setSnackbar(t('geoEventEvacuations.evacZones.copySuccess'), 'success');
  };

  return (
    <div className={classes.container}>
      <div className={classes.selectSection}>
        <Typography variant="subtitle1" color="textSecondary">
          {t('geoEventEvacuations.evacZones.available')}
        </Typography>
        <MultiSelectField
          id="select-multiple-zones"
          value={zoneUIds}
          options={availableZones}
          onChange={handleZonesChange}
          selectClassName={classes.available}
        />
      </div>

      <div className={classes.actions}>
        {upgradeable && (
          <GrayButton
            className={classes.button}
            startIcon={<ArrowUpwardIcon />}
            onClick={() => handleUpdate('upgrade')}
          >
            {t('geoEventEvacuations.actions.up')}
          </GrayButton>
        )}
        <GrayButton
          className={classes.button}
          startIcon={<ArrowForwardIcon />}
          onClick={handleAdd}
        >
          {t('geoEventEvacuations.actions.add')}
        </GrayButton>
        <GrayButton
          className={classes.button}
          startIcon={<ArrowBackIcon />}
          onClick={handleRemove}
        >
          {t('geoEventEvacuations.actions.remove')}
        </GrayButton>
        {downgradeable && (
          <GrayButton
            className={classes.button}
            startIcon={<ArrowDownwardIcon />}
            onClick={() => handleUpdate('downgrade')}
          >
            {t('geoEventEvacuations.actions.down')}
          </GrayButton>
        )}
      </div>

      <div className={classes.selectSection}>
        <Typography variant="subtitle1" color="textSecondary">
          {t('geoEventEvacuations.evacZones.selected', {
            count: selectedZones.length
          })}
        </Typography>
        <MultiSelectField
          id="select-multiple-selected-zones"
          value={selectedZoneUIds}
          options={selectedZones}
          onChange={handleSelectedZonesChange}
        />
        {!Capacitor.isNativePlatform() && (
          <Button
            variant="text"
            onClick={handleCopyToClipboard}
            disabled={selectedZones.length === 0}
            className={classes.textButton}
            disableTouchRipple
          >
            {t('geoEventEvacuations.evacZones.copy')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default TwoSideMultiSelect;
