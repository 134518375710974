import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TurnOnNotificationsIcon from 'assets/turn_on_notifications.svg?react';
import OnboardingLayout from 'components/OnboardingLayout';
import { useCacheState } from 'state';
import initPushNotifications from 'shared/push';
import { requestGeolocationPermissionsForNative } from 'hooks/usePosition';

const useStyles = makeStyles()((theme) => ({
  image: {
    width: '90vmin',
    height: 'auto',
    maxWidth: 345
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16
  }
}));

const PermissionsContent = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { setCacheState } = useCacheState();

  const handleContinue = async (): Promise<void> => {
    await requestGeolocationPermissionsForNative();
    await initPushNotifications(history, setCacheState);
    history.push('onboarding_notifications');
  };

  return (
    <OnboardingLayout
      title={t('permissions.title')}
      footerText={
        <Trans i18nKey="permissions.footer">
          Select <b>Allow While Using the App</b> then <b>Allow</b>.
        </Trans>
      }
      buttonTitle={t('permissions.button')}
      onClick={handleContinue}
    >
      <Grid item xs={12}>
        <Typography>{t('permissions.description')}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.iconContainer}>
          <TurnOnNotificationsIcon className={classes.image} />
        </Box>
      </Grid>
    </OnboardingLayout>
  );
};

export default PermissionsContent;
