import { ReactNode } from 'react';
import { Box, Container, Grid, Typography, Button } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

type OnboardingLayoutProps = {
  title: string;
  children: ReactNode;
  footerText?: string | ReactNode;
  buttonTitle?: string;
  onClick?: () => void;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundImage: `url("/images/app-background.png")`,
    backgroundSize: '100% 100%',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  container: {
    paddingLeft: `env(safe-area-inset-left)`,
    paddingRight: `env(safe-area-inset-right)`,
    paddingTop: `env(safe-area-inset-top)`,
    paddingBottom: `env(safe-area-inset-bottom)`,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  contentContainer: {
    padding: theme.spacing(0, 2)
  },
  footerContainer: {
    padding: theme.spacing(2)
  },
  footerText: {
    color: theme.palette.common.white
  },
  button: {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium
  }
}));

const OnboardingLayout = (props: OnboardingLayoutProps): JSX.Element => {
  const { title, children, footerText, buttonTitle, onClick } = props;
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={2} className={classes.contentContainer}>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              align="center"
              data-testid="onboarding-title"
            >
              {title}
            </Typography>
          </Grid>

          {children}
        </Grid>

        <Grid container spacing={2} className={classes.footerContainer}>
          {!!footerText && (
            <Grid item xs={12}>
              <Typography className={classes.footerText} component="div">
                {footerText}
              </Typography>
            </Grid>
          )}

          {!!buttonTitle && (
            <Grid item xs={12}>
              <Button
                fullWidth
                className={classes.button}
                onClick={onClick}
                data-testid="onboarding-button"
              >
                {buttonTitle}
              </Button>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default OnboardingLayout;
