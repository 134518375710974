import { Typography } from '@mui/material';

type GeoEventContainmentProps = {
  isFps: boolean;
  containment: number | null;
};

const GeoEventContainment = (props: GeoEventContainmentProps): JSX.Element => {
  const { containment, isFps } = props;

  if (isFps) {
    <Typography align="center">-</Typography>;
  }

  if (typeof containment === 'number') {
    return <Typography align="center">{`${containment}%`}</Typography>;
  }

  return <Typography align="center">-</Typography>;
};

export default GeoEventContainment;
