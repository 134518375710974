import { useCallback } from 'react';
import { Layer, MapGeoJSONFeature, useMap } from 'react-map-gl/maplibre';
import { EvacZonePoi, Poi } from 'shared/types';
import { usePoisState } from 'state/usePoisState';
import VisibilityToggledSource from '../VisibilityToggledSource';
import { INACTIVE_ZONES_LAYER_MIN_ZOOM, SOURCE_ID } from './constants';
import {
  getCountyNameFromSlug,
  getSelectedEvacZoneUId,
  getTilesUrl
} from './utils';
import {
  getFillStyleAll,
  getLabelLayerStyleAll,
  getSelectedZonePatternStyle,
  getSelectedZoneStrokeStyle,
  getStrokeStyleAll
} from './layerStyles';
import { BEFORE_LAYER_ID } from '../../styles/constants';
import { useMapLayerEvents } from '../useMapLayerEvents';
import { EvacZoneDialogContent } from '../../EvacZoneDialogContent';
import { EvacZoneStyles } from '../../../../constants';

type StructuredEvacuationsViewLayerProps = {
  visible: boolean;
};

const VIEW_SOURCE_ID = `${SOURCE_ID}_ALL`;
const FILL_LAYER_ID = 'fill_layer_all';

const handleClickEvent = (
  features: MapGeoJSONFeature[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  map: any,
  setSelectedPoi: (poi: Poi) => void
): void => {
  const feature = features[0];

  // we don't render the fills/strokes via opacity above a certain zoom level for inactive zones
  if (map && map.getZoom() < INACTIVE_ZONES_LAYER_MIN_ZOOM) {
    return;
  }

  const poiDialogData: EvacZonePoi = {
    uid: feature.properties.zone_slug,
    displayName: feature.properties.zone_name,
    county: getCountyNameFromSlug(feature.properties.county_slug),
    status: feature.state.status,
    source: feature.properties.source_type,
    style: EvacZoneStyles.default
  };
  setSelectedPoi({
    type: 'evacZone',
    id: feature.id,
    PoiDialogContent: () => <EvacZoneDialogContent evacZone={poiDialogData} />
  });
};

/*
 * This layer is designed to show all pre-planned zones available with no filtering directly from the tile feature
 * properties with no affiliation to our backend evac zone data and/or statuses.
 */
const StructuredEvacuationsAllLayer = (
  props: StructuredEvacuationsViewLayerProps
): JSX.Element => {
  const { visible } = props;
  const { current: map } = useMap();
  const { selectedPoi, setSelectedPoi } = usePoisState();

  const clickCallback = useCallback(
    (features: MapGeoJSONFeature[]) =>
      handleClickEvent(features, map, setSelectedPoi),
    [map, setSelectedPoi]
  );

  // Not clear why - but the all view is receiving click events before the layers that are rendered above it.
  //   so we need to allow the event propagation to occur on the click.
  useMapLayerEvents({
    layerId: FILL_LAYER_ID,
    onClick: clickCallback
  });

  const selectedEvacZoneUId = getSelectedEvacZoneUId(selectedPoi);

  return (
    <VisibilityToggledSource
      url={getTilesUrl()}
      visible={visible}
      promoteId="zone_slug"
      // Do not unset `volatile` ever for this layer.
      volatile
      id={VIEW_SOURCE_ID}
    >
      <Layer id={FILL_LAYER_ID} {...getFillStyleAll()} />
      <Layer id="stroke_layer_all" {...getStrokeStyleAll()} />

      <Layer
        id="selected_pattern_all"
        beforeId={BEFORE_LAYER_ID}
        {...getSelectedZonePatternStyle(selectedEvacZoneUId)}
      />
      <Layer
        id="selected_stroke_all"
        beforeId={BEFORE_LAYER_ID}
        {...getSelectedZoneStrokeStyle(selectedEvacZoneUId)}
      />
      <Layer id="zones_label_all" {...getLabelLayerStyleAll()} />
    </VisibilityToggledSource>
  );
};

export default StructuredEvacuationsAllLayer;
