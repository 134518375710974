import Map from 'components/Map';
import { Location, LatLng } from 'shared/types';
import { getTimePass } from 'shared/utils';

export const getDefaultMapCenter = (
  searchParams: URLSearchParams,
  location?: Location
): LatLng => {
  if (location?.id) {
    return { lat: location.lat, lng: location.lng };
  }

  const latParam = parseFloat(searchParams.get('lat') ?? '0');
  const lngParam = parseFloat(searchParams.get('lng') ?? '0');

  if (latParam && lngParam) {
    return { lat: latParam, lng: lngParam };
  }

  return Map.DefaultConfig.center;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRedundancyMessage = (errorResponse: any): string => {
  const { data: errorData } = errorResponse;

  if (errorData.length === 1) {
    const recentGeoEvent = errorData[0];
    const creator = recentGeoEvent.userCreated?.username || 'Someone';
    const timeSince = getTimePass(recentGeoEvent.dateCreated);

    return `${creator} just posted ${timeSince}: \n"${recentGeoEvent.name}"\n\nAre you sure you still want to post this incident?`;
  }

  return `${errorData.length} incidents have been created in the past few minutes for this county!\n\nAre you sure you still want to post this incident?`;
};
