import { Theme, Button } from '@mui/material';

import { withStyles } from 'tss-react/mui';

const BlackButton = withStyles(Button, (theme: Theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.text.primary
    }
  }
})) as typeof Button;

export default BlackButton;
