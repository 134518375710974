import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Stack,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Trans, useTranslation } from 'react-i18next';
import { EvacZonePoi } from 'shared/types';
import {
  getDateFormatted,
  getResponsiveFontSize,
  getTimePass
} from 'shared/utils';
import {
  EVAC_ZONE_LABEL_TRANS_KEYS,
  EVAC_ZONE_LAYER_COLORS,
  EvacZoneStyles
} from '../../constants';

type EvacZoneDialogContentProps = {
  evacZone: EvacZonePoi;
};

const useStyles = makeStyles()((theme) => ({
  title: {
    padding: theme.spacing(2, 6, 0, 2),
    lineHeight: '1.2 !important',
    display: 'flex',
    flexDirection: 'column'
  },
  zoneTitle: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: getResponsiveFontSize('0.625rem')
  },
  content: {
    padding: theme.spacing(0, 2, 1)
  },
  info: {
    padding: theme.spacing(0, 2, 1.5)
  },
  icon: {
    width: 12,
    height: 12,
    borderRadius: 12
  }
}));

export const EvacZoneDialogContent = (
  props: EvacZoneDialogContentProps
): JSX.Element => {
  const { evacZone } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const isActiveZone: boolean = !!evacZone.status;
  const evacZoneStyle = evacZone.style || EvacZoneStyles.default;

  return (
    <>
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <Typography component="span" variant="body2" fontWeight="bold">
          {t('map.poi.evacZone.title', { displayName: evacZone.displayName })}
        </Typography>
      </DialogTitle>

      {evacZone.status && (
        <DialogContent className={classes.content}>
          <Stack direction="row" spacing={1} alignItems="center">
            <div
              className={classes.icon}
              style={{
                backgroundColor:
                  EVAC_ZONE_LAYER_COLORS[evacZoneStyle][evacZone.status]
              }}
            />

            <DialogContentText
              id="alert-dialog-description"
              color="textPrimary"
              variant="body2"
              fontWeight="bold"
            >
              {t(EVAC_ZONE_LABEL_TRANS_KEYS[evacZoneStyle][evacZone.status])}
            </DialogContentText>
          </Stack>
        </DialogContent>
      )}

      <DialogContent className={classes.info}>
        {evacZone.dateModified && (
          <Typography variant="body2">
            <Trans
              i18nKey="map.poi.evacZone.updated"
              values={{
                timeAgo: evacZone.dateModified
                  ? getTimePass(evacZone.dateModified)
                  : '-'
              }}
            >
              Updated
              <b>
                timeAgo: evacZone.dateModified ?
                getTimePass(evacZone.dateModified) : &ndash;
              </b>
            </Trans>
          </Typography>
        )}

        {evacZone.source === 'arcgis' && isActiveZone && (
          <Typography variant="body2" color="secondary">
            <Trans
              i18nKey="map.poi.evacZone.county"
              values={{
                county: evacZone.county ?? '-',
                timeAgo: evacZone.dateCreated
                  ? getTimePass(evacZone.dateCreated)
                  : '-',
                formattedDate: evacZone.dateCreated
                  ? getDateFormatted(evacZone.dateCreated)
                  : '-'
              }}
            >
              <b>El Dorado County</b> • 5 days ago • Aug 4, 12:54 PM
            </Trans>
          </Typography>
        )}

        {evacZone.source === 'zonehaven' && isActiveZone && (
          <Typography variant="body2" color="secondary">
            <Trans
              i18nKey="map.poi.evacZone.genasys"
              values={{
                timeAgo: evacZone.dateCreated
                  ? getTimePass(evacZone.dateCreated)
                  : '-',
                formattedDate: evacZone.dateCreated
                  ? getDateFormatted(evacZone.dateCreated)
                  : '-'
              }}
            >
              <Link
                href="https://protect.genasys.com/"
                target="_blank"
                rel="nofollow"
                color="inherit"
                underline="none"
                fontWeight="bold"
                sx={{ pointerEvents: 'auto' }}
              >
                Genasys Protect
              </Link>{' '}
              • 5 days ago • Aug 4, 12:54 PM
            </Trans>
          </Typography>
        )}

        {!isActiveZone && (
          <Typography variant="body2" color="secondary">
            {evacZone.source === 'zonehaven' ? (
              <Trans>
                <Link
                  href="https://protect.genasys.com/"
                  target="_blank"
                  rel="nofollow"
                  color="inherit"
                  underline="none"
                  fontWeight="bold"
                  sx={{ pointerEvents: 'auto' }}
                >
                  Genasys Protect
                </Link>
              </Trans>
            ) : (
              <b>
                {evacZone.county}{' '}
                {evacZone.county?.toLocaleLowerCase().includes('county')
                  ? ''
                  : t('map.poi.evacZone.countyTitle')}
              </b>
            )}
          </Typography>
        )}
      </DialogContent>
    </>
  );
};
