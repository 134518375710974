import { useCallback, useMemo } from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl/maplibre';
import { useHistory } from 'react-router-dom';
import { LocationListItem } from 'shared/types';
import { FeatureCollection, Feature, Geometry } from 'geojson';
import { usePoisState } from 'state/usePoisState';
import useMapLayersState from 'state/useMapLayersState';
import { getLocationIcon } from '../Icons';
import {
  FONT_NAMES,
  LOCATIONS_SOURCE_ID,
  LOCATION_ICONS_LAYER_ID
} from '../styles/constants';
import { useMapLayerEvents } from './useMapLayerEvents';

type LocationsLayerProps = {
  locations: LocationListItem[];
  selectedGeoEventId?: number;
};

type LocationFeatureProperties = {
  id: number;
  name: string;
  icon: string;
  isActive: boolean;
  scale: number;
  offset?: number[];
  opacity: number;
  index: number;
  indexInv: number;
};

const LABELS_LAYER_ID = 'location-labels';
const ICONS_ZOOM = 8; // ~10 miles

const getIconStyle = (selectedGeoEventId: number): LayerProps => ({
  type: 'symbol',
  layout: {
    'icon-image': ['get', 'icon'],
    'icon-allow-overlap': true,
    'icon-anchor': 'bottom',
    'icon-ignore-placement': false,
    'icon-size': ['get', 'scale'],
    'icon-offset': ['get', 'offset'],
    // For some reason, the icons need to be sorted by 'index',
    // but the labels by 'indexInv', in order to be sorted correctly
    // in both cases.
    'symbol-sort-key': ['get', 'index'],
    'symbol-z-order': 'source'
  },
  paint: {
    'icon-opacity': [
      'step',
      ['zoom'],
      [
        // Default opacity when zoom is less than ICONS_ZOOM
        'case',
        ['==', ['get', 'id'], selectedGeoEventId],
        1, // Opacity when 'id' is equal to 'selectedGeoEventId'
        0 // Opacity when 'id' is not equal to 'selectedGeoEventId'
      ],
      ICONS_ZOOM, // Zoom level at which opacity changes
      ['get', 'opacity'] // Opacity when zoom is greater than or equal to ICONS_ZOOM
    ]
  }
});

const labelStyleCommon: LayerProps = {
  type: 'symbol',
  layout: {
    'text-font': FONT_NAMES.regular,
    'text-field': [
      'format',
      ['get', 'name'],
      { 'text-font': ['literal', FONT_NAMES.bold] }
    ],
    'text-anchor': 'top',
    'text-justify': 'auto',
    'symbol-sort-key': ['get', 'indexInv'],
    'symbol-z-order': 'source'
  },
  paint: {
    'text-halo-width': 1.8
  }
};

const labelStyleLight: LayerProps = {
  ...labelStyleCommon,
  paint: {
    ...labelStyleCommon.paint,
    'text-color': '#333333',
    'text-halo-color': 'rgba(255,255,255,0.8)'
  }
};

const labelStyleDark: LayerProps = {
  ...labelStyleCommon,
  paint: {
    ...labelStyleCommon.paint,
    'text-color': '#ffffff',
    'text-halo-color': 'rgba(0,0,0,0.8)'
  }
};

const getLabelStyle = (
  lightBaseLayer: boolean,
  selectedGeoEventId: number
): LayerProps => {
  const { layout, ...style } = lightBaseLayer
    ? labelStyleLight
    : labelStyleDark;
  return {
    ...style,
    layout: {
      ...layout,
      'symbol-sort-key': [
        // ensure it is always on top if it is selected
        'case',
        ['==', ['get', 'id'], selectedGeoEventId],
        -99999,
        ['get', 'indexInv']
      ]
    },
    paint: {
      ...style.paint,
      'text-opacity': [
        'step',
        ['zoom'],
        [
          // Default opacity when zoom is less than ICONS_ZOOM
          'case',
          ['==', ['get', 'id'], selectedGeoEventId],
          1, // Opacity when 'id' is equal to 'selectedGeoEventId'
          0 // Opacity when 'id' is not equal to 'selectedGeoEventId'
        ],
        ICONS_ZOOM, // Zoom level at which opacity changes
        1 // Opacity when zoom is greater than or equal to ICONS_ZOOM
      ]
    }
  };
};

const toGeojsonFeature = (
  location: LocationListItem
): Feature<Geometry, LocationFeatureProperties> => {
  const {
    id,
    isActive,
    name,
    lat,
    lng,
    data: { locationType },
    dateCreated
  } = location;
  const icon = getLocationIcon(locationType);
  const scale = 1;
  const opacity = isActive ? 1 : 0;

  // Sort indexes for labels and text. Inactive and Rx fires on the bottom,
  // then oldest to newest.
  const index = isActive ? Date.parse(dateCreated) : 0;
  const indexInv = 1 / (index + 1);

  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [lng, lat]
    },
    properties: {
      id,
      name,
      icon: icon.name,
      isActive,
      scale,
      offset: [0, 0],
      opacity,
      index,
      indexInv
    }
  };
};

const LocationsLayer = (props: LocationsLayerProps): JSX.Element => {
  const { locations, selectedGeoEventId = 0 } = props;
  const history = useHistory();
  const { clearSelectedPois } = usePoisState();
  const { isLightBaseLayer } = useMapLayersState();

  useMapLayerEvents<LocationFeatureProperties>({
    layerId: LOCATION_ICONS_LAYER_ID,
    onClick: useCallback(
      (geoJsonFeatures) => {
        const feature = geoJsonFeatures[0];
        const { id, opacity } = feature.properties;
        if (opacity > 0) {
          // Close any selected POI dialog modal
          // Short setTimeout() is necessary to wait for POI array to fill (see usePoisState())
          setTimeout(() => clearSelectedPois(), 10);
          history.push(`/location/${id}`);
        }
      },
      [history, clearSelectedPois]
    )
  });

  const geojson: FeatureCollection<Geometry, LocationFeatureProperties> =
    useMemo(
      () => ({
        type: 'FeatureCollection',
        features: locations
          .map((geoEvent) => toGeojsonFeature(geoEvent))
          .filter((feature) => feature.properties.opacity > 0)
      }),
      [locations]
    );

  return (
    <Source id={LOCATIONS_SOURCE_ID} type="geojson" data={geojson}>
      <Layer
        id={LOCATION_ICONS_LAYER_ID}
        {...getIconStyle(selectedGeoEventId)}
      />
      <Layer
        id={LABELS_LAYER_ID}
        {...getLabelStyle(isLightBaseLayer, selectedGeoEventId)}
      />
    </Source>
  );
};

export default LocationsLayer;
