import { useCallback } from 'react';
import {
  atomFamily,
  Resetter,
  useRecoilState,
  useResetRecoilState
} from 'recoil';
import { localStorageEffect } from './localStorage';

type ScrollPositionState = {
  scrollPosition: number;
  scrollHeight: number;
};

type UseScrollPositionState = ScrollPositionState & {
  setScrollPosition: (scrollPosition: number) => void;
  setScrollHeight: (scrollHeight: number) => void;
  reset: Resetter;
};

const DEFAULT_SCROLL_POSITION_STATE: ScrollPositionState = {
  scrollPosition: 0,
  scrollHeight: 0
};

const scrollPositionStateAtom = atomFamily({
  key: 'SCROLL_POSITION_STATE',
  default: DEFAULT_SCROLL_POSITION_STATE,
  effects: [localStorageEffect('SCROLL_STATE')]
});

const useScrollPositionState = (
  pageId = 'GLOBAL_PAGE_ID'
): UseScrollPositionState => {
  const resetScrollPositionState = useResetRecoilState(
    scrollPositionStateAtom(pageId)
  );
  const [scrollPositionState, setScrollPositionState] = useRecoilState(
    scrollPositionStateAtom(pageId)
  );

  const updateScrollPositionState = useCallback(
    (obj: Partial<ScrollPositionState>): void =>
      setScrollPositionState((prev) => ({ ...prev, ...obj })),
    [setScrollPositionState]
  );

  const setScrollPosition = useCallback(
    (scrollPosition: number): void =>
      updateScrollPositionState({ scrollPosition }),
    [updateScrollPositionState]
  );

  const setScrollHeight = useCallback(
    (scrollHeight: number): void => updateScrollPositionState({ scrollHeight }),
    [updateScrollPositionState]
  );

  return {
    scrollHeight: scrollPositionState.scrollHeight,
    scrollPosition: scrollPositionState.scrollPosition,
    setScrollPosition,
    setScrollHeight,
    reset: resetScrollPositionState
  };
};

export default useScrollPositionState;
