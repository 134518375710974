import { LayerProps } from 'react-map-gl/maplibre';
import { DataDrivenPropertyValueSpecification } from 'maplibre-gl';
import {
  ACTIVE_LINE_COLOR,
  ELECTRICAL_COLOR,
  MIN_ZOOM_VISIBILITY,
  GAS_PIPILINE_COLOR,
  ELECTRICAL_SOURCE_DATA_LAYER,
  GAS_SOURCE_DATA_LAYER
} from './constants';

type StyleFunctionProps = {
  selectedId: number | string;
  sourceLayer: string;
};

const lineWidth: DataDrivenPropertyValueSpecification<number> = [
  'interpolate',
  ['linear'],
  ['zoom'],
  10,
  4,
  18,
  12
];

const lineCasingWidth: DataDrivenPropertyValueSpecification<number> = [
  'interpolate',
  ['linear'],
  ['zoom'],
  10,
  2,
  18,
  6
];

export const layerStyleLineCasing: LayerProps = {
  type: 'line',
  minzoom: MIN_ZOOM_VISIBILITY,
  paint: {
    'line-color': '#ffffff',
    'line-gap-width': lineWidth,
    'line-width': lineCasingWidth
  }
};

export const electricalLayerStyle: LayerProps = {
  type: 'line',
  minzoom: MIN_ZOOM_VISIBILITY,
  'source-layer': ELECTRICAL_SOURCE_DATA_LAYER,
  paint: {
    'line-color': ELECTRICAL_COLOR,
    'line-width': lineWidth
  }
};

export const gasLayerStyle: LayerProps = {
  type: 'line',
  minzoom: MIN_ZOOM_VISIBILITY,
  'source-layer': GAS_SOURCE_DATA_LAYER,
  paint: {
    'line-color': GAS_PIPILINE_COLOR,
    'line-width': lineWidth
  }
};

export const getSelectedStrokeStyle = (
  props: StyleFunctionProps
): LayerProps => {
  const { selectedId, sourceLayer } = props;
  return {
    type: 'line',
    'source-layer': sourceLayer,
    paint: {
      'line-color': ACTIVE_LINE_COLOR,
      'line-width': lineWidth
    },
    filter: [
      'any',
      ['==', ['get', 'ID'], selectedId],
      ['==', ['get', 'FID'], selectedId]
    ]
  };
};

export const getInteractiveLayerStyle = (sourceLayer: string): LayerProps => ({
  type: 'line',
  minzoom: MIN_ZOOM_VISIBILITY,
  'source-layer': sourceLayer,
  paint: {
    'line-width': ['interpolate', ['linear'], ['zoom'], 10, 12, 18, 36],
    'line-opacity': 0
  }
});
