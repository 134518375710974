import { RouteComponentProps } from 'react-router-dom';
import PageLayout from 'components/PageLayout';
import HeaderBar from 'components/HeaderBar';
import { CameraFullscreenDetails } from './CameraFullscreenDetails';

export const FullscreenCamera = (
  props: RouteComponentProps<{ cameraId: string }>
): JSX.Element => {
  const {
    match: {
      params: { cameraId }
    }
  } = props;

  return (
    <PageLayout data-cname="FullscreenCameraPage">
      {{
        content: <CameraFullscreenDetails />,
        headerBar: (
          <HeaderBar
            action={`/camera/${cameraId}`}
            hideAction
            showSupportUs
            dark
          />
        )
      }}
    </PageLayout>
  );
};
