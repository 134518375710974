import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useCacheState } from 'state';
import { AxiosResponse } from 'axios';
import { API, CacheAPI } from 'api';
import { useSelectedWildfireGeoEventId } from './useSelectedWildfireGeoEventId';
import { useSelectedLocationGeoEventId } from './useSelectedLocationGeoEventId';

type UseGeoEventQuery<T> = Omit<
  UseQueryResult<AxiosResponse<T> | undefined>,
  'data'
> & {
  geoEvent?: T;
};

const fetchGeoEvent = <T>(
  geoEventId: number,
  cacheBusterTs: number | null
): Promise<AxiosResponse<T>> => {
  if (cacheBusterTs) {
    return CacheAPI.get<T>(`geo_events/${geoEventId}?ts=${cacheBusterTs}`);
  }
  return API.get<T>(`geo_events/${geoEventId}`);
};

const useGeoEventQuery = <T>(): UseGeoEventQuery<T> => {
  const selectedGeoEventId = useSelectedWildfireGeoEventId();
  const selectedLocationId = useSelectedLocationGeoEventId();
  const id = selectedGeoEventId || selectedLocationId;

  const { cacheBusterTs } = useCacheState();

  const queryResult = useQuery({
    queryKey: ['geoEvent', id, cacheBusterTs],
    queryFn: () => (id ? fetchGeoEvent<T>(id, cacheBusterTs) : undefined),
    enabled: Boolean(id),
    staleTime: 1000 // allow fetching the same geo event from various places on the page without re-fetching
  });

  return {
    ...queryResult,
    geoEvent: queryResult.data?.data
  };
};

export default useGeoEventQuery;
