import { MouseEvent } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { Browser } from '@capacitor/browser';
import CameraPlayer from 'components/CameraDetails/CameraPlayer';
import {
  AlertCamera,
  useAlertCamera,
  useAlertCameras
} from 'hooks/useAlertCameras';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import Map from 'components/Map';
import { AllMapLayers } from 'components/Map/constants';
import { AlertCamerasLayer } from 'components/Map/layers/AlertCamerasLayer';
import useGeoEvents from 'hooks/useGeoEvents';
import GeoEventsLayer from 'components/Map/layers/GeoEventsLayer';
import {
  getCameraAttributionImage,
  getRelatedCameras,
  isCameraOffline,
  isTimelapseSupported
} from 'components/CameraDetails/CameraDetails.utils';
import { OfflineMessage } from 'components/CameraDetails/OfflineMessage';
import { CameraPreview } from 'components/CameraDetails/CameraPreview';

type CameraProps = {
  camera: AlertCamera;
};

const attributionToLogoUrl = {
  UCSD: '/images/camera-providers/alertca-white.png',
  ALERTWEST: '/images/camera-providers/alertwest-white.png',
  ALERTWILDFIRE: '/images/camera-providers/alertwildfire-white.png'
};

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    minHeight: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100vw',
    padding: theme.spacing(2),
    paddingBottom: 0
  },
  map: {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    width: '100%',
    height: 282
  }
}));

const Camera = (props: CameraProps): JSX.Element => {
  const { camera } = props;

  const cameraOffline = isCameraOffline(camera.imageTimestamp);

  if (cameraOffline) {
    return <OfflineMessage camera={camera} />;
  }

  if (isTimelapseSupported(camera)) {
    return (
      <CameraPlayer
        // key prop needed to force component to remount when cameraId
        // changes so the component's local state is reset; useful when
        // switching between related cameras
        key={camera.id}
        cameraId={camera.id}
        previewUrl={camera.imageUrl}
        previewDate={camera.imageTimestamp}
        fullScreen
        isPtz={camera.hasPtz}
      />
    );
  }

  return <CameraPreview url={camera.imageUrl} />;
};

export const CameraFullscreenDetails = (): JSX.Element => {
  const { cameraId } = useParams<{ cameraId: string }>();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { alertCamera, isLoading, error } = useAlertCamera({
    id: cameraId,
    refetchInterval: 1000 * 15
  });
  const {
    alertCameras: allCameras,
    isLoading: isLoadingAll,
    error: allErrors
  } = useAlertCameras({ enabled: true });
  const { wildfireEvents } = useGeoEvents();

  const relatedCameras =
    alertCamera && allCameras?.length
      ? getRelatedCameras(alertCamera, allCameras, t)
      : [];

  const attributionImage = alertCamera
    ? getCameraAttributionImage(alertCamera, attributionToLogoUrl)
    : '';

  const handleClickAttribution = async (
    event: MouseEvent<HTMLAnchorElement>
  ): Promise<void> => {
    event.preventDefault();

    if (!alertCamera) return;

    await Browser.open({
      url: alertCamera.cameraUrl,
      presentationStyle: 'popover'
    });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {alertCamera && <Camera camera={alertCamera} />}
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container spacing={1} sx={{ height: '100%' }}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h3" fontWeight="bold">
                    {alertCamera?.name}
                  </Typography>
                </Grid>

                {alertCamera && (
                  <Grid item xs={12}>
                    <div className={classes.map}>
                      <Map
                        center={alertCamera.latlng}
                        zoom={10}
                        noControls
                        disableMapLayers={AllMapLayers}
                      >
                        <AlertCamerasLayer visible interactive={false} />
                        <GeoEventsLayer
                          geoEvents={wildfireEvents}
                          isFadable
                          interactive={false}
                        />
                      </Map>
                    </div>
                  </Grid>
                )}

                {alertCamera && relatedCameras.length > 1 && (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        id="additional-cameras-header"
                        variant="body1"
                        component="h4"
                        fontWeight="bold"
                      >
                        {t('alertCameras.additionalCameras')}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <ToggleButtonGroup
                        value={alertCamera.id}
                        variant="watchduty-gap"
                        aria-labelledby="additional-cameras-header"
                      >
                        {relatedCameras?.map((camera: AlertCamera) => (
                          <ToggleButton
                            color="primary"
                            key={camera.id}
                            value={camera.id}
                            size="large"
                            component={Link}
                            to={`/camera/${camera.id}/fullscreen`}
                            sx={{ minWidth: 48 }}
                          >
                            {camera.name}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            {alertCamera && attributionImage && (
              <Grid item xs={12} alignSelf="flex-end">
                <Stack spacing={2}>
                  <Typography
                    variant="subtitle1"
                    color="white"
                    textAlign="center"
                    textTransform="uppercase"
                    fontWeight="bold"
                  >
                    {t('alertCameras.operatedBy')}
                  </Typography>
                  <a
                    href={alertCamera.cameraUrl}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textAlign: 'center' }}
                    onClick={handleClickAttribution}
                  >
                    <img
                      src={attributionImage}
                      alt={alertCamera.attribution}
                      style={{ maxWidth: 260 }}
                    />
                  </a>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {(isLoading || isLoadingAll || error || allErrors) && (
        <LoadingAndErrors
          isLoading={isLoading || isLoadingAll}
          progressColor="primary"
        />
      )}
    </div>
  );
};
