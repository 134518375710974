import useInitUserOnboard from 'hooks/useInitUserOnboard';
import useFirebaseAnalytics from 'shared/analytics';
import { useEffect } from 'react';
import { SplashScreen } from '@capacitor/splash-screen';
import useInboxMessages from 'hooks/useInboxMessages';
import useAppInfo from 'hooks/useAppInfo';
import useOnboardingState from 'state/useOnboardingState';
import { API } from 'api';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useAuthState } from 'state';
import OnboardingWalkMe from 'components/OnboardingWalkMe';
import { useLocation } from 'react-router-dom';
import useSelectedLocationState from 'state/useSelectedLocationState';
import { useIapInitializer } from 'hooks/useIapInitializer';
import { UserPlanType } from 'shared/types';
import Routes from './Routes';

type MembershipStatusData = {
  hasActiveMembership: boolean;
  isDonor: boolean;
  planType: UserPlanType;
};

const getMembershipStatus = (): Promise<AxiosResponse<MembershipStatusData>> =>
  API.get<MembershipStatusData>('/recurly_integration/supporter_status/');

const Navigation = (): JSX.Element => {
  const { onboardCompleted } = useOnboardingState();
  useFirebaseAnalytics();
  useIapInitializer();
  useInitUserOnboard();
  useAppInfo(true);
  useInboxMessages();
  const { isAuthenticated, updateAuth } = useAuthState();
  const location = useLocation();
  const { setSelectedLocation } = useSelectedLocationState();

  useEffect(() => {
    // If we've completed the onboarding once - we can immediately hide the splash screen
    // otherwise, let the the logic in useInitUserOnboard handle it
    const hideSplashScreen = async (): Promise<void> => {
      await SplashScreen.hide();
    };
    if (onboardCompleted) {
      hideSplashScreen();
    }
  }, [onboardCompleted]);

  const { data } = useQuery({
    queryKey: ['membership-status'],
    queryFn: getMembershipStatus,
    staleTime: 1000 * 60 * 60 * 60 * 48, // 48 hours
    gcTime: 1000 * 60 * 60 * 60 * 49, // 49 hours - must be bigger than staleTime
    enabled: isAuthenticated
  });

  const membershipStatus = data?.data;

  useEffect(() => {
    if (membershipStatus) {
      updateAuth({
        hasActiveMembership: membershipStatus.hasActiveMembership,
        user: { supporterStatus: membershipStatus }
      });
    }
  }, [membershipStatus, updateAuth]);

  useEffect(() => {
    setSelectedLocation(null);
  }, [location, setSelectedLocation]);

  return (
    <>
      <Routes />
      <OnboardingWalkMe />
    </>
  );
};

export default Navigation;
