import { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import {
  IconButton,
  Slider as MuiSlider,
  SliderOwnProps,
  Stack,
  Typography,
  Menu,
  MenuItem,
  Button
} from '@mui/material';
import { makeStyles, withStyles } from 'tss-react/mui';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import {
  usePopupState,
  bindTrigger,
  bindMenu
} from 'material-ui-popup-state/hooks';
import moment from 'moment';
import { getTimePass, isPhone } from 'shared/utils';
import { useAlertCameraPlayerState } from 'state/useAlertCameraPlayerState';
import {
  parseReplayTime,
  preloadImages,
  fetchCameraTimeLapse
} from './CameraPlayer.utils';
import { CameraImage } from './CameraImage';
import { CameraPreview } from './CameraPreview';
import { CameraFullScreenButton } from './CameraFullScreenButton';

type CameraPlayerProps = {
  cameraId: string;
  previewUrl: string;
  previewDate: Date;
  fullScreen?: boolean;
  isPtz?: boolean;
};

const DEFAULT_REPLAY_SEC_PTZ = 60 * 15;
const DEFAULT_REPLAY_SEC_STATIONARY = 60 * 60;

const FRAME_INTERVAL_MS = 200;

const REPLAY_TIMES_SECONDS = [
  60 * 5, // 5 minutes
  60 * 10, // 10 minutes
  60 * 15, // 15 minutes
  60 * 30, // 30 minutes
  60 * 60, // 1 hour
  60 * 60 * 2, // 2 hours
  60 * 60 * 3, // 3 hours
  60 * 60 * 6 // 6 hours
];

const SLIDER_MIN_DEFAULT_VALUE = 0;
const SLIDER_MAX_DEFAULT_VALUE = 100;

const Slider = withStyles(MuiSlider, (theme) => ({
  root: {
    height: 8,
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  },
  thumb: {
    width: 14,
    height: 14,
    border: '2px solid white',
    '&:before': {
      boxShadow: 'none'
    },
    boxShadow: theme.shadows[1]
  },
  track: {
    height: 6,
    borderRadius: 4
  },
  rail: {
    height: 6,
    borderRadius: 4,
    color: theme.palette.grey[400]
  }
}));

const useStyles = makeStyles<{ fullScreen: boolean }>()(
  (theme, { fullScreen }) => ({
    root: {
      width: '100%',
      height: '100%',
      position: 'relative'
    },
    button: {
      borderRadius: theme.shape.borderRadius * 2,
      backgroundColor: theme.palette.grey[300],
      '&:hover': {
        backgroundColor: theme.palette.grey[500]
      },
      '&:disabled': {
        backgroundColor: theme.palette.grey[700]
      }
    },
    menuPaper: {
      backgroundColor: fullScreen
        ? theme.palette.background.paper
        : theme.palette.grey[900],
      color: fullScreen
        ? theme.palette.text.primary
        : theme.palette.background.paper
    },
    replayTimeButton: {
      fontWeight: 'bold',
      '.MuiButton-endIcon': {
        marginLeft: '2px'
      },
      '&:disabled': {
        color: fullScreen
          ? theme.palette.background.paper
          : theme.palette.text.primary
      }
    }
  })
);

const CameraPlayer = (props: CameraPlayerProps): JSX.Element => {
  const {
    cameraId,
    previewUrl,
    previewDate,
    fullScreen = false,
    isPtz
  } = props;
  const [timelapseFrameNumber, setTimelapseFrameNumberInternal] = useState(0);
  const { playerStatus, setPlayerStatus, timelapseFrame, setTimelapseFrame } =
    useAlertCameraPlayerState();
  const [replayTime, setReplayTime] = useState(
    isPtz ? DEFAULT_REPLAY_SEC_PTZ : DEFAULT_REPLAY_SEC_STATIONARY
  );
  const { classes } = useStyles({ fullScreen });
  const { t } = useTranslation();
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'playTimeMenu'
  });

  const {
    isFetching: isFetchingTimelapse,
    data: timelapse,
    refetch: refetchTimelapse
  } = useQuery({
    queryKey: ['camera-timelapse', cameraId, fullScreen, replayTime],
    queryFn: async () => {
      const data = await fetchCameraTimeLapse(cameraId, replayTime.toString());
      preloadImages(data.map((value) => value.imageUrl));
      return data;
    },
    staleTime: 0,
    gcTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false
  });

  const cameraTimeLapse = useMemo(() => timelapse || [], [timelapse]);

  // Entrypoint for setting the frame number for all functions below. Ensures
  // that the time-lapse frame is always in sync with the frame number.
  const setTimelapseFrameNumber = useCallback(
    (frameNumber: number) => {
      if (frameNumber < 0 || frameNumber >= cameraTimeLapse.length) return;
      setTimelapseFrameNumberInternal(frameNumber);
      setTimelapseFrame(cameraTimeLapse[frameNumber]);
    },
    [cameraTimeLapse, setTimelapseFrame]
  );

  const intervalRef = useRef<NodeJS.Timeout>();

  const fetchTimeLapseBySlidingRef = useRef(false);

  const pause = useCallback((): void => {
    setPlayerStatus('paused');
  }, [setPlayerStatus]);

  const playTimelapse = useCallback((): void => {
    setPlayerStatus('playing');
  }, [setPlayerStatus]);

  const stopTimelapse = useCallback((): void => {
    pause(); // Clears the playback timer.
    setTimelapseFrameNumber(0); // Reset the playback slider.
    setPlayerStatus('live');
  }, [pause, setTimelapseFrameNumber, setPlayerStatus]);

  const advanceTimelapseFrame = useCallback((): void => {
    if (timelapseFrameNumber >= cameraTimeLapse.length - 1) {
      pause();
      return;
    }
    setTimelapseFrameNumber(timelapseFrameNumber + 1);
  }, [
    cameraTimeLapse.length,
    pause,
    setTimelapseFrameNumber,
    timelapseFrameNumber
  ]);

  // Causes the playback timer to fire when the player is in "playing" state.
  // Similarly causes it to stop when the player is in any other state, or reset
  // when any data used within the setInterval() callback changes.
  useEffect(() => {
    if (playerStatus !== 'playing') return () => {};
    intervalRef.current = setInterval(
      () => advanceTimelapseFrame(),
      FRAME_INTERVAL_MS
    );

    return () => {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    };
  }, [playerStatus, advanceTimelapseFrame]);

  // On first load, and when the player is unmounted, set the player to
  // 'live'. Necessary since the player status state is shared across all
  // components in the app using React Recoil.
  useEffect(() => {
    setPlayerStatus('live');
    return () => {
      setPlayerStatus('live');
    };
  }, [setPlayerStatus]);

  const handleTogglePlay = async (): Promise<void> => {
    if (playerStatus === 'playing') {
      pause();
      return;
    }
    // If the player was just playing the live image, refresh the timelapse
    // data.
    if (playerStatus === 'live') {
      await refetchTimelapse();
    }
    // If the time-lapse has played to the end, reset it.
    if (timelapseFrameNumber === cameraTimeLapse.length - 1) {
      setTimelapseFrameNumber(0);
    }
    playTimelapse();
  };

  const handleStopTimelapse = async (): Promise<void> => {
    stopTimelapse();
  };

  const handleSliderChange: SliderOwnProps['onChange'] = async (
    _,
    value
  ): Promise<void> => {
    // If the player is currently playing, pause it.
    pause();
    let progressValue = value as number;
    if (!cameraTimeLapse.length) {
      fetchTimeLapseBySlidingRef.current = true;
      const res = await refetchTimelapse();
      const maxSliderValue = res.data
        ? res.data.length - 1
        : SLIDER_MAX_DEFAULT_VALUE;
      // Interpolate new value to new interval specified by the recently fetched timelapse
      progressValue = Math.floor(
        (progressValue / SLIDER_MAX_DEFAULT_VALUE) * maxSliderValue
      );
    }
    setTimelapseFrameNumber(progressValue);
  };

  const handleSetReplayTime = (time: number): void => {
    // Reset everything when changing the replay time
    stopTimelapse();
    fetchTimeLapseBySlidingRef.current = false;
    setReplayTime(time);
    popupState.close();
  };

  const fullscreenButtonVisible = !fullScreen && !isPhone();

  const currentFrameTime =
    playerStatus !== 'live' && timelapseFrame?.imageTimestamp
      ? new Date(timelapseFrame.imageTimestamp)
      : previewDate;

  const currentFrameTimeRelative = ` • ${getTimePass(
    currentFrameTime.toISOString()
  )}`;

  return (
    <Stack spacing={0}>
      {!timelapseFrameNumber ? (
        <CameraPreview url={previewUrl} loading={isFetchingTimelapse} />
      ) : (
        <CameraImage
          url={timelapseFrame!.imageUrl}
          loading={isFetchingTimelapse}
        />
      )}

      <Typography
        variant="subtitle1"
        fontWeight="bold"
        paragraph
        marginTop={0.5}
        marginBottom={0.5}
      >
        {moment(currentFrameTime).format('MMM D hh:mm a')}
        {currentFrameTimeRelative}
      </Typography>

      <Slider
        color="accent"
        size="small"
        value={timelapseFrameNumber}
        onChange={handleSliderChange}
        min={SLIDER_MIN_DEFAULT_VALUE}
        max={
          cameraTimeLapse.length
            ? cameraTimeLapse.length - 1
            : SLIDER_MAX_DEFAULT_VALUE
        }
        disabled={isFetchingTimelapse || playerStatus === 'live'}
      />

      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        marginTop={1}
      >
        <Stack direction="row" spacing={1}>
          <IconButton
            className={classes.button}
            onClick={handleTogglePlay}
            disabled={isFetchingTimelapse}
          >
            {playerStatus === 'playing' ? (
              <PauseIcon fontSize="medium" sx={{ color: 'text.primary' }} />
            ) : (
              <PlayArrowIcon fontSize="medium" sx={{ color: 'text.primary' }} />
            )}
          </IconButton>
          {playerStatus !== 'live' && (
            <IconButton
              className={classes.button}
              onClick={handleStopTimelapse}
              disabled={isFetchingTimelapse}
            >
              <StopIcon fontSize="medium" sx={{ color: 'text.primary' }} />
            </IconButton>
          )}
        </Stack>

        <Stack direction="row">
          <Button
            {...bindTrigger(popupState)}
            disabled={isFetchingTimelapse}
            variant="text"
            color="inherit"
            size="small"
            endIcon={
              <ExpandMoreIcon
                fontSize="medium"
                sx={{ color: fullScreen ? 'background.paper' : 'text.primary' }}
              />
            }
            className={classes.replayTimeButton}
          >
            {parseReplayTime(replayTime, t)}
          </Button>

          {fullscreenButtonVisible && (
            <CameraFullScreenButton
              cameraId={cameraId}
              disabled={isFetchingTimelapse}
            />
          )}
        </Stack>
      </Stack>

      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        classes={{ paper: classes.menuPaper }}
      >
        {REPLAY_TIMES_SECONDS.map((time) => (
          <MenuItem
            key={time}
            sx={{ padding: '4px 16px' }}
            onClick={() => handleSetReplayTime(time)}
            disabled={isFetchingTimelapse || (!isPtz && time / 60 <= 30)}
          >
            <Typography key={time} variant="subtitle1" component="p">
              {parseReplayTime(time, t)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default CameraPlayer;
