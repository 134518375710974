import { getOpacityForGeoEventMarker } from 'components/Map/Icons';
import useGeoEvents from 'hooks/useGeoEvents';
import { LngLat, LngLatBounds } from 'maplibre-gl';
import { WildfireListItem } from 'shared/types';
import { setNaiveISODateStringToUTC } from 'shared/utils';
import { useMapState } from 'state';
import { UseMapState } from 'state/useMapState';

type UseVisibleWildfireGeoEventsProps = {
  enabled?: boolean;
};

const getGeoEventsInViewport = (
  incidentMapState: UseMapState['incidentMapState'],
  geoEvents: WildfireListItem[]
): WildfireListItem[] => {
  const { bounds } = incidentMapState;

  if (!bounds) return [];

  const southWest = new LngLat(
    // eslint-disable-next-line no-underscore-dangle
    (bounds._southWest?.lng || bounds._sw?.lng)!,
    // eslint-disable-next-line no-underscore-dangle
    (bounds._southWest?.lat || bounds._sw?.lat)!
  );
  const northEast = new LngLat(
    // eslint-disable-next-line no-underscore-dangle
    (bounds._northEast?.lng || bounds._ne?.lng)!,
    // eslint-disable-next-line no-underscore-dangle
    (bounds._northEast?.lat || bounds._ne?.lat)!
  );
  const bbounds = new LngLatBounds(southWest, northEast);

  return geoEvents
    .filter(
      (geoEvent) =>
        bbounds.contains(new LngLat(geoEvent.lng, geoEvent.lat)) &&
        getOpacityForGeoEventMarker(geoEvent, true) > 0
    )
    .sort((a, b) => {
      return (
        new Date(setNaiveISODateStringToUTC(b.dateModified)).getTime() -
        new Date(setNaiveISODateStringToUTC(a.dateModified)).getTime()
      );
    });
};

export const useVisibleWildfireGeoEvents = (
  props: UseVisibleWildfireGeoEventsProps
): WildfireListItem[] => {
  const { enabled = true } = props;
  const { incidentMapState } = useMapState();

  const { wildfireEvents } = useGeoEvents({
    enabled
  });

  return getGeoEventsInViewport(incidentMapState, wildfireEvents);
};
