import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { API } from 'api';
import { EvacZone, GeoEvent } from 'shared/types';

const fetchEvacZonesForRegions = (
  regionIds: number[]
): Promise<AxiosResponse<EvacZone[]>> => {
  return API.get<EvacZone[]>(`evac_zones/?region_ids=${regionIds}`);
};

const useGeoEventEvacZonesQuery = (
  geoEvent?: GeoEvent
): UseQueryResult<AxiosResponse<EvacZone[]>> => {
  const regionIds =
    (geoEvent && geoEvent.regions.map((region) => region.id)) || [];
  const enabled = Boolean(geoEvent && regionIds.length);
  return useQuery({
    queryKey: ['evacuation-zones', geoEvent?.id],
    queryFn: () => fetchEvacZonesForRegions(regionIds),
    enabled,
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 6 // 6 minutes - must be bigger than staleTime
  });
};

export default useGeoEventEvacZonesQuery;
