/* eslint-disable i18next/no-literal-string */
import { Alert, Button, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useMapRenderBugDetector } from 'hooks/useMapRenderBugDetector';
import { useState } from 'react';

type MapRenderBugDetectorProps = {
  map: maplibregl.Map | undefined;
};

export const MapRenderBugDetector = (
  props: MapRenderBugDetectorProps
): JSX.Element => {
  const { map } = props;
  const isBugDetected = useMapRenderBugDetector(map);
  const [ignore, setIgnore] = useState(false);

  const reloadPage = (): void => {
    window.location.reload();
  };

  const handleIgnore = (): void => {
    setIgnore(true);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={isBugDetected && !ignore}
      onClose={() => handleIgnore()}
      ClickAwayListenerProps={{ onClickAway: () => null }}
      // zIndex to move above everything else, marginBottom to avoid overlapping with the bottom search bar.
      sx={{ zIndex: 2147483647, marginBottom: '82px' }}
    >
      <Alert
        severity="error"
        icon={false}
        variant="filled"
        sx={{ width: '100%' }}
        onClose={() => handleIgnore()}
        action={[
          <Button
            key="reload"
            color="primary"
            variant="contained"
            onClick={() => reloadPage()}
            size="medium"
          >
            FIXME
          </Button>,
          <IconButton
            key="close"
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleIgnore}
            sx={{ marginLeft: '8px' }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ]}
      >
        Map rendering bug detected! Please:
        <br />
        <ol style={{ paddingLeft: '24px' }}>
          <li>Screenshot the app now.</li>
          <li>
            Press <b>FIXME</b>.
          </li>
          <li>Screenshot again.</li>
          <li>
            Send to <code>#help-bugs</code>.
          </li>
        </ol>
      </Alert>
    </Snackbar>
  );
};
