import { ReactNode } from 'react';
import { List, ListSubheader, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type RegionListProps = {
  children: ReactNode;
  name: string;
  className?: string;
  subheaderClassName?: string;
};

const useStyles = makeStyles()((theme) => ({
  subheader: {
    lineHeight: theme.typography.pxToRem(26)
  }
}));

const RegionList = (props: RegionListProps): JSX.Element => {
  const { children, name, className, subheaderClassName } = props;
  const { classes, cx } = useStyles();

  return (
    <List
      subheader={
        <ListSubheader
          disableSticky
          className={cx(classes.subheader, subheaderClassName)}
        >
          <Typography variant="h3" color="textPrimary">
            {name}
          </Typography>
        </ListSubheader>
      }
      className={className}
      aria-label="Counties list"
    >
      {children}
    </List>
  );
};

export default RegionList;
