import { useCallback } from 'react';
import { Resetter, atom, useRecoilState, useResetRecoilState } from 'recoil';
import { SerializedBounds } from 'shared/types';
import { DEFAULT_LATLNG } from '../components/Map/constants';
import { localStorageEffect } from './localStorage';

type MapState = {
  incident: {
    center: { lat: number; lng: number };
    zoom: number;
    bounds?: SerializedBounds;
  };
  report: {
    center: { lat: number; lng: number };
    zoom: number;
    bounds?: SerializedBounds;
    currentIncidentId: number;
    currentGeoEventId: number;
  };
  geoEvent: {
    lat: number;
    lng: number;
  } | null;
  activeMapBounds?: SerializedBounds;
};

export type IncidentMapStateUpdate = Partial<MapState['incident']>;

export type UseMapState = {
  reportMapState: {
    center: {
      lat: number;
      lng: number;
    };
    zoom: number;
    bounds?: SerializedBounds | undefined;
    currentIncidentId: number;
    currentGeoEventId: number;
  };
  incidentMapState: {
    center: {
      lat: number;
      lng: number;
    };
    zoom: number;
    bounds?: SerializedBounds | undefined;
  };
  reset: Resetter;
  geoEventMapState: {
    lat: number;
    lng: number;
  } | null;
  updateReportMapState: (obj: Partial<MapState['report']>) => void;
  updateIncidentMapState: (obj: IncidentMapStateUpdate) => void;
  resetReportMapState: () => void;
  updateGeoEventMapState: (
    obj: {
      lat: number;
      lng: number;
    } | null
  ) => void;
  activeMapBounds?: SerializedBounds;
  setActiveMapBounds: (bounds?: SerializedBounds) => void;
};

const DEFAULT_MAP_STATE: MapState = {
  incident: {
    center: DEFAULT_LATLNG,
    zoom: 6,
    bounds: undefined
  },
  report: {
    center: DEFAULT_LATLNG,
    zoom: 9,
    bounds: undefined,
    currentIncidentId: -1,
    currentGeoEventId: -1
  },
  geoEvent: null,
  activeMapBounds: undefined
};

const mapStateAtom = atom({
  key: 'MAP_STATE',
  default: DEFAULT_MAP_STATE,
  effects: [localStorageEffect('MAP_STATE')]
});

const useMapState = (): UseMapState => {
  const resetMapState = useResetRecoilState(mapStateAtom);
  const [mapState, setMapState] = useRecoilState(mapStateAtom);

  const updateIncidentMapState = useCallback(
    (obj: IncidentMapStateUpdate) =>
      setMapState((prev) => ({
        ...prev,
        incident: { ...prev.incident, ...obj }
      })),
    [setMapState]
  );

  const updateReportMapState = useCallback(
    (obj: Partial<MapState['report']>) =>
      setMapState((prev) => ({
        ...prev,
        report: { ...prev.report, ...obj }
      })),
    [setMapState]
  );

  const updateGeoEventMapState = useCallback(
    (obj: { lat: number; lng: number } | null) => {
      setMapState((prev) => ({ ...prev, geoEvent: obj }));
    },
    [setMapState]
  );

  const resetReportMapState = useCallback(
    () =>
      updateReportMapState({
        currentIncidentId: -1
      }),
    [updateReportMapState]
  );

  const setActiveMapBounds = useCallback(
    (bounds?: SerializedBounds) =>
      setMapState((prev) => ({
        ...prev,
        activeMapBounds: bounds
      })),
    [setMapState]
  );

  return {
    reportMapState: mapState.report,
    incidentMapState: mapState.incident,
    geoEventMapState: mapState.geoEvent,
    updateReportMapState,
    updateIncidentMapState,
    resetReportMapState,
    updateGeoEventMapState,
    reset: resetMapState,
    activeMapBounds: mapState.activeMapBounds,
    setActiveMapBounds
  };
};

export default useMapState;
