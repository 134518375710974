import { Dispatch, SetStateAction, useMemo } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  FormHelperText,
  Grid,
  TextField,
  Alert,
  AlertTitle
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Photo } from '@watchduty/camera';
import { Capacitor } from '@capacitor/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { useSnackbarState } from 'state';
import { ReportMedia, UploadImageData } from 'shared/types';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import {
  formatFileUploadKey,
  getGPSDataFromFileAndroid,
  getGPSDataFromFileIOS,
  readFilePath,
  uploadImage
} from 'shared/utils';
import usePosition from 'hooks/usePosition';
import { TEXTAREA_MAX_ROWS } from '../../../constants';
import FilePicker from './FilePicker';
import ImagePreview from './ImagePreview';

type PhotoSubmissionFormProps = {
  geoEventId?: number;
  setSuccess: Dispatch<SetStateAction<boolean>>;
};

type FormValues = {
  photo: File | Blob | null;
  fileType: string;
  message: string;
  lat: number;
  lng: number;
  az: number;
};

type ReportData = {
  media: Partial<ReportMedia>[];
  message: string;
  geoEventId?: number;
};

const submitNewReport = async (reportData: ReportData): Promise<void> => {
  await API.post('/reports/', reportData);
};

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
    overflowY: 'auto'
  },
  button: {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    height: 48
  },
  error: {
    margin: '3px 14px 0px'
  }
}));

const initialValues: FormValues = {
  photo: null,
  fileType: '',
  message: '',
  lat: 0,
  lng: 0,
  az: 0
};

const PhotoSubmissionForm = (props: PhotoSubmissionFormProps): JSX.Element => {
  const { geoEventId, setSuccess } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbarState();
  const { lat: userLat, lng: userLng } = usePosition();

  const s3AuthQuery = useQuery({
    queryKey: ['gets3Auth'],
    queryFn: () => API.get('media/storage_auth/')
  });

  const uploadImageMutation = useMutation({
    mutationFn: (data: UploadImageData) => uploadImage(data)
  });

  const postReportMutation = useMutation({
    mutationFn: (data: ReportData) => submitNewReport(data)
  });

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        photo: Yup.mixed<File | Blob | null>().required(
          t('photoSubmission.form.inputs.photo.required')
        ),
        fileType: Yup.string().nullable(),
        message: Yup.string()
          .required(t('photoSubmission.form.inputs.description.required'))
          .min(3, t('photoSubmission.form.inputs.description.minError')),
        lat: Yup.number(),
        lng: Yup.number(),
        az: Yup.number()
      }),
    [t]
  );

  const { control, formState, setValue, setError, handleSubmit } =
    useForm<FormValues>({
      resolver: yupResolver(validationSchema),
      defaultValues: initialValues,
      mode: 'onBlur'
    });

  const handleFilePicked = async (
    newFile: File | File[] | Photo
  ): Promise<void> => {
    if (Capacitor.getPlatform() === 'web') {
      // this is from the Web. We get an array, even though we are only handling 1 photo for now
      const webFile = Array.isArray(newFile) ? newFile[0] : newFile;

      if (!('type' in webFile)) return;

      // type is the image mime type ie: 'image/jpeg'
      const imageTypeIndex = 1;
      const parsedFileType = webFile.type.split('/')[imageTypeIndex];

      setValue('fileType', parsedFileType);
      setValue('photo', webFile);

      return;
    }

    if ('format' in newFile) {
      const gpsData =
        Capacitor.getPlatform() === 'ios'
          ? getGPSDataFromFileIOS(newFile)
          : getGPSDataFromFileAndroid(newFile);

      const { bearing, lat = userLat, lng = userLng } = gpsData;

      if (lat && lng) {
        setValue('lat', lat);
        setValue('lng', lng);
      }

      if (bearing) {
        setValue('az', bearing);
      }

      const res = await readFilePath(newFile);
      if (!res) return;

      setValue('fileType', res.type);
      setValue('photo', res.file);
    }
  };

  const handleInvalidFiles = (error: string): void => {
    setError('photo', { message: error });
  };

  const handleSubmitReport: SubmitHandler<FormValues> = async (values) => {
    const { photo, fileType, lat, lng, az, message } = values;
    const mediaUrl = formatFileUploadKey(fileType);

    /**
     * Maybe janky but for now we aren't allowing geodata with reports
     * only with Media.
     */
    const reportData: ReportData = {
      message,
      media: [
        {
          lat: lat || null,
          lng: lng || null,
          az: az || null,
          url: mediaUrl
        }
      ],
      geoEventId
    };

    try {
      if (!s3AuthQuery.data || !photo) return;

      await uploadImageMutation.mutateAsync({
        media: photo,
        s3AuthData: s3AuthQuery.data,
        s3Key: mediaUrl
      });

      await postReportMutation.mutateAsync(reportData);

      setSuccess(true);
    } catch (err: unknown) {
      setSnackbar(t('common.unknownErrorTryAgain'), 'error');
    }
  };

  if (s3AuthQuery.isLoading) {
    return <LoadingAndErrors isLoading />;
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      <Grid
        container
        spacing={2}
        direction="column"
        component="form"
        onSubmit={handleSubmit(handleSubmitReport)}
        noValidate
      >
        <Grid item>
          <Alert severity="error">
            <AlertTitle>{t('photoSubmission.form.alert')}</AlertTitle>
          </Alert>
        </Grid>

        <Grid item>
          <Controller
            name="photo"
            control={control}
            render={({ field, fieldState }): JSX.Element => {
              if (!field.value) {
                return (
                  <>
                    <FilePicker
                      message={t('photoSubmission.form.inputs.photo.label')}
                      onFilesPicked={handleFilePicked}
                      onFilesInvalid={handleInvalidFiles}
                      disabled={formState.isSubmitting}
                      error={fieldState.error?.message}
                      minHeight={268}
                    />
                    {!!fieldState.error?.message && (
                      <FormHelperText error className={classes.error}>
                        {fieldState.error?.message}
                      </FormHelperText>
                    )}
                  </>
                );
              }
              return (
                <ImagePreview title={field.value.name} data={field.value} />
              );
            }}
          />
        </Grid>

        <Grid item>
          <Controller
            name="message"
            control={control}
            render={({ field, fieldState }): JSX.Element => {
              const { ref, ...muiFieldProps } = field;
              return (
                <TextField
                  id="field-control-message"
                  label={t('photoSubmission.form.inputs.description.label')}
                  fullWidth
                  {...muiFieldProps}
                  inputRef={ref}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  required
                  multiline
                  minRows={4}
                  maxRows={TEXTAREA_MAX_ROWS}
                  disabled={formState.isSubmitting}
                />
              );
            }}
          />
        </Grid>

        <Grid item>
          <Button
            type="submit"
            fullWidth
            className={classes.button}
            disabled={formState.isSubmitting}
          >
            {formState.isSubmitting ? (
              <CircularProgress size={24} />
            ) : (
              t('photoSubmission.form.button')
            )}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PhotoSubmissionForm;
