import { Layer } from 'react-map-gl/maplibre';
import { useCallback } from 'react';
import { usePoisState } from 'state/usePoisState';
import { MapLayerProps } from 'components/Map/types';
import { useTranslation } from 'react-i18next';
import addVisible from 'shared/addVisible';
import { NaturalGasPipelineType } from './types';
import {
  GAS_MAPTILES_DIRECTORY,
  GAS_MAP_INTERACTIVE_LAYER_ID,
  GAS_MAP_LAYER_ID,
  GAS_SOURCE_DATA_LAYER
} from './constants';
import {
  gasLayerStyle,
  getSelectedStrokeStyle,
  getInteractiveLayerStyle,
  layerStyleLineCasing
} from './layerStyles';
import { MapboxFeature, useMapLayerEvents } from '../useMapLayerEvents';
import ElectricLineDialogContent from './DialogContent';
import VisibilityToggledSource from '../VisibilityToggledSource';
import getWatchdutyTileUrl from '../getWatchdutyTileUrl';

const GasPipelineLayer = (props: MapLayerProps): JSX.Element => {
  const { visible } = props;
  const { selectedPoi, setSelectedPoi } = usePoisState();
  const { t } = useTranslation();

  const selectedGasUId =
    (selectedPoi?.type === 'gasPipeline' && selectedPoi.id) || '';

  const selectedStrokeStyle = getSelectedStrokeStyle({
    selectedId: selectedGasUId,
    sourceLayer: GAS_SOURCE_DATA_LAYER
  });

  useMapLayerEvents({
    layerId: GAS_MAP_INTERACTIVE_LAYER_ID,
    onClick: useCallback(
      (features: MapboxFeature<NaturalGasPipelineType>[]) => {
        const gasPipeline: NaturalGasPipelineType = features[0].properties;
        setSelectedPoi({
          type: 'gasPipeline',
          id: gasPipeline.FID,
          PoiDialogContent: () => (
            <ElectricLineDialogContent
              title={t('map.poi.gasPipelines.title')}
              list={[
                {
                  name: t('map.poi.gasPipelines.operator'),
                  value: gasPipeline.Operator
                },
                {
                  name: t('map.poi.gasPipelines.type'),
                  value: gasPipeline.TYPEPIPE
                }
              ]}
            />
          )
        });
      },
      [setSelectedPoi, t]
    )
  });

  return (
    <VisibilityToggledSource
      visible={visible}
      url={getWatchdutyTileUrl(GAS_MAPTILES_DIRECTORY)}
    >
      <Layer
        source-layer={GAS_SOURCE_DATA_LAYER}
        {...addVisible(layerStyleLineCasing, visible)}
      />
      <Layer id={GAS_MAP_LAYER_ID} {...addVisible(gasLayerStyle, visible)} />
      <Layer
        id={GAS_MAP_INTERACTIVE_LAYER_ID}
        {...addVisible(
          getInteractiveLayerStyle(GAS_SOURCE_DATA_LAYER),
          visible
        )}
      />
      <Layer {...addVisible(selectedStrokeStyle, visible)} />
    </VisibilityToggledSource>
  );
};

export default GasPipelineLayer;
