import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { API } from 'api';
import PageLayout from 'components/PageLayout';
import HeaderBar from 'components/HeaderBar';
import { GeoEvent } from 'shared/types';
import { useAuthState } from 'state';
import Unauthorized from 'components/Unauthorized';
import EditAutomatedGeoEventForm from './EditAutomatedGeoEventForm';

type ContentProps = {
  geoEvent: GeoEvent;
};

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fetchGeoEvent = (geoEventId: string) => {
  return API.get(`geo_events/${geoEventId}`);
};

const Content = (props: ContentProps): JSX.Element => {
  const { geoEvent } = props;
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('editGeoEvent.title')}</title>
      </Helmet>
      <EditAutomatedGeoEventForm geoEvent={geoEvent} />
    </>
  );
};

const EditAutomatedGeoEvent = (
  props: RouteComponentProps<{ geoEventId: string }>
): JSX.Element => {
  const {
    match: {
      params: { geoEventId }
    }
  } = props;
  const {
    permissions: { canReport }
  } = useAuthState();
  const { t } = useTranslation();

  const query = useQuery({
    queryKey: ['geoEvent', geoEventId],
    queryFn: () => fetchGeoEvent(geoEventId),
    enabled: !!geoEventId && canReport
  });
  const { data, error, isLoading, isError } = query;

  if (!canReport) {
    return (
      <PageLayout data-cname="CreateEditIncidentPage">
        {{
          content: <Unauthorized />,
          headerBar: (
            <HeaderBar
              title={t('unauthorized.title')}
              action="back"
              hideAction
            />
          )
        }}
      </PageLayout>
    );
  }

  if (isLoading) {
    return (
      <PageLayout data-cname="EditGeoEventPage">
        {{
          content: <CircularProgress />,
          headerBar: <HeaderBar title="Loading..." action="back" hideAction />
        }}
      </PageLayout>
    );
  }

  if (isError || !data?.data) {
    return (
      <PageLayout data-cname="EditGeoEventPage">
        {{
          content: (
            <div>{error ? JSON.stringify(error) : 'Geo event not found!'} </div>
          ),
          headerBar: <HeaderBar title="Incident" action="back" hideAction />
        }}
      </PageLayout>
    );
  }

  return (
    <PageLayout data-cname="EditGeoEventPage">
      {{
        content: <Content geoEvent={data.data} />,
        headerBar: (
          <HeaderBar title={data.data?.name} action="back" hideAction />
        )
      }}
    </PageLayout>
  );
};

export default EditAutomatedGeoEvent;
