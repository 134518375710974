import { LayerProps } from 'react-map-gl/maplibre';
import { FONT_NAMES } from 'components/Map/styles/constants';
import {
  BORDER_LAYER_ID,
  FILL_LAYER_ID,
  LABEL_LAYER_ID,
  MIN_ZOOM_VISIBILITY,
  SOURCE_DATA_POINTS_LAYER_ID,
  SOURCE_DATA_POLYGONS_LAYER_ID
} from './constants';

export const layerFillStyle: LayerProps = {
  id: FILL_LAYER_ID,
  type: 'fill',
  'source-layer': SOURCE_DATA_POLYGONS_LAYER_ID,
  minzoom: MIN_ZOOM_VISIBILITY,
  paint: {
    'fill-color': 'transparent'
  }
};

export const layerBorderStyle: LayerProps = {
  id: BORDER_LAYER_ID,
  type: 'line',
  'source-layer': SOURCE_DATA_POLYGONS_LAYER_ID,
  minzoom: MIN_ZOOM_VISIBILITY,
  paint: {
    'line-color': '#000000',
    'line-opacity': 0.75,
    'line-width': ['interpolate', ['linear'], ['zoom'], 8, 1.0, 18, 4.0]
  }
};

export const layerLabelStyle: LayerProps = {
  id: LABEL_LAYER_ID,
  type: 'symbol',
  'source-layer': SOURCE_DATA_POINTS_LAYER_ID,
  minzoom: MIN_ZOOM_VISIBILITY,
  paint: {
    'text-color': '#000000',
    'text-halo-width': 1.8,
    'text-halo-color': '#ffffff',
    'text-opacity': 1
  },
  layout: {
    'text-field': ['get', 'owner'],
    'text-justify': 'auto',
    'text-size': [
      'interpolate',
      ['linear'],
      ['zoom'],
      MIN_ZOOM_VISIBILITY,
      10,
      20,
      14
    ],
    'text-font': FONT_NAMES.bold
  }
};
