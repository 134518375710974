import { Capacitor } from '@capacitor/core';
import {
  FirebaseAnalytics,
  FirebaseAnalyticsPlugin
} from '@capacitor-community/firebase-analytics';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/capacitor';

let FirebaseAnalyticsWebApp: typeof FirebaseAnalytics | null = null;

export const ANALYTICS_EVENTS = {
  VIEWED: {
    MAP_BANNER: 'viewed_map_banner',
    INCIDENT_BANNER: 'viewed_incident_banner',
    PAYWALL: 'paywall_shown', // does not follow naming convention because it's a preexisting event
    INBOX_MESSAGE: 'read_inbox_message'
  },
  CLICKED: {
    MAP_BANNER_CLOSE: 'clicked_map_banner_close',
    MAP_BANNER_WAYS_TO_GIVE: 'clicked_map_banner_ways_to_give',
    INCIDENT_BANNER_CLOSE: 'clicked_incident_banner_close',
    INCIDENT_BANNER_WAYS_TO_GIVE: 'clicked_incident_banner_ways_to_give',
    PAYWALL_BACK: 'clicked_paywall_back',
    PAYWALL_JOIN: 'clicked_paywall_join',
    // preexisting events
    DOWNLOAD_APP_BANNER: 'click_app_banner',
    DOWNLOAD_APP_BANNER_CLOSE: 'click_app_banner_close',
    BASE_MAP_LAYER_CHANGE: 'change_base_map_layer',
    // support us
    MEMBERSHIP_REGULAR: 'clicked_membership_regular',
    MEMBERSHIP_PRO: 'clicked_membership_pro',
    MEMBERSHIP_PRO_YEARLY: 'clicked_membership_pro_yearly',
    MEMBERSHIP_PRO_MONTHLY: 'clicked_membership_pro_monthly',
    DONATION: 'clicked_donation'
  },
  SUPPORT: {
    PURCHASE: 'purchase',
    PURCHASE_MEMBERSHIP: 'purchase_membership',
    SUCCESS_MEMBERSHIP: 'membership_success_login',
    SUCCESS_DONATION: 'donation_success_login'
  }
} as const;

const initFirebase = async (): Promise<void> => {
  if (Capacitor.getPlatform() !== 'web') {
    return; // initialization is done on the native side for Native apps
  }

  try {
    FirebaseAnalyticsWebApp = await FirebaseAnalytics.initializeFirebase(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      JSON.parse(import.meta.env.VITE_FIREBASE_ANALYTICS_WEB_CONFIG!)
    );
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const logEvent = async ({
  name,
  params = {}
}: {
  name: string;
  params?: object;
}): Promise<void> => {
  if (Capacitor.getPlatform() === 'web') {
    if (FirebaseAnalyticsWebApp) {
      FirebaseAnalyticsWebApp.logEvent({ name, params });
    }
    return;
  }
  FirebaseAnalytics.logEvent({ name, params });
};

export const setAnalyticsUser: FirebaseAnalyticsPlugin['setUserId'] = async (
  options
) => {
  if (Capacitor.getPlatform() === 'web') {
    if (FirebaseAnalyticsWebApp) {
      FirebaseAnalyticsWebApp.setUserId(options);
    }
    return;
  }
  FirebaseAnalytics.setUserId(options);
};

/**
 * Firebase/GA normally automatically tracks "screen_view" and "page_view" events,
 * but "screen_view" only triggers automatically when the app is loaded because
 * native code doesn't recognize WebView page changes. We disabled the automatic
 * "screen_view" event in native iOS/Android, so we handle it ourselves here.
 *
 * @see https://firebase.google.com/docs/analytics/screenviews
 */
const onLocationChange = (pathname: string): void => {
  if (Capacitor.getPlatform() === 'web') {
    logEvent({
      name: 'screen_view',
      params: {
        firebase_screen: pathname,
        firebase_screen_class: pathname
      }
    });
  } else {
    // Native apps must log "screen_view" with `setScreenName()` or log will fail
    // and be changed to a "firebase_error" event.
    FirebaseAnalytics.setScreenName({
      screenName: pathname,
      nameOverride: pathname
    });
  }
};

const useFirebaseAnalytics = (): void => {
  const location = useLocation();
  const hasInitialized = useRef(false);

  const { pathname } = location;

  useEffect(() => {
    (async (): Promise<void> => {
      if (!hasInitialized.current) {
        hasInitialized.current = true;
        await initFirebase();
      }

      onLocationChange(pathname);
    })();
  }, [pathname]);
};

export default useFirebaseAnalytics;
