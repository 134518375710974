import {
  MutableRefObject,
  ReactNode,
  createContext,
  useEffect,
  useRef
} from 'react';

type MapEntityDrawerContextType = {
  scrollElementRef: MutableRefObject<Element | undefined> | null;
  closeDrawer: () => void;
};

type DesktopScrollProviderProps = {
  children: ReactNode;
  initialScrollPosition: number | null;
  scrollElementRef: MutableRefObject<Element | undefined>;
  closeDrawer: () => void;
};

type MobileScrollProviderProps = {
  children: ReactNode;
  scrollElementRef: MutableRefObject<Element | undefined>;
  closeDrawer: () => void;
};

export const MapEntityDrawerContext = createContext<MapEntityDrawerContextType>(
  { scrollElementRef: null, closeDrawer: () => {} }
);

export const DesktopScrollProvider = (
  props: DesktopScrollProviderProps
): JSX.Element => {
  const { children, closeDrawer, initialScrollPosition, scrollElementRef } =
    props;
  const hasSetInitialScrollPosition = useRef<boolean>();

  useEffect(() => {
    if (
      initialScrollPosition &&
      scrollElementRef.current &&
      !hasSetInitialScrollPosition.current
    ) {
      hasSetInitialScrollPosition.current = true;
      scrollElementRef.current.scrollTo({ top: initialScrollPosition });
    }
  }, [scrollElementRef, initialScrollPosition]);

  return (
    <MapEntityDrawerContext.Provider value={{ scrollElementRef, closeDrawer }}>
      {children}
    </MapEntityDrawerContext.Provider>
  );
};

export const MobileScrollProvider = (
  props: MobileScrollProviderProps
): JSX.Element => {
  const { scrollElementRef, children, closeDrawer } = props;
  return (
    <MapEntityDrawerContext.Provider value={{ scrollElementRef, closeDrawer }}>
      {children}
    </MapEntityDrawerContext.Provider>
  );
};
