import { GeoEventFormStatus } from 'shared/types';

export const hasEvacInfoChanged = (
  prev: string | null,
  next: string
): boolean => {
  const prevValue = (prev || '').trim();
  const nextValue = next.trim();
  return prevValue !== nextValue;
};

export const statusToTransKey: Record<GeoEventFormStatus, string> = {
  inactive: 'common.inactive',
  active: 'common.active'
};
