import {
  List,
  ListSubheader,
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import useMapLayersState from 'state/useMapLayersState';
import { getResponsiveFontSize } from 'shared/utils';
import {
  DiagonalHatchPatternBlack,
  FIRISPerimeter,
  NIFCPerimeter,
  PowerOutageIcon,
  RedFlagWarnings
} from '../Icons';
import { MapLayers } from '../constants';

const useStyles = makeStyles()((theme) => ({
  title: {
    fontVariant: 'all-small-caps',
    fontSize: getResponsiveFontSize(theme.typography.pxToRem(20))
  },
  noCoverageIcon: {
    backgroundColor: '#cccccc',
    maskImage: `url("${DiagonalHatchPatternBlack.data}")`,
    maskSize: `${DiagonalHatchPatternBlack.width}px ${DiagonalHatchPatternBlack.height}px`,
    width: 53,
    height: 24,
    borderRadius: 0,
    marginRight: theme.spacing(3.5)
  },
  firisPerimeterIcon: {
    backgroundColor: '#FD2D2C',
    maskImage: `url("${FIRISPerimeter.data}")`,
    maskSize: `${FIRISPerimeter.width}px ${FIRISPerimeter.height}px`,
    width: 53,
    height: 24,
    borderRadius: 0,
    marginRight: theme.spacing(3.5)
  },
  nifcPerimeterIcon: {
    backgroundColor: '#FD2D2C',
    maskImage: `url("${NIFCPerimeter.data}")`,
    maskSize: `${NIFCPerimeter.width}px ${NIFCPerimeter.height}px`,
    width: 53,
    height: 24,
    borderRadius: 0,
    marginRight: theme.spacing(3.5)
  },
  redFlagWarningsIcon: {
    backgroundColor: '#FA6FB8',
    maskImage: `url("${RedFlagWarnings.data}")`,
    maskSize: `${RedFlagWarnings.width}px ${RedFlagWarnings.height}px`,
    width: 53,
    height: 24,
    borderRadius: 0,
    marginRight: theme.spacing(3.5)
  },
  powerOutagesIcon: {
    background: `url("${PowerOutageIcon.data}")`,
    backgroundSize: 25,
    backgroundRepeat: 'repeat',
    // Giant inset box shadow is the same as a background color.
    boxShadow: 'inset 0 0 0 2000px #F7FB2D35',
    border: '2px solid #F7FB2DBF',
    width: 53,
    height: 24,
    borderRadius: 0,
    marginRight: theme.spacing(3.5)
  },
  landManagmentIcon: {
    background: 'rgba(252, 240, 180, 0.6)',
    border: '2px solid #FCF0B4',
    width: 53,
    height: 24,
    borderRadius: 0,
    marginRight: theme.spacing(3.5)
  },
  bureauOfReclamationIcon: {
    background: 'rgba(255, 255, 212, 0.6)',
    border: '2px solid #FFFFD4',
    width: 53,
    height: 24,
    borderRadius: 0,
    marginRight: theme.spacing(3.5)
  },
  departmentOfDefenseIcon: {
    background: 'rgba(245, 209, 223, 0.6)',
    border: '2px solid #F5D1DF',
    width: 53,
    height: 24,
    borderRadius: 0,
    marginRight: theme.spacing(3.5)
  },
  fishWildlifeIcon: {
    background: 'rgba(185, 221, 200, 0.6)',
    border: '2px solid #B9DDC8',
    width: 53,
    height: 24,
    borderRadius: 0,
    marginRight: theme.spacing(3.5)
  },
  forestIcon: {
    background: 'rgba(226, 242, 220, 0.6)',
    border: '2px solid #E2F2DC',
    width: 53,
    height: 24,
    borderRadius: 0,
    marginRight: theme.spacing(3.5)
  },
  nationalParkIcon: {
    background: 'rgba(220, 213, 231, 0.6)',
    border: '2px solid #DCD5E7',
    width: 53,
    height: 24,
    borderRadius: 0,
    marginRight: theme.spacing(3.5)
  },
  electricRetailService: {
    background: 'rgba(56, 133, 186, 0.6)',
    border: '2px solid rgba(56, 133, 186, 1)',
    width: 53,
    height: 24,
    borderRadius: 0,
    marginRight: theme.spacing(3.5)
  },
  responsibilityAreasFRA: {
    background: 'rgba(78, 7, 1, 0.5)',
    border: '2px solid rgba(78, 7, 1, 1)',
    width: 53,
    height: 24,
    borderRadius: 0,
    marginRight: theme.spacing(3.5)
  },
  responsibilityAreasSRA: {
    background: 'rgba(0, 40, 104, 0.6)',
    border: '2px solid rgba(0, 40, 104, 1)',
    width: 53,
    height: 24,
    borderRadius: 0,
    marginRight: theme.spacing(3.5)
  },
  responsibilityAreasLRA: {
    background: 'rgba(229, 160, 0, 0.5)',
    border: '2px solid rgba(229, 160, 0, 1)',
    width: 53,
    height: 24,
    borderRadius: 0,
    marginRight: theme.spacing(3.5)
  }
}));

const FireLegend = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { mapLayers } = useMapLayersState();

  return (
    <List
      disablePadding
      dense
      subheader={
        <ListSubheader disableSticky>
          <Typography
            variant="h6"
            component="h3"
            className={classes.title}
            color="textPrimary"
          >
            {t('map.legends.coverage.title')}
          </Typography>
        </ListSubheader>
      }
    >
      {mapLayers.includes(MapLayers.RED_FLAG_WARNINGS) && (
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar className={classes.redFlagWarningsIcon}>
              <></>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t('map.legends.coverage.redFlagWarnings.title')}
            secondary={t('map.legends.coverage.redFlagWarnings.subtitle')}
          />
        </ListItem>
      )}
      {mapLayers.includes(MapLayers.ACTIVE_FIRE_PERIMETERS) && (
        <>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar className={classes.firisPerimeterIcon}>
                <></>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('map.legends.coverage.firisPerimeter.title')}
              secondary={t('map.legends.coverage.firisPerimeter.subtitle')}
            />
          </ListItem>

          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar className={classes.nifcPerimeterIcon}>
                <></>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('map.legends.coverage.nifcPerimeter.title')}
              secondary={t('map.legends.coverage.nifcPerimeter.subtitle')}
            />
          </ListItem>
        </>
      )}
      {mapLayers.includes(MapLayers.POWER_OUTAGES) && (
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar className={classes.powerOutagesIcon}>
              <></>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t('map.legends.coverage.powerOutages.title')}
            secondary={t('map.legends.coverage.powerOutages.subtitle')}
          />
        </ListItem>
      )}
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar className={classes.noCoverageIcon}>
            <></>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={t('map.legends.coverage.noCoverage.title')}
          secondary={t('map.legends.coverage.noCoverage.subtitle')}
        />
      </ListItem>
      {mapLayers.includes(MapLayers.FEDERAL_STATE_LOCAL) && (
        <>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar className={classes.landManagmentIcon}>
                <></>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('map.legends.coverage.landManagment.title')}
              secondary={t('map.legends.coverage.landManagment.subtitle')}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar className={classes.bureauOfReclamationIcon}>
                <></>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('map.legends.coverage.bureauOfReclamation.title')}
              secondary={t('map.legends.coverage.bureauOfReclamation.subtitle')}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar className={classes.departmentOfDefenseIcon}>
                <></>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('map.legends.coverage.departmentOfDefense.title')}
              secondary={t('map.legends.coverage.departmentOfDefense.subtitle')}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar className={classes.fishWildlifeIcon}>
                <></>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('map.legends.coverage.fishWildlife.title')}
              secondary={t('map.legends.coverage.fishWildlife.subtitle')}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar className={classes.forestIcon}>
                <></>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('map.legends.coverage.forestService.title')}
              secondary={t('map.legends.coverage.forestService.subtitle')}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar className={classes.nationalParkIcon}>
                <></>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('map.legends.coverage.nationalPark.title')}
              secondary={t('map.legends.coverage.nationalPark.subtitle')}
            />
          </ListItem>
        </>
      )}
      {mapLayers.includes(MapLayers.ELECTRIC_RETAIL_SERVICE) && (
        <>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar className={classes.electricRetailService}>
                <></>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('map.legends.coverage.electricRetailService.title')}
              secondary={t(
                'map.legends.coverage.electricRetailService.subtitle'
              )}
            />
          </ListItem>
        </>
      )}
      {mapLayers.includes(MapLayers.RESPONSIBILITY_AREAS) && (
        <>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar className={classes.responsibilityAreasFRA}>
                <></>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('map.legends.coverage.responsibilityAreasFRA.title')}
              secondary={t(
                'map.legends.coverage.responsibilityAreasFRA.subtitle'
              )}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar className={classes.responsibilityAreasSRA}>
                <></>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('map.legends.coverage.responsibilityAreasSRA.title')}
              secondary={t(
                'map.legends.coverage.responsibilityAreasSRA.subtitle'
              )}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar className={classes.responsibilityAreasLRA}>
                <></>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('map.legends.coverage.responsibilityAreasLRA.title')}
              secondary={t(
                'map.legends.coverage.responsibilityAreasLRA.subtitle'
              )}
            />
          </ListItem>
        </>
      )}
    </List>
  );
};

export default FireLegend;
