import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { API } from 'api';
import {
  ProcessDonationPaymentTokenData,
  ProcessInitialDonationPaymentTokenData,
  ProcessMembershipPaymentTokenData,
  ProcessMembershipUpgradeData
} from 'shared/types';

type UsePaymentApiProps = {
  onSuccess: () => void;
};

type UsePaymentApi<T> = {
  processMembershipPaymentMutation: UseMutationResult<void, Error, T, unknown>;
  processDonationPaymentMutation: UseMutationResult<void, Error, T, unknown>;
};

export const usePaymentApi = <
  T extends
    | ProcessMembershipPaymentTokenData
    | ProcessDonationPaymentTokenData
    | ProcessInitialDonationPaymentTokenData
    | ProcessMembershipUpgradeData
>(
  props: UsePaymentApiProps
): UsePaymentApi<T> => {
  const { onSuccess } = props;

  const processMembershipPaymentMutation = useMutation<void, Error, T, unknown>(
    {
      mutationFn: async (data) => {
        if ('upgradePlanCode' in data) {
          await API.post('recurly_integration/upgrade_membership/', data);
        } else {
          await API.post('recurly_integration/process_payment_token/', data);
        }
      },
      onSuccess
    }
  );

  const processDonationPaymentMutation = useMutation<void, Error, T, unknown>({
    mutationFn: async (data) => {
      if ('userId' in data && 'verificationToken' in data) {
        await API.post('recurly_integration/one_time_donation/', data);
      } else {
        await API.post('recurly_integration/initial_donation/', data);
      }
    },
    onSuccess
  });

  return { processMembershipPaymentMutation, processDonationPaymentMutation };
};
