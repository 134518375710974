// Use a static glyphs URL with known fonts. This URL contains:
//
// * Arial Unicode MS Regular
// * Arial Unicode MS Bold
//
// Then, ensure that all layer styles utilize fonts from this set of glyphs.

export const FONT_NAMES = {
  regular: ['Arial Unicode MS Regular'],
  bold: ['Arial Unicode MS Bold']
};

export const GEO_EVENT_SOURCE_ID = 'geo-events';

export const PLACE_LABELS_SOURCE_ID = 'place-labels';

export const LOCATIONS_SOURCE_ID = 'locations';

export const BEFORE_LAYER_ID = 'wd-inject-layers-below';

export const GEO_EVENT_ICONS_LAYER_ID = 'geo-event-icons';

export const LOCATION_ICONS_LAYER_ID = 'location-icons';
