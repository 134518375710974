import { LayerProps } from 'react-map-gl/maplibre';
import { DataDrivenPropertyValueSpecification } from 'maplibre-gl';
import {
  MIN_ZOOM_VISIBILITY,
  RESPONSIBILITY_AREAS_OPACITY,
  RESPONSIBILITY_AREAS_SOURCE_LAYER,
  RESPONSIBILITY_AREAS
} from './constants';

const colorExpression:
  | DataDrivenPropertyValueSpecification<string>
  | undefined = [
  'case',
  ['==', ['get', 'SRA'], RESPONSIBILITY_AREAS.FRA.id],
  RESPONSIBILITY_AREAS.FRA.color,
  ['==', ['get', 'SRA'], RESPONSIBILITY_AREAS.SRA.id],
  RESPONSIBILITY_AREAS.SRA.color,
  RESPONSIBILITY_AREAS.LRA.color
];

export const layerStrokeStyle: LayerProps = {
  type: 'line',
  'source-layer': RESPONSIBILITY_AREAS_SOURCE_LAYER,
  minzoom: MIN_ZOOM_VISIBILITY,
  paint: {
    'line-color': colorExpression,
    'line-opacity': 1,
    'line-width': ['interpolate', ['linear'], ['zoom'], 10, 0, 18, 10]
  }
};

export const layerFillStyle: LayerProps = {
  type: 'fill',
  'source-layer': RESPONSIBILITY_AREAS_SOURCE_LAYER,
  minzoom: MIN_ZOOM_VISIBILITY,
  paint: {
    'fill-color': colorExpression,
    'fill-opacity': RESPONSIBILITY_AREAS_OPACITY
  }
};
